var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('report-image-damage-detail-card', {
    attrs: {
      "minimum": 2,
      "screen": _vm.screen
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }