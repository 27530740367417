var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card-text', [_c('template-card', {
    ref: "templateInput",
    staticClass: "ma-n2",
    staticStyle: {
      "height": "100%"
    },
    style: "".concat(_vm.isLoading || _vm.isSent ? 'display:none' : ''),
    attrs: {
      "partnerId": _vm.partner._id || _vm.partner.id,
      "from": [_vm.from],
      "to": _vm.to,
      "context": _vm.context,
      "isEditorMobile": _vm.isEditorMobile
    },
    on: {
      "isValid": function isValid(event) {
        return _vm.isValid = event;
      }
    }
  }), _vm.isLoading && !_vm.isSent ? _c('div', [_c('v-row', {
    staticClass: "mr-2 ml-2 mt-3"
  }, [_c('v-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "text"
    }
  })], 1), _c('v-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "text"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mr-2 ml-2 mt-3"
  }, [_c('v-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "text"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "ma-2 mt-12"
  }, [_c('v-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "image"
    }
  })], 1)], 1)], 1) : _vm._e(), !_vm.isLoading && _vm.isSent ? _c('div', {
    staticClass: "mt-12"
  }, [_c('v-img', {
    attrs: {
      "align": "center",
      "justify": "center",
      "contain": "",
      "max-height": "250px",
      "src": require("@/assets/partner_contact_success.svg")
    }
  }), _c('v-card-actions', [_c('v-spacer'), _vm._v(" " + _vm._s(_vm.$t("components.partner.UserAdministrationResendInvitationDialog.success")) + " "), _c('v-spacer')], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "ma-1 mt-5",
    attrs: {
      "color": "success"
    },
    on: {
      "click": function click($event) {
        _vm.isSent = false;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerManageStepper.StepLayout.continue")) + " ")]), _c('v-spacer')], 1)], 1) : _vm._e()], 1), !_vm.isSent ? _c('v-card-actions', {
    staticClass: "mt-n8"
  }, [_c('v-btn', {
    attrs: {
      "color": "success",
      "loading": _vm.isLoading,
      "elevation": "0",
      "small": "",
      "dense": "",
      "disabled": !_vm.isValid
    },
    on: {
      "click": function click($event) {
        return _vm.send();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.template.dialog.preview.send")) + " ")])], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }