










































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import FeatureMixin from "@/mixins/FeatureMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import draggable from "vuedraggable";

@Component({
  components: { draggable }
})
export default class ReportActivityBoxShareComponentScreenOrder extends mixins(
  DarkModeHighlightMixin,
  FeatureMixin,
  PermissionMixin
) {
  @Prop({ default: () => [] })
  screenSelection!: ReportScreenEnum[];

  @Prop({ default: false })
  readonly!: boolean;

  get defaultPartnerUrl() {
    return PartnerModule.partner.settings?.defaultUrl;
  }
}
