/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivPartnerLinkFinderControllerFindByGeocodeParamsGen,
  MrfiktivPartnerWithDistanceViewModelGen
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class PartnerLinkerFinderService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Partner Linker Finder Service
   * @name PartnerLinkFinderControllerFindByGeocode
   * @summary (Link - READ) Get links by location
   * @request GET:/partner/{partnerId}/link/byLocation
   * @response `200` `(MrfiktivPartnerWithDistanceViewModelGen)[]` The found records
   */
  partnerLinkFinderControllerFindByGeocode = (
    { partnerId, ...query }: MrfiktivPartnerLinkFinderControllerFindByGeocodeParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivPartnerWithDistanceViewModelGen[], any>({
      path: `/partner/${partnerId}/link/byLocation`,
      method: "GET",
      query: query,
      format: "json",
      ...params
    });
}
