




























import { deepCopy } from "@/lib/utility/deep-copy";
import { MrfiktivCreateInsuranceDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ReportModule } from "@/store/modules/report.store";
import { Component, Vue, Watch } from "vue-property-decorator";
import InsuranceForm from "./InsuranceForm.vue";

export enum InsuranceTypeEnum {
  /**
   * Personal and third party liability coverage aka Haftpflicht
   */
  MOTORISTS_THIRD_PARTY_LIABILITY_COVERAGE = "MOTORISTS_THIRD_PARTY_LIABILITY_COVERAGE",

  /**
   * fully comprehensive motor vehicle insurance (Vollkaskoversicherung) PROVIDES coverage for accidents that are the policyholder’s own fault, and for damage as a result of vandalism.
   */
  FULLY_COMPREHENSIVE_COVERAGE = "FULLY_COMPREHENSIVE_COVERAGE",

  /**
   * Partially comprehensive motor vehicle insurance (Teilkaskoversicherung) provides cover against theft, hailstorm damage and broken glass.
   */
  PARTIALLY_COMPREHENSIVE_COVERAGE = "PARTIALLY_COMPREHENSIVE_COVERAGE",

  /**
   * For example towing, delivery of replacement parts, but not repair costs), accident (for example rental car or hotel costs) or illness (return transport).
   * e.g. ADAC
   */
  ROADSIDE_ASSISTANCE_COVERAGE = "ROADSIDE_ASSISTANCE_COVERAGE",

  /**
   * Verkehrsrechtsschutzversicherung
   */
  MOTORISTS_LEGAL_PROTECTION = "MOTORISTS_LEGAL_PROTECTION",

  /**
   * Felgenversicherung
   */
  WHEEL_INSURANCE = "WHEEL_INSURANCE",

  /**
   * Others
   */
  OTHER = "OTHER"
}

@Component({
  components: { InsuranceForm }
})
export default class InsurancesForm extends Vue {
  valid = false;

  @Watch("valid", { deep: true })
  emitValid() {
    this.$emit("valid", this.valid);
  }

  get isDone() {
    return this.valid;
  }

  insurance: MrfiktivCreateInsuranceDtoGen = {
    name: "",
    number: "",
    type: undefined
  };

  /**
   * All available insurance types
   */
  get insuranceTypeItems() {
    return Object.values(InsuranceTypeEnum);
  }

  /**
   * Access to all already added insurance types
   */
  get addedInsuranceTypes() {
    return ReportModule.insuranceList.map(insurance => insurance.type);
  }

  /**
   * Displays the missing insurance types
   */
  get addableInsurances() {
    return this.insuranceTypeItems.filter(type => !this.addedInsuranceTypes.includes(type));
  }

  get list() {
    return ReportModule.insuranceList;
  }

  set list(list: MrfiktivCreateInsuranceDtoGen[]) {
    ReportModule.setInsuranceList(list);
  }

  add() {
    this.list.push(deepCopy(this.insurance));
  }

  remove(index: number) {
    this.list.splice(index, 1);
  }
}
