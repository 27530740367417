






























import { PartnerModule } from "@/store/modules/partner";
import { ExportModule } from "@/store/modules/export.store";
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class SendToPdrDialog extends Vue {
  isDialogActive = true;

  closeDialog() {
    this.isDialogActive = false;
  }

  async send() {
    try {
      await ExportModule.exportReportToPdr({
        partnerId: PartnerModule.partner._id,
        reportId: PartnerModule.report._id
      });

      this.$toast.success(this.$t("components.partner.PartnerReportDetail.SendToPdrDialog.success"));
    } catch (e) {
      this.$log.error(e);

      this.$toast.error(this.$t("components.partner.PartnerReportDetail.SendToPdrDialog.error"));
    }

    this.isDialogActive = false;
  }
}
