


















import { Component, Vue, Prop } from "vue-property-decorator";
import MDetailForm from "./mmmint/MDetailForm.vue";
import { setNestedObjectValues } from "@/lib/objectPath-helper";
import { CreateDto } from "@/lib/utility/data/create-dto.interface";
import { IFormableClass, MDetailFormConfig } from "@/lib/formable";

@Component({
  components: {
    MDetailForm
  },
  filters: {}
})
export default class CreateForm<T> extends Vue {
  /**
   * The dto class to create a new item
   */
  @Prop()
  dto!: { new (): CreateDto<T> } & IFormableClass;

  /**
   * Instance of dto class
   */
  model = new this.dto();

  isLoading = false;

  get config() {
    return this.dto.formables;
  }

  async mounted() {
    await this.abortChanges();
  }

  /**
   * Syncs the changes from the form to the value
   * and show the update confirmation dialog.
   */
  async syncChanges() {
    const config = new MDetailFormConfig(this.config);
    const configKeys = config.getConfigKeys();

    for (const key of configKeys) {
      setNestedObjectValues(this.model, key, config.getConfigValueByKey(key));
    }

    this.isLoading = true;
    const created = await this.model.create();
    this.$toast.success("👍");
    this.isLoading = false;
    this.$emit("created", created);
  }

  async abortChanges() {
    const config = new MDetailFormConfig(this.config);
    const configKeys = config.getConfigKeys();

    for (const key of configKeys) {
      config.configValueByKey = { key: key, value: this.model[key] };
    }
  }
}
