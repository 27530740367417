var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-col', {
    staticClass: "d-flex child-flex",
    attrs: {
      "cols": _vm.dense ? 12 : 2
    }
  }, [_vm.dense ? _c('v-row', {
    staticClass: "ma-0"
  }, [_c('v-card', {
    attrs: {
      "flat": "",
      "outlined": ""
    },
    on: {
      "click": _vm.startUpload
    }
  }, [!_vm.isUploading ? _c('v-icon', {
    attrs: {
      "large": ""
    }
  }, [_vm._v(" mdi-plus ")]) : _c('v-progress-circular', {
    attrs: {
      "indeterminate": ""
    }
  })], 1)], 1) : _c('v-row', {
    staticClass: "fill-height ma-0",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-img', {
    attrs: {
      "aspect-ratio": "1"
    },
    on: {
      "click": _vm.startUpload
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function fn() {
        return [_c('v-card', {
          staticStyle: {
            "height": "100%",
            "width": "100%"
          },
          attrs: {
            "flat": "",
            "outlined": ""
          }
        }, [_c('v-row', {
          staticClass: "fill-height ma-0",
          attrs: {
            "align": "center",
            "justify": "center"
          }
        }, [!_vm.isUploading ? _c('v-icon', {
          attrs: {
            "large": ""
          }
        }, [_vm._v(" mdi-plus ")]) : _c('v-progress-circular', {
          attrs: {
            "indeterminate": ""
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isConfirmDialogActive,
      "title": _vm.$t('timeLine.PartnerReportDetailImageUpload.upload'),
      "rightText": _vm.$t('timeLine.PartnerReportDetailImageUpload.confirm'),
      "leftText": _vm.$t('timeLine.PartnerReportDetailImageUpload.abort'),
      "fullscreen": _vm.$vuetify.breakpoint.mdAndDown
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isConfirmDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isConfirmDialogActive = $event;
      },
      "leftClick": function leftClick($event) {
        _vm.isConfirmDialogActive = true;
      },
      "rightClick": _vm.confirmType
    }
  }, [_c('v-select', {
    attrs: {
      "outlined": "",
      "items": _vm.reportImageTypes,
      "label": _vm.$t('timeLine.PartnerReportDetailImageUpload.imageType')
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(_vm.$t("timeLine.PartnerReportDetailImageTimeLineElement.imageTypes.".concat(item))) + " ")];
      }
    }, {
      key: "selection",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm.$t("timeLine.PartnerReportDetailImageTimeLineElement.imageTypes.".concat(item))) + " ")];
      }
    }]),
    model: {
      value: _vm.imageType,
      callback: function callback($$v) {
        _vm.imageType = $$v;
      },
      expression: "imageType"
    }
  })], 1), _c('input', {
    ref: "uploader",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "data-test-report-image-upload": "",
      "name": "file",
      "type": "file",
      "accept": _vm.pdfInputTypes
    },
    on: {
      "change": _vm.onFileInputEvent
    }
  }), _c('pdf-upload-dialog', {
    attrs: {
      "isDialog": _vm.pdfDialog,
      "pdf": _vm.pdf
    },
    on: {
      "closePdfDialog": _vm.closePdfDialog,
      "onImageExport": _vm.onImageExport
    }
  }), _c('camera-bottom-sheet', {
    ref: "sheet",
    on: {
      "camera-clicked": function cameraClicked($event) {
        _vm.isCameraDialogActive = true;
      },
      "library-clicked": function libraryClicked($event) {
        return _vm.$refs.uploader.click();
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }