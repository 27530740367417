import attachmentService from "@/services/mrfiktiv/services/attachmentService";
import {
  MrfiktivAttachmentResponseDtoGen,
  MrfiktivAttachmentResponseViewModelGen,
  MrfiktivReportViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { ResourceEnum } from "../enum/authResourceEnum";
import store from "../VuexPlugin";

export class AttachmentResponseViewModel {
  constructor(private readonly attachmentResponse: MrfiktivAttachmentResponseViewModelGen) {}

  getReport() {
    return this.attachmentResponse.shared.find(shared => shared.refType === ResourceEnum.REPORT)
      ?.document as MrfiktivReportViewModelGen;
  }
}

@Module({
  dynamic: true,
  namespaced: true,
  name: "attachment-response",
  store
})
class AttachmentResponseStore extends VuexModule {
  protected _attachmentRequestId: string | null = null;
  protected _attachmentRequestToken: string | null = null;
  protected _attachmentResponse: MrfiktivAttachmentResponseViewModelGen | null = null;

  get attachmentRequestId() {
    return this._attachmentRequestId;
  }

  get attachmentRequestToken() {
    return this._attachmentRequestToken;
  }

  get attachmentResponse() {
    return this._attachmentResponse;
  }

  @Mutation
  protected _mutateAttachmentRequestId(attachmentRequestId: string) {
    this._attachmentRequestId = attachmentRequestId;
  }

  @Mutation
  protected _mutateAttachmentRequestToken(attachmentRequestToken: string) {
    this._attachmentRequestToken = attachmentRequestToken;
  }

  @Mutation
  protected _mutateAttachmentResponse(attachmentResponse: MrfiktivAttachmentResponseViewModelGen) {
    this._attachmentResponse = attachmentResponse;
  }

  @Action
  setAttachmentRequestId(attachmentRequestId: string) {
    this.context.commit("_mutateAttachmentRequestId", attachmentRequestId);
  }

  @Action
  setAttachmentRequestToken(attachmentRequestToken: string) {
    this.context.commit("_mutateAttachmentRequestToken", attachmentRequestToken);
  }

  @Action
  async get({
    partnerId,
    attachmentId,
    accessToken
  }: {
    partnerId: string;
    attachmentId: string;
    accessToken: string;
  }): Promise<MrfiktivAttachmentResponseViewModelGen> {
    const gotten = await attachmentService.get(partnerId, attachmentId, accessToken);

    this.context.commit("_mutateAttachmentResponse", gotten);

    return gotten;
  }

  @Action
  async attach({
    partnerId,
    attachmentId,
    accessToken,
    data
  }: {
    partnerId: string;
    attachmentId: string;
    accessToken: string;
    data: MrfiktivAttachmentResponseDtoGen;
  }): Promise<MrfiktivAttachmentResponseViewModelGen> {
    const attached = await attachmentService.attach(partnerId, attachmentId, accessToken, data);

    this.context.commit("_mutateAttachmentResponse", attached);

    return attached;
  }
}

export const AttachmentResponseModule = getModule(AttachmentResponseStore);
