var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.isLoading ? _c('v-progress-linear', {
    attrs: {
      "indeterminate": ""
    }
  }) : _vm._e(), _c('m-detail-form', {
    attrs: {
      "extraPanelsCount": 1,
      "config": _vm.config,
      "item": _vm.model,
      "containButtons": true,
      "abortChanges": _vm.abortChanges,
      "syncChanges": _vm.syncChanges
    },
    scopedSlots: _vm._u([{
      key: "extraPanels",
      fn: function fn() {
        return [_vm._t("extraPanels", null, null, {
          item: _vm.model
        })];
      },
      proxy: true
    }], null, true)
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }