import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { Cost, ICost } from "@/models/cost.entity";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";

export const CostDataAccessLayer = new (class extends AbstractLocalDataAccessLayer<ICost, "vehicleId"> {
  protected mapEntity(entity: ICost): ICost {
    return new Cost(entity);
  }

  getIdentifier(entity: ICost): string {
    return entity.id;
  }

  mapsConfig = {
    vehicleId: {
      getIdentifier: (entity: ICost) =>
        entity.refs.filter(r => r.refType === BackendResourceEnum.VEHICLE).map(r => r.refId) || []
    }
  };
})();
