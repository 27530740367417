import { IKsrJobDetailViewmodel } from "@/lib/interfaces/ksr/ksr-job-detail-viewmodel.interface";
import ksrService from "@/services/mrfiktiv/services/ksrService";
import {
  MrfiktivKsrContactInfoViewModelGen,
  MrfiktivKsrControllerGetCustomerContactInfoParamsGen,
  MrfiktivKsrCustomerInfoViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import Vue from "vue";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { ExportModule } from "./export.store";
import { PartnerModule } from "./partner";
import { ReportModule } from "./report.store";

/**
 * Manages login inputs to prefill input fields
 */
@Module({
  dynamic: true,
  namespaced: true,
  name: "ksr",
  store
})
class Ksr extends VuexModule {
  /**
   * Value of mail field
   */
  _ksrCustomerInfo: MrfiktivKsrCustomerInfoViewModelGen = {};

  get ksrCustomerInfo(): MrfiktivKsrCustomerInfoViewModelGen {
    return this._ksrCustomerInfo;
  }

  @Mutation
  private _mutateKsrCustomerInfo(ksrCustomerInfo: MrfiktivKsrCustomerInfoViewModelGen) {
    this._ksrCustomerInfo = ksrCustomerInfo;
  }

  @Action
  async getUserDetails(customerIdentityToken: string): Promise<void> {
    const partnerId =
      PartnerModule.partner._id || PartnerModule.partner.id || ReportModule.partner._id || ReportModule.partner.id;
    if (!partnerId) {
      Vue.$log.error(
        "Partner id is not set when trying to get user details using customerIdentityToken in getUserDetails in ksr.module"
      );
      return;
    }
    const res = await ksrService.getUserDetails(partnerId, customerIdentityToken);
    this.context.commit("_mutateKsrCustomerInfo", res);
  }

  private _contact: Promise<MrfiktivKsrContactInfoViewModelGen[]>[] = [];
  get contact() {
    return this._contact;
  }
  @Mutation
  mutateContact(contact: Promise<MrfiktivKsrContactInfoViewModelGen[]>) {
    this._contact = [contact];
  }
  @Action
  getCustomerContactInfo(
    data: Partial<MrfiktivKsrControllerGetCustomerContactInfoParamsGen> & {
      partnerId: string;
    }
  ) {
    const contact = ExportModule.getCustomerContactInfo({
      email: data.email ?? "",
      phoneNumber: data.phoneNumber ?? "",
      partnerId: data.partnerId
    });

    this.context.commit("mutateContact", contact);

    return contact;
  }

  private _jobs: Promise<IKsrJobDetailViewmodel[]>[] = [];
  get jobs() {
    return this._jobs;
  }
  @Mutation
  mutateJobs(jobs: Promise<IKsrJobDetailViewmodel[]>) {
    this._jobs.splice(0, this._jobs.length, jobs);
  }
  @Action
  getKsrDocuments(data: { partnerId: string; numberPlate: string; amount: number }) {
    const jobs = ExportModule.getKsrDocuments(data);

    this.context.commit("mutateJobs", jobs);

    return jobs;
  }
}

export const KsrModule = getModule(Ksr);
