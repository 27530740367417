var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-report-step', {
    attrs: {
      "isDone": _vm.isDone,
      "title": _vm.header.title,
      "description": _vm.header.description,
      "image": _vm.header.image,
      "debug": _vm.header.debug
    },
    on: {
      "next": _vm.setInsurance
    }
  }, [_c('v-card', {
    staticClass: "pt-4 pr-4 pl-4",
    attrs: {
      "flat": "",
      "max-width": "500"
    }
  }, [_c('v-form', {
    ref: "form",
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-card-text', [_vm._v(_vm._s(_vm.$t(_vm.question)))]), _c('v-radio-group', {
    model: {
      value: _vm.hasInsurance,
      callback: function callback($$v) {
        _vm.hasInsurance = $$v;
      },
      expression: "hasInsurance"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": _vm.$t('report.motoristLegalInsurance.no'),
      "value": false
    }
  }), _c('v-radio', {
    attrs: {
      "label": _vm.$t('report.motoristLegalInsurance.yes'),
      "value": true
    }
  })], 1), _vm.hasInsurance ? _c('div', [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "required": "",
      "rules": _vm.requiredRule,
      "label": _vm.$t('report.insurance.name') + ' *',
      "data-test-insurance-name": ""
    },
    model: {
      value: _vm.insurance.name,
      callback: function callback($$v) {
        _vm.$set(_vm.insurance, "name", $$v);
      },
      expression: "insurance.name"
    }
  }), _c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": _vm.$t('report.insurance.number'),
      "data-test-insurance-number": ""
    },
    model: {
      value: _vm.insurance.number,
      callback: function callback($$v) {
        _vm.$set(_vm.insurance, "number", $$v);
      },
      expression: "insurance.number"
    }
  })], 1) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }