var render = function () {
  var _vm$selectedPartner, _vm$selectedPartner$a, _vm$selectedPartner2, _vm$selectedPartner2$;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-report-step', {
    attrs: {
      "isDone": _vm.isDone,
      "title": _vm.header.title,
      "description": _vm.header.description,
      "image": _vm.header.image
    }
  }, [_c('template', {
    slot: "header"
  }, [_vm.selectedPartner && _vm.isMapShown ? _c('partner-map', {
    attrs: {
      "coordinates": (_vm$selectedPartner = _vm.selectedPartner) === null || _vm$selectedPartner === void 0 ? void 0 : (_vm$selectedPartner$a = _vm$selectedPartner.address) === null || _vm$selectedPartner$a === void 0 ? void 0 : _vm$selectedPartner$a.geo,
      "colour": (_vm$selectedPartner2 = _vm.selectedPartner) === null || _vm$selectedPartner2 === void 0 ? void 0 : (_vm$selectedPartner2$ = _vm$selectedPartner2.settings) === null || _vm$selectedPartner2$ === void 0 ? void 0 : _vm$selectedPartner2$.color
    }
  }) : _vm._e(), _c('debug', {
    attrs: {
      "debug": _vm.partners
    }
  })], 1), _c('v-row', [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "loading": _vm.loading,
      "prepend-inner-icon": "mdi-magnify",
      "autofocus": "",
      "hint": _vm.$t('confirmSearchWithEnter'),
      "label": _vm.$t('objects.partner.address')
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        $event.preventDefault();
        return _vm.debounceFind.apply(null, arguments);
      }
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c('v-row', [_c('v-item-group', {
    attrs: {
      "active-class": "primary",
      "mandatory": ""
    },
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, [_vm._l(_vm.partners, function (item, i) {
    return [_c('v-item', {
      key: i,
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref) {
          var active = _ref.active,
              toggle = _ref.toggle;
          return [_c('v-sheet', {
            staticClass: "mb-2 py-n6",
            class: active ? 'primary' : '',
            attrs: {
              "outlined": "",
              "rounded": ""
            }
          }, [_c('v-card', {
            attrs: {
              "flat": ""
            },
            on: {
              "click": toggle
            }
          }, [_c('v-list', {
            attrs: {
              "three-line": ""
            }
          }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(item.companyName) + " "), item.distance ? _c('v-chip', {
            attrs: {
              "color": active ? 'primary' : 'grey',
              "x-small": ""
            }
          }, [_vm._v(" " + _vm._s(Math.round(item.distance * 10) / 10) + " km ")]) : _vm._e()], 1), _c('v-list-item-subtitle', [_c('v-icon', {
            attrs: {
              "small": ""
            }
          }, [_vm._v("mdi-map-marker-outline")]), _vm._v(" " + _vm._s(item.address.street) + ", " + _vm._s(item.address.zip) + " " + _vm._s(item.address.city) + " ")], 1), _c('v-list-item-subtitle', [_c('v-icon', {
            attrs: {
              "small": ""
            }
          }, [_vm._v("mdi-email-outline")]), _vm._v(" " + _vm._s(item.settings.contact.email) + " ")], 1), _c('v-list-item-subtitle', [_c('v-icon', {
            attrs: {
              "small": ""
            }
          }, [_vm._v("mdi-phone-outline")]), _vm._v(_vm._s(item.settings.contact.phone) + " ")], 1)], 1), _c('v-list-item-action', [_c('v-icon', {
            attrs: {
              "color": active ? 'primary' : ''
            }
          }, [_vm._v(" " + _vm._s(active ? "mdi-radiobox-marked" : "mdi-radiobox-blank") + " ")])], 1)], 1)], 1)], 1)], 1)];
        }
      }], null, true)
    })];
  })], 2), _c('debug', {
    attrs: {
      "debug": _vm.partners
    }
  })], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }