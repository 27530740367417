var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('m-header', {
    attrs: {
      "subtitle": _vm.subtitle,
      "actions": _vm.actions,
      "breadCrumbs": _vm.breadCrumbs
    },
    on: {
      "actionClicked": _vm.processAction
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [!_vm.isEditTitle ? _c('div', {
          staticClass: "text-md-h4 title text-wrap mb-2"
        }, [_vm._v(" " + _vm._s(_vm.value.title) + " ")]) : _c('v-card', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-card-text', {
          staticClass: "pa-0 mb-n6"
        }, [_c('v-text-field', {
          attrs: {
            "label": _vm.$t('project.ticket.title'),
            "dense": "",
            "required": "",
            "outlined": ""
          },
          model: {
            value: _vm.editTitle,
            callback: function callback($$v) {
              _vm.editTitle = $$v;
            },
            expression: "editTitle"
          }
        })], 1), _c('v-card-actions', {
          staticClass: "mt-n4"
        }, [_c('v-btn', {
          staticClass: "mr-2",
          attrs: {
            "x-small": "",
            "elevation": "0",
            "color": "info",
            "disabled": !_vm.editTitle
          },
          on: {
            "click": _vm.updateTitle
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")]), _c('v-btn', {
          attrs: {
            "x-small": "",
            "outlined": "",
            "color": "info"
          },
          on: {
            "click": _vm.cancelUpdateTitle
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("discard")) + " ")])], 1)], 1)];
      },
      proxy: true
    }, {
      key: "subtitle",
      fn: function fn() {
        return [_c('div', {
          staticStyle: {
            "display": "flex",
            "align-items": "center"
          }
        }, [_vm._v(" " + _vm._s(_vm.subtitle) + " "), _c('v-menu', {
          attrs: {
            "offset-y": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on,
                  attrs = _ref.attrs;
              return [_c('v-chip', _vm._g(_vm._b({
                staticClass: "ml-2",
                attrs: {
                  "color": _vm.value.isExpired ? 'error' : 'success',
                  "dark": "",
                  "small": ""
                }
              }, 'v-chip', attrs, false), on), [_vm._v(" " + _vm._s(_vm.validBy) + " ")])];
            }
          }])
        }, [_c('v-card', {
          staticClass: "pa-1",
          attrs: {
            "elevation": 0,
            "loading": _vm.isEntityLoading,
            "close-on-content-click": false
          }
        }, [_c('v-date-picker', {
          attrs: {
            "min": _vm.datePickerMinimum
          },
          on: {
            "change": _vm.debounceUpdate
          },
          model: {
            value: _vm.validByDatePickerValue,
            callback: function callback($$v) {
              _vm.validByDatePickerValue = $$v;
            },
            expression: "validByDatePickerValue"
          }
        }), _c('v-btn', {
          attrs: {
            "block": "",
            "text": ""
          },
          on: {
            "click": function click($event) {
              _vm.value.validBy = null;

              _vm.debounceUpdate();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("timeLine.ActivityTimeLineItemShare.validIndefinitelyBtn")) + " ")])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  }), !_vm.value ? _c('v-card-actions', [_c('latest-entries-card-empty', {
    staticClass: "mt-5 mb-5"
  })], 1) : _vm._e(), _vm.value ? _c('div', [_c('div', {
    class: !_vm.isEditDescription ? 'mb-2 mt-2' : 'mt-2',
    staticStyle: {
      "display": "flex",
      "align-items": "center",
      "height": "35px"
    }
  }, [_c('v-spacer'), !_vm.isEditDescription ? _c('v-btn', {
    staticClass: "mr-1",
    attrs: {
      "x-small": "",
      "text": ""
    },
    on: {
      "click": _vm.startEditDescription
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("edit")) + " ")]) : _vm._e()], 1), !_vm.isEditDescription ? _c('v-textarea', {
    staticClass: "mx-2 mb-n4",
    attrs: {
      "readonly": "",
      "outlined": ""
    },
    model: {
      value: _vm.value.description,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "description", $$v);
      },
      expression: "value.description"
    }
  }) : _c('v-textarea', {
    staticClass: "mx-2 mb-n4",
    attrs: {
      "outlined": ""
    },
    model: {
      value: _vm.editDescription,
      callback: function callback($$v) {
        _vm.editDescription = $$v;
      },
      expression: "editDescription"
    }
  }), _vm.isEditDescription ? _c('v-card-actions', {
    staticClass: "px-4 mt-n1"
  }, [_c('v-btn', {
    staticClass: "mr-2",
    attrs: {
      "x-small": "",
      "elevation": "0",
      "color": "info"
    },
    on: {
      "click": _vm.updateDescription
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")]), _c('v-btn', {
    attrs: {
      "x-small": "",
      "outlined": "",
      "color": "info"
    },
    on: {
      "click": _vm.cancelUpdateDescription
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("discard")) + " ")])], 1) : _vm._e()], 1) : _vm._e(), _vm.value ? _c('v-card-actions', [!_vm.value.refs.length ? _c('v-spacer') : _vm._e(), _c('refs-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "partnerId": _vm.value.partnerId,
      "loading": _vm.value.isLoading,
      "suggested": _vm.suggested,
      "hideSuggestions": !_vm.suggested.length,
      "hideEditButton": false,
      "categories": _vm.referenceCategories
    },
    on: {
      "change": _vm.debounceUpdate,
      "input": _vm.debounceUpdate
    },
    model: {
      value: _vm.value.refs,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "refs", $$v);
      },
      expression: "value.refs"
    }
  })], 1) : _vm._e(), _vm.value && !_vm.value.refs.length ? _c('v-card-actions', [_c('v-card', {
    staticClass: "mt-n2",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "outlined": ""
    }
  }, [_c('latest-entries-card-empty', {
    staticClass: "my-5 mx-auto"
  })], 1)], 1) : _vm._e(), _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isDeleteDialogActive,
      "title": _vm.$t('components.fleet.FleetVehicleDetailContextMenu.confirmDeletionTitle'),
      "rightLoading": _vm.isEntityLoading
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "leftClick": function leftClick($event) {
        _vm.isDeleteDialogActive = false;
      },
      "rightClick": _vm.onDelete
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }