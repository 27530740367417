
import { debounce } from "debounce";
import { Component, Vue } from "vue-property-decorator";

/**
 * Bundles methods to handle assignee changes and emits events to add or remove assignees.
 * add a watcher for handleAssigneesChange and implement onAssigneeChange
 */
export interface IAssigneeUpdateMixin {
  /**
   * method that will trigger the changeAssignees callback after a debounce
   * @param after
   * @param before
   */
  changeAssignees(after: string[], before: string[], all: string[], documentId?: string): void;
}

/**
 * Bundles methods to handle assignee changes and emits events to add or remove assignees.
 * add a watcher for handleAssigneesChange and implement changeAssignees
 */
@Component({})
export default class AssigneeUpdateMixin extends Vue implements IAssigneeUpdateMixin {
  assigneeAddLog: string[] = [];
  assigneeRemoveLog: string[] = [];
  assigneeAll: string[] = [];

  /**
   * Method to handle assignee changes. Needs to be implemented in the component.
   * @param added All added assignees
   * @param removed All removed assignees
   * @param all All current assignees
   * @param documentId The document id of the current document
   */
  changeAssignees(added: string[], removed: string[], all: string[], documentId?: string) {
    // implement in component
    throw Error(
      "Method not implemented: changeAssignees (+" +
        added.join(",") +
        ", -" +
        removed.join(",") +
        all.join(",") +
        documentId +
        ")"
    );
  }

  private emitAssigneeChanges(documentId?: string) {
    // count how often a user was added
    const assigneeAddCount = this.assigneeAddLog.reduce((acc, a) => {
      acc[a] = (acc[a] || 0) + 1;
      return acc;
    }, {} as Record<string, number>);

    // count how often a user was removed
    const assigneeRemoveCount = this.assigneeRemoveLog.reduce((acc, a) => {
      acc[a] = (acc[a] || 0) + 1;
      return acc;
    }, {} as Record<string, number>);

    // filter out assignees that were removed more often than added and filter out duplicates
    const assigneeRemoveLog = Array.from(
      new Set(this.assigneeRemoveLog.filter(a => assigneeRemoveCount[a] > (assigneeAddCount[a] || 0)))
    );

    // filter out assignees that were added more often than removed and filter out duplicates
    const assigneeAddLog = Array.from(
      new Set(this.assigneeAddLog.filter(a => assigneeAddCount[a] > (assigneeRemoveCount[a] || 0)))
    );

    // emit events
    this.changeAssignees(assigneeAddLog, assigneeRemoveLog, this.assigneeAll, documentId);

    this.assigneeRemoveLog = [];
    this.assigneeAddLog = [];
  }

  private debounceEmitAssigneeChanges = debounce(this.emitAssigneeChanges, 1500);

  /**
   * method that will trigger the changeAssignees callback after a debounce
   * @param after
   * @param before
   * @param documentId
   */
  debounceChangeAssignees(after: string[], before: string[], documentId?: string) {
    // set current assignees
    this.assigneeAll = after;

    // added assignees
    for (const a of after) {
      if (!before.includes(a)) {
        this.assigneeAddLog.push(a);
      }
    }

    // removed assignees
    for (const b of before) {
      if (!after.includes(b)) {
        this.assigneeRemoveLog.push(b);
      }
    }

    // emit assignee changes
    this.debounceEmitAssigneeChanges(documentId);
  }
}
