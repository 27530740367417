import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { AttachmentRequest, IAttachmentRequest } from "@/models/attachment-request.entity";

export const AttachmentRequestDataAccessLayer = new (class AttachmentRequestDataAccessLayer extends AbstractLocalDataAccessLayer<
  IAttachmentRequest
> {
  getIdentifier(entity: IAttachmentRequest): string {
    return entity.id;
  }

  protected mapEntity(entity: IAttachmentRequest): IAttachmentRequest {
    return new AttachmentRequest(entity);
  }
})();
