
































import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import { requiredRule } from "@/lib/rules/requiredRule";
import ReportStepMixin from "@/mixins/ReportStepMixin.vue";
import { MrfiktivCreatePoliceDetailsDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { ReportModule } from "@/store/modules/report.store";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    LayoutReportStep
  }
})
export default class ReportPolice extends ReportStepMixin implements IReportStep {
  get header(): IReportHeaderData {
    return {
      title: this.$t("report.policeDetails.title").toString(),
      description: this.$t("report.policeDetails.description").toString()
    };
  }
  screen = ReportScreenEnum.police;

  valid = false;

  get requiredRule() {
    const rules = [];

    rules.push(requiredRule());

    return rules;
  }

  get policeDetails() {
    return ReportModule.policeDetails;
  }

  set policeDetails(details: MrfiktivCreatePoliceDetailsDtoGen) {
    ReportModule.mutatePoliceDetails(details);
  }

  get isDone() {
    return this.valid;
  }

  get color() {
    return getDefaultPartnerColor();
  }
}
