



























import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { AssetEnum } from "@/lib/AssetRepository";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportInstructionData } from "@/lib/interfaces/Report/IReportInstructionData";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import ReportImageSubmission from "@/mixins/ReportImageSubmissionMixin.vue";
import { IImageUploaded } from "@/models/Image/IImageUploaded";
import { ReportImageType } from "@/models/Report/ReportImageType";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { ReportType } from "@/store/enum/reportType";
import { ReportModule } from "@/store/modules/report.store";
import { Component } from "vue-property-decorator";
import ReportImageUpload from "./ReportImageUpload.vue";

@Component({
  components: {
    ReportImageUpload,
    LayoutReportStep
  }
})
export default class ReportImageDriversLicense extends ReportImageSubmission implements IReportStep {
  assetFront = AssetEnum.driversLicenseExampleFrontOutlined;
  exampleFront = AssetEnum.driversLicenseExampleFront;

  screen = ReportScreenEnum.driverslicense;

  assetBack = AssetEnum.driversLicenseExampleBackOutlined;
  exampleBack = AssetEnum.driversLicenseExampleBack;

  instruction: IReportInstructionData = {
    title: "",
    text: "",
    pictures: [AssetEnum.registration],
    example: AssetEnum.registration,
    display: false
  };

  instructionBack: IReportInstructionData = {
    title: "",
    text: "",
    pictures: [AssetEnum.registrationAtBack],
    example: AssetEnum.registrationAtBack,
    display: false
  };

  readonly driversLicenseFrontKey = "driversLicenseFront";
  readonly driversLicenseBackKey = "driversLicenseBack";

  get frontImageCount() {
    return this.files.filter(i => i.categoryKey === this.driversLicenseFrontKey).length;
  }
  get backImageCount() {
    return this.files.filter(i => i.categoryKey === this.driversLicenseBackKey).length;
  }

  get header(): IReportHeaderData {
    return {
      title: "report.imageDriversLicense.title",
      description: this.description,
      debug: this.files
    };
  }

  get typeFront() {
    return ReportImageType.driverslicensefront;
  }

  get typeBack() {
    return ReportImageType.driverslicenseback;
  }

  get isOptional(): boolean {
    return [ReportType.OTHER].includes(ReportModule.reportType);
  }

  get description(): string {
    if (this.isOptional) {
      return "report.imageDriversLicense.optional";
    }
    return "report.imageDriversLicense.description";
  }

  /**
   * @inheritdoc
   */
  get files(): IImageUploaded[] {
    return ReportModule.overviews;
  }

  /**
   * the following behavior is expected
   * both, front and back side are optional, if the report is of type: others, bycicle, container
   * in all other cases: both, front and back side are mandatory fields
   */
  get isDone() {
    if ([ReportType.OTHER, ReportType.BICYCLE, ReportType.CONTAINER].includes(ReportModule.reportType)) {
      return true;
    }

    return this.frontImageCount > 0 && this.backImageCount > 0;
  }
}
