/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */
import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import reportControlService from "@/services/mrfiktiv/services/reportControlService";
import {
  MrfiktivPartnerWithDistanceViewModelGen,
  MrfiktivReportControlControllerFindAllParamsGen,
  MrfiktivReportControlViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, Module, Mutation, getModule } from "vuex-module-decorators";
import { PaginatedBaseStore } from "../paginated-base.store";

export class ReportControlDataAccessLayer extends AbstractLocalDataAccessLayer<MrfiktivReportControlViewModelGen> {
  getIdentifier(entity: MrfiktivReportControlViewModelGen): string {
    return entity.id;
  }
}

export class ReportControlPageDataProvider extends AbstractPageDataProvider<
  MrfiktivReportControlViewModelGen,
  MrfiktivReportControlControllerFindAllParamsGen
> {
  itemsPerPage = 25;

  async getPage(
    query: MrfiktivReportControlControllerFindAllParamsGen
  ): Promise<IPageViewModel<MrfiktivReportControlViewModelGen>> {
    const res = await reportControlService.getAll(query);

    return res as IPageViewModel<MrfiktivReportControlViewModelGen>;
  }
}

/**
 * Example store for testing
 */
@Module({
  dynamic: true,
  namespaced: true,
  name: "report-control",
  store
})
export class ReportControlStore extends PaginatedBaseStore<
  MrfiktivReportControlViewModelGen,
  MrfiktivReportControlControllerFindAllParamsGen
> {
  _data = new ReportControlDataAccessLayer();
  _pageProvider = new ReportControlPageDataProvider();

  _pager = new PageDataHandler(this._data, this._pageProvider);

  _selected: MrfiktivPartnerWithDistanceViewModelGen | null = null;

  @Action
  setSelected(item: MrfiktivPartnerWithDistanceViewModelGen) {
    this.context.commit("_mutateSelected", item);
  }

  @Mutation
  _mutateSelected(item: MrfiktivPartnerWithDistanceViewModelGen) {
    this._selected = item;
  }

  get selected() {
    return this._selected;
  }
}

export const ReportControlModule = getModule(ReportControlStore);
