var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-form', {
    ref: "form",
    model: {
      value: _vm.isValid,
      callback: function callback($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_vm._l(_vm.reportDto.witness || [], function (witness, index) {
    return _c('card', {
      key: 'witness' + index,
      staticClass: "mt-1 mb-1",
      attrs: {
        "margin": 0,
        "flat": "",
        "outlined": "",
        "hideTitle": true,
        "subtitle": _vm.$t('components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.witnesses.title', {
          number: index + 1
        })
      },
      scopedSlots: _vm._u([{
        key: "actions",
        fn: function fn() {
          return [_c('v-btn', {
            attrs: {
              "text": "",
              "data-test-witness-remove-btn": ""
            },
            on: {
              "click": function click($event) {
                return _vm.remove(index);
              }
            }
          }, [_vm._v(" " + _vm._s(_vm.$t("close")) + " ")])];
        },
        proxy: true
      }], null, true)
    }, [_c('v-row', {
      staticClass: "ma-1",
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-col', {
      staticClass: "pt-2 pl-2 pr-2",
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('v-text-field', {
      attrs: {
        "label": _vm.$t('components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.witness.firstName'),
        "flat": "",
        "outlined": "",
        "rules": _vm.requiredRule,
        "data-test-witness-first-name": ""
      },
      model: {
        value: witness.firstName,
        callback: function callback($$v) {
          _vm.$set(witness, "firstName", $$v);
        },
        expression: "witness.firstName"
      }
    })], 1), _c('v-col', {
      staticClass: "pt-2 pl-2 pr-2",
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('v-text-field', {
      attrs: {
        "label": _vm.$t('components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.witness.lastName'),
        "flat": "",
        "outlined": "",
        "rules": _vm.requiredRule,
        "data-test-witness-last-name": ""
      },
      model: {
        value: witness.lastName,
        callback: function callback($$v) {
          _vm.$set(witness, "lastName", $$v);
        },
        expression: "witness.lastName"
      }
    })], 1), _c('v-col', {
      staticClass: "pt-2 pl-2 pr-2",
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('v-text-field', {
      attrs: {
        "label": _vm.$t('components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.witness.email'),
        "flat": "",
        "outlined": "",
        "rules": _vm.emailRules,
        "data-test-witness-email": ""
      },
      model: {
        value: witness.contact.email,
        callback: function callback($$v) {
          _vm.$set(witness.contact, "email", $$v);
        },
        expression: "witness.contact.email"
      }
    })], 1), _c('v-col', {
      staticClass: "pt-2 pl-2 pr-2",
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('v-text-field', {
      attrs: {
        "label": _vm.$t('components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.witness.phone'),
        "flat": "",
        "outlined": "",
        "rules": _vm.phoneRules,
        "data-test-witness-phone": ""
      },
      model: {
        value: witness.contact.phone,
        callback: function callback($$v) {
          _vm.$set(witness.contact, "phone", $$v);
        },
        expression: "witness.contact.phone"
      }
    })], 1), _c('v-col', {
      staticClass: "pt-2 pl-2 pr-2",
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('v-text-field', {
      attrs: {
        "label": _vm.$t('components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.witness.numberplate'),
        "flat": "",
        "outlined": "",
        "rules": _vm.numberplateRules,
        "data-test-witness-numberplate": ""
      },
      model: {
        value: witness.numberplate,
        callback: function callback($$v) {
          _vm.$set(witness, "numberplate", $$v);
        },
        expression: "witness.numberplate"
      }
    })], 1), _c('v-col', {
      staticClass: "pt-2 pl-2 pr-2",
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('v-select', {
      attrs: {
        "label": _vm.$t('components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.witness.role'),
        "flat": "",
        "outlined": "",
        "items": _vm.roleItems,
        "data-test-witness-role": ""
      },
      scopedSlots: _vm._u([{
        key: "item",
        fn: function fn(_ref) {
          var item = _ref.item;
          return [_vm._v(" " + _vm._s(_vm.$t("report.witness.roles.".concat(item))) + " ")];
        }
      }, {
        key: "selection",
        fn: function fn(_ref2) {
          var item = _ref2.item;
          return [_vm._v(" " + _vm._s(_vm.$t("report.witness.roles.".concat(item))) + " ")];
        }
      }], null, true),
      model: {
        value: witness.role,
        callback: function callback($$v) {
          _vm.$set(witness, "role", $$v);
        },
        expression: "witness.role"
      }
    }), _c('v-checkbox', {
      attrs: {
        "data-test-witness-is-injured": "",
        "label": _vm.$t('components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.witness.isInjured')
      },
      model: {
        value: witness.isInjured,
        callback: function callback($$v) {
          _vm.$set(witness, "isInjured", $$v);
        },
        expression: "witness.isInjured"
      }
    })], 1), _c('v-col', {
      staticClass: "pt-2 pl-2 pr-2",
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('v-textarea', {
      attrs: {
        "data-test-witness-note": "",
        "outlined": "",
        "label": _vm.$t('components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.witness.note')
      },
      model: {
        value: witness.note,
        callback: function callback($$v) {
          _vm.$set(witness, "note", $$v);
        },
        expression: "witness.note"
      }
    })], 1)], 1)], 1);
  }), _c('card', {
    attrs: {
      "margin": 0,
      "flat": "",
      "outlined": "",
      "hideTitle": true,
      "subtitle": _vm.$t('components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.witnesses.newTitle')
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "text": "",
            "data-test-witness-add-btn": ""
          },
          on: {
            "click": _vm.add
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("add")) + " ")])];
      },
      proxy: true
    }])
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }