var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-report-step', {
    attrs: {
      "title": _vm.header.title,
      "description": _vm.header.description,
      "debug": _vm.header.debug,
      "hasInstructions": false
    }
  }, [_c('v-checkbox', {
    ref: "reviewed",
    staticClass: "padded",
    attrs: {
      "rules": _vm.requiredRule,
      "label": _vm.$t('report.summary.inputIsValid'),
      "data-test-review-checkbox": ""
    },
    model: {
      value: _vm.isReviewed,
      callback: function callback($$v) {
        _vm.isReviewed = $$v;
      },
      expression: "isReviewed"
    }
  }), _c('v-checkbox', {
    ref: "privacy",
    staticClass: "padded",
    attrs: {
      "rules": _vm.requiredRule,
      "label": _vm.$t('report.summary.privacyPolicyText'),
      "data-test-privacy-checkbox": ""
    },
    model: {
      value: _vm.isPrivacyChecked,
      callback: function callback($$v) {
        _vm.isPrivacyChecked = $$v;
      },
      expression: "isPrivacyChecked"
    }
  }), _c('v-btn', {
    attrs: {
      "disabled": !_vm.isReviewed || !_vm.isPrivacyChecked,
      "loading": _vm.loading,
      "block": "",
      "x-large": "",
      "color": "primary"
    },
    on: {
      "click": _vm.attach
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("submit")) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }