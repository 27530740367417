


























import { IForm } from "@/lib/interfaces/form.interface";
import { Component, Watch } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import DateTimePicker from "../utility/DateTimePicker.vue";
import PartnerReportCardGeneral from "./PartnerReportCardGeneral.vue";
import { DateVuetifyHelper } from "@/lib/utility/date-helper";

@Component({
  components: {
    Card,
    DateTimePicker
  }
})
export default class PartnerReportCardDatePreference extends PartnerReportCardGeneral {
  showRemove = false;

  datePreference = "";

  @Watch("datePreference")
  watchDatePreference() {
    if (this.datePreference) {
      this.reportDto.datePreference = new Date(this.datePreference).toISOString();
    }
  }

  add() {
    const date = new Date().toISOString();
    this.datePreference = new DateVuetifyHelper(date).date;
    this.reportDto.datePreference = date;
    this.showRemove = true;
    this.key++;

    this.$nextTick(() => {
      (this.$refs.form as IForm)?.validate();
    });
  }

  remove() {
    this.reportDto.datePreference = undefined;
    this.datePreference = "";
    this.showRemove = false;
    this.key++;
  }
}
