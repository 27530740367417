



















import { Component } from "vue-property-decorator";

import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { AssetEnum } from "@/lib/AssetRepository";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportInstructionData } from "@/lib/interfaces/Report/IReportInstructionData";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import ReportImageSubmission from "@/mixins/ReportImageSubmissionMixin.vue";
import { IImageUploaded } from "@/models/Image/IImageUploaded";
import { ReportModule } from "@/store/modules/report.store";
import ReportImageUpload from "./ReportImageUpload.vue";
import { ReportImageType } from "@/models/Report/ReportImageType";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";

@Component({
  components: {
    ReportImageUpload,
    LayoutReportStep
  }
})
export default class ReportAdditionalImage extends ReportImageSubmission implements IReportStep {
  example = AssetEnum.scene;
  screen = ReportScreenEnum.additionalimage;

  get header(): IReportHeaderData {
    return {
      title: "report.additionalImage.title",
      description: "report.additionalImage.description",
      debug: this.files
    };
  }

  instruction: IReportInstructionData = {
    title: "",
    text: "",
    pictures: [AssetEnum.scene],
    example: AssetEnum.scene,
    display: false
  };

  get type() {
    return ReportImageType.scene;
  }

  get isDone() {
    return true;
  }

  /**
   * @inheritdoc
   */
  get files(): IImageUploaded[] {
    return ReportModule.damages;
  }
}
