






















































































import PartnerDetailAttributeWithToolTip from "@/components/partner/PartnerDetailAttributeWithToolTip.vue";
import { RegistrationResultsEntity } from "@/models/registrationResultsEntity";
import { Component, Prop } from "vue-property-decorator";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import RegistrationUpdateDialog from "@/components/utility/RegistrationUpdateDialog.vue";
import Card from "../utility/Card.vue";

@Component({
  components: {
    PartnerDetailAttributeWithToolTip,
    RegistrationUpdateDialog,
    Card
  }
})
export default class PartnerRegistrationCard extends DarkModeHighlightMixin {
  @Prop()
  date!: string;

  @Prop()
  numberplate!: string;

  @Prop({ default: false })
  loading!: boolean;

  @Prop()
  registration!: RegistrationResultsEntity;

  @Prop({ default: false })
  displayName!: boolean;

  @Prop({ default: "" })
  registrationImageLink!: string;

  @Prop({ default: false })
  displayAddress!: boolean;

  @Prop({ default: true })
  displayManufacturerTypeCode!: boolean;

  @Prop({ default: true })
  displayManufacturerCode!: boolean;

  @Prop({ default: true })
  displayHuDate!: boolean;

  @Prop({ default: true })
  displayDriveTyp!: boolean;

  @Prop({ default: false })
  displayVehicleClass!: boolean;

  update(registration: RegistrationResultsEntity) {
    this.$emit("update", registration);
  }
}
