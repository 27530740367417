



























import { IForm } from "@/lib/interfaces/form.interface";
import { Component } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import DateTimePicker from "../utility/DateTimePicker.vue";
import PartnerReportCardGeneral from "./PartnerReportCardGeneral.vue";

@Component({
  components: {
    Card,
    DateTimePicker
  }
})
export default class PartnerReportCardRegistrationDate extends PartnerReportCardGeneral {
  showRemove = false;

  get isRegistrationDate() {
    const day = this.reportDto.registrationResults?.firstregistrationDay;
    const month = this.reportDto.registrationResults?.firstregistrationMonth;
    const year = this.reportDto.registrationResults?.firstregistrationYear;

    return day && month && year;
  }

  get registrationDate() {
    const day = this.reportDto.registrationResults?.firstregistrationDay;
    const month = this.reportDto.registrationResults?.firstregistrationMonth;
    const year = this.reportDto.registrationResults?.firstregistrationYear;

    if (isNaN(Number(day)) || isNaN(Number(month)) || isNaN(Number(year))) {
      return "";
    }

    return new Date(Number(year), Number(month), Number(day));
  }

  add() {
    this.reportDto = {
      ...this.reportDto,
      registrationResults: {
        ...(this.reportDto.registrationResults ?? {}),
        firstregistrationDay: new Date().getDay().toString(),
        firstregistrationMonth: new Date().getMonth().toString(),
        firstregistrationYear: new Date().getFullYear().toString()
      }
    };
    this.showRemove = true;
    this.key++;

    this.$nextTick(() => {
      (this.$refs.form as IForm)?.validate();
    });
  }

  remove() {
    this.reportDto = {
      ...this.reportDto,
      registrationResults: {
        ...(this.reportDto.registrationResults ?? {}),
        firstregistrationDay: undefined,
        firstregistrationMonth: undefined,
        firstregistrationYear: undefined
      }
    };

    this.showRemove = false;
    this.key++;
  }
}
