




























































































import { DigitalSignatureRequestStatusEnum } from "@/lib/enum/digital-signature-request-status.enum";
import { formatHoursAndMinutes, simpleDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import { ISignRequest } from "@/models/sign-request.entity";
import { Component, Prop, Vue } from "vue-property-decorator";
import { SIGN_COLOR_BY_STATUS } from "./SignRequestTable.vue";

@Component({
  components: {},
  filters: { simpleDate, formatHoursAndMinutes }
})
export default class ReportDocumentCardStatusMenu extends Vue {
  @Prop()
  signRequest!: ISignRequest;

  selected: DigitalSignatureRequestStatusEnum = "" as DigitalSignatureRequestStatusEnum;

  isMenu = false;

  loading = false;

  isWarningAcknowledged = false;

  get colorByStatus() {
    return SIGN_COLOR_BY_STATUS;
  }

  get DigitalSignatureRequestStatusEnum() {
    return DigitalSignatureRequestStatusEnum;
  }

  async updateStatus() {
    try {
      this.loading = true;

      await this.signRequest.updateState(this.selected);

      this.isMenu = false;
    } catch (e) {
      handleError(e);
    } finally {
      this.loading = false;
    }
  }
}
