var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('file-upload-preview', {
    attrs: {
      "example": _vm.example,
      "displayFiles": _vm.files,
      "asset": _vm.asset,
      "instruction": _vm.instruction,
      "type": _vm.type,
      "text": _vm.text,
      "textAlt": _vm.textAlt,
      "onDelete": _vm.handleDelete,
      "onUpload": _vm.handleUpload
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }