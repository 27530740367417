
























import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import MHeader from "../utility/mmmint/MHeader.vue";
import CostCard from "./CostCard.vue";
import { ICost } from "@/models/cost.entity";
import { handleError } from "@/lib/utility/handleError";
import SideCard from "../utility/SideCard.vue";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";

@Component({
  components: {
    MHeader,
    SideCard,
    CostCard,
    LatestEntriesCardEmpty
  }
})
export default class CostSideCard extends mixins(PartnerFallbackMixin) {
  @Prop()
  value!: ICost;

  get fullscreenBreakpoint() {
    return this.$vuetify.breakpoint.width < 1500;
  }

  isLoading = false;

  async mounted() {
    try {
      this.isLoading = true;

      await this.value.fetch();
    } catch (error) {
      handleError(error);
      this.close();
    } finally {
      this.isLoading = false;
    }
  }

  close() {
    this.$emit("close");
  }
}
