/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivAiControllerGetParamsGen,
  MrfiktivAiControllerSummarizeParamsGen,
  MrfiktivOpenAiPartnerViewModelGen,
  MrfiktivOpenAiRegistrationRecognitionViewModelGen,
  MrfiktivOpenAiReportSummaryViewModelGen,
  MrfiktivPageViewModelGen
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Ai<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags ai
   * @name AiControllerGet
   * @summary (AI - READ) Get all ai results
   * @request GET:/partner/{partnerId}/ai
   * @secure
   * @response `200` `(MrfiktivPageViewModelGen & { data?: (MrfiktivOpenAiPartnerViewModelGen)[] })`
   */
  aiControllerGet = ({ partnerId, ...query }: MrfiktivAiControllerGetParamsGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivPageViewModelGen & { data?: MrfiktivOpenAiPartnerViewModelGen[] }, any>({
      path: `/partner/${partnerId}/ai`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ai
   * @name AiControllerGetOne
   * @summary (AI - READ) Get a result
   * @request GET:/partner/{partnerId}/ai/{id}
   * @secure
   * @response `200` `MrfiktivOpenAiPartnerViewModelGen` The ticket
   */
  aiControllerGetOne = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivOpenAiPartnerViewModelGen, any>({
      path: `/partner/${partnerId}/ai/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ai
   * @name AiControllerReadRegistrationResult
   * @summary Get registration result for image
   * @request GET:/partner/{partnerId}/ai/image/{imageId}/registration-recognition
   * @secure
   * @response `200` `MrfiktivOpenAiRegistrationRecognitionViewModelGen` The registration result
   */
  aiControllerReadRegistrationResult = (partnerId: string, imageId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivOpenAiRegistrationRecognitionViewModelGen, any>({
      path: `/partner/${partnerId}/ai/image/${imageId}/registration-recognition`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ai
   * @name AiControllerSummarize
   * @summary Get summary of report
   * @request GET:/partner/{partnerId}/ai/report/{reportId}/report-summarization
   * @secure
   * @response `200` `MrfiktivOpenAiReportSummaryViewModelGen` The summary of the report
   */
  aiControllerSummarize = (
    { partnerId, reportId, ...query }: MrfiktivAiControllerSummarizeParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivOpenAiReportSummaryViewModelGen, any>({
      path: `/partner/${partnerId}/ai/report/${reportId}/report-summarization`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
}
