var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.isLoading ? _c('vue-picture-swipe', {
    ref: "pictureSwipe",
    staticClass: "mx-2 mt-4 mb-2",
    attrs: {
      "items": _vm.mapToImage,
      "options": {
        shareEl: false,
        rotationOn: true,
        showAnimationDuration: 0,
        hideAnimationDuration: 0
      }
    }
  }) : _c('v-skeleton-loader', {
    attrs: {
      "type": "image"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }