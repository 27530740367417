import { Filter } from "@/lib/filterable";
import { Form } from "@/lib/formable";
import { IEntity } from "@/lib/utility/data/entity.interface";
import partnerPdfService from "@/services/mrfiktiv/services/partnerPdfService";
import { MrfiktivBasePdfViewmodelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { PartnerPdfDataAccessLayer } from "@/store/modules/access-layers/partner-pdf.access-layer";
import { ITimestamp, Timestamp } from "./timestamp.entity";

class PartnerPdfBase implements Omit<MrfiktivBasePdfViewmodelGen, "timestamp">, Partial<IEntity<IPartnerPdf>> {
  /** id of document */
  id = "";

  /**
   * name of the folder
   * @example appraisals
   */
  folderName = "";

  /**
   * metada of the pdf
   * @example {"originalName":"MISQ-RatingDisplays-Forthcoming.pdf","size":992301}
   */
  metaData: object = {};

  /**
   * name of the file
   * @example 4673985834961234nf
   */
  name = "";

  /**
   * Url of the file
   * @example https://devapimrfiktiv.blob.core.windows.net/appraisals/71e8c062bfae5ffb71214932.pdf
   */
  url = "";

  /**
   * Type of the Pdf
   * @example appraisal
   */
  type: "calculation" | "billing" | "appraisal" | "documentation" | "document" | "sign" = "document";

  /**
   * The timestamps of the partner
   * @example {"created":"2021-01-01T12:12:12.317Z","lastModified":"2021-01-01T12:12:12.317Z"}
   */
  timestamp: ITimestamp = new Timestamp();

  pdf?: File;

  partnerId: string;

  constructor(pdf: Partial<IPartnerPdf> & { partnerId: string; pdf?: File; id?: string }) {
    this.partnerId = pdf?.partnerId ?? "";
    this.pdf = pdf?.pdf;
    this.id = pdf?.id ?? "";
    this.map(pdf);
  }

  private map(pdf?: Partial<MrfiktivBasePdfViewmodelGen | IPartnerPdf>) {
    this.id = pdf?.id ?? "";
    this.folderName = pdf?.folderName ?? "";
    this.metaData = pdf?.metaData ?? {};
    this.name = pdf?.name ?? "";
    this.url = pdf?.url ?? "";
    this.type = pdf?.type ?? "document";
    this.timestamp = new Timestamp(pdf?.timestamp as any);
  }

  async fetch() {
    await partnerPdfService.findOne(this.partnerId, this.id).then(p => this.map(p));

    PartnerPdfDataAccessLayer.set(this);

    return this;
  }

  async create() {
    if (!this.partnerId || !this.pdf) {
      throw new Error("PartnerId and Pdf are required to create a new pdf");
    }

    await partnerPdfService
      .create(this.partnerId, {
        pdf: this.pdf,
        type: this.type
      })
      .then(p => this.map(p));
    await this.fetch();

    return this;
  }
}
type IPartnerPdf = PartnerPdfBase;
const PartnerPdf = Form.createForClass(Filter.createForClass(PartnerPdfBase));

export { IPartnerPdf, PartnerPdf };
