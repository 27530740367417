/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivCostGroupControllerGetParamsGen,
  MrfiktivCostGroupViewModelGen,
  MrfiktivCreateCostGroupDtoGen,
  MrfiktivExceptionViewmodelGen,
  MrfiktivPageViewModelGen,
  MrfiktivUpdateCostGroupDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class CostGroup<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags cost-group
   * @name CostGroupControllerCreate
   * @summary Creates a cost group for a given partner
   * @request POST:/partner/{partnerId}/cost-group
   * @secure
   * @response `201` `MrfiktivCostGroupViewModelGen` CostGroup created
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not Found
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  costGroupControllerCreate = (partnerId: string, data: MrfiktivCreateCostGroupDtoGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivCostGroupViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/cost-group`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags cost-group
   * @name CostGroupControllerGet
   * @summary Gets all cost groups from a given partner
   * @request GET:/partner/{partnerId}/cost-group
   * @secure
   * @response `200` `(MrfiktivPageViewModelGen & { data?: (MrfiktivCostGroupViewModelGen)[] })`
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not Found
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  costGroupControllerGet = (
    { partnerId, ...query }: MrfiktivCostGroupControllerGetParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<
      MrfiktivPageViewModelGen & { data?: MrfiktivCostGroupViewModelGen[] },
      MrfiktivExceptionViewmodelGen
    >({
      path: `/partner/${partnerId}/cost-group`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags cost-group
   * @name CostGroupControllerUpdate
   * @summary Update cost group for a given partner
   * @request PUT:/partner/{partnerId}/cost-group/{id}
   * @secure
   * @response `200` `MrfiktivCostGroupViewModelGen` Updated cost group document
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not Found
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  costGroupControllerUpdate = (
    partnerId: string,
    id: string,
    data: MrfiktivUpdateCostGroupDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivCostGroupViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/cost-group/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags cost-group
   * @name CostGroupControllerGetOne
   * @summary Gets a particular cost group from a given partner
   * @request GET:/partner/{partnerId}/cost-group/{id}
   * @secure
   * @response `200` `MrfiktivCostGroupViewModelGen` CostGroup returned
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not Found
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  costGroupControllerGetOne = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivCostGroupViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/cost-group/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags cost-group
   * @name CostGroupControllerRemove
   * @summary Deletes a cost group for a given partner
   * @request DELETE:/partner/{partnerId}/cost-group/{id}
   * @secure
   * @response `200` `MrfiktivCostGroupViewModelGen` CostGroup deleted
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not Found
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  costGroupControllerRemove = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivCostGroupViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/cost-group/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
}
