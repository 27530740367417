






import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component, Prop } from "vue-property-decorator";

@Component({
  components: {}
})
export default class CardIcon extends DarkModeHighlightMixin {
  @Prop()
  icon!: string;

  @Prop()
  iconColor!: string;
}
