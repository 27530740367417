




















































import { detailedDate } from "@/lib/utility/date-helper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ISignDocument } from "@/models/sign-document.entity";
import { Component, Prop, Ref } from "vue-property-decorator";
import FileDownload from "../utility/FileDownload.vue";
import { IDownloadFile } from "../utility/interface/download-file.interface";
import TimelineCard from "../utility/TimelineItem.vue";
import ActivityTimeLineItemDelete from "./ActivityTimeLineItemDelete.vue";
import ActivityTimeLineItemDocumentCardImages from "./ActivityTimeLineItemDocumentCardImages.vue";
import { MrfiktivActivityLogViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import ContextMenu from "../utility/ContextMenu.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { mixins } from "vue-class-component";
import CostCreateDialog from "@/components/cost/CostCreateDialog.vue";
import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";

@Component({
  components: {
    TimelineCard,
    ActivityTimeLineItemDocumentCardImages,
    FileDownload,
    ActivityTimeLineItemDelete,
    ContextMenu,
    CostCreateDialog
  }
})
export default class ActivityTimeLineItemDocumentCard extends mixins(DarkModeHighlightMixin, PermissionMixin) {
  @Ref("costCreateDialog")
  costCreateDialog!: CostCreateDialog;

  @Prop({})
  isLoading = false;

  @Prop()
  document!: ISignDocument;

  @Prop()
  name!: string;

  @Prop()
  created!: string;

  @Prop()
  activityLogEntry?: MrfiktivActivityLogViewModelGen;

  @Prop()
  source!: MrfiktivReferenceGen;

  isCostCreateDialogActive = false;

  get downloadFile(): IDownloadFile {
    return {
      filename: this.document.name,
      url: this.document.url
    };
  }

  get date() {
    if (this.created) {
      const locale = this.$t("utility.toLocalDateString").toString() || "de-de";
      return detailedDate(this.created, locale);
    }
    return "Kein Datum";
  }

  get title() {
    if (this.name && this.date) {
      return this.$t("timeLine.ActivityTimeLineItemDocument.title", { name: this.name, date: this.date });
    } else {
      return this.$t("timeLine.ActivityTimeLineItemDocument.sharedDocument");
    }
  }

  createCost() {
    this.costCreateDialog?.show();
    this.costCreateDialog?.cost.files.push(this.document.id);
    this.costCreateDialog?.onCreatedDocument(this.document);
  }
}
