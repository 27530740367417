









import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import {
  MrfiktivDocumentViewModelGen,
  MrfiktivReferenceGen,
  MrfiktivReportViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { DocumentModule } from "@/store/modules/document.store";
import { ReportPaginationModule } from "@/store/modules/report-pagination.store";
import { Component, Prop } from "vue-property-decorator";
import Tooltip from "../utility/tooltip.vue";
import SharedContentMixin from "./SharedContentMixin.vue";
import { ISignDocument } from "@/models/sign-document.entity";
import { IReport } from "@/models/report.entity";
import { HandoverSnapshotModule } from "@/store/modules/handover-snapshot.store";
import { ISnapshot } from "@/models/snapshot.entity";
import { ResourceEnum } from "@/store/enum/authResourceEnum";

@Component({
  components: { Tooltip }
})
export default class SharedContentCardRefTypes extends SharedContentMixin {
  @Prop()
  reference!: MrfiktivReferenceGen;

  get itemDetail() {
    switch (this.reference.refType as ResourceEnum) {
      case ResourceEnum.DOCUMENT: {
        let doc: ISignDocument | undefined = DocumentModule.maps.id.get(this.reference.refId)[0];
        if (!doc) doc = DocumentModule.entities.find((d: ISignDocument) => d.id === this.reference.refId);
        return doc;
      }

      case ResourceEnum.REPORT: {
        let doc: IReport | undefined = ReportPaginationModule.maps.id.get(this.reference.refId)[0];
        if (!doc) doc = ReportPaginationModule.entities.find((d: IReport) => d.id === this.reference.refId);
        return doc;
      }

      case ResourceEnum.SNAPSHOT: {
        let doc: ISnapshot | undefined = HandoverSnapshotModule.maps.id.get(this.reference.refId)[0];
        if (!doc) doc = HandoverSnapshotModule.entities.find((d: ISnapshot) => d.id === this.reference.refId);
        return doc;
      }

      default:
        return this.reference.refId;
    }
  }

  get itemShortText() {
    const detail = this.itemDetail;

    if (!detail) return "";

    switch (this.reference.refType as ResourceEnum) {
      case ResourceEnum.DOCUMENT:
        return (detail as ISignDocument).title;

      case ResourceEnum.REPORT:
        return (detail as IReport).numberplate;

      case ResourceEnum.SNAPSHOT:
        return `#${(detail as ISnapshot).number}`;

      default:
        return "";
    }
  }

  get itemText() {
    const detail = this.itemDetail;

    if (!detail) return "";

    switch (this.reference.refType as ResourceEnum) {
      case ResourceEnum.DOCUMENT: {
        const documentDetail = detail as MrfiktivDocumentViewModelGen;
        const title = documentDetail.title + "<br>";
        const description = documentDetail.description ? documentDetail.description + "<br>" : "";
        const date = simpleDoubleDigitDate(documentDetail.timestamp.created);

        return `${title} ${description} ${date}`;
      }

      case ResourceEnum.REPORT: {
        const documentDetail = detail as MrfiktivReportViewModelGen;
        const numberplate = documentDetail.numberplate + "<br>";
        const customerName = documentDetail.customerName + "<br>";
        const date = simpleDoubleDigitDate(documentDetail.timestamps.created);

        return `${numberplate} ${customerName} ${date}`;
      }

      case ResourceEnum.SNAPSHOT: {
        const documentDetail = detail as ISnapshot;
        const title = documentDetail.handover.title + "<br>";
        const date = simpleDoubleDigitDate(documentDetail.timestamp.created);

        return `${title} ${date}`;
      }

      default:
        return "";
    }
  }
}
