import { MrfiktivHttpClientProvider } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import { MrfiktivPartnerLinkFinderControllerFindByGeocodeParamsGen } from "../v1/data-contracts";
import { PartnerLinkerFinderService } from "../v1/PartnerLinkerFinderService";

/**
 * The service to communicate with the partner backend.
 */
class PartnerLinkerFinderServiceService {
  proxy: PartnerLinkerFinderService;

  /**
   * Construct service
   */
  constructor(mrfiktivHttpClientProvider: MrfiktivHttpClientProvider) {
    this.proxy = new PartnerLinkerFinderService(mrfiktivHttpClientProvider.client());
  }
  /**
   * Get
   */
  async find(query: MrfiktivPartnerLinkFinderControllerFindByGeocodeParamsGen) {
    return (await this.proxy.partnerLinkFinderControllerFindByGeocode(query)).data;
  }
}

/**
 * Communicate with ticket API
 */
export default new PartnerLinkerFinderServiceService(new MrfiktivHttpClientProvider());
