




























































































































import { handleError } from "@/lib/utility/handleError";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import ExportMixin from "@/mixins/XMLExportMixin.vue";
import {
  MrfiktivKsrContactInfoViewModelGen,
  MrfiktivKsrExportReportDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { KsrModule } from "@/store/modules/ksr.store";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component, Watch } from "vue-property-decorator";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import Card from "../utility/Card.vue";
import Tooltip from "../utility/tooltip.vue";

enum ContactOptions {
  PHONE = "phone",
  MAIL = "mail"
}

@Component({ components: { Card, LatestEntriesCardEmpty, Tooltip } })
export default class PartnerReportDetailSendToKsrDialogNew extends mixins(PermissionMixin, ExportMixin) {
  /**
   * Search input for user detail
   */
  search = "";

  /**
   * Whether to search for mail or phone
   */
  contactOption: ContactOptions = ContactOptions.MAIL;

  /**
   * Is user search loading
   */
  isLoading = false;

  /**
   * Is user search done loading
   */
  isLoaded = false;

  /**
   * The found contact and report id
   */
  identities: MrfiktivKsrContactInfoViewModelGen[] = [];

  /**
   * The identity that was selected in the list of identities
   */
  selectedIdentity = -1;

  isSearchEditActive = false;

  get searchText() {
    const prefix =
      this.contactOption === ContactOptions.MAIL
        ? this.$t("components.partner.PartnerReportDetail.SendToKsrDialog.mail")
        : this.$t("components.partner.PartnerReportDetail.SendToKsrDialog.phone");
    return `${prefix} <b>${this.search}</b>`;
  }
  /**
   * Enum with search options (phone/mail)
   */
  get ContactOptions() {
    return ContactOptions;
  }

  /**
   * id for partner that exports
   */
  get partnerId() {
    return this.$route.params.partnerId ?? PartnerModule.partner._id;
  }

  get report() {
    return PartnerModule.report;
  }

  async downloadXml() {
    await this.downloadReportExport("ksr", this.report);
  }

  async mounted() {
    this.setSearch();

    try {
      this.isLoading = true;
      this.isLoaded = false;
      const contact: MrfiktivKsrContactInfoViewModelGen[] = [];
      if (PartnerModule.report.customerContact.email) {
        contact.push(
          ...(await KsrModule.getCustomerContactInfo({
            partnerId: PartnerModule.partner.id,
            email: PartnerModule.report.customerContact.email
          }))
        );
      } else {
        contact.push(
          ...(await KsrModule.getCustomerContactInfo({
            partnerId: PartnerModule.partner.id,
            phoneNumber: PartnerModule.report.customerContact.phone
          }))
        );
      }
      this.identities.splice(0, this.identities.length, ...contact);
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
      this.isLoaded = true;
    }
  }

  toggleSearchEdit() {
    this.isSearchEditActive = !this.isSearchEditActive;

    if (!this.isSearchEditActive) {
      this.searchUser();
    }
  }

  getAddressText(identity: MrfiktivKsrContactInfoViewModelGen) {
    const addressText = `📍 ${this.$t("components.partner.PartnerReportDetail.SendToKsrDialog.addressId")}  ${
      identity.addressId
    }`;
    const contactText = `📞 ${this.$t("components.partner.PartnerReportDetail.SendToKsrDialog.contactId")}  ${
      identity.contactId
    }`;

    return `${this.$t("components.partner.PartnerReportDetail.SendToKsrDialog.createForUserTooltip", {
      addressText,
      contactText
    })}`;
  }

  @Watch("contactOption")
  setSearch() {
    this.isLoaded = false;
    if (this.contactOption === ContactOptions.MAIL) {
      const mail = PartnerModule.report?.customerContact?.email ?? "";
      this.search = mail;
    } else {
      const phone = PartnerModule.report?.customerContact?.phone ?? "";
      this.search = phone;
    }
  }

  /**
   * Search KSR for user identity
   */
  async searchUser() {
    try {
      this.identities.splice(0);
      this.isSearchEditActive = false;
      this.isLoading = true;
      this.isLoaded = false;

      const identities = await KsrModule.getCustomerContactInfo({
        partnerId: this.partnerId,
        email: this.contactOption === ContactOptions.MAIL ? this.search : "",
        phoneNumber: this.contactOption === ContactOptions.PHONE ? this.search : ""
      });

      this.identities.splice(0, this.identities.length, ...(identities ?? []));
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
      this.isLoaded = true;
    }
  }

  /**
   * confirm submission to KSR
   */
  confirm(dto: MrfiktivKsrExportReportDtoGen) {
    this.$emit("submit", dto);
  }
}
