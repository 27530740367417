var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('timeline-card', {
    attrs: {
      "title": _vm.$t('timeLine.PartnerAccidentDetailsTimeLineElement.title'),
      "divider": true
    },
    scopedSlots: _vm._u([_vm.canUpdateReport ? {
      key: "menu",
      fn: function fn() {
        return [_c('partner-report-update-report-dialog', {
          attrs: {
            "config": [_vm.ReportUpdateTabEnum.accident],
            "activityTarget": "accident"
          }
        })];
      },
      proxy: true
    } : null], null, true)
  }, [_c('v-card', {
    staticClass: "ma-4",
    attrs: {
      "flat": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-overlay', {
    staticClass: "z-index: 2000; !important; ",
    attrs: {
      "absolute": "",
      "value": _vm.overlay
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("timeLine.PartnerAccidentDetailsTimeLineElement.noGeoData")))])]), _vm.coordinates ? _c('PartnerMap', {
    staticClass: "z-index: 0; !important; ",
    attrs: {
      "coordinates": _vm.coordinates,
      "height": 265,
      "colour": "red"
    }
  }) : _vm._e()], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', [_c('copy-clipboard-text', {
    attrs: {
      "icon": "mdi-account-outline",
      "text": _vm.$t(_vm.role)
    }
  })], 1), _c('div', [_c('copy-clipboard-text', {
    attrs: {
      "icon": "mdi-calendar-blank-outline",
      "text": _vm.date
    }
  })], 1), _c('div', [_c('copy-clipboard-text', {
    attrs: {
      "icon": "mdi-clock-outline",
      "text": _vm.time
    }
  })], 1), _c('div', [_c('copy-clipboard-text', {
    attrs: {
      "icon": "mdi-map-marker-outline",
      "text": _vm.locationText
    }
  })], 1), _c('v-textarea', {
    staticClass: "mb-n8 mt-4",
    attrs: {
      "label": "Kommentar",
      "outlined": "",
      "readonly": "",
      "value": _vm.comment
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }