/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { CancelToken } from "@/lib/utility/cancelToken";
import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { IPageFilterElement } from "@/models/page-filter-element.entity";
import { MrfiktivPageViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ThgPageViewModelGen } from "@/services/thg/v1/data-contracts";
import Vue from "vue";
import { Action, Mutation } from "vuex-module-decorators";
import { PageDataHandler } from "../lib/utility/data/page-data-handler";
import { IPageDataProvider } from "../lib/utility/data/page-data-provider.interface";
import { IPaginationParams } from "../lib/utility/data/pagination-params.interface";
import { PaginationFilterListElement } from "./modules/base-pagination.store";
import { PaginatedBaseStore } from "./paginated-base.store";

export class BackwardsCompatibleDataAccessLayer<T extends object> extends AbstractLocalDataAccessLayer<T> {
  getIdentifier(entity: T): string {
    return (entity as any).id ?? (entity as any)._id;
  }
}

export class BackwardsCompatiblePageDataProvider<
  T extends object,
  Q extends IPaginationParams
> extends AbstractPageDataProvider<T, Q> {
  constructor(private loadDocuments: Function) {
    super();
  }

  async getPage(query: Q): Promise<IPageViewModel<T>> {
    return await this.loadDocuments(query);
  }
}

/**
 * A backwards compatible facade for of the `PaginatedBaseStore` to replace the BasePagination.
 * @deprecated
 */
export abstract class BackwardsCompatiblePaginatedStore<
  T extends object,
  Q extends IPaginationParams
> extends PaginatedBaseStore<T, Q> {
  protected abstract _isLoadAll: boolean;
  abstract filterOptions: PaginationFilterListElement[];
  hiddenFilter: IPageFilterElement[] = [];

  cancelToken: CancelToken | undefined;

  get filter() {
    return this.filters;
  }

  set filter(value: IPageFilterElement[]) {
    this.filters = value;
  }

  /**
   * Template method to "establish the backend connection".
   * This method should implement only the backend call with the given query and return the data that the backend gives us.
   * The method is integrated into the pagination logic, so it will take care of filling out the pagination list and the maps. This should not be done manually.
   *
   * In the facade this is used to create the page provider
   *
   * @param query
   */
  @Action
  protected async loadDocuments(
    query: Q
  ): Promise<
    (ThgPageViewModelGen | MrfiktivPageViewModelGen) & {
      data?: T[] | undefined;
    }
  > {
    throw new Error(`loadDocuments not implemented by parent class. Cannot process query ${query}`);
  }

  abstract _data: BackwardsCompatibleDataAccessLayer<T>;
  _pageProvider: IPageDataProvider<T, Q> = new BackwardsCompatiblePageDataProvider(this.loadDocuments);
  abstract _pager: PageDataHandler<T, Q>;

  /**
   * Hook to configure for document maps
   * key must be a property of the document
   * map is a reference to a map for the document.
   * By configuring a key and a map, the document will be added/removed to the map when being added/removed to/from the pagination list
   * @deprecated tbd
   */
  protected _documentMapConfig: Record<string, Map<string | number, T>> = {};

  /**
   * Create document maps
   * @param documents
   * @deprecated tbd
   */
  @Mutation
  private setDocumentMaps(documents: T[]): void {
    Vue.$log.debug("setDocumentMaps", documents);

    if (!this._documentMapConfig) {
      return;
    }

    for (const document of documents) {
      for (const config of Object.entries(this._documentMapConfig)) {
        const key = document[config[0]];
        const map = config[1];

        if (key) {
          map.set(key, document);
        }
      }
    }
  }

  /**
   * Rremove specific document from maps
   * @deprecated tbd
   */
  @Mutation
  private deleteFromDocumentMaps(document: T): void {
    Vue.$log.debug("deleteFromDocumentMaps", document);

    if (!this._documentMapConfig) {
      return;
    }
    for (const config of Object.entries(this._documentMapConfig)) {
      const key = document[config[0]];
      const map = config[1];

      if (key) {
        map.delete(key);
      }
    }
  }

  /**
   * Reset document maps
   * @deprecated tbd
   */
  @Mutation
  private clearDocumentMaps(): void {
    Vue.$log.debug("clearDocumentMaps", document);

    if (!this._documentMapConfig) {
      return;
    }
    for (const config of Object.entries(this._documentMapConfig)) {
      const key = document[config[0]];
      const map = config[1];

      if (key) {
        map.clear();
      }
    }
  }

  /**
   * @deprecated use filterOptions
   */
  get filterList(): PaginationFilterListElement[] {
    return this.filterOptions;
  }

  /**
   * @deprecated use filtered instead
   */
  get paginationList(): T[] {
    return this.filteredAndSorted;
  }

  /**
   * @deprecated don't load all
   */
  get isLoadAll() {
    return this._isLoadAll;
  }

  /**
   * @deprecated
   */
  @Mutation
  private _mutateIsLoadAll(isLoadAll: boolean) {
    this._isLoadAll = isLoadAll;
  }

  /**
   * @deprecated
   */
  @Mutation _mutateCancelToken(cancelToken: CancelToken) {
    this.cancelToken = cancelToken;
  }

  /**
   * @deprecated tbd
   */
  @Mutation _mutateHiddenFilter(filter: IPageFilterElement[]) {
    this.hiddenFilter = filter;
  }

  /**
   * @deprecated tbd
   */
  @Action
  prependToList(document: T) {
    const paginationList = [document, ...this.paginationList];
    this.context.commit("_mutatePaginationList", paginationList);
    this.context.commit("setDocumentMaps", paginationList);
  }

  /**
   * @deprecated don't load all
   */
  @Action
  setIsLoadAll(isLoadAll: boolean) {
    this.context.commit("_mutateIsLoadAll", isLoadAll);
  }

  /**
   * @deprecated don't load all
   */
  @Action
  setCancelToken(cancelToken: CancelToken) {
    this.context.commit("_mutateCancelToken", cancelToken);
  }

  /**
   * @deprecated tbd
   */
  @Action
  setFilter(filter: IPageFilterElement[]) {
    Vue.$log.debug("setFilter", filter);
    this.context.dispatch("setFilters", filter);
  }

  /**
   * @deprecated tbd
   */
  @Action
  replaceInList(document: T) {
    this._data.set(document);
    this.context.commit("setDocumentMaps", [document]);
  }

  /**
   * @deprecated tbd
   */
  @Action
  removeInList(document: T) {
    this._data.delete(document);

    // remove in maps
    this.context.commit("deleteFromDocumentMaps", document);
  }

  /**
   * @deprecated tbd
   */
  @Action
  addToList(document: T) {
    this._data.set(document);
  }

  /**
   * Returns the item that follows the passed item, or returns the first item, if this item is not found, or there is no following item
   * @deprecated tbd
   */
  @Action
  getNextItemInList(document: T) {
    const documentId = (document as any).id ?? (document as any)._id;
    const index = this.filtered.findIndex((el: any) => {
      const elementId = el.id || (el as any)._id;

      return elementId === documentId;
    });

    const next = this.filtered[index + 1];
    if (next) {
      return next;
    }

    return this.filtered[0];
  }

  /**
   * @deprecated use `reset` instead
   */
  @Action
  emptyList() {
    this.context.dispatch("reset");
  }

  /**
   * Sets the query page to 1, in order to start fetching from beginning.
   * Then runs fetch rest in the background to start fetching the documents from last the page id that was loaded.
   *
   * Fetch rest will call itself until all pages are loaded, or the cancelation token is set.
   *
   * @deprecated don't load all
   * @param query page dto to
   * @returns
   */
  @Action
  async fetchAllFromBeginning(query: Q) {
    Vue.$log.debug("fetchAllFromBeginning", query);

    // cancel active request
    this.cancelToken?.requestCancellation();

    // create new cancel token for next series of requests
    const cancelToken = new CancelToken();

    // replace active cancel token
    this.context.dispatch("setCancelToken", cancelToken);

    // request first page
    await this.context.dispatch("fetchFirst", query);

    // check if all are supposed to be loaded
    if (!this.isLoadAll) {
      return;
    }

    await this.context.dispatch("fetchRest", { query: query, cancelToken: cancelToken });
  }

  /**
   * @deprecated use fetchFirstPage
   */
  /**
   * Loads the first page of items
   *
   * @param query
   * @returns
   */
  @Action
  async fetchFirst(query: Q): Promise<T[]> {
    // reset maps
    this.context.commit("clearDocumentMaps");

    const docs = await this.context.dispatch("fetchFirstPage", query);

    // set items
    this.context.commit("setDocumentMaps", docs);

    return docs;
  }

  /**
   * @deprecated don't fetch all
   */
  @Action
  async fetchRest({ query, cancelToken }: { query: Q; cancelToken: CancelToken }) {
    Vue.$log.debug("fetchRest", { query, cancelToken });

    // fetch next page
    const docs: T[] = await this.context.dispatch("fetchNext", query);

    if (
      !this.isLoadAll ||
      cancelToken.isCancellationRequested() ||
      !docs?.length ||
      this._data.entities.length >= this.totalItems
    ) {
      return;
    }

    // get rest while there is something to get and request is not interrupted
    await this.context.dispatch("fetchRest", { query: query, cancelToken: cancelToken });
  }

  /**
   * @deprecated use fetchNextPage
   */
  @Action
  async fetchNext(query: Q): Promise<T[]> {
    Vue.$log.debug("fetchNext", query);

    const docs = await this.context.dispatch("fetchNextPage", query);

    this.context.commit("setDocumentMaps", docs);

    return docs;
  }
}
