/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

/**
 * Amount types
 */
export enum TransmissionTypeEnum {
  AUTOMATIC = "automatic",
  MANUAL = "manual"
}
