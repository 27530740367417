
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { ReportModule } from "@/store/modules/report.store";
import ReportHome from "@/views/ReportHome.vue";

export default class RepareoHome extends ReportHome {
  screenName = ReportScreenEnum.REPAREO_HOME;

  title = "report.homeRepareo.title";
  buttonText = "report.homeRepareo.start";
  slogan = "report.homeRepareo.slogan";

  imageSrc = "https://www.mmmint.ai/partner/logos/repareo_report_home.png";

  items = [
    { text: "report.homeRepareo.step1", icon: "mdi-camera" },
    { text: "report.homeRepareo.step2", icon: "mdi-account" },
    { text: "report.homeRepareo.step3", icon: "mdi-calendar-check" }
  ];
  async mounted() {
    ReportModule.setStep(this.screenName);
  }
}
