
import { InsuranceTypeEnum } from "@/components/report/InsuranceForm.vue";
import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import { MrfiktivCreateInsuranceDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { Component } from "vue-property-decorator";
import ReportMotoristLegalInsurance from "./ReportMotoristLegalInsurance.vue";

@Component({
  components: {
    LayoutReportStep
  }
})
export default class ReportWheelInsurance extends ReportMotoristLegalInsurance implements IReportStep {
  header: IReportHeaderData = {
    title: "report.wheelInsurance.title",
    description: "report.wheelInsurance.description"
  };

  screen = ReportScreenEnum.wheelInsurance;

  question = "report.wheelInsurance.hasInsurance";

  type = InsuranceTypeEnum.WHEEL_INSURANCE;

  valid = false;
  hasInsurance = false;

  insurance: MrfiktivCreateInsuranceDtoGen = {
    name: "",
    number: "",
    type: InsuranceTypeEnum.WHEEL_INSURANCE
  };
}
