











import { Component, Prop, Vue } from "vue-property-decorator";
import { ICost } from "@/models/cost.entity";
import { CostTypeEnum } from "@/lib/enum/cost-type.enum";

@Component({
  components: {}
})
export default class CostChip extends Vue {
  @Prop()
  value!: ICost;

  @Prop()
  small!: boolean;

  @Prop()
  large!: boolean;

  get color() {
    if (!this.value.total) return "";
    if (this.value.expenseOrIncome === CostTypeEnum.EXPENSE) return "error";
    if (this.value.expenseOrIncome === CostTypeEnum.INCOME) return "success";
    return "";
  }
}
