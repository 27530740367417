




























import { activityLogName } from "@/lib/utility/activityLogName";
import { detailedDate } from "@/lib/utility/date-helper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import {
  MrfiktivActivityLogViewModelGen,
  MrfiktivPartnerImageViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { Component, Prop } from "vue-property-decorator";
import { PartnerImageModule } from "@/store/modules/partner-image.store";
import TimelineCard from "../utility/TimelineItem.vue";
import ContextMenu from "../utility/ContextMenu.vue";
import FileDownload from "../utility/FileDownload.vue";
import ActivityTimeLineItemDelete from "./ActivityTimeLineItemDelete.vue";
import { IDownloadFile } from "../utility/interface/download-file.interface";

@Component({
  components: {
    TimelineCard,
    FileDownload,
    ActivityTimeLineItemDelete,
    ContextMenu
  }
})
export default class ActivityTimeLineItemImage extends DarkModeHighlightMixin {
  @Prop()
  activityLogEntry!: MrfiktivActivityLogViewModelGen;

  images: MrfiktivPartnerImageViewModelGen[] = [];

  isLoading = false;

  get name() {
    return activityLogName(this.activityLogEntry);
  }

  get date() {
    if (this.activityLogEntry.timestamp?.created) {
      const locale = this.$t("utility.toLocalDateString").toString() || "de-de";
      return detailedDate(this.activityLogEntry.timestamp?.created, locale);
    }
    return this.$t("sign.DocumentDetail.noDate");
  }

  get title() {
    return this.$t("timeLine.ActivityTimeLineItemImage.title", { name: this.name, date: this.date });
  }

  async mounted() {
    this.images = [];
    await this.fetchImages();
  }

  async fetchImages() {
    this.isLoading = true;
    try {
      const promises: Promise<MrfiktivPartnerImageViewModelGen>[] = [];
      for (const target of this.activityLogEntry.target ?? []) {
        const promise = PartnerImageModule.fetchOne({ partnerId: this.activityLogEntry.partnerId, id: target.refId });
        promises.push(promise);
      }
      this.images = await Promise.all(promises);
    } catch (error) {
      this.$log.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  get downloads(): IDownloadFile[] {
    return this.images.map(i => {
      return {
        url: i.url,
        filename: i.metaData?.originalName ?? i.name
      };
    });
  }

  get items() {
    return this.images.map(i => {
      return {
        src: i.url,
        thumbnail: i.url,
        w: 0,
        h: 0,
        title: i.metaData?.originalName ?? i.name
      };
    });
  }
}
