var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('card', {
    attrs: {
      "flat": "",
      "outlined": "",
      "min-width": "250px",
      "title": _vm.$t('components.partner.PartnerReportDetailImageCard.title')
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('file-download', {
          attrs: {
            "downloadFiles": _vm.downloadableFiles
          }
        })];
      },
      proxy: true
    }])
  }, [_c('v-divider'), _c('v-card', {
    staticClass: "mt-6",
    attrs: {
      "flat": ""
    }
  }, [_c('vue-picture-swipe', {
    attrs: {
      "items": _vm.mapToImage,
      "options": {
        shareEl: false,
        rotationOn: true
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }