var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-report-step', {
    staticClass: "autoHypen",
    attrs: {
      "isDone": _vm.isDone,
      "title": _vm.header.title,
      "description": _vm.header.description
    }
  }, [_c('v-card', {
    staticClass: "pt-4",
    staticStyle: {
      "min-height": "calc(100vh - 360px)"
    },
    attrs: {
      "flat": "",
      "max-width": "500"
    }
  }, [_c('v-card-text', {
    staticClass: "pb-0"
  }, [_c('form', [_c('v-radio-group', {
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('div', [_vm._v(_vm._s(_vm.$t("report.reponsibleParty.content.title")))])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.isLiabilityDamage.isLiabilityDamage,
      callback: function callback($$v) {
        _vm.$set(_vm.isLiabilityDamage, "isLiabilityDamage", $$v);
      },
      expression: "isLiabilityDamage.isLiabilityDamage"
    }
  }, [_c('v-radio', {
    attrs: {
      "value": false,
      "label": _vm.$t('report.reponsibleParty.content.driver'),
      "data-test-driver": ""
    }
  }), _c('v-radio', {
    attrs: {
      "value": true,
      "label": _vm.$t('report.reponsibleParty.content.other'),
      "data-test-other": ""
    }
  })], 1)], 1), _c('v-card-text', [_c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.isLiabilityDamage.isLiabilityDamage ? _c('v-sheet', {
    staticStyle: {
      "border-radius": "25px 5px  25px 5px"
    },
    attrs: {
      "outlined": "",
      "shaped": "",
      "color": "success"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": "",
      "shaped": "",
      "elevation": "0"
    }
  }, [_c('v-card-title', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('report.reponsibleParty.content.unfallnavi.title'))
    }
  }), _c('v-card-subtitle', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('report.reponsibleParty.content.unfallnavi.subtitle'))
    }
  }), _c('v-card-text', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('report.reponsibleParty.content.unfallnavi.dataAgreement'))
    }
  }), _c('v-card-actions', {
    staticClass: "mt-n8"
  }, [_c('v-spacer'), _c('v-radio-group', {
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.isDataProcessingAgreement,
      callback: function callback($$v) {
        _vm.isDataProcessingAgreement = $$v;
      },
      expression: "isDataProcessingAgreement"
    }
  }, [_c('v-radio', {
    attrs: {
      "value": true,
      "label": _vm.$t('report.reponsibleParty.content.unfallnavi.yes'),
      "data-test-yes": ""
    }
  }), _c('v-radio', {
    attrs: {
      "value": false,
      "label": _vm.$t('report.reponsibleParty.content.unfallnavi.no'),
      "data-test-no": ""
    }
  })], 1), _c('v-spacer')], 1)], 1)], 1) : _vm._e()], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }