












import ReportImageGallery from "@/components/report/ReportImageGallery.vue";
import FileDownload from "@/components/utility/FileDownload.vue";
import { mapBaseImageToDownloadFile } from "@/lib/utility/map-base-image-to-download-file";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { IBaseImage } from "@/models/caseEntity";
import { ReportImageEntity } from "@/models/reportImagesEntity";
import { Component, Prop } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import { IDownloadFile } from "../utility/interface/download-file.interface";

@Component({
  components: {
    ReportImageGallery,
    FileDownload,
    Card
  }
})
export default class PartnerReportDetailImageCard extends DarkModeHighlightMixin {
  @Prop({})
  images!: ReportImageEntity;

  get downloadableFiles(): IDownloadFile[] {
    const imagesToDownload: IDownloadFile[] = [];

    mapBaseImageToDownloadFile(imagesToDownload, this.images.registrations, "fahrzeugschein");
    mapBaseImageToDownloadFile(imagesToDownload, this.images.overviews, "uerbersicht");
    mapBaseImageToDownloadFile(imagesToDownload, this.images.damages, "schaden");
    mapBaseImageToDownloadFile(imagesToDownload, this.images.damagesDetail, "detail");
    mapBaseImageToDownloadFile(imagesToDownload, this.images.cockpits, "cockpit");
    return imagesToDownload;
  }

  get mapToImage() {
    const images: any[] = [];
    this.imageMapper(images, this.images.registrations, "Fahrzeugschein.");
    this.imageMapper(images, this.images.overviews, "Übersichtsaufnahme.");
    this.imageMapper(images, this.images.damages, "Schadensbilder.");
    this.imageMapper(images, this.images.damagesDetail, "Detailaufnahmen vom Schaden.");
    this.imageMapper(images, this.images.cockpits, "Cockpit und Kilometerstand.");
    return images;
  }

  private imageMapper(imageItemList: any[], reportImages: IBaseImage[], title: string) {
    for (const image of reportImages) {
      const item = {
        src: image.url,
        thumbnail: image.url,
        w: 0,
        h: 0,
        title: title
      };
      imageItemList.push(item);
    }

    return imageItemList;
  }
}
