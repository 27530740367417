
import Debug from "@/components/utility/Debug.vue";
import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { FeatureModule } from "@/store/modules/feature.store";
import { PartnerModule } from "@/store/modules/partner";
import { ReportControlModule } from "@/store/modules/report-control.store";
import { ReportModule } from "@/store/modules/report.store";
import { Component } from "vue-property-decorator";
import ReportSummary from "./report/ReportSummary.vue";

@Component({
  components: {
    LayoutReportStep,
    Debug
  }
})
export default class ReportControlClosing extends ReportSummary implements IReportStep {
  screen = ReportScreenEnum.REPORT_CONTROL_CLOSING;

  get partner() {
    return PartnerModule.partner;
  }

  get partnerId() {
    return this.partner.id;
  }

  get selectedPartner() {
    return ReportControlModule.selected;
  }

  get isMapShown() {
    return FeatureModule.isMapShown;
  }

  get isDone() {
    return this.valid && this.isReviewed && this.isPrivacyChecked && Boolean(this.selectedPartner);
  }

  async submit() {
    if (this.form.validate() && this.selectedPartner) {
      this.isLoading = true;

      try {
        /**
         * This is important as we want to selected partner as the receiver of the report
         */
        ReportModule.setPartnerName(this.selectedPartner.companyUsername);
        ReportModule.setForwardedBy(this.partnerId);

        await ReportModule.submit();
        PartnerModule.setRatingSubmissionEnabled(true);
        this.$router.push({ name: "ReportSuccess" });
      } catch (error) {
        this.$log.error(error);
        this.$toast.error("Fehler beim senden.");
      } finally {
        this.isLoading = false;
      }
    }
  }
}
