var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-report-step', {
    attrs: {
      "hasNext": "false",
      "title": _vm.header.title,
      "description": _vm.header.description,
      "debug": _vm.report
    }
  }, [_c('v-card', {
    staticClass: "pl-3 pr-3",
    attrs: {
      "flat": "",
      "max-width": "500"
    }
  }, [_vm.email ? _c('div', {
    staticClass: "pl-1 text"
  }) : _vm._e(), _c('v-card', {
    staticClass: "pt-4 pl-4 pr-4",
    attrs: {
      "flat": "",
      "max-width": "500"
    }
  }, [_c('v-form', {
    ref: "form",
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('report.summaryAlt.form.numberplate'),
      "value": _vm.numberplate,
      "rules": _vm.numberPlateRules,
      "hint": _vm.$t('report.formPersonalData.numberplateHint'),
      "required": "",
      "data-test-form-numberplate": "",
      "outlined": ""
    },
    on: {
      "blur": _vm.updateNumberplate
    }
  }), _c('v-text-field', {
    attrs: {
      "value": _vm.user.firstName,
      "label": _vm.$t('report.summaryAlt.form.firstName'),
      "rules": _vm.requiredRule,
      "data-test-form-firstName": "",
      "outlined": ""
    },
    on: {
      "blur": _vm.updateUserFirstName
    }
  }), _c('v-text-field', {
    attrs: {
      "value": _vm.user.lastName,
      "label": _vm.$t('report.summaryAlt.form.lastName'),
      "rules": _vm.requiredRule,
      "data-test-form-lastName": "",
      "outlined": ""
    },
    on: {
      "blur": _vm.updateUserLastName
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.$t('report.summaryAlt.form.mail'),
      "value": _vm.contact.email,
      "rules": _vm.emailRules,
      "type": "email",
      "autocomplete": "email",
      "data-test-form-email": "",
      "outlined": ""
    },
    on: {
      "blur": _vm.updateUserEmail
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.$t('report.summaryAlt.form.phone'),
      "value": _vm.contact.phone,
      "type": "tel",
      "rules": _vm.phoneNumberRules,
      "data-test-form-phone": "",
      "outlined": ""
    },
    on: {
      "blur": _vm.updateUserPhone
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.$t('report.summaryAlt.form.city'),
      "value": _vm.address.city,
      "rules": _vm.noNumberRule,
      "data-test-form-city": "",
      "outlined": ""
    },
    on: {
      "blur": _vm.updateUserCity
    }
  }), _vm.isPrivacyShown ? _c('v-checkbox', {
    ref: "privacy",
    staticClass: "padded",
    attrs: {
      "rules": [function (v) {
        return !!v || _vm.$t('report.summary.readPrivacy');
      }],
      "label": _vm.$t('report.summary.privacyPolicyText'),
      "data-test-privacy-checkbox": ""
    },
    model: {
      value: _vm.isPrivacyChecked,
      callback: function callback($$v) {
        _vm.isPrivacyChecked = $$v;
      },
      expression: "isPrivacyChecked"
    }
  }) : _vm._e(), _c('v-row', {
    staticClass: "justify-center align-center pt-8"
  }, [_c('v-btn', {
    staticClass: "submitButton mb-16",
    attrs: {
      "color": "success",
      "rounded": "",
      "x-large": "",
      "disabled": !_vm.valid,
      "loading": _vm.isLoading,
      "data-test-submit-button": ""
    },
    on: {
      "click": _vm.submit
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-send ")]), _vm._v(" " + _vm._s(_vm.$t("report.summary.buttonTitle")) + " ")], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }