var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('confirm-action-dialog', {
    attrs: {
      "title": _vm.$t('create'),
      "subtitle": _vm.$t('createNewDocument'),
      "hideRight": true,
      "hideLeft": true,
      "isDialogActive": _vm.isDialogActive,
      "width": '800px'
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "close": function close($event) {
        _vm.isDialogActive = false;
      }
    }
  }, [_c('create-form', {
    attrs: {
      "dto": _vm.dto
    },
    on: {
      "created": function created($event) {
        _vm.isDialogActive = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "extraPanels",
      fn: function fn(props) {
        return [_vm._t("extraPanels", null, null, props)];
      }
    }], null, true)
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }