







































import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import { requiredRule } from "@/lib/rules/requiredRule";
import ReportStepMixin from "@/mixins/ReportStepMixin.vue";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { ReportModule } from "@/store/modules/report.store";
import { Component } from "vue-property-decorator";
import { AssetEnum } from "@/lib/AssetRepository";
import { IReportInstructionData } from "@/lib/interfaces/Report/IReportInstructionData";
import FileUploadMulti from "@/components/files/FileUploadMulti.vue";
import { ReportImageType } from "@/models/Report/ReportImageType";

@Component({
  components: {
    LayoutReportStep,
    FileUploadMulti
  }
})
export default class ReportPreExistingDamage extends ReportStepMixin implements IReportStep {
  get header(): IReportHeaderData {
    return {
      title: "report.preexistingdamage.title",
      description: "report.preexistingdamage.description"
    };
  }

  get instruction(): IReportInstructionData {
    return {
      title: "report.preexistingdamage.title",
      text: "report.preexistingdamage.instructionText",
      pictures: [AssetEnum.damageDetailInstruction],
      example: AssetEnum.damageDetailExample,
      display: true
    };
  }
  screen = ReportScreenEnum.preexistingdamage;

  valid = false;
  hasPreExistingDamage = false;

  get preExistingDamageDetails() {
    return ReportModule.preExistingDamageDetails;
  }

  get isDone() {
    if (this.hasPreExistingDamage && (!this.preExistingDamageDetails.description || this.displayImages.length < 1)) {
      return false;
    }

    if (!this.valid) {
      return false;
    }

    return true;
  }

  get requiredRule() {
    const rules = [];

    rules.push(requiredRule());

    return rules;
  }

  get description(): string {
    return ReportModule.preExistingDamageDetails?.description || "";
  }

  set description(description: string) {
    ReportModule.setPreExistingDamageDetails({ description: description });
  }

  created() {
    if (!this.preExistingDamageDetails) {
      ReportModule.setPreExistingDamageDetails({ description: "" });
    }
  }

  get displayImages() {
    return ReportModule.overviews.filter(i => i.type === ReportImageType.preExisting);
  }

  example = AssetEnum.damageDetail;
  asset = AssetEnum.damageDetailOutline;

  handleUpload(file: File) {
    ReportModule.addImage({ file, type: ReportImageType.preExisting });
  }

  handleDelete(file: File) {
    ReportModule.removeImage({ file, type: ReportImageType.preExisting });
  }
}
