import { AppContextEnum } from "@/lib/enum/appContext.enum";
import { MrfiktivHttpClientProvider } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import { MrfiktivPartnerMessageViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ConfigModule } from "@/store/modules/config";
import { ThgHttpClientProvider } from "../thg/thg-http-client.provider";
import { FrequentlyAskedQuestionsService as ThgFrequentlyAskedQuestionsService } from "../thg/v1/FrequentlyAskedQuestionsService";
import { FrequentlyAskedQuestionsService as MrfiktivFrequentlyAskedQuestionsService } from "../mrfiktiv/v1/FrequentlyAskedQuestionsService";
import {
  ThgCreateFaqDtoGen,
  ThgFaqControllerFindAllParamsGen,
  ThgFaqViewModelGen,
  ThgUpdateFaqDtoGen
} from "../thg/v1/data-contracts";

/**
 * Communicates with the faq endpoints
 */
class FaqService {
  /**
   * The proxy.
   */
  mrfiktivProxy: MrfiktivFrequentlyAskedQuestionsService;

  /**
   * The proxy.
   */
  thgProxy: ThgFrequentlyAskedQuestionsService;

  /**
   * @class Initialize AccountService
   */
  constructor(mrfiktivHttpClientProvider: MrfiktivHttpClientProvider, thgHttpClientProvider: ThgHttpClientProvider) {
    this.mrfiktivProxy = new MrfiktivFrequentlyAskedQuestionsService(mrfiktivHttpClientProvider.client());
    this.thgProxy = new ThgFrequentlyAskedQuestionsService(thgHttpClientProvider.client());
  }

  async create(
    partnerId: string,
    data: ThgCreateFaqDtoGen
  ): Promise<MrfiktivPartnerMessageViewModelGen | ThgFaqViewModelGen | undefined> {
    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL) {
      return this.mrfiktivProxy.faqControllerCreate(partnerId, data).then(res => res.data);
    }

    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL) {
      return this.thgProxy.faqControllerCreate(partnerId, data).then(res => res.data);
    }
  }

  async updateForPartner(
    id: string,
    partnerId: string,
    data: ThgUpdateFaqDtoGen
  ): Promise<ThgFaqViewModelGen | ThgFaqViewModelGen | undefined> {
    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL) {
      return this.mrfiktivProxy.faqControllerUpdate(partnerId, id, data).then(res => res.data);
    }

    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL) {
      return this.thgProxy.faqControllerUpdate(partnerId, id, data).then(res => res.data);
    }
  }

  async removeForPartner(id: string, partnerId: string): Promise<ThgFaqViewModelGen | ThgFaqViewModelGen | undefined> {
    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL) {
      return this.mrfiktivProxy.faqControllerRemove(partnerId, id).then(res => res.data);
    }

    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL) {
      return this.thgProxy.faqControllerRemove(partnerId, id).then(res => res.data);
    }
  }

  async getOneForPartner(
    partnerId: string,
    messageId: string
  ): Promise<ThgFaqViewModelGen | ThgFaqViewModelGen | undefined> {
    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL) {
      return this.mrfiktivProxy.faqControllerFindOne(partnerId, messageId).then(res => res.data);
    }

    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL) {
      return this.thgProxy.faqControllerFindOne(partnerId, messageId).then(res => res.data);
    }
  }

  async getAllForPartner(query: ThgFaqControllerFindAllParamsGen) {
    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL || ConfigModule.appContext === AppContextEnum.REPORT) {
      return this.mrfiktivProxy.faqControllerFindAll(query).then(res => res.data);
    }

    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL || ConfigModule.appContext === AppContextEnum.THG) {
      return this.thgProxy.faqControllerFindAll(query).then(res => res.data);
    }
  }
}

export default new FaqService(new MrfiktivHttpClientProvider(), new ThgHttpClientProvider());
