import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { Cost, ICost } from "@/models/cost.entity";
import costService from "@/services/mrfiktiv/services/costService";
import { MrfiktivCostControllerGetParamsGen } from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Module, getModule } from "vuex-module-decorators";
import { PaginatedBaseStore } from "../paginated-base.store";
import { CostDataAccessLayer } from "./access-layers/cost.access-layer";
import { PaginationFilterListElement } from "./base-pagination.store";

const CostPageDataProvider = new (class extends AbstractPageDataProvider<ICost, MrfiktivCostControllerGetParamsGen> {
  itemsPerPage = 25;

  async getPage(query: MrfiktivCostControllerGetParamsGen): Promise<IPageViewModel<ICost>> {
    const res = await costService.get(query);

    const meta = res.meta;
    const data = (res.data ?? []) as ICost[];

    return { meta, data };
  }
})();

@Module({
  dynamic: true,
  namespaced: true,
  name: "cost",
  store
})
export class CostPaginationStore extends PaginatedBaseStore<ICost, MrfiktivCostControllerGetParamsGen> {
  protected _data = CostDataAccessLayer;
  protected _pageProvider = CostPageDataProvider;
  protected _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions: PaginationFilterListElement[] = Cost.filterables;
}

export const CostModule = getModule(CostPaginationStore);
