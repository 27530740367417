
import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { ReportModule } from "@/store/modules/report.store";
import { Component } from "vue-property-decorator";
import ReportSummary from "../ReportSummary.vue";

@Component({
  components: {
    LayoutReportStep
  }
})
export default class RepareoSummary extends ReportSummary {
  header: IReportHeaderData = {
    title: "report.repareoSummary.title",
    description: this.description
  };

  screen = ReportScreenEnum.REPAREO_CLOSING;

  get description() {
    return String(
      this.$t("report.repareoSummary.description", {
        privacy: "<a target='blank()' href='#/privacy'>" + this.$t("report.repareoSummary.privacyPolicy") + "</a>."
      })
    );
  }

  async submit() {
    if (this.form.validate()) {
      this.isLoading = true;
      try {
        await ReportModule.submit();
        this.$router.push({ name: "RepareoSuccess" });
      } catch (error) {
        this.$log.error(error);
        this.$toast.error("Fehler beim senden.");
      } finally {
        this.isLoading = false;
      }
    }
  }
}
