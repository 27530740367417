























import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import ReportStepMixin from "@/mixins/ReportStepMixin.vue";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { ReportModule } from "@/store/modules/report.store";
import { Component, Prop } from "vue-property-decorator";

@Component({
  components: {
    LayoutReportStep
  }
})
export default class ReportDescriptionCard extends ReportStepMixin implements IReportStep {
  public header: IReportHeaderData = {
    title: "report.formDescription.title",
    description: this.description
  };

  @Prop()
  screen!: ReportScreenEnum;

  @Prop({ default: 0 })
  minLength!: number;
  @Prop({ default: 1000 })
  maxLength!: number;

  placeholder = "report.formDescription.placeholder";

  internalMessage = "";

  isDone = Boolean(this.minLength);

  get description() {
    if (!this.minLength) {
      return "report.formDescription.description";
    }
    return "report.formDescription.descriptionRequired";
  }
  get message(): string {
    return ReportModule.inCreationReport.message;
  }
  set message(m: string) {
    this.internalMessage = m;
  }

  lengthRule(v: string) {
    v = v ?? "";
    if (v.length >= this.maxLength) {
      return this.$t("report.reportDescriptionCard.maxRule", { length: this.maxLength });
    }
    if (this.minLength && v.length <= this.minLength) {
      return this.$t("report.reportDescriptionCard.minRule", { length: this.minLength });
    }

    return true;
  }

  updateMessage(e: any) {
    ReportModule.setMessage(e.target.value);
  }
}
