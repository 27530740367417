







































import Debug from "@/components/utility/Debug.vue";
import SideCard from "@/components/utility/SideCard.vue";
import { SignDigitalSignatureRequestSignerViewModelGen } from "@/services/sign/v1/data-contracts";
import { Component, Prop, Vue } from "vue-property-decorator";
import { UserGoToHelper } from "@/lib/utility/user-go-to-helper";
import { ReportModule } from "@/store/modules/report.store";
import { downloadUrlFromRemoteOrigin } from "@/lib/utility/downloadFileFunc";
import { handleError } from "@/lib/utility/handleError";
import { simpleDate } from "@/lib/utility/date-helper";
import Tooltip from "@/components/utility/tooltip.vue";
import { ISignDocument } from "@/models/sign-document.entity";

@Component({
  components: {
    SideCard,
    Tooltip,
    Debug
  }
})
export default class MySignatureDetailSideCard extends Vue {
  @Prop()
  signature!: SignDigitalSignatureRequestSignerViewModelGen;

  @Prop()
  documents!: ISignDocument[];

  @Prop({ default: false })
  loading!: boolean;

  loadingDownload = false;

  get i18n() {
    return this.$t("views.report.my.MySignatureView");
  }

  get items() {
    return [
      {
        text: this.i18n["status"],
        value: this.signature?.status || ""
      },
      {
        text: this.i18n["signatureTitle"],
        value: this.signature?.title || ""
      },
      {
        text: this.i18n["description"],
        value: this.signature?.description || ""
      }
    ];
  }

  /**
   * Signatures can only be created from the partners application.
   * If the partner id miss matches we know the signature was created from another partner.
   */
  get isSignable() {
    return ReportModule.partner.id === this.signature.partnerId;
  }

  get creationDate() {
    return simpleDate(this.signature?.timestamp.created) || "";
  }

  get isSigned(): boolean {
    return ["signed", "accepted", "declined", "voided", "deleted"].includes(this.signature.status);
  }

  async update() {
    await new UserGoToHelper(this.$router).goToSignature(this.signature.requestId);
  }

  async download() {
    this.loadingDownload = true;
    await downloadUrlFromRemoteOrigin(this.documents[0].url, `${this.signature.title}.pdf`).catch(handleError);
    this.loadingDownload = false;
  }
}
