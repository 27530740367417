var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', {
    staticClass: "mt-4"
  }, [_c('v-col', {
    attrs: {
      "xl": "9",
      "md": "8",
      "cols": "12"
    }
  }, [_vm._t("default")], 2), _vm.showDetails ? _c('v-col', {
    attrs: {
      "xl": "3",
      "md": "4",
      "cols": "12"
    }
  }, [_vm._t("details"), _vm._t("actions")], 2) : _vm._e()], 1), _vm.isMobile && _vm.bottomSheet ? _c('v-btn', {
    attrs: {
      "color": "info",
      "fab": "",
      "dark": "",
      "bottom": "",
      "right": "",
      "fixed": ""
    },
    on: {
      "click": function click($event) {
        _vm.isBottomSheetOpen = true;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-dots-vertical")])], 1) : _vm._e(), _vm.isMobile && _vm.bottomSheet ? _c('v-bottom-sheet', {
    directives: [{
      name: "touch",
      rawName: "v-touch",
      value: _vm.closeOnSwipe,
      expression: "closeOnSwipe"
    }],
    model: {
      value: _vm.isBottomSheetOpen,
      callback: function callback($$v) {
        _vm.isBottomSheetOpen = $$v;
      },
      expression: "isBottomSheetOpen"
    }
  }, [_c('div', {
    directives: [{
      name: "touch",
      rawName: "v-touch",
      value: {
        down: function down() {
          return _vm.closeBottomSheet();
        }
      },
      expression: "{\n        down: () => closeBottomSheet()\n      }"
    }]
  }, [_c('v-card', {
    staticClass: "pt-2 pb-4"
  }, [_c('div', {
    staticClass: "swipe-indicator"
  }), _vm._t("details")], 2)], 1)]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }