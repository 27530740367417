var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-report-step', {
    attrs: {
      "isDone": _vm.isDone,
      "title": _vm.header.title,
      "description": _vm.header.description,
      "image": _vm.header.image,
      "debug": _vm.header.debug
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-radio-group', {
    model: {
      value: _vm.liabilityDamage.isLiabilityDamage,
      callback: function callback($$v) {
        _vm.$set(_vm.liabilityDamage, "isLiabilityDamage", $$v);
      },
      expression: "liabilityDamage.isLiabilityDamage"
    }
  }, [_c('v-card', {
    staticClass: "pa-2 pt-4 my-2",
    attrs: {
      "flat": "",
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        _vm.liabilityDamage.isLiabilityDamage = true;
      }
    }
  }, [_c('v-radio', {
    attrs: {
      "value": true
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('div', [_c('strong', [_vm._v(_vm._s(_vm.$t("report.liabilityDamage.cards.true.title")))])])];
      },
      proxy: true
    }])
  }), _c('v-card-text', {
    staticClass: "ml-4"
  }, [_vm._v(_vm._s(_vm.$t("report.liabilityDamage.cards.true.description")))])], 1), _c('v-card', {
    staticClass: "pa-2 pt-4 my-2",
    attrs: {
      "flat": "",
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        _vm.liabilityDamage.isLiabilityDamage = false;
      }
    }
  }, [_c('v-radio', {
    attrs: {
      "value": false
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('strong', [_vm._v(_vm._s(_vm.$t("report.liabilityDamage.cards.false.title")))])];
      },
      proxy: true
    }])
  }), _c('v-card-text', {
    staticClass: "ml-4"
  }, [_vm._v(_vm._s(_vm.$t("report.liabilityDamage.cards.false.description")))])], 1)], 1)], 1), _vm.liabilityDamage.isLiabilityDamage ? _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', [_vm._v(_vm._s(_vm.$t("report.liabilityDamage.liable.title")))]), _c('witness-form', {
    attrs: {
      "role": _vm.ReportRoleEnum.RESPONSIBLE,
      "show-insurance": true,
      "show-injury": false,
      "required": false
    },
    model: {
      value: _vm.witness,
      callback: function callback($$v) {
        _vm.witness = $$v;
      },
      expression: "witness"
    }
  })], 1) : _vm._e(), !_vm.liabilityDamage.isLiabilityDamage ? _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', [_vm._v(_vm._s(_vm.$t("report.liabilityDamage.cards.false.title")))]), _c('insurance-form', {
    attrs: {
      "types": _vm.insuranceTypes,
      "required": false
    },
    model: {
      value: _vm.insurance,
      callback: function callback($$v) {
        _vm.insurance = $$v;
      },
      expression: "insurance"
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }