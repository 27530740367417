var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card-text', {
    staticClass: "pb-0"
  }, [_c('template-editor', {
    ref: "templateEditor",
    attrs: {
      "readOnly": false,
      "partnerId": _vm.id,
      "label": _vm.$t('components.template.detail.body.body'),
      "isMobile": _vm.isEditorMobile
    },
    model: {
      value: _vm.comment,
      callback: function callback($$v) {
        _vm.comment = $$v;
      },
      expression: "comment"
    }
  })], 1), _c('v-card-actions', {
    staticClass: "mt-n8 px-4"
  }, [_vm.report && _vm.report.progressStatus != _vm.finished ? _c('v-btn', {
    attrs: {
      "color": "grey-lighten-4",
      "small": "",
      "elevation": "0",
      "disabled": !_vm.comment
    },
    on: {
      "click": function click($event) {
        return _vm.closeAndAddComment();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.template.detail.body.closeAndSend")) + " ")]) : _vm._e(), _c('v-btn', {
    attrs: {
      "color": "success",
      "disabled": !_vm.comment,
      "loading": _vm.isLoading,
      "small": "",
      "elevation": "0"
    },
    on: {
      "click": function click($event) {
        return _vm.addComment();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.template.detail.body.comment")) + " ")]), _vm._t("actions")], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }