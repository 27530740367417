var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('card', {
    key: 'preference' + _vm.key,
    attrs: {
      "margin": 0,
      "flat": "",
      "outlined": "",
      "subtitle": _vm.$t('components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.registration.registrationVehicle.title'),
      "hideTitle": true
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [!_vm.isRegistrationVehicleInfo ? _c('v-btn', {
          attrs: {
            "text": ""
          },
          on: {
            "click": _vm.add
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("add")) + " ")]) : _vm._e(), _vm.showRemove ? _c('v-btn', {
          attrs: {
            "text": ""
          },
          on: {
            "click": _vm.remove
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("close")) + " ")]) : _vm._e()];
      },
      proxy: true
    }])
  }, [_vm.isRegistrationVehicleInfo ? _c('div', {
    staticClass: "pt-2 pr-2 pl-2"
  }, [_c('v-form', {
    ref: "form",
    model: {
      value: _vm.isValid,
      callback: function callback($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": _vm.$t('components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.registration.registrationVehicle.identificationnumber')
    },
    model: {
      value: _vm.reportDto.registrationResults.identificationnumber,
      callback: function callback($$v) {
        _vm.$set(_vm.reportDto.registrationResults, "identificationnumber", $$v);
      },
      expression: "reportDto.registrationResults.identificationnumber"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "clearable": "",
      "outlined": "",
      "label": _vm.$t('components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.registration.registrationVehicle.vehicleClass'),
      "items": _vm.vehicleTypesOptions
    },
    model: {
      value: _vm.reportDto.registrationResults.vehicleClass,
      callback: function callback($$v) {
        _vm.$set(_vm.reportDto.registrationResults, "vehicleClass", $$v);
      },
      expression: "reportDto.registrationResults.vehicleClass"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "clearable": "",
      "outlined": "",
      "label": _vm.$t('components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.registration.registrationVehicle.driveTyp'),
      "items": _vm.driveType
    },
    model: {
      value: _vm.reportDto.registrationResults.driveTyp,
      callback: function callback($$v) {
        _vm.$set(_vm.reportDto.registrationResults, "driveTyp", $$v);
      },
      expression: "reportDto.registrationResults.driveTyp"
    }
  })], 1), _c('v-col', {
    staticClass: "pr-1",
    attrs: {
      "cols": "8"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": _vm.$t('components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.registration.registrationVehicle.manufacturerName')
    },
    model: {
      value: _vm.reportDto.registrationResults.manufacturerName,
      callback: function callback($$v) {
        _vm.$set(_vm.reportDto.registrationResults, "manufacturerName", $$v);
      },
      expression: "reportDto.registrationResults.manufacturerName"
    }
  })], 1), _c('v-col', {
    staticClass: "pl-1",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": _vm.$t('components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.registration.registrationVehicle.manufacturerNameCode')
    },
    model: {
      value: _vm.reportDto.registrationResults.manufacturerNameCode,
      callback: function callback($$v) {
        _vm.$set(_vm.reportDto.registrationResults, "manufacturerNameCode", $$v);
      },
      expression: "reportDto.registrationResults.manufacturerNameCode"
    }
  })], 1), _c('v-col', {
    staticClass: "pr-1",
    attrs: {
      "cols": "8"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": _vm.$t('components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.registration.registrationVehicle.manufacturerType')
    },
    model: {
      value: _vm.reportDto.registrationResults.manufacturerType,
      callback: function callback($$v) {
        _vm.$set(_vm.reportDto.registrationResults, "manufacturerType", $$v);
      },
      expression: "reportDto.registrationResults.manufacturerType"
    }
  })], 1), _c('v-col', {
    staticClass: "pl-1",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": _vm.$t('components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.registration.registrationVehicle.manufacturerTypeCode')
    },
    model: {
      value: _vm.reportDto.registrationResults.manufacturerTypeCode,
      callback: function callback($$v) {
        _vm.$set(_vm.reportDto.registrationResults, "manufacturerTypeCode", $$v);
      },
      expression: "reportDto.registrationResults.manufacturerTypeCode"
    }
  })], 1)], 1)], 1)], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }