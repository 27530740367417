






































































import ReportImageGallery from "@/components/report/ReportImageGallery.vue";
import FileDownload from "@/components/utility/FileDownload.vue";
import { handleError } from "@/lib/utility/handleError";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import ReportImageSubmission from "@/mixins/ReportImageSubmissionMixin.vue";
import { ReportImageType } from "@/models/Report/ReportImageType";
import { ReportImageEntity } from "@/models/reportImagesEntity";
import { MrfiktivUpdateReportImagesDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { PartnerModule } from "@/store/modules/partner";
import { ReportModule } from "@/store/modules/report.store";
import ReportImageUpload from "@/views/report/ReportImageUpload.vue";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import CameraBottomSheet from "../camera/CameraBottomSheet.vue";
import CameraDialog from "../camera/CameraDialog.vue";
import ImageUploadButton from "../files/ImageUploadButton.vue";
import PdfUploadDialog from "../files/PdfUploadDialog.vue";
import Card from "../utility/Card.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import TimelineCard from "../utility/TimelineItem.vue";
import Tooltip from "../utility/tooltip.vue";
import { ConfigModule } from "@/store/modules/config";

@Component({
  components: {
    TimelineCard,
    ReportImageGallery,
    FileDownload,
    Card,
    ConfirmActionDialog,
    ReportImageUpload,
    CameraDialog,
    PdfUploadDialog,
    ImageUploadButton,
    CameraBottomSheet,
    Tooltip
  }
})
export default class PartnerReportDetailImageUpload extends mixins(
  ReportImageSubmission,
  DarkModeHighlightMixin,
  PermissionMixin
) {
  @Prop({})
  images!: ReportImageEntity;

  @Prop({ default: false })
  dense?: boolean;

  get pdfInputTypes() {
    return ConfigModule.pdfInputTypes;
  }

  get reportImageTypes() {
    return Object.values(ReportImageType);
  }

  get imageIds() {
    const ids: string[] = [];

    [
      ...(this.images.registrations || []),
      ...(this.images.cockpits || []),
      ...(this.images.damages || []),
      ...(this.images.damagesDetail || []),
      ...(this.images.overviews || []),
      ...(this.images.plates || [])
    ].forEach(i => {
      if (i.id) {
        ids.push(i.id);
      }
    });

    return ids;
  }

  isConfirmDialogActive = false;

  isUploading = false;

  imageType = ReportImageType.overview;

  confirmType() {
    this.isConfirmDialogActive = false;
    this.click();
  }
  startUpload() {
    this.isConfirmDialogActive = true;
  }

  async handleUpload(file: File): Promise<boolean> {
    this.isUploading = true;
    ReportModule.setPartnerName(PartnerModule.partner.companyUsername);
    const uploaded = await ReportModule.addImage({ file, type: this.imageType, categoryKey: "ReportDetail" });

    const imageIds = this.imageIds;
    imageIds.push(uploaded.uploadId);
    await this.update(imageIds);

    this.$emit("onUpload", uploaded);
    this.isUploading = false;

    return true;
  }

  async update(imageIds: string[]) {
    const updateDto: MrfiktivUpdateReportImagesDtoGen = { imageIds };

    await PartnerModule.report.updateReportImages(updateDto).catch(handleError);
  }
}
