
















































import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import AssigneePreview from "../utility/AssigneePreview.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import { requiredRule } from "@/lib/rules/requiredRule";
import { IRefSuggestion } from "@/store/modules/fleet-aggregation.store";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import RefsSelect from "../utility/RefsSelect.vue";
import { handleError } from "@/lib/utility/handleError";
import SelectAssignees from "../utility/SelectAssignees.vue";
import { CostModule } from "@/store/modules/cost.store";
import PartnerPdfs from "../files/PartnerPdfs.vue";
import { CostGroup, ICostGroup } from "@/models/cost-group.entity";
import CustomFieldConfigurationForm from "@/components/report/CustomFieldConfigurationForm.vue";
import CustomFieldValueChip from "@/components/report/CustomFieldValueChip.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";

@Component({
  components: {
    PaginatedTable,
    ConfirmActionDialog,
    AssigneePreview,
    RefsSelect,
    SelectAssignees,
    PartnerPdfs,
    CustomFieldConfigurationForm,
    CustomFieldValueChip
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class CostGroupCreateDialog extends mixins(PartnerFallbackMixin, PermissionMixin) {
  @Prop()
  partnerId!: string;

  @Prop()
  isDialogActive!: boolean;

  @Prop()
  hideButton!: boolean;

  isLoading = false;

  costGroup: ICostGroup = new CostGroup({});

  get categories(): BackendResourceEnum[] {
    return [BackendResourceEnum.VEHICLE, BackendResourceEnum.REPORT];
  }

  get requiredRule() {
    return [requiredRule()];
  }

  get isDialogActiveLocal() {
    return this.isDialogActive;
  }

  set isDialogActiveLocal(isDialogActive: boolean) {
    this.$emit("update:isDialogActive", isDialogActive);
  }

  get suggested(): IRefSuggestion[] {
    return [];
  }

  get tags() {
    const tags = new Set<string>();
    CostModule.entities.forEach(t => t.tags?.forEach(tag => tags.add(tag)));

    return Array.from(tags).sort();
  }

  show() {
    this.costGroup = new CostGroup({ partnerId: this.partnerId });
    this.isDialogActive = true;
    this.$nextTick(() => {
      document.getElementById("titleInput")?.focus();
    });
  }

  async onRightClick() {
    try {
      this.isDialogActive = true;
      await this.costGroup.create();
      this.$emit("created", this.costGroup);
      this.$toast.success("👍");
    } catch (e) {
      handleError(e);
    } finally {
      this.isDialogActive = false;
    }
  }

  onConfirm(item: MrfiktivReferenceGen) {
    this.$log.debug("onConfirm", item);
  }

  onRefsChange() {
    this.$log.debug("onRefsChange");
  }
}
