var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.divider ? _c('v-divider', {
    staticClass: "mx-4 my-6"
  }) : _vm._e(), _vm._l(_vm.activeActions, function (action) {
    return _c('div', {
      key: action.key
    }, [_c('v-list-item', {
      attrs: {
        "dense": ""
      },
      on: {
        "click": function click($event) {
          return _vm.actionClicked(action);
        }
      }
    }, [_c('v-list-item-icon', [action.icon ? _c('v-icon', {
      attrs: {
        "small": "",
        "color": action.color
      }
    }, [_vm._v(_vm._s(action.icon))]) : _vm._e()], 1), _c('v-list-item-subtitle', {
      style: _vm.textStyle(action)
    }, [_vm._v(" " + _vm._s(action.text) + " ")])], 1)], 1);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }