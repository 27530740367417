




import ReportImageDamageDetailCard from "@/components/report/ReportImageDamageDetailCard.vue";
import { Component, Vue } from "vue-property-decorator";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";

@Component({
  components: {
    ReportImageDamageDetailCard
  }
})
export default class ReportImageDamageDetailRequired extends Vue {
  screen = ReportScreenEnum.damagedetailrequired;
}
