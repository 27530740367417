

































import { activityLogName } from "@/lib/utility/activityLogName";
import { detailedDate } from "@/lib/utility/date-helper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { MrfiktivActivityLogViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { Component, Prop } from "vue-property-decorator";
import TimelineCard from "../utility/TimelineItem.vue";
import ContextMenu from "../utility/ContextMenu.vue";
import FileDownload from "../utility/FileDownload.vue";
import ActivityTimeLineItemDelete from "./ActivityTimeLineItemDelete.vue";
import { EventModule } from "@/store/modules/event.store";
import { VehicleEventModule } from "@/store/modules/vehicle-event.store";
import { IEventUIDto } from "@/lib/dto/event/event-ui.dto";
import RefsEvent from "../utility/RefsEvent.vue";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { ActivityTypeEnum } from "@/store/modules/activity-log.store";

@Component({
  components: {
    TimelineCard,
    FileDownload,
    ActivityTimeLineItemDelete,
    ContextMenu,
    RefsEvent,
    LatestEntriesCardEmpty
  }
})
export default class ActivityTimeLineItemEventSeries extends DarkModeHighlightMixin {
  @Prop()
  activityLogEntry!: MrfiktivActivityLogViewModelGen;

  isLoading = false;

  targetEventSeries: IEventUIDto | null = null;

  get name() {
    return activityLogName(this.activityLogEntry);
  }

  get date() {
    if (this.activityLogEntry.timestamp?.created) {
      const locale = this.$t("utility.toLocalDateString").toString() || "de-de";
      return detailedDate(this.activityLogEntry.timestamp?.created, locale);
    }

    return this.$t("sign.DocumentDetail.noDate");
  }

  get title() {
    if (this.activityLogEntry.activity === ActivityTypeEnum.INTERRUPT_EVENT_SERIES_WITH_NEW) {
      return this.$t("timeLine.ActivityTimeLineItemEventSeries.titleInterrupt", { name: this.name, date: this.date });
    }

    if (this.activityLogEntry.activity === ActivityTypeEnum.CREATE_EVENT_SERIES_FROM_OLD) {
      return this.$t("timeLine.ActivityTimeLineItemEventSeries.titleNew", { name: this.name, date: this.date });
    }

    return "-";
  }

  async mounted() {
    if (!this.activityLogEntry.target?.length) return;
    const eventId = this.activityLogEntry.target[0].refId;
    const event =
      EventModule.maps.id.get(eventId)[0] ??
      VehicleEventModule.maps.id.get(eventId)[0] ??
      (await EventModule.getOne({ partnerId: this.activityLogEntry.partnerId, eventId: eventId }));
    this.targetEventSeries = event;
  }

  openEventInNew() {
    if (this.targetEventSeries?.id) {
      new GoToHelper(this.$router).goToEventDetail(this.targetEventSeries.partnerId, this.targetEventSeries.id, true);
    }
  }
}
