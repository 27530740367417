










































































































































import { IEventUIDto } from "@/lib/dto/event/event-ui.dto";
import { detailedDateWithDay, simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { Component, Prop, Vue } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import { EventModule } from "@/store/modules/event.store";
import { RRule } from "rrule";
import { handleError } from "@/lib/utility/handleError";
import { $t } from "@/lib/utility/t";
import { ActivityLogModule, ActivityTypeEnum } from "@/store/modules/activity-log.store";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { FeatureModule } from "@/store/modules/feature.store";
import AEicon from "@/components/utility/AEicon.vue";
import { GoToHelper } from "@/lib/utility/goToHelper";

enum NextOccurenceTypeEnum {
  PLANNED = "planned",
  SUGGESTED = "suggested",
  CUSTOM = "custom"
}

@Component({
  components: {
    ConfirmActionDialog,
    AEicon
  }
})
export default class EventCardAcknowledgementDialog extends Vue {
  @Prop()
  value!: IEventUIDto;

  readonly NextOccurenceTypeEnum = NextOccurenceTypeEnum;

  step = 0;

  isDialogActive = false;

  loadingAck = false;

  loadingUpdateEventSeries = false;

  loadingRecurringEvent = false;

  ackAt = new Date().toISOString().slice(0, 16);

  recurringEvent: IEventUIDto | null = null;

  suggestedNextOccurence: Date | null = null;

  customNextOccurence: Date | null = null;

  nextPlannedOccurence = "";

  nextOccurenceType: NextOccurenceTypeEnum = NextOccurenceTypeEnum.PLANNED;

  customDateMenu = false;

  get isSuggestedDifferentThanPlanned() {
    return this.nextPlannedOccurence !== this.readableSuggestedNextOccurenceDate;
  }

  get isCustomDifferentThanPlanned() {
    return this.nextPlannedOccurence !== this.readableCustomNextOccurenceDate;
  }

  get rruleTextTranslated() {
    return this.recurringEvent?.ruleTextTranslated ?? "";
  }

  get originalStartOfRecurring() {
    if (!this.recurringEvent) return "";
    return detailedDateWithDay(this.recurringEvent.startDate);
  }

  get title() {
    switch (this.step) {
      case 0:
        return $t("components.EventCardAcknowledgementDialog.title.ackAt");
      case 1:
        return $t("components.EventCardAcknowledgementDialog.title.moveSeries");
      default:
        return "";
    }
  }

  get rightText() {
    switch (this.step) {
      case 0:
        return $t("components.EventCardAcknowledgementDialog.confirm.ackAt");
      case 1:
        switch (this.nextOccurenceType) {
          case NextOccurenceTypeEnum.PLANNED:
            return $t("components.EventCardAcknowledgementDialog.confirm.leaveSeries");
          case NextOccurenceTypeEnum.SUGGESTED: {
            if (!this.isSuggestedDifferentThanPlanned) {
              return $t("components.EventCardAcknowledgementDialog.confirm.leaveSeries");
            }

            return $t("components.EventCardAcknowledgementDialog.confirm.changeSeries", {
              old: simpleDoubleDigitDate(this.nextPlannedOccurence),
              new: simpleDoubleDigitDate(this.suggestedNextOccurence?.toISOString() || "")
            });
          }
          case NextOccurenceTypeEnum.CUSTOM: {
            if (!this.isCustomDifferentThanPlanned) {
              return $t("components.EventCardAcknowledgementDialog.confirm.leaveSeries");
            }

            return $t("components.EventCardAcknowledgementDialog.confirm.changeSeries", {
              old: simpleDoubleDigitDate(this.nextPlannedOccurence),
              new: simpleDoubleDigitDate(this.customNextOccurence?.toISOString() || "")
            });
          }
          default:
            return "";
        }
      default:
        return "";
    }
  }

  get readableSuggestedNextOccurenceDate() {
    if (!this.suggestedNextOccurence) return "";
    return detailedDateWithDay(this.suggestedNextOccurence.toISOString());
  }

  get customNextOccurenceDate() {
    if (!this.customNextOccurence) return "";
    return this.customNextOccurence.toISOString().slice(0, 10);
  }

  set customNextOccurenceDate(value: string) {
    const newDate = new Date(value);
    const year = newDate.getFullYear();
    const month = newDate.getMonth();
    const date = newDate.getDate();

    const customNextOccurence = new Date(this.suggestedNextOccurence ?? "");
    customNextOccurence.setFullYear(year);
    customNextOccurence.setMonth(month);
    customNextOccurence.setDate(date);

    this.customNextOccurence = customNextOccurence;
  }

  get readableCustomNextOccurenceDate() {
    if (!this.customNextOccurence) return "";
    return detailedDateWithDay(this.customNextOccurence.toISOString());
  }

  async onRightClick() {
    switch (this.step) {
      case 0:
        this.value.loading = true;
        await this.setAcknowledged();
        this.value.loading = false;
        break;
      case 1:
        this.value.loading = true;
        await this.updateEventSeries();
        this.value.loading = false;
        break;
    }
  }

  async setAcknowledged() {
    this.loadingAck = true;
    if (this.value.isVirtual) {
      await this.value.createVirtual();
    }
    await this.value.acknowledge(new Date(this.ackAt).toISOString());
    this.setNextOccurenceDate();
    this.loadingAck = false;

    if (!FeatureModule.isExtendedAcknowledgement) {
      this.isDialogActive = false;
      return;
    }

    if (this.recurringEvent && this.nextPlannedOccurence && !this.value.isRecurringRoot) {
      this.step = 1;
    } else if (this.value.ack) {
      this.isDialogActive = false;
    } else {
      throw new Error("Acknowledgement failed.");
    }
  }

  async addReferenceToOldSeries(oldSeries: string, newSeries: string) {
    try {
      const activity = await ActivityLogModule.create({
        partnerId: this.value.partnerId,
        data: {
          source: {
            refType: BackendResourceEnum.EVENT,
            refId: oldSeries
          },
          target: [
            {
              refType: BackendResourceEnum.EVENT,
              refId: newSeries
            }
          ],
          actionType: ActionEnum.CREATE,
          activity: ActivityTypeEnum.INTERRUPT_EVENT_SERIES_WITH_NEW
        }
      });

      return activity;
    } catch (e) {
      handleError(e);
    }
  }

  async addReferenceToNewSeries(oldSeries: string, newSeries: string) {
    try {
      const activity = await ActivityLogModule.create({
        partnerId: this.value.partnerId,
        data: {
          source: {
            refType: BackendResourceEnum.EVENT,
            refId: newSeries
          },
          target: [
            {
              refType: BackendResourceEnum.EVENT,
              refId: oldSeries
            }
          ],
          actionType: ActionEnum.CREATE,
          activity: ActivityTypeEnum.CREATE_EVENT_SERIES_FROM_OLD
        }
      });

      return activity;
    } catch (e) {
      handleError(e);
    }
  }

  async updateEventSeries() {
    if ([NextOccurenceTypeEnum.SUGGESTED, NextOccurenceTypeEnum.CUSTOM].includes(this.nextOccurenceType)) {
      if (!this.recurringEvent) throw new Error("No recurring event found");

      // create new series starting from chosen nextOccurence
      this.loadingUpdateEventSeries = true;

      const originalStart = this.recurringEvent.getNextEventOfSeriesAfter(new Date(this.value.startDate))?.getTime();
      let newStart;
      if (NextOccurenceTypeEnum.SUGGESTED === this.nextOccurenceType && this.isSuggestedDifferentThanPlanned) {
        if (!this.suggestedNextOccurence) throw new Error("No nextOccurence given");
        newStart = this.suggestedNextOccurence.getTime();
      }
      if (NextOccurenceTypeEnum.CUSTOM === this.nextOccurenceType && this.isCustomDifferentThanPlanned) {
        if (!this.customNextOccurence) throw new Error("No customNextOccurence given");
        newStart = this.customNextOccurence.getTime();
      }

      if (originalStart && newStart) {
        const newSeries = await this.recurringEvent.interruptSeries(originalStart, newStart);

        // end series on the current event
        if (newSeries) {
          if (this.recurringEvent.id && newSeries.id) {
            // add event to the old series that sets a reference to the new series
            const oldAsync = this.addReferenceToOldSeries(this.recurringEvent.id, newSeries.id);
            // add event to the new series that sets a reference to the old series
            const newAsync = this.addReferenceToNewSeries(this.recurringEvent.id, newSeries.id);
            await Promise.all([oldAsync, newAsync]);
          }
        }
      }
    }

    // update the vehicle aggregation
    await this.value.updateVehicleAggregation();
    this.loadingUpdateEventSeries = false;

    this.$emit("confirmed");
    this.isDialogActive = false;
  }

  async setRecurringEvent() {
    if (!this.value.recurringEventId) return;
    try {
      this.loadingRecurringEvent = true;
      this.recurringEvent = await EventModule.getOne({
        partnerId: this.value.partnerId,
        eventId: this.value.recurringEventId
      });
    } catch (e) {
      handleError(e);
    } finally {
      this.loadingRecurringEvent = false;
    }
  }

  setNextOccurenceDate() {
    if (!this.recurringEvent) return;
    const nextDate = this.recurringEvent.getNextEventOfSeriesAfter(new Date(this.value.startDate));
    this.nextPlannedOccurence = nextDate ? detailedDateWithDay(nextDate?.toISOString()) : "";

    let nextOccurence: Date | null = null;
    const ruleOptions = { ...(this.recurringEvent.ruleOptions ?? {}) };
    if (ruleOptions.dtstart) {
      // calculate next occurence based on the ack date
      const ackAt = new Date(this.ackAt);
      ackAt.setUTCHours(0, 0, 0, 0);
      ruleOptions.dtstart = ackAt;
      const rrule = new RRule(ruleOptions);
      nextOccurence = rrule.after(ackAt, false);
    }

    this.suggestedNextOccurence = nextOccurence;
    this.customNextOccurence = nextOccurence;
  }

  async open() {
    this.step = 0;
    this.loadingAck = false;
    this.loadingUpdateEventSeries = false;
    this.loadingRecurringEvent = false;
    this.ackAt = new Date().toISOString().slice(0, 16);
    this.recurringEvent = null;
    this.suggestedNextOccurence = null;
    this.customNextOccurence = null;
    this.nextPlannedOccurence = "";
    this.isDialogActive = true;

    await this.setRecurringEvent();
  }

  goToRecurringEvent() {
    if (!this.recurringEvent?.id) return;

    new GoToHelper(this.$router).goToEventDetail(this.recurringEvent.partnerId, this.recurringEvent.id, true);
  }
}
