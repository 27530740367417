

































































































































import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { ReportRoleEnum } from "@/lib/enum/ReportRole.enum";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import { requiredRule } from "@/lib/rules/requiredRule";
import { DateVuetifyHelper } from "@/lib/utility/date-helper";
import ReportStepMixin from "@/mixins/ReportStepMixin.vue";
import { MrfiktivCreateAccidentDetailsDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { ReportModule } from "@/store/modules/report.store";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    LayoutReportStep
  }
})
export default class ReportAccidentDetails extends ReportStepMixin implements IReportStep {
  header: IReportHeaderData = {
    title: "report.accidentDetails.title",
    description: "report.accidentDetails.description"
  };

  screen = ReportScreenEnum.accidentdetails;

  isLoadingGeo = false;

  menu2 = false;

  get isAtAccidentPlace(): boolean | number {
    return ReportModule.isAtAccidentPlace;
  }

  set isAtAccidentPlace(isAtAccidentPlace: boolean | number) {
    ReportModule.setIsAtAccidentPlace(isAtAccidentPlace);
  }

  get isAddress(): boolean {
    return ReportModule.isAddress;
  }

  set isAddress(isAddress: boolean) {
    ReportModule.setIsAddress(isAddress);
  }

  get roles() {
    return [
      {
        text: this.$t("report.accidentDetails.role.responsible"),
        value: ReportRoleEnum.RESPONSIBLE
      },
      {
        text: this.$t("report.accidentDetails.role.victim"),
        value: ReportRoleEnum.VICTIM
      },
      {
        text: this.$t("report.accidentDetails.role.unclear"),
        value: ReportRoleEnum.UNCLEAR
      }
    ];
  }

  get requiredRule() {
    return [requiredRule()];
  }

  get localeDateFormat() {
    return new DateVuetifyHelper(this.date).localeDate;
  }

  /**
   * Gets date as iso string
   */
  get date(): string {
    if (!this.accidentDetails.date) {
      this.date = new Date().toISOString();
    }
    return this.accidentDetails.date;
  }

  /**
   * Set date (iso date string requried)
   */
  set date(date: string) {
    ReportModule.accidentDetails.date = date;
  }

  get formatedDate() {
    return new DateVuetifyHelper(this.date).date;
  }

  set formatedDate(date) {
    const newDate = new DateVuetifyHelper(this.date);
    newDate.date = date;
    this.date = newDate.isoDate;
  }

  get formatedtime() {
    return new DateVuetifyHelper(this.date).time;
  }

  set formatedtime(time) {
    const date = new DateVuetifyHelper(this.date);
    date.time = time;
    this.date = date.isoDate;
  }

  get accidentDetails() {
    return ReportModule.accidentDetails;
  }

  set accidentDetails(accidentDetails: MrfiktivCreateAccidentDetailsDtoGen) {
    ReportModule.mutateAccidentDetails(accidentDetails);
  }

  valid = false;
  timepickerMenu = false;

  geolocationCoordinates = {
    latitude: 0,
    longitude: 0,
    accuracy: 0
  };

  get geo() {
    return this.geolocationCoordinates;
  }

  async getPositionIfTrue() {
    if (this.isAtAccidentPlace == 1) {
      await this.getLocation();
    } else {
      this.accidentDetails.address.geo.lat = 0;
      this.accidentDetails.address.geo.lng = 0;
    }
  }

  async getLocation() {
    this.isLoadingGeo = true;
    const geo: any = await new Promise((resolve, reject) => {
      if (!("geolocation" in navigator)) {
        reject(new Error("Geolocation is not available."));
      }

      navigator.geolocation.getCurrentPosition(
        pos => {
          resolve(pos);
        },
        err => {
          reject(err);
        }
      );
    });
    this.accidentDetails.address.geo.lat = geo.coords.latitude;
    this.accidentDetails.address.geo.lng = geo.coords.longitude;

    this.isLoadingGeo = false;
  }

  get isDone() {
    return this.valid && this.isAtAccidentPlace != 0;
  }

  get color() {
    return getDefaultPartnerColor();
  }
}
