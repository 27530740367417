












import { AssetEnum } from "@/lib/AssetRepository";
import { IImageUploaded } from "@/models/Image/IImageUploaded";
import { ReportImageType } from "@/models/Report/ReportImageType";
import { PartnerImageModule } from "@/store/modules/partner-image.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import FileUploadPreview from "../utility/FileUploadPreview.vue";

@Component({
  components: {
    FileUploadPreview
  }
})
export default class PartnerSingleImageUpload extends Vue {
  @Prop()
  value!: string;

  @Prop()
  partnerId!: string;

  /**
   * The image type that should be used for uploaded images
   */
  @Prop({ default: ReportImageType.others })
  imageType!: ReportImageType;

  /**
   * Local image store
   */
  displayImages: IImageUploaded[] = [];

  /**
   * Loading state of the activity
   */
  isLoading = false;

  get AssetEnum() {
    return AssetEnum;
  }

  async mounted() {
    if (this.value) {
      const image = await PartnerImageModule.fetchOne({ partnerId: this.partnerId, id: this.value });
      this.displayImages.splice(0);
      await fetch(image.url)
        .then(response => response.blob())
        .then(blob => {
          const file = new File([blob], image.name, { type: image.type });
          this.displayImages.push({ isLoading: false, isUploaded: true, uploadId: this.value, file });
        });
    }
  }

  /**
   * Uploads a file to the report endpoint
   * @param file the file to be uploaded, provided by the file upload preview component
   */
  async handleUpload(file: File) {
    const image = {
      file,
      type: this.imageType
    };

    const uploaded = await PartnerImageModule.create({ partnerId: this.partnerId, ...image });
    this.displayImages.push({ isLoading: false, isUploaded: false, uploadId: uploaded.id, ...image });

    this.$emit("input", uploaded.id);
  }

  /**
   * Splices an image from the local image store
   * @param file the file to be removed
   */
  handleDelete(file: File) {
    const index = this.displayImages.findIndex(f => f.file.name === file.name);
    this.displayImages.splice(index, 1);
    this.$emit("input", null);
  }

  /**
   * Clears all local images
   */
  clear() {
    this.displayImages = [];
  }
}
