var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-timeline-item', {
    staticClass: "pt-6 mb-4",
    attrs: {
      "icon": "mdi-car-wrench",
      "fill-dot": "",
      "color": "success",
      "small": ""
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("timeLine.PreExistingDamageDetails.text", {
    description: _vm.description
  })) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }