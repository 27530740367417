import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { Module, getModule } from "vuex-module-decorators";
import { PaginatedBaseStore } from "../paginated-base.store";
import { PaginationFilterListElement } from "./base-pagination.store";
import store from "@/store/VuexPlugin";
import { AttachmentRequest, IAttachmentRequest } from "@/models/attachment-request.entity";
import attachmentService from "@/services/mrfiktiv/services/attachmentService";
import { MrfiktivAttachmentRequestControllerGetAllForPartnerParamsGen } from "@/services/mrfiktiv/v1/data-contracts";
import { AttachmentRequestDataAccessLayer } from "./access-layers/attachment-request.access-layer";

export class AttachmentRequestPageDataProvider extends AbstractPageDataProvider<
  IAttachmentRequest,
  MrfiktivAttachmentRequestControllerGetAllForPartnerParamsGen
> {
  async getPage(
    query: MrfiktivAttachmentRequestControllerGetAllForPartnerParamsGen
  ): Promise<IPageViewModel<IAttachmentRequest>> {
    const res = await attachmentService.getAll(query);

    if (!res.data) {
      res.data = [];
    }

    return res as IPageViewModel<IAttachmentRequest>;
  }

  getIdentifier(entity: IAttachmentRequest): string {
    return entity.id;
  }
}

@Module({
  dynamic: true,
  namespaced: true,
  name: "attachmentRequestPaginationStore",
  store
})
export class AttachmentRequestStore extends PaginatedBaseStore<
  IAttachmentRequest,
  MrfiktivAttachmentRequestControllerGetAllForPartnerParamsGen
> {
  _data = AttachmentRequestDataAccessLayer;
  _pageProvider = new AttachmentRequestPageDataProvider();
  _pager = new PageDataHandler<IAttachmentRequest, MrfiktivAttachmentRequestControllerGetAllForPartnerParamsGen>(
    this._data,
    this._pageProvider
  );

  filterOptions: PaginationFilterListElement[] = AttachmentRequest.filterables;
}

export const AttachmentRequestModule = getModule(AttachmentRequestStore);
