/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { MrfiktivAttachmentResponseDtoGen, MrfiktivAttachmentResponseViewModelGen } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class AttachmentResponse<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags attachment-response
   * @name AttachmentRequestResponseControllerAttach
   * @summary (PUBLIC - ACCESS_TOKEN) Create attachment for request
   * @request POST:/partner/{partnerId}/attachment/{attachmentId}/access/{accessToken}
   * @response `201` `MrfiktivAttachmentResponseViewModelGen` The attachment request
   */
  attachmentRequestResponseControllerAttach = (
    partnerId: string,
    attachmentId: string,
    accessToken: string,
    data: MrfiktivAttachmentResponseDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivAttachmentResponseViewModelGen, any>({
      path: `/partner/${partnerId}/attachment/${attachmentId}/access/${accessToken}`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags attachment-response
   * @name AttachmentRequestResponseControllerGet
   * @summary (PUBLIC - ACCESS_TOKEN) Get attachment request
   * @request GET:/partner/{partnerId}/attachment/{attachmentId}/access/{accessToken}
   * @response `201` `MrfiktivAttachmentResponseViewModelGen` The attachment request
   */
  attachmentRequestResponseControllerGet = (
    partnerId: string,
    attachmentId: string,
    accessToken: string,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivAttachmentResponseViewModelGen, any>({
      path: `/partner/${partnerId}/attachment/${attachmentId}/access/${accessToken}`,
      method: "GET",
      format: "json",
      ...params
    });
}
