









































































import { detailedDateWithDay, simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { CustomProjectTableHelper } from "@/lib/utility/project/customProjectTableHelper";
import {
  MrfiktivCustomFieldValueGen,
  MrfiktivProjectCustomViewFieldDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { CustomFieldEnum } from "@/store/modules/custom-field.store";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { ProjectModule } from "@/store/modules/project.store";
import ProjectGoToMixin from "@/views/project/mixins/ProjectGoToMixin.vue";
import { Component, Prop } from "vue-property-decorator";
import FilterCardPagination from "../filter/FilterCardPagination.vue";
import TicketDetailCardAssignees from "../project/TicketDetailCardAssignees.vue";
import CustomFieldValueChip from "../report/CustomFieldValueChip.vue";
import { IRefDetail } from "./RefsList.vue";
import Tooltip from "./tooltip.vue";
import TicketCrudMixin from "@/views/project/mixins/TicketCrudMixin.vue";
import { mixins } from "vue-class-component";
import { ITicket, Ticket } from "@/models/ticket.entity";
import { TicketModule } from "@/store/modules/ticket.store";
import { TicketStatusEnum } from "@/lib/enum/ticket-status.enum";

@Component({
  components: {
    FilterCardPagination,
    Tooltip,
    TicketDetailCardAssignees,
    CustomFieldValueChip
  },
  filters: {
    detailedDateWithDay
  }
})
export default class RefsTicket extends mixins(ProjectGoToMixin, TicketCrudMixin) implements IRefDetail<ITicket> {
  @Prop()
  item!: ITicket;

  @Prop()
  customConfig!: MrfiktivProjectCustomViewFieldDtoGen[];

  @Prop({ default: false })
  small!: boolean;

  @Prop({ default: true })
  showAssignees!: boolean;

  @Prop({ default: false })
  confirmable!: boolean;

  @Prop({ default: false })
  confirmablePersistant!: boolean;

  expandTicket = true;

  hasClickListener() {
    return Boolean(this.$listeners && this.$listeners.click);
  }

  hasOpenDetailListener() {
    return Boolean(this.$listeners && this.$listeners.openDetail);
  }

  hasActionListener() {
    return Boolean(this.$listeners && this.$listeners.action);
  }

  get loadingConfirm() {
    return this.loadingUpdate;
  }

  get ticketColor() {
    return TicketModule.ticketStateMap.get(this.item.state as TicketStatusEnum)?.color || "blue-grey lighten-4";
  }

  get attachEventListener() {
    if (this.hasClickListener()) {
      return { click: this.click };
    }

    return undefined;
  }

  get project() {
    if (!this.item.projectId) return undefined;
    return ProjectModule._data.get(this.item.projectId);
  }

  simpleDoubleDigitDate(date: string) {
    return simpleDoubleDigitDate(date);
  }

  detailedDateWithDay(date: string) {
    return detailedDateWithDay(date);
  }

  /**
   * Custom Field value lookup
   * @param customConfig
   */
  collectFieldValues(
    customConfig: MrfiktivProjectCustomViewFieldDtoGen[]
  ): {
    id: string;
    label: string;
    description: string;
    value: string;
    color?: string;
    dark?: boolean;
    style?: string;
  }[] {
    if (!this.item.projectId) {
      return [];
    }
    return new CustomProjectTableHelper(
      customConfig,
      Ticket.filterables,
      ProjectModule._data.get(this.item.projectId)?.configuration?.customFieldConfig ?? [],
      this.item
    ).getExtendedDocument();
  }

  get numberOfCustomFields() {
    let count = 0;
    for (const customField of this.item.values) {
      if (customField.value && this.getCustomFieldLabel(customField)) {
        count = count + 1;
      }
    }
    return count;
  }

  /**
   * returns all values if expand is true
   * returns preview (1 value), if expand is false
   */
  get visibleCustomFieldValues(): MrfiktivCustomFieldValueGen[] {
    if (this.expandTicket) {
      return this.item.values.filter(customField => customField.value && this.getCustomFieldLabel(customField));
    }

    if (this.item.values.length) {
      return [this.item.values[0]];
    }
    return this.item.values;
  }

  /**
   * Custom field configuration for a project
   */
  get customFieldProjectConfiguration() {
    if (!this.item.projectId) {
      return undefined;
    }

    return ProjectModule._data.get(this.item.projectId)?.configuration.customFieldConfig;
  }

  get isClosed() {
    return this.item.state === TicketStatusEnum.CLOSED;
  }

  get isOverdue() {
    if (this.item.due && this.item.state === TicketStatusEnum.OPEN) {
      return new Date(this.item.due) < new Date();
    }

    return false;
  }

  get isConfirmable() {
    return this.item.state !== TicketStatusEnum.CLOSED;
  }

  getUserDetails(userId: string) {
    return PartnerUserModule.maps.id.get(userId)[0];
  }

  getUserInitials(userId: string) {
    const user = this.getUserDetails(userId);
    if (!user) {
      return "";
    }

    return user.firstName[0] + user.lastName[0];
  }

  getUserName(userId: string) {
    const user = this.getUserDetails(userId);

    return user ? user.firstName + " " + user.lastName : "";
  }

  getUserMail(userId: string) {
    const user = this.getUserDetails(userId);

    return user ? user.userName : "";
  }

  getCustomFieldConfig(customFieldId: string) {
    return this.customFieldProjectConfiguration?.find(c => c.customField.id === customFieldId);
  }

  getCustomFieldLabel(customField: MrfiktivCustomFieldValueGen): string {
    const config = this.getCustomFieldConfig(customField.id);
    if (!config) {
      return "";
    }
    return config.customField.label;
  }

  transformCustomFieldValue(customField: MrfiktivCustomFieldValueGen): string {
    if (!customField.value) {
      return "";
    }
    const config = this.getCustomFieldConfig(customField.id);
    if (!config) {
      return customField.value;
    }

    if (config.customField.type === CustomFieldEnum.DATE) {
      return simpleDoubleDigitDate(customField.value);
    }
    return customField.value;
  }

  emitOpenDetail() {
    this.$emit("openDetail", this.item);
  }

  click() {
    this.$emit("click", this.item);
  }

  action() {
    this.$emit("action", this.item);
  }

  confirm() {
    this.$log.debug("confirm", this.item);
    this.confirmAsync();
    this.$emit("confirm", this.item);
  }

  async goToDetail() {
    const partnerId = this.item.partnerId;
    const number = this.item.number;

    this.goTo.ticketDetail({ partnerId, number, newTab: true });
  }

  async confirmAsync() {
    if (!this.item) {
      return;
    }

    this.partnerId = this.item.partnerId;
    this.ticket = this.item;

    this.ticket.state = TicketStatusEnum.CLOSED;
    await this.updateTicketStatus(this.ticket);
  }
}
