import { MrfiktivHttpClientProvider } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import { ReportControlService } from "../v1/ReportControlService";
import { MrfiktivReportControlControllerFindAllParamsGen } from "../v1/data-contracts";

/**
 * The service to communicate with the partner backend.
 */
class ReportControlServiceService {
  proxy: ReportControlService;

  /**
   * Construct service
   */
  constructor(mrfiktivHttpClientProvider: MrfiktivHttpClientProvider) {
    this.proxy = new ReportControlService(mrfiktivHttpClientProvider.client());
  }

  /**
   * Get all
   */
  async getAll(data: MrfiktivReportControlControllerFindAllParamsGen) {
    return (await this.proxy.reportControlControllerFindAll(data)).data;
  }
}

/**
 * Communicate with ticket API
 */
export default new ReportControlServiceService(new MrfiktivHttpClientProvider());
