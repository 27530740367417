var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-timeline-item', {
    staticClass: "pt-6",
    attrs: {
      "icon": "mdi-cake-variant-outline",
      "fill-dot": "",
      "color": "success",
      "small": ""
    }
  }, [_c('b', [_vm._v(_vm._s(_vm.name) + " ")]), _vm._v(_vm._s(_vm.$t("timeLine.ActivityTimeLineItemCreated.title", {
    date: _vm.date
  })) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }