var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('timeline-card', {
    attrs: {
      "title": _vm.$t('timeLine.PartnerReportDetailImageTimeLineElement.title', {
        number: _vm.mappedImages.length
      })
    }
  }, [!_vm.isMobileDevice ? _c('vue-picture-swipe', {
    ref: "pictureSwipe",
    staticClass: "mx-2 mt-4 mb-2",
    staticStyle: {
      "position": "absolute",
      "left": "-50000px",
      "width": "0px"
    },
    attrs: {
      "hidden": !_vm.isImageDialogActive,
      "items": _vm.mapToImage,
      "options": {
        shareEl: false,
        rotationOn: true,
        showAnimationDuration: 0,
        hideAnimationDuration: 0
      }
    },
    on: {
      "close": function close($event) {
        _vm.isImageDialogActive = false;
      }
    }
  }) : _vm._e(), _c('template', {
    slot: "menu"
  }, [_c('tooltip', {
    attrs: {
      "text": "Filter"
    }
  }, [_c('v-select', {
    staticClass: "noUnderline v-btn v-btn--outlined theme--light v-size--x-small info--text mx-1",
    attrs: {
      "append-icon": " ",
      "items": _vm.imageTypes,
      "hide-details": "",
      "dense": "",
      "chips": ""
    },
    on: {
      "change": _vm.setSelectedImageUrls
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-icon', {
          staticStyle: {
            "position": "relative",
            "right": "15px",
            "top": "3px"
          },
          attrs: {
            "x-small": "",
            "color": "info"
          }
        }, [_vm._v(" mdi-filter-outline ")])];
      },
      proxy: true
    }, {
      key: "selection",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "v-size--x-small info--text mr-1 ml-1 "
        }, [_vm._v(" " + _vm._s(_vm.$t("timeLine.PartnerReportDetailImageTimeLineElement.imageTypes.".concat(item))) + " ")])];
      }
    }, {
      key: "item",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: " v-btn theme--light v-size--x-small info--text",
          attrs: {
            "x-small": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("timeLine.PartnerReportDetailImageTimeLineElement.imageTypes.".concat(item))) + " ")])];
      }
    }]),
    model: {
      value: _vm.selectedImageTypes[0],
      callback: function callback($$v) {
        _vm.$set(_vm.selectedImageTypes, 0, $$v);
      },
      expression: "selectedImageTypes[0]"
    }
  })], 1), !_vm.isEditActive && _vm.imagesHaveId ? _c('v-btn', {
    staticClass: "mx-1",
    attrs: {
      "outlined": "",
      "x-small": "",
      "dense": "",
      "color": "info"
    },
    on: {
      "click": _vm.startEdit
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("timeLine.PartnerReportDetailImageTimeLineElement.edit")) + " ")]) : _vm._e(), _vm.isEditActive ? _c('v-btn', {
    staticClass: "mx-1",
    attrs: {
      "outlined": "",
      "x-small": "",
      "dense": "",
      "color": "info"
    },
    on: {
      "click": _vm.abortDeletion
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("timeLine.PartnerReportDetailImageTimeLineElement.close")) + " ")]) : _vm._e(), _vm.isEditActive && _vm.canUpdateReport ? _c('v-btn', {
    staticClass: "mx-1",
    attrs: {
      "disabled": !_vm.selectedForDeletion.length,
      "outlined": "",
      "x-small": "",
      "dense": "",
      "color": "error"
    },
    on: {
      "click": _vm.startDeleteDialog
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("timeLine.PartnerReportDetailImageTimeLineElement.delete")) + " ")]) : _vm._e(), _c('file-download', {
    attrs: {
      "icon": false,
      "downloadFiles": _vm.downloadableFiles,
      "filePrefix": _vm.zipPrefix
    }
  })], 1), !_vm.showSelectableGallery ? _c('div', [_c('v-row', {
    staticClass: "pa-4",
    on: {
      "mouseleave": _vm.mouseleave
    }
  }, [_c('vue-picture-swipe', {
    ref: "pictureSwipe",
    staticClass: "mx-2 mt-4 mb-2",
    attrs: {
      "items": _vm.mapToImage,
      "options": {
        shareEl: false,
        rotationOn: true
      }
    },
    on: {
      "close": function close($event) {
        _vm.isImageDialogActive = false;
      }
    }
  })], 1), _vm.canUpdateReport ? _c('partner-report-detail-image-upload', {
    staticClass: "d-print-none",
    attrs: {
      "dense": true,
      "images": _vm.images
    },
    on: {
      "onUpload": _vm.handleUpload
    }
  }) : _vm._e()], 1) : _c('v-row', {
    staticClass: "pa-4",
    on: {
      "mouseleave": _vm.mouseleave
    }
  }, [_vm._l(_vm.filteredImages, function (image) {
    return _c('v-col', {
      key: "image".concat(image.id || image.url),
      staticClass: "d-flex child-flex d-print-block",
      attrs: {
        "sm": "2",
        "cols": "4",
        "xs": "6"
      }
    }, [_vm.isEditActive ? _c('v-row', {
      staticStyle: {
        "position": "absolute",
        "z-index": "1"
      }
    }, [image.id && _vm.isSelectedForDeletion(image.url) ? _c('v-checkbox', {
      staticClass: "ml-5 mt-4",
      attrs: {
        "color": "info",
        "input-value": "1"
      },
      on: {
        "click": function click($event) {
          return _vm.toggleSelectForDeletion(image.url);
        }
      }
    }) : image.id ? _c('v-checkbox', {
      staticClass: "ml-5 mt-4",
      attrs: {
        "color": "info"
      },
      on: {
        "click": function click($event) {
          return _vm.toggleSelectForDeletion(image.url);
        }
      }
    }) : _vm._e()], 1) : _vm._e(), _c('v-card', {
      attrs: {
        "flat": "",
        "outlined": ""
      }
    }, [_c('v-img', {
      style: _vm.getImageRotationStyle(image),
      attrs: {
        "hidden": true,
        "src": image.url,
        "aspect-ratio": "1"
      },
      on: {
        "click": function click($event) {
          return _vm.onImageClick(image.url);
        }
      },
      scopedSlots: _vm._u([{
        key: "placeholder",
        fn: function fn() {
          return [_c('v-row', {
            staticClass: "fill-height ma-0",
            attrs: {
              "align": "center",
              "justify": "center"
            }
          }, [_c('v-progress-circular', {
            attrs: {
              "indeterminate": ""
            }
          })], 1)];
        },
        proxy: true
      }], null, true)
    })], 1)], 1);
  }), _vm.canUpdateReport && !_vm.isEditActive ? _c('partner-report-detail-image-upload', {
    staticClass: "d-print-none",
    attrs: {
      "images": _vm.images
    },
    on: {
      "onUpload": _vm.handleUpload
    }
  }) : _vm._e()], 2), _c('confirm-action-dialog', {
    attrs: {
      "title": _vm.$t('timeLine.PartnerReportDetailImageTimeLineElement.deleteTitle'),
      "rightText": _vm.$t('timeLine.PartnerReportDetailImageTimeLineElement.confirm'),
      "leftText": _vm.$t('timeLine.PartnerReportDetailImageTimeLineElement.deny'),
      "isDialogActive": _vm.isDeleteDialogActive,
      "rightLoading": _vm.isDeletionLoading,
      "rightDisabled": _vm.isDeletionLoading,
      "hideLeft": _vm.isDeletionLoading
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "leftClick": function leftClick($event) {
        _vm.isDeleteDialogActive = false;
      },
      "rightClick": function rightClick() {
        return _vm.deleteImage();
      }
    }
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }