
























import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { MrfiktivReportViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import TimelineCard from "../utility/TimelineItem.vue";
import PartnerReportUpdateReportDialog, { ReportUpdateTabEnum } from "./PartnerReportUpdateReportDialog.vue";
import { ILiabilityDamage } from "@/store/interface/reportbody/liability-damage.interface";

@Component({
  components: {
    TimelineCard,
    PartnerReportUpdateReportDialog
  }
})
export default class PartnerReportDetailInfoBodyTimeLineElement extends mixins(
  DarkModeHighlightMixin,
  PermissionMixin
) {
  @Prop()
  report!: MrfiktivReportViewModelGen;

  search = "";

  get ReportUpdateTabEnum() {
    return ReportUpdateTabEnum;
  }

  get isBody() {
    if (this.body) {
      if (this.body.length) {
        return true;
      }
    }
    return false;
  }

  get body() {
    return this.report.body?.filter((o: any) => o["isLiabilityDamage"] === undefined);
  }

  get hasLiabilityDamage() {
    return this.report.body?.find((o: any) => o["isLiabilityDamage"] !== undefined);
  }

  get isLiabilityDamage() {
    return (this.report.body?.find((o: any) => o["isLiabilityDamage"] !== undefined) as Partial<ILiabilityDamage>)
      ?.isLiabilityDamage;
  }
}
