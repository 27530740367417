
































import PartnerDetailAttributeWithToolTip from "@/components/partner/PartnerDetailAttributeWithToolTip.vue";
import { downloadUrlFromRemoteOrigin } from "@/lib/utility/downloadFileFunc";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import {
  MrfiktivBaseImageGen,
  MrfiktivReportViewModelGen,
  MrfiktivWitnessGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { Component, Prop } from "vue-property-decorator";
import FileDownload from "../utility/FileDownload.vue";
import TimelineCard from "../utility/TimelineItem.vue";
import PartnerReportDetailInfoWitnessTimeLineElementDialog from "./PartnerReportDetailInfoWitnessTimeLineElementDialog.vue";
import PartnerReportUpdateReportDialog, { ReportUpdateTabEnum } from "./PartnerReportUpdateReportDialog.vue";
import { mixins } from "vue-class-component";
import PermissionMixin from "@/mixins/PermissionMixin.vue";

@Component({
  components: {
    TimelineCard,
    FileDownload,
    PartnerDetailAttributeWithToolTip,
    PartnerReportDetailInfoWitnessTimeLineElementDialog,
    PartnerReportUpdateReportDialog
  }
})
export default class PartnerReportDetailInfoWitnessTimeLineElement extends mixins(
  DarkModeHighlightMixin,
  PermissionMixin
) {
  @Prop()
  report!: MrfiktivReportViewModelGen;

  search = "";

  isInjuredText(item: MrfiktivWitnessGen): string {
    if (item.isInjured) {
      return this.$t("components.partner.PartnerReportDetailInfoWitnessCard.isInjuredTrue").toString();
    } else {
      return this.$t("components.partner.PartnerReportDetailInfoWitnessCard.isInjuredFalse").toString();
    }
  }

  get ReportUpdateTabEnum() {
    return ReportUpdateTabEnum;
  }

  get witnesses() {
    return this.report?.witness || [];
  }

  get title() {
    return this.$t("components.partner.PartnerReportDetailInfoWitnessCard.cardTitle", {
      customerName: this.report.customerName,
      length: this.witnesses.length
    });
  }

  get headers() {
    return [
      { text: this.$t("components.partner.PartnerReportDetailInfoWitnessCard.firstName"), value: "firstName" },
      { text: this.$t("components.partner.PartnerReportDetailInfoWitnessCard.lastName"), value: "lastName" },
      { text: this.$t("components.partner.PartnerReportDetailInfoWitnessCard.phone"), value: "contact.phone" },
      { text: this.$t("components.partner.PartnerReportDetailInfoWitnessCard.mail"), value: "contact.email" },
      { text: this.$t("components.partner.PartnerReportDetailInfoWitnessCard.role"), value: "role" },
      { text: this.$t("components.partner.PartnerReportDetailInfoWitnessCard.images"), value: "imageIds" },
      { text: this.$t("components.partner.PartnerReportDetailInfoWitnessCard.isInjured"), value: "isInjured" },
      { text: this.$t("components.partner.PartnerReportDetailInfoWitnessCard.note"), value: "note" },
      { text: this.$t("components.partner.PartnerReportDetailInfoWitnessCard.numberplate"), value: "numberplate" },
      { text: this.$t("components.partner.PartnerReportDetailInfoWitnessCard.insurances"), value: "insurances" }
    ];
  }

  getImageForId(imageId: string) {
    return this.report.images.overviews.find((i: any) => i.id === imageId);
  }

  download(image: MrfiktivBaseImageGen) {
    downloadUrlFromRemoteOrigin(image.url, image.name);
  }
}
