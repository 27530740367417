

























import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { Component, Prop, Vue } from "vue-property-decorator";
import { IRefDetail } from "./RefsList.vue";
import Tooltip from "./tooltip.vue";
import { IInspection } from "@/models/inspection.entity";

@Component({
  components: { Tooltip },
  filters: { simpleDoubleDigitDate }
})
export default class RefsInspection extends Vue implements IRefDetail<IInspection> {
  @Prop()
  item!: IInspection;

  @Prop({ default: false })
  small!: boolean;

  confirmable = false;

  goToDetail(openInNew = true) {
    new GoToHelper(this.$router).goToInspectionDetail(
      this.item.id,
      this.item.handoverId,
      this.item.partnerId,
      openInNew
    );
  }

  hasOpenDetailListener() {
    return Boolean(this.$listeners && this.$listeners.openDetail);
  }

  emitOpenDetail() {
    this.$emit("openDetail", this.item);
  }

  get names() {
    const names = [];

    for (const attendee of this.item.attendees) {
      names.push(attendee.email);
    }

    return names;
  }
}
