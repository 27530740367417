



















import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { MrfiktivReportViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import TimelineCard from "../utility/TimelineItem.vue";
import PartnerReportUpdateReportDialog, { ReportUpdateTabEnum } from "./PartnerReportUpdateReportDialog.vue";

@Component({
  components: {
    TimelineCard,
    PartnerReportUpdateReportDialog
  }
})
export default class PartnerReportDetailInfoLeasingTimeLineElement extends mixins(
  DarkModeHighlightMixin,
  PermissionMixin
) {
  @Prop()
  report!: MrfiktivReportViewModelGen;

  search = "";

  get ReportUpdateTabEnum() {
    return ReportUpdateTabEnum;
  }

  get leasing() {
    if (!this.report.leasing) {
      return [];
    }

    return [
      {
        key: this.$t("timeLine.PartnerReportDetailInfoLeasingTimeLineElement.isLeasingKey"),
        value: this.isLeasingText
      },
      {
        key: this.$t("timeLine.PartnerReportDetailInfoLeasingTimeLineElement.isLeasingCompanyKey"),
        value: this.report.leasing.company || ""
      },
      {
        key: this.$t("timeLine.PartnerReportDetailInfoLeasingTimeLineElement.isLeasingClaimNumberKey"),
        value: this.report.leasing.claimNumber || ""
      }
    ];
  }

  get isLeasingText() {
    if (this.report.leasing?.isLeasing) {
      return this.$t("yes");
    }
    return this.$t("timeLine.PartnerReportDetailInfoLeasingTimeLineElement.isNoLeasing");
  }

  get headers() {
    return [
      { text: "Schlüssel", value: "key", width: 100, align: "start" },
      { text: "Wert", value: "value" }
    ];
  }
}
