






import { Component, Prop, Vue } from "vue-property-decorator";
import Tooltip from "./tooltip.vue";

/**
 * Egg icon coated in a fresh mint used to indicate artificial eggtelligence magic
 * Makes company stock go ↗↗brrrrrr↗↗↗↗
 */
@Component({
  components: { Tooltip }
})
export default class AEicon extends Vue {
  @Prop({ default: false })
  noColor!: boolean;

  @Prop({ default: true })
  small!: boolean;

  @Prop({ default: false })
  large!: boolean;

  @Prop({ default: "" })
  color!: string;

  get mintyFreshness() {
    if (this.noColor) {
      return "";
    }
    return "info"; //"rgb(203, 248, 201)";
  }
}
