
import { FeatureModule } from "@/store/modules/feature.store";
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class FeatureMixin extends Vue {
  get isDocumentUploadActive() {
    return FeatureModule.isDocumentUploadEnabled;
  }
}
