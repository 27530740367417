





























import { handleError } from "@/lib/utility/handleError";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { IReport } from "@/models/report.entity";
import { ISignDocument, SignDocument } from "@/models/sign-document.entity";
import { Component, Prop, Ref } from "vue-property-decorator";
import ConfirmActionDialog from "../../components/utility/ConfirmActionDialog.vue";
import ReportDocumentSignRequestSteps, { SignRequestStepperPageEnum } from "./ReportDocumentSignRequestSteps.vue";

@Component({
  components: {
    // the dialog doesn't work if the import is not like this. I don't know why. I don't get it
    ReportDocumentSignRequestSteps: () => import("./ReportDocumentSignRequestSteps.vue"),
    ConfirmActionDialog
  }
})
export default class ReportDocumentSignRequestDialog extends DarkModeHighlightMixin {
  @Ref("steps")
  signRequestSteps!: ReportDocumentSignRequestSteps;

  @Prop()
  report?: IReport;

  step: SignRequestStepperPageEnum = SignRequestStepperPageEnum.CHOICE;

  isValid = true;

  isDialogActive = false;

  isLoading = false;

  get SignRequestStepperPageEnum() {
    return SignRequestStepperPageEnum;
  }

  get hideLeft() {
    return this.step === SignRequestStepperPageEnum.SUCCESS;
  }

  get hideRight() {
    return [SignRequestStepperPageEnum.CHOICE, SignRequestStepperPageEnum.DA_IMPORT].includes(this.step);
  }

  get rightText() {
    if (this.step === SignRequestStepperPageEnum.SUCCESS || this.step === SignRequestStepperPageEnum.MAIL_SUCCESS) {
      return this.$t("components.template.dialog.preview.end");
    }

    if (this.step === SignRequestStepperPageEnum.INPUTS) {
      return this.$t("components.template.dialog.preview.create");
    }

    if (this.step === SignRequestStepperPageEnum.MAIL) {
      return this.$t("components.template.dialog.preview.send");
    }

    return this.$t("components.template.dialog.preview.next");
  }

  previous() {
    this.signRequestSteps.previous();
  }

  show() {
    this.isDialogActive = true;
  }

  /**
   * Gets sign document from backend and starts the dialog for it
   * @param partnerId
   * @param documentId
   */
  async initializeForSignDocument(partnerId: string, documentId: string) {
    try {
      this.show();

      this.isLoading = true;

      const signDocument = await new SignDocument({ partnerId, id: documentId }).fetch();
      this.$nextTick(() => {
        this.step = SignRequestStepperPageEnum.SIGNATURE_SELECTION;
        this.signRequestSteps.setSignDocument(signDocument);
      });
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * start dialog for given sign document
   * @param signDocument
   */
  async initializeForLocalSignDocument(signDocument: ISignDocument) {
    try {
      this.isLoading = true;
      const fetched = await new SignDocument({ partnerId: signDocument.partnerId, id: signDocument.id }).fetch();
      this.show();
      this.$nextTick(() => {
        this.step = SignRequestStepperPageEnum.SIGNATURE_SELECTION;
        this.signRequestSteps.setSignDocument(fetched);
      });
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }

  async next() {
    await this.signRequestSteps.next();
  }

  async close() {
    this.step = SignRequestStepperPageEnum.CHOICE;
    this.isDialogActive = false;
    (this.$refs.steps as ReportDocumentSignRequestSteps).setReport(null);
    this.$emit("close");
  }
}
