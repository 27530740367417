var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-report', {
    attrs: {
      "renderBackAndForthFooter": false,
      "isProgressDone": true,
      "showFooter": false
    }
  }, [_c('v-container', {
    staticClass: "padded"
  }, [!_vm.isLoading ? _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', [_vm._v("Häufige Fragen")]), _c('v-expansion-panels', _vm._l(_vm.faqs, function (item, i) {
    return _c('v-expansion-panel', {
      key: i
    }, [_c('v-expansion-panel-header', {
      scopedSlots: _vm._u([{
        key: "actions",
        fn: function fn() {
          return [_c('v-icon', {
            attrs: {
              "color": _vm.color
            }
          }, [_vm._v(" $expand ")])];
        },
        proxy: true
      }], null, true)
    }, [_vm._v(" " + _vm._s(item.question) + " ")]), _c('v-expansion-panel-content', [_c('span', [_vm._v(_vm._s(item.answer))])])], 1);
  }), 1)], 1) : _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-skeleton-loader', {
    staticClass: "mx-auto",
    attrs: {
      "type": "card-heading, divider, list-item-two-line,list-item-two-line,list-item-two-line"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }