




















import NumberplateForm from "@/components/utility/NumberplateForm.vue";
import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { AssetEnum } from "@/lib/AssetRepository";
import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import ReportStepMixin from "@/mixins/ReportStepMixin.vue";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { ReportModule } from "@/store/modules/report.store";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    NumberplateForm,
    LayoutReportStep
  }
})
export default class ReportNumberplate extends ReportStepMixin implements IReportStep {
  asset = AssetEnum.vinExmapleOutlined;
  example = AssetEnum.vinExample;

  screen = ReportScreenEnum.numberplate;

  header: IReportHeaderData = {
    title: "report.numberplate.title",
    description: "report.numberplate.description"
  };

  valid = false;

  setValid(value: boolean) {
    this.valid = value;
  }

  get numberplate(): string {
    return ReportModule.numberplate;
  }

  set numberplate(value: string) {
    ReportModule.setNumberplate(value);
  }

  get isDone() {
    return this.valid;
  }

  get countryCode(): CountryCodeEnum {
    return ReportModule.partnerCountryCode;
  }
}
