















































































































import CustomFieldForm from "@/components/report/CustomFieldForm.vue";
import {
  MrfiktivCustomFieldListElementConfigurationViewModelGen,
  MrfiktivCustomFieldListElementViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { MrfiktivCustomFieldViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import {
  CustomFieldCreateDtoHelper,
  CustomFieldEnum,
  customFieldIconTypeMap,
  CustomFieldHelper,
  CustomFieldModule
} from "@/store/modules/custom-field.store";
import { Component, Prop } from "vue-property-decorator";
import CustomField from "./CustomField.vue";
import CustomFieldDetailIcon from "./CustomFieldDetailIcon.vue";
import draggable from "vuedraggable";
import { mixins } from "vue-class-component";
import CustomFieldMixin from "@/components/report/CustomFieldMixin.vue";

/**
 * Form to change custom field configuration
 *
 * PROPS:
 * v-model/value: configuration of custom fields
 * valid.sync: validity of form
 */
@Component({
  components: { CustomFieldDetailIcon, CustomField, CustomFieldForm, draggable }
})
export default class CustomFieldConfigurationForm extends mixins(CustomFieldMixin) {
  @Prop()
  private value!: MrfiktivCustomFieldListElementViewModelGen[];

  @Prop()
  private valid!: boolean;

  @Prop({ default: false })
  disabled!: boolean;

  isCreateDialogActive = false;

  isCreateValid = false;

  createCustomFieldDto = new CustomFieldCreateDtoHelper();

  get customFieldList(): MrfiktivCustomFieldListElementViewModelGen[] {
    return this.value;
  }
  set customFieldList(v: MrfiktivCustomFieldListElementViewModelGen[]) {
    this.$emit("input", v);
  }

  get isValid() {
    return this.valid;
  }

  set isValid(v: boolean) {
    this.$emit("update:valid", v);
  }

  // filter out custom fields that are already configured to get a list of custom fields that is selectable
  get customFields() {
    return CustomFieldModule.paginationList.filter(f => !this.customFieldList.find(c => c.customField.id === f.id));
  }

  get filteredCustomFields() {
    return [...this.customFields].filter(f =>
      JSON.stringify(f)
        .toLowerCase()
        .includes(this.search.toLowerCase())
    );
  }

  search = "";

  remove(index: number) {
    this.customFieldList.splice(index, 1);
  }

  moveUp(indexBefore: number) {
    const moved = this.customFieldList.splice(indexBefore, 1)[0];
    const indexAfter = indexBefore - 1;
    this.customFieldList.splice(indexAfter, 0, moved);
  }

  moveDown(indexBefore: number) {
    const moved = this.customFieldList.splice(indexBefore, 1)[0];
    const indexAfter = indexBefore + 1;
    this.customFieldList.splice(indexAfter, 0, moved);
  }

  getIconForField(type: CustomFieldEnum) {
    return customFieldIconTypeMap.get(type);
  }

  addNewCustomField(customField: MrfiktivCustomFieldViewModelGen) {
    const configuration: MrfiktivCustomFieldListElementConfigurationViewModelGen = {
      isRequired: false
    };

    const newCustomField: MrfiktivCustomFieldListElementViewModelGen = {
      customField,
      configuration
    };

    this.customFieldList.push(newCustomField);
  }

  initCreation() {
    this.createCustomFieldDto = new CustomFieldCreateDtoHelper();

    this.isCreateDialogActive = true;
    this.setCustomField(new CustomFieldHelper());
  }

  async onCreate() {
    const created = await this.create(this.createCustomFieldDto);
    if (created) {
      this.createCustomFieldDto = new CustomFieldCreateDtoHelper();
      this.isCreateDialogActive = false;
      this.addNewCustomField(created);
    }
  }
}
