























import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { AssetEnum, AssetRepository } from "@/lib/AssetRepository";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import ReportStepMixin from "@/mixins/ReportStepMixin.vue";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { ReportType } from "@/store/enum/reportType";
import { ReportModule } from "@/store/modules/report.store";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    LayoutReportStep
  }
})
export default class ReportTypeSelector extends ReportStepMixin implements IReportStep {
  header: IReportHeaderData = {
    title: "report.reportTypeSelector.title",
    description: "report.reportTypeSelector.description"
  };

  screen = ReportScreenEnum.reportType;

  AssetEnum = AssetEnum;

  car = ReportType.CAR;
  container = ReportType.CONTAINER;

  reportTypeConfiguration = [
    {
      type: ReportType.CAR,
      image: AssetRepository.getAsset(this.$vuetify.theme.dark, AssetEnum.carFrontLeft),
      label: "Auto"
    },
    {
      type: ReportType.CONTAINER,
      image: AssetRepository.getAsset(this.$vuetify.theme.dark, AssetEnum.containerFrontLeft),
      label: "Container"
    },
    {
      type: ReportType.MOTORBIKE,
      image: AssetRepository.getAsset(this.$vuetify.theme.dark, AssetEnum.motorbikeFrontLeft),
      label: "Zweirad"
    },
    {
      type: ReportType.CRANE,
      image: AssetRepository.getAsset(this.$vuetify.theme.dark, AssetEnum.craneFrontLeft),
      label: "Kran"
    },
    {
      type: ReportType.TRUCK,
      image: AssetRepository.getAsset(this.$vuetify.theme.dark, AssetEnum.truckFrontLeft),
      label: "LKW"
    },
    {
      type: ReportType.TRACTOR_UNIT,
      image: AssetRepository.getAsset(this.$vuetify.theme.dark, AssetEnum.tractorUnitFrontLeft),
      label: "LKW"
    },
    {
      type: ReportType.TRAILER,
      image: AssetRepository.getAsset(this.$vuetify.theme.dark, AssetEnum.trailerFrontLeft),
      label: "Anhänger"
    },
    {
      type: ReportType.TRUCK_TRAILER,
      image: AssetRepository.getAsset(this.$vuetify.theme.dark, AssetEnum.truckTrailerFrontLeft),
      label: "Anhänger"
    },
    {
      type: ReportType.SEMITRAILER,
      image: AssetRepository.getAsset(this.$vuetify.theme.dark, AssetEnum.semitrailerFrontLeft),
      label: "Auflieger"
    },
    {
      type: ReportType.BICYCLE,
      image: AssetRepository.getAsset(this.$vuetify.theme.dark, AssetEnum.bicycleFrontLeft),
      label: "Fahrrad"
    },
    {
      type: ReportType.OTHER,
      image: AssetRepository.getAsset(this.$vuetify.theme.dark, AssetEnum.emptyData),
      label: "Sonstiges"
    }
  ];

  get activeReportTypeConfigurations() {
    return this.reportTypeConfiguration.filter(item => ReportModule.activeReportTypes.includes(item.type));
  }

  get reportType() {
    return ReportModule.reportType;
  }

  set reportType(value: ReportType) {
    ReportModule.setReportType(value);
    // Clear selected damages on other type of vehicle.
    // When switching between car and truck don't store specific parts of the truck in the car damages
    ReportModule.clearDamageLocation();
  }

  get isDone() {
    return true;
  }

  get color() {
    return getDefaultPartnerColor();
  }
}
