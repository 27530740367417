

































import { Component, Vue } from "vue-property-decorator";
import { DamageMeModule } from "@/store/modules/damage-me.store";
import PartnerReportDetailImageTimeLineElement from "@/components/partner/PartnerReportDetailImageTimeLineElement.vue";
import DamageLocatorCombined from "@/components/damagelocator/DamageLocatorCombined.vue";

@Component({
  components: {
    PartnerReportDetailImageTimeLineElement,
    DamageLocatorCombined
  }
})
export default class MyDamageDetail extends Vue {
  renderComponent = true;

  get report() {
    return DamageMeModule.currentReport;
  }

  get i18n() {
    return this.$t("report.user.MyDamageDetail");
  }

  get reportType() {
    return this.report?.reportType;
  }

  get damageLocation() {
    this.forceRerender();
    return this.report?.damage;
  }

  get images() {
    return this.report?.images || [];
  }

  get reportImagesLength() {
    const totalLength = Object.values(this.images)?.reduce((accumulator, currentArray) => {
      return accumulator + (currentArray ? currentArray.length : 0);
    }, 0);
    return totalLength;
  }

  get items() {
    return [
      {
        text: this.i18n["progressStatus"],
        value: this.report?.progressStatus || ""
      },
      {
        text: this.i18n["customerName"],
        value: this.report?.customerName || ""
      },
      {
        text: this.i18n["numberplate"],
        value: this.report?.numberplate || ""
      },
      {
        text: this.i18n["message"],
        value: this.report?.message || ""
      }
    ];
  }

  get getDamageLocatorWidth() {
    return "250px";
  }

  get getDamageLocatorHeight() {
    return "300px";
  }

  forceRerender() {
    // remove the my-component component from the DOM
    this.renderComponent = false;

    this.$nextTick(() => {
      // add my-component component in DOM
      this.renderComponent = true;
    });
  }
}
