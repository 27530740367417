import { ISnapshot, Snapshot } from "@/models/snapshot.entity";
import snapshotService from "@/services/mrfiktiv/services/snapshotService";
import store from "@/store/VuexPlugin";
import { Action, Module, getModule } from "vuex-module-decorators";
import { BaseStore } from "../base.store";
import { PaginationFilterListElement } from "./base-pagination.store";
import { HandoverSnapshotDataAccessLayer } from "./access-layers/snapshot.access-layer";

/**
 * Store for admins
 */
@Module({
  dynamic: true,
  namespaced: true,
  name: "handoverSnapshot",
  store
})
export class HandoverSnapshotStore extends BaseStore<ISnapshot> {
  _data = HandoverSnapshotDataAccessLayer;

  /**
   * Filters, ordered by most used
   */
  filterOptions: PaginationFilterListElement[] = Snapshot.filterables;

  @Action
  async fetchAll(data: { partnerId: string; handoverId: string }) {
    const res = await snapshotService.findAllByHandoverId(data.partnerId, data.handoverId);

    const snapshots = res.map(e => new Snapshot(e));

    for (const s of snapshots) {
      this._data.set(s);
    }

    return res;
  }

  @Action
  async generatePdfPreview({ handoverId, partnerId }: { handoverId: string; partnerId: string }) {
    const pdf = await snapshotService.generatePdfPreview(partnerId, handoverId);

    return pdf;
  }
}

export const HandoverSnapshotModule = getModule(HandoverSnapshotStore);
