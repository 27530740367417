import { MrfiktivHttpClientProvider } from "../mrfiktiv-http-client.provider";
import { AttachmentRequest } from "../v1/AttachmentRequest";
import { AttachmentResponse } from "../v1/AttachmentResponse";
import {
  MrfiktivAttachmentRequestControllerGetAllForPartnerParamsGen,
  MrfiktivAttachmentResponseDtoGen,
  MrfiktivCreateAttachmentRequestDtoGen,
  MrfiktivUpdateSharedContentElementDtoGen
} from "../v1/data-contracts";
import { HttpClient } from "../v1/http-client";

/**
 * Service to request data
 */
export class AttachmentService {
  /**
   * The HttpClient containing the Axios Instance
   */
  client: HttpClient;

  /**
   * The proxy for communicating with backend.
   */
  requestProxy: AttachmentRequest;
  responseProxy: AttachmentResponse;

  /**
   * @class Initialize Service
   */
  constructor(clientProvider: MrfiktivHttpClientProvider) {
    this.client = clientProvider.client();
    this.requestProxy = new AttachmentRequest(this.client);
    this.responseProxy = new AttachmentResponse(this.client);
  }

  /**
   * Creates a attachment request.
   */
  async create(partnerId: string, data: MrfiktivCreateAttachmentRequestDtoGen) {
    return this.requestProxy.attachmentRequestControllerCreateRequest(partnerId, data).then(value => value.data);
  }

  /**
   * Gets an attachment request
   * @param partnerId
   * @param attachmentId
   * @returns
   */
  async getRequest(partnerId: string, attachmentId: string) {
    return this.requestProxy.attachmentRequestControllerGetRequest(partnerId, attachmentId).then(value => value.data);
  }

  /**
   * Gets an attachment request
   * @param partnerId
   * @param attachmentId
   * @returns
   */
  async getAll(query: MrfiktivAttachmentRequestControllerGetAllForPartnerParamsGen) {
    return this.requestProxy.attachmentRequestControllerGetAllForPartner(query).then(value => value.data);
  }

  /**
   * Create attachment for the request
   *
   */
  async attach(partnerId: string, attachmentId: string, accessToken: string, data: MrfiktivAttachmentResponseDtoGen) {
    return this.responseProxy
      .attachmentRequestResponseControllerAttach(partnerId, attachmentId, accessToken, data)
      .then(value => value.data);
  }

  /**
   * Gets an attachment request.
   */
  async get(partnerId: string, attachmentId: string, accessToken: string) {
    return this.responseProxy
      .attachmentRequestResponseControllerGet(partnerId, attachmentId, accessToken)
      .then(value => value.data);
  }
  /**
   * Gets an attachment request.
   */
  async update(partnerId: string, attachmentId: string, data: MrfiktivUpdateSharedContentElementDtoGen) {
    return (await this.requestProxy.attachmentRequestControllerUpdateRequest(partnerId, attachmentId, data)).data;
  }

  /**
   *
   * @param partnerId
   * @param attachmentId
   * @returns
   */
  async delete(partnerId: string, attachmentId: string) {
    return this.requestProxy.attachmentRequestControllerDelete(partnerId, attachmentId);
  }
}

/**
 * Service to communicate with backend.
 */
export default new AttachmentService(new MrfiktivHttpClientProvider());
