






























































import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import { IRefSuggestion } from "@/store/modules/fleet-aggregation.store";
import { RefTypeMap } from "@/store/modules/refs.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import RefsSelected from "./RefsSelected.vue";
import RefsSelection from "./RefsSelection.vue";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";

@Component({
  components: { RefsSelected, RefsSelection },
  filters: {}
})
export default class RefsSelect extends Vue {
  @Prop()
  private value!: MrfiktivReferenceGen[];

  @Prop({ default: false })
  hideSuggestions!: boolean;

  get selected(): MrfiktivReferenceGen[] {
    return this.value;
  }
  set selected(v: MrfiktivReferenceGen[]) {
    this.$emit("input", v);
  }

  @Prop({ default: () => [] })
  suggested!: IRefSuggestion[];

  @Prop()
  partnerId!: string;

  @Prop({ default: true })
  isConfirmable!: boolean;

  @Prop({
    default: () => [
      BackendResourceEnum.VEHICLE,
      BackendResourceEnum.EVENT,
      BackendResourceEnum.REPORT,
      BackendResourceEnum.TICKET,
      BackendResourceEnum.DOCUMENT
    ]
  })
  categories!: BackendResourceEnum[];

  /**
   * if this is set to true the list with suggestions and page items will always be shown, not just the items that are already selected.
   * e.g. used in create ticket stepper
   */
  @Prop({ default: false })
  listMode!: boolean;

  @Prop({ default: false })
  hideEditButton!: boolean;

  @Prop({ default: false })
  loading!: boolean;

  readonly SCROLL_MARKER_ID = "refs-select-scroller";

  readonly BackendResourceEnum = BackendResourceEnum;

  readonly SUGGESTION = "suggestion";

  readonly RefTypeMap = RefTypeMap;

  isEdit = this.listMode;

  searchFilter = "";

  isLoading = false;

  isLoadingAll = false;

  selectedCategory = this.suggested.length ? this.SUGGESTION : this.categories[0];

  scrollToEnd() {
    const end = document.getElementById(this.SCROLL_MARKER_ID);
    end?.scrollIntoView({ behavior: "smooth" });
  }

  async clickEdit() {
    this.$log.debug("clickEdit");
    this.isEdit = !this.isEdit;
    this.$emit("click");
  }

  emitChange() {
    this.$emit("change", this.selected);
  }
}
