



















import { Component, Prop } from "vue-property-decorator";
import PartnerDetailAttributeWithToolTip from "@/components/partner/PartnerDetailAttributeWithToolTip.vue";
import { simpleDate } from "@/lib/utility/date-helper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";

@Component({
  components: {
    PartnerDetailAttributeWithToolTip
  }
})
export default class PartnerReportDetailInfoDateCard extends DarkModeHighlightMixin {
  @Prop()
  date!: string;

  get datePreference() {
    return simpleDate(this.date);
  }
}
