























import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { AssetEnum } from "@/lib/AssetRepository";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportInstructionData } from "@/lib/interfaces/Report/IReportInstructionData";
import { IViewImageUploadable } from "@/lib/interfaces/Report/IViewImageUploadable";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import ReportImageSubmission from "@/mixins/ReportImageSubmissionMixin.vue";
import { IImageUploaded } from "@/models/Image/IImageUploaded";
import { ReportModule } from "@/store/modules/report.store";
import { Component } from "vue-property-decorator";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import FileUploadPreview from "@/components/utility/FileUploadPreview.vue";

@Component({
  components: {
    LayoutReportStep,
    FileUploadPreview
  }
})
export default class ReportImageDamage extends ReportImageSubmission implements IReportStep, IViewImageUploadable {
  public example = AssetEnum.damage;
  asset = AssetEnum.damageOutline;

  screen = ReportScreenEnum.componentimage;

  header: IReportHeaderData = {
    title: "report.imageDamage.title",
    description: "report.imageDamage.description",
    image: AssetEnum.damageExample,
    debug: this.files
  };

  instruction: IReportInstructionData = {
    title: "report.imageDamage.title",
    text: "report.imageDamage.instructionText",
    pictures: [AssetEnum.damageInstruction],
    example: AssetEnum.damageExample,
    display: true
  };

  /**
   * @inheritdoc
   */
  async handleUpload(file: File): Promise<boolean> {
    await ReportModule.addDamage(file);
    return true;
  }

  /**
   * @inheritdoc
   */
  async handleDelete(file: File): Promise<boolean> {
    return await ReportModule.removeDamage(file);
  }

  /**
   * @inheritdoc
   */
  get files(): IImageUploaded[] {
    return ReportModule.damages;
  }
}
