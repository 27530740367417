




import ReportDescriptionCard from "@/components/report/ReportDescriptionCard.vue";
import ReportStepMixin from "@/mixins/ReportStepMixin.vue";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    ReportDescriptionCard
  }
})
export default class ReportDescription extends ReportStepMixin {
  screen = ReportScreenEnum.message;
}
