var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-list-item', [_vm._t("icon"), _c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "text-truncate"
  }, [_vm.hasOpenDetailListener() ? _c('a', {
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.emitOpenDetail.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.item.number) + " ")]) : _c('span', [_vm._v(" #" + _vm._s(_vm.item.number) + " ")])]), _vm.item.handover && _vm.item.handover.title && !_vm.small ? _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.item.handover.title) + " ")]) : _vm._e(), !_vm.small && _vm.item.status === _vm.DigitalSignatureRequestStatusEnum.SIGNED ? _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t("sign.DocumentDetail.signed")) + " ")]) : _vm._e()], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }