
import { Component, Vue } from "vue-property-decorator";
import { requiredRule } from "@/lib/rules/requiredRule";

@Component({})
export default class RulesMixin extends Vue {
  get requiredRule() {
    return [requiredRule()];
  }

  /**
   * hides error text
   */
  get requiredRuleSilent() {
    return [requiredRule(" ")];
  }
}
