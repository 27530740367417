












































import { handleError } from "@/lib/utility/handleError";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import {
  MrfiktivActivityLogViewModelGen,
  MrfiktivUpdateActivityLogDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { ActivityLogModule } from "@/store/modules/activity-log.store";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import TemplateEditor from "../template/TemplateEditor.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";

@Component({
  components: {
    ConfirmActionDialog,
    TemplateEditor
  }
})
export default class ActivityTimeLineItemUpdate extends mixins(PermissionMixin) {
  @Prop()
  activityLogEntry!: MrfiktivActivityLogViewModelGen;

  @Prop({ default: false })
  text!: boolean;

  isEditDialogActive = false;

  isLoading = false;

  updateData: MrfiktivUpdateActivityLogDtoGen = {};

  startDialog() {
    this.isEditDialogActive = true;
    this.updateData = { comment: this.activityLogEntry.comment };
  }

  async deleteActivity() {
    try {
      this.isLoading = true;

      const partnerId = this.activityLogEntry.partnerId;
      const activityId = this.activityLogEntry.id;
      const data = this.updateData;

      const updated = await ActivityLogModule.update({
        partnerId,
        activityId,
        data
      });

      ActivityLogModule.replaceInList({ ...updated, user: this.activityLogEntry.user });

      this.$toast.success("👍");

      this.isEditDialogActive = false;
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
