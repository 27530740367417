/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { MrfiktivHttpClientProvider } from "../mrfiktiv-http-client.provider";
import { MyReportService } from "../v1/MyReportService";

export class ReportMeService {
  /**
   * The proxy.
   */
  proxy: MyReportService;

  /**
   * @class Initialize ReportMeService
   */
  constructor(clientProvider: MrfiktivHttpClientProvider) {
    this.proxy = new MyReportService(clientProvider.client());
  }

  findAll() {
    return this.proxy.reportMeControllerFindMyReports();
  }

  findOne(id: string) {
    return this.proxy.reportMeControllerFindMyReport(id);
  }
}

export default new ReportMeService(new MrfiktivHttpClientProvider());
