import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { MrfiktivTransactionDataViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";

@IsFilterable
class TransactionDataBase implements MrfiktivTransactionDataViewModelGen {
  @FilterConfig({ type: FilterTypes.NUMBER, displayName: "objects.transactionData.mileage" })
  mileage?: number;

  constructor(data?: Partial<MrfiktivTransactionDataViewModelGen>) {
    this.mileage = data?.mileage;
  }
}

type ITransactionData = TransactionDataBase;
const TransactionData = Filter.createForClass(TransactionDataBase);

export { ITransactionData, TransactionData };
