var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.title ? _c('v-timeline-item', {
    staticClass: "pt-6 mb-n6",
    attrs: {
      "icon": "mdi-export-variant",
      "fill-dot": "",
      "color": "success",
      "small": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]) : !_vm.isEditable ? _c('v-timeline-item', {
    staticClass: "pt-6 mb-n6",
    attrs: {
      "icon": "mdi-export-variant",
      "fill-dot": "",
      "color": "success",
      "small": ""
    }
  }, [_c('b', [_vm._v(_vm._s(_vm.name) + " ")]), _vm._v(_vm._s(_vm.$t("timeLine.ActivityTimeLineItemShare.title", {
    date: _vm.date
  })) + " ")]) : _c('v-timeline-item', {
    staticClass: "pt-6 mb-n6",
    attrs: {
      "icon": "mdi-export-variant",
      "fill-dot": "",
      "color": "success",
      "small": ""
    }
  }, [_c('b', [_vm._v(_vm._s(_vm.name) + " ")]), _vm._v(_vm._s(_vm.$t("timeLine.ActivityTimeLineItemShare.titleEdit", {
    date: _vm.date
  })) + " ")]), _vm.loading ? _c('v-timeline-item', {
    attrs: {
      "small": "",
      "icon": "mdi-file-sign",
      "fill-dot": ""
    }
  }, [_c('v-skeleton-loader', {
    staticClass: "mx-auto",
    attrs: {
      "type": "paragraph"
    }
  })], 1) : _vm.errorState ? _c('div', [_c('v-timeline-item', {
    attrs: {
      "small": "",
      "icon": "mdi-close",
      "color": "error",
      "fill-dot": ""
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "justify-content": "space-between",
      "align-items": "center"
    }
  }, [_c('v-card-text', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('timeLine.ActivityTimeLineItemShare.error'))
    }
  }), _vm.activityLogEntry ? _c('activity-time-line-item-delete', {
    attrs: {
      "partnerId": _vm.activityLogEntry.partnerId,
      "activityId": _vm.activityLogEntry.id
    }
  }) : _vm._e()], 1)])], 1)], 1) : _c('div', [_vm.sharedContent ? _c('timeline-card', {
    attrs: {
      "divider": false,
      "showAvatar": false,
      "title": _vm.shareTitle
    }
  }, [_c('template', {
    slot: "menu"
  }, [_vm.$vuetify.breakpoint.smAndDown ? _c('context-menu', [_c('v-list', [_vm.isActive && !_vm.sharedContent.isNotFound ? _c('v-list-item', {
    on: {
      "click": _vm.copyLinkToClipboard
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("timeLine.ActivityTimeLineItemShare.btnText")) + " ")]) : _vm._e(), _c('v-list-item', {
    on: {
      "click": function click($event) {
        _vm.showSideCard = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("common.nouns.detail")) + " ")]), _c('activity-time-line-item-delete', {
    attrs: {
      "text": true,
      "partnerId": _vm.activityLogEntry.partnerId,
      "activityId": _vm.activityLogEntry.id
    }
  })], 1)], 1) : _c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "center"
    }
  }, [_vm.isActive && !_vm.sharedContent.isNotFound ? _c('v-btn', {
    staticClass: "mr-1",
    attrs: {
      "x-small": "",
      "dense": "",
      "outlined": "",
      "color": "info"
    },
    on: {
      "click": _vm.copyLinkToClipboard
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("timeLine.ActivityTimeLineItemShare.btnText")) + " ")]) : _vm._e(), _c('v-btn', {
    attrs: {
      "x-small": "",
      "dense": "",
      "outlined": "",
      "color": "info"
    },
    on: {
      "click": function click($event) {
        _vm.showSideCard = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("common.nouns.detail")) + " ")]), _c('activity-time-line-item-delete', {
    staticClass: "mt-n1 mb-n2",
    attrs: {
      "partnerId": _vm.activityLogEntry.partnerId,
      "activityId": _vm.activityLogEntry.id
    }
  })], 1)], 1), _vm.sharedContent.title || _vm.sharedContent.description ? _c('v-divider') : _vm._e(), _c('v-card-text', [_vm.sharedContent.title ? _c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.sharedContent.title) + " ")]) : _vm._e(), _vm.sharedContent.description ? _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.sharedContent.description) + " ")]) : _vm._e()], 1)], 2) : _vm._e()], 1), _vm.showSideCard && _vm.sharedContent && !_vm.sharedContent.screenOrder ? _c('shared-content-side-card', {
    attrs: {
      "hideSuggested": false,
      "value": _vm.sharedContent
    },
    on: {
      "close": function close($event) {
        _vm.showSideCard = false;
      }
    }
  }) : _vm.showSideCard && _vm.sharedContent && _vm.sharedContent.screenOrder ? _c('attachment-request-side-card', {
    attrs: {
      "value": _vm.sharedContent
    },
    on: {
      "close": function close($event) {
        _vm.showSideCard = false;
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }