



















import { Component, Prop, Vue } from "vue-property-decorator";
import StatisticsCardColumn from "../cards/StatisticsCardColumn.vue";

export interface IStatisticRowElement {
  icon: string;
  title: string;
  color: string;
  data: string;
  tooltip?: string;
  display?: boolean;
  click?: () => void;
}

@Component({ components: { StatisticsCardColumn } })
export default class StatisticsCardRow extends Vue {
  @Prop({})
  data!: IStatisticRowElement[];

  @Prop({ default: false })
  loading?: boolean;
}
