var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "locatorDiv",
    staticClass: "svg-container",
    style: {
      height: "".concat(_vm.height, "px")
    },
    attrs: {
      "id": _vm.svgContainerId
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }