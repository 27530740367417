














































































import { ResourceEnum } from "@/store/enum/authResourceEnum";
import ContextMenu from "./ContextMenu.vue";
import RefsEvent from "./RefsEvent.vue";
import RefsReport from "./RefsReport.vue";
import RefsVehicle from "./RefsVehicle.vue";
import RefsTicket from "./RefsTicket.vue";
import RefsCost from "./RefsCost.vue";
import RefsCostGroup from "./RefsCostGroup.vue";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import { Prop, Component, Vue, Ref } from "vue-property-decorator";
import { MrfiktivReportViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { IVehicle } from "@/models/vehicle.entity";
import { IEventUIDto } from "@/lib/dto/event/event-ui.dto";
import RefsDocument from "./RefsDocument.vue";
import { ITicket } from "@/models/ticket.entity";
import { MrfiktivProjectCustomViewFieldDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import RefsSnapshot from "./RefsSnapshot.vue";

export interface IRefDetail<T> {
  /**
   * The ref to be displayed.
   */
  item: T;

  /**
   * Displays the ref with less details.
   */
  small: boolean;

  /**
   * Indicates wether the ref should be displayed as confirmable.
   */
  confirmable: boolean;

  /**
   * Navigates to the detail page of the ref.
   */
  goToDetail: () => void;
}

type ItemTypes = MrfiktivReportViewModelGen | IVehicle | IEventUIDto | ITicket;

@Component({
  components: {
    ContextMenu,
    RefsReport,
    RefsVehicle,
    RefsEvent,
    RefsTicket,
    RefsDocument,
    RefsCost,
    RefsCostGroup,
    RefsSnapshot,
    LatestEntriesCardEmpty
  }
})
export default class RefsList extends Vue implements IRefDetail<ItemTypes> {
  @Prop()
  item!: ItemTypes;

  @Prop()
  itemPromise?: () => Promise<ItemTypes>;

  @Prop()
  refType!: ResourceEnum;

  @Prop({ default: false })
  small!: boolean;

  @Prop({ default: false })
  confirmable!: boolean;

  @Prop()
  customConfig!: MrfiktivProjectCustomViewFieldDtoGen[];

  loading = true;

  loadedItem: ItemTypes | null = null;

  readonly ResourceEnum = ResourceEnum;

  @Ref("refDetail")
  refDetail!: IRefDetail<ItemTypes>;

  emitOpenDetail(v: any) {
    this.$emit("openDetail", v);
  }

  goToDetail() {
    this.refDetail?.goToDetail();
  }

  async mounted() {
    try {
      this.loading = true;

      if (this.item) {
        this.loadedItem = this.item;
      } else if (this.itemPromise) {
        const loadedItem = await this.itemPromise();
        this.loadedItem = loadedItem;
      }
    } catch (e) {
      this.$log.error(e);
    } finally {
      this.loading = false;
    }
  }
}
