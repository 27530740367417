/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { MrfiktivExceptionViewmodelGen, MrfiktivReportViewModelGen } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class MyReportService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags My Report Service
   * @name ReportMeControllerFindMyReports
   * @summary Get my reports.
   * @request GET:/report/me
   * @secure
   * @response `200` `(MrfiktivReportViewModelGen)[]` The reports of a user
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  reportMeControllerFindMyReports = (params: RequestParams = {}) =>
    this.http.request<MrfiktivReportViewModelGen[], MrfiktivExceptionViewmodelGen>({
      path: `/report/me`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags My Report Service
   * @name ReportMeControllerFindMyReport
   * @summary Get my report.
   * @request GET:/report/me/{id}
   * @secure
   * @response `200` `MrfiktivReportViewModelGen` The report of a user
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not Found
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  reportMeControllerFindMyReport = (id: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivReportViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/report/me/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
}
