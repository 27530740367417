import { MrfiktivHttpClientProvider } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import { PartnerImage } from "../v1/PartnerImage";
import {
  MrfiktivCreateReportImageDtoGen,
  MrfiktivPageViewModelGen,
  MrfiktivPartnerImageControllerFindAllParamsGen,
  MrfiktivPartnerImageViewModelGen
} from "../v1/data-contracts";

/**
 * Communicate with image API
 */
class PartnerImageService {
  mrfiktivProxy: PartnerImage;

  /**
   * Construct service
   */
  constructor(mrfiktivHttpClientProvider: MrfiktivHttpClientProvider) {
    this.mrfiktivProxy = new PartnerImage(mrfiktivHttpClientProvider.client());
  }

  /**
   * Create an image
   */
  async create(partnerId: string, data: MrfiktivCreateReportImageDtoGen) {
    return (await this.mrfiktivProxy.partnerImageControllerUploadImageQuery(partnerId, data)).data;
  }

  /**
   * Get all images
   */
  async get(
    query: MrfiktivPartnerImageControllerFindAllParamsGen
  ): Promise<MrfiktivPageViewModelGen & { data?: MrfiktivPartnerImageViewModelGen[] }> {
    return (await this.mrfiktivProxy.partnerImageControllerFindAll({ ...query })).data;
  }

  /**
   * Get an image
   */
  async getOne(partnerId: string, id: string) {
    return (await this.mrfiktivProxy.partnerImageControllerFindOne(partnerId, id)).data;
  }

  /**
   * Delete an image
   */
  async delete(partnerId: string, id: string) {
    return (await this.mrfiktivProxy.partnerImageControllerRemove(partnerId, id)).data;
  }
}

/**
 * Communicate with image API
 */
export default new PartnerImageService(new MrfiktivHttpClientProvider());
