






















































































import { mixins } from "vue-class-component";
import { Component, Prop, Vue } from "vue-property-decorator";
import { PartnerPdfDataAccessLayer } from "@/store/modules/access-layers/partner-pdf.access-layer";
import { PartnerPdf, IPartnerPdf } from "@/models/partner-pdf.entity";
import FileInput from "../utility/FileInput.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import RefsDocument from "../utility/RefsDocument.vue";
import { handleError } from "@/lib/utility/handleError";

@Component({
  components: { FileInput, RefsDocument },
  filters: { simpleDoubleDigitDate }
})
export default class PartnerPdfs extends mixins(Vue) {
  @Prop()
  value!: string[];

  @Prop()
  partnerId!: string;

  @Prop()
  loading!: boolean;

  @Prop({ default: true })
  isMenu!: boolean;

  isAddMenu = false;

  pdfs: IPartnerPdf[] = [];

  newFile: File | null = null;

  isCreatingNewPdf = false;

  async mounted() {
    for (const pdfId of this.value) {
      const pdf =
        PartnerPdfDataAccessLayer.maps.id.get(pdfId)[0] ?? new PartnerPdf({ id: pdfId, partnerId: this.partnerId });
      await pdf.fetch();
      this.pdfs.push(pdf);
    }
  }

  simpleDoubleDigitDate(date: string) {
    return simpleDoubleDigitDate(date);
  }

  async add() {
    if (!this.newFile) {
      return;
    }

    try {
      this.isCreatingNewPdf = true;
      const created = await new PartnerPdf({ partnerId: this.partnerId, pdf: this.newFile }).create();
      this.pdfs.push(created);
      this.$emit(
        "input",
        this.pdfs.map(pdf => pdf.id)
      );
      this.newFile = null;
    } catch (e) {
      handleError(e);
    } finally {
      this.isCreatingNewPdf = false;
    }
  }

  async remove(index: number) {
    try {
      this.isCreatingNewPdf = true;
      this.value.splice(index, 1);
      this.pdfs.splice(index, 1);
      this.$emit(
        "input",
        this.pdfs.map(pdf => pdf.id)
      );
    } catch (e) {
      handleError(e);
    } finally {
      this.isCreatingNewPdf = false;
    }
  }
}
