var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-report-step', {
    attrs: {
      "isDone": _vm.valid,
      "title": _vm.configuration.header.title,
      "description": _vm.configuration.header.description,
      "image": _vm.configuration.header.image,
      "debug": _vm.configuration.header.debug,
      "hasInstructions": _vm.instruction ? true : false,
      "instructionPictures": _vm.configuration.instruction.pictures,
      "instructionText": _vm.configuration.instruction.text,
      "instructionTitle": _vm.configuration.instruction.title
    }
  }, [_c('v-radio-group', {
    attrs: {
      "label": _vm.$t('report.imageDamageOptional.isDamage.question')
    },
    model: {
      value: _vm.isDamage,
      callback: function callback($$v) {
        _vm.isDamage = $$v;
      },
      expression: "isDamage"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": _vm.$t('report.imageDamageOptional.isDamage.false'),
      "value": false
    }
  }), _c('v-radio', {
    attrs: {
      "label": _vm.$t('report.imageDamageOptional.isDamage.true'),
      "value": true
    }
  })], 1), _vm.isDamage ? _c('v-row', {
    attrs: {
      "fill-height": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('v-textarea', {
    staticClass: "contain padded",
    attrs: {
      "clearable": "",
      "auto-grow": "",
      "outlined": "",
      "label": _vm.$t('report.imageDamageOptional.isDamage.placeHolderText'),
      "counter": _vm.counter,
      "rows": "7",
      "data-test-form-description": ""
    },
    on: {
      "blur": _vm.updateMessage
    },
    model: {
      value: _vm.message,
      callback: function callback($$v) {
        _vm.message = $$v;
      },
      expression: "message"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('file-upload-preview', {
    attrs: {
      "example": _vm.configuration.exampleImages.damageDetail.example,
      "displayFiles": _vm.files,
      "asset": _vm.configuration.exampleImages.damageDetail.overlay,
      "instruction": _vm.instruction,
      "onDelete": _vm.handleDelete,
      "onUpload": _vm.handleUpload
    }
  })], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }