/**
 * removes all special characters from numberplate
 *
 * @example 'AB CD 123' becomes 'AB-CD 123'
 * @example 'AB-CD   123' becomes 'AB-CD 123'
 * @example 'AB-CD-123' becomes 'AB-CD 123'
 * @example 'AB - CD - 123' becomes 'AB-CD 123'
 * @param numberPlate the numberplate to format
 * @returns the formatted numberplate
 */
export function formatNumberPlate(numberPlate: string): string {
  const specialChars = findAllSpecialCharIndices(numberPlate);

  numberPlate = numberPlate.trim();

  if (specialChars.length === 0) {
    return numberPlate;
  }

  let lastCharIndex = -2;
  let removed = 0;

  for (const charIndex of specialChars) {
    if (lastCharIndex === -2) {
      //replace first special cahracter with -
      numberPlate = numberPlate.substring(0, charIndex) + "-" + numberPlate.substring(charIndex + 1);
    } else if (lastCharIndex === charIndex - 1) {
      // there are multiple special characters following each other - remove them
      numberPlate = numberPlate.substring(0, charIndex - removed) + numberPlate.substring(charIndex + 1 - removed);
      removed++;
    } else {
      numberPlate =
        numberPlate.substring(0, charIndex - removed) + " " + numberPlate.substring(charIndex + 1 - removed);
    }
    lastCharIndex = charIndex;
  }

  return numberPlate.toUpperCase();
}

/**
 * lists all special chars of string
 *
 * @example AB-CD - 123 becomes [2, 5, 6, 7]
 * @param numberPlate
 */
function findAllSpecialCharIndices(numberPlate: string): number[] {
  const indices = [];
  let index = 0;

  for (const letter of numberPlate) {
    if (letter.match(/[^a-zA-Z0-9]/)) {
      indices.push(index);
    }
    index++;
  }

  return indices;
}
