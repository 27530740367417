/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { ThgRegistrationDtoGen } from "@/services/thg/v1/data-contracts";

/**
 * Implementation of the thg registration dto
 */
export class ThgRegistration implements ThgRegistrationDtoGen {
  /**
   * @inheritdoc
   */
  firstname: string;

  /**
   * @inheritdoc
   */
  name: string;

  /**
   * @inheritdoc
   */
  driveTyp: string;

  /**
   * @inheritdoc
   */
  identificationnumber: string;
  /**
   * @inheritdoc
   */
  firstregistrationDay: string;

  /**
   * @inheritdoc
   */
  firstregistrationMonth: string;

  /**
   * @inheritdoc
   */
  firstregistrationYear: string;

  /**
   * @inheritdoc
   */
  vehicleClass: string;

  /**
   * @inheritdoc
   */
  street?: string;

  /**
   * @inheritdoc
   */
  zipCode?: string;

  /**
   * @inheritdoc
   */
  city?: string;

  /**
   * @inheritdoc
   */
  huYear?: string;

  /**
   * @inheritdoc
   */
  huMonth?: string;

  /**
   * @inheritdoc
   */
  manufacturerNameCode?: string;

  /**
   * @inheritdoc
   */
  manufacturerTypeCode?: string;

  /**
   * @inheritdoc
   */
  numberplate?: string;

  /**
   * @inheritdoc
   */
  manufacturerName?: string;

  /**
   * @inheritdoc
   */
  manufacturerType?: string;

  static VEHICLE_CLASS = [
    { showText: "M1 (PKW)", value: "M1" },
    { showText: "N1 (LNF)", value: "N1" },
    { showText: "N2 (schwere Nutzfahrzeuge)", value: "N2" },
    { showText: "N3 (schwere Nutzfahrzeuge)", value: "N3" },
    { showText: "M3 (Bus)", value: "M3" },
    { showText: "Andere", value: "other" }
  ];

  static DAY = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31"
  ];

  static MONTH = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

  static YEAR = [
    "2024",
    "2023",
    "2022",
    "2021",
    "2020",
    "2019",
    "2018",
    "2017",
    "2016",
    "2015",
    "2014",
    "2013",
    "2012",
    "2011",
    "2010",
    "2009",
    "2008",
    "2007",
    "2006",
    "2005",
    "2004",
    "2003",
    "2002",
    "2001",
    "2000",
    "1999",
    "1998",
    "1997",
    "1996",
    "1995",
    "1994",
    "1993",
    "1992",
    "1991",
    "1990"
  ];

  static DRIVE_TYPE = [
    "0000",
    "0001",
    "0002",
    "0003",
    "0004",
    "0005",
    "0006",
    "0007",
    "0008",
    "0009",
    "0010",
    "0011",
    "0012",
    "0013",
    "0014",
    "0015",
    "0016",
    "0017",
    "0018",
    "0019",
    "0020",
    "0021",
    "0022",
    "0023",
    "0024",
    "0025",
    "0026",
    "0027",
    "0028",
    "0029",
    "0030",
    "0031",
    "0032",
    "0033",
    "0034",
    "0035",
    "0036",
    "0037",
    "0038",
    "9999"
  ];

  /**
   * Adds the properties of an existing registration
   * @param registration optional provide a registration
   */
  constructor(registration?: ThgRegistrationDtoGen) {
    this.identificationnumber = "";
    this.name = "";
    this.firstname = "";

    this.firstregistrationDay = "";
    this.firstregistrationMonth = "";
    this.firstregistrationYear = "";

    this.vehicleClass = ThgRegistration.VEHICLE_CLASS[0].value;
    this.driveTyp = "0004";

    if (registration) {
      for (const key in registration) {
        this[key] = registration[key];
      }
    }
  }
}
