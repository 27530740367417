







import { Component, Vue, Prop } from "vue-property-decorator";
import SideCard from "../utility/SideCard.vue";
import AttachmentRequestDetail from "./AttachmentRequestDetail.vue";
import { IAttachmentRequest } from "@/models/attachment-request.entity";

@Component({
  components: {
    SideCard,
    AttachmentRequestDetail
  }
})
export default class AttachmentRequestSideCard extends Vue {
  @Prop()
  value!: IAttachmentRequest;

  isLoading = false;

  close() {
    this.$emit("close");
  }

  async mounted() {
    this.isLoading = true;

    await this.value.fetch();

    if (this.value.isNotFound) {
      this.close();
    }

    this.isLoading = false;
  }
}
