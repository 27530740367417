var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-report-step', {
    attrs: {
      "isDone": _vm.isDone,
      "title": _vm.header.title,
      "description": _vm.header.description,
      "image": _vm.header.image,
      "debug": _vm.header.debug
    }
  }, [_c('v-card', {
    staticClass: "pt-4 pr-4 pl-4",
    attrs: {
      "flat": "",
      "max-width": "500"
    }
  }, [_c('v-form', {
    ref: "form",
    attrs: {
      "id": "thgContact"
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('div', {
    staticClass: "ml-n7 mr-n7"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t("report.reportContact.numberplate")))]), _c('v-card-text', [_c('numberplate-form', {
    attrs: {
      "valid": _vm.numberplateValid,
      "countryCode": _vm.countryCode
    },
    on: {
      "valid": function valid($event) {
        _vm.numberplateValid = $event;
      }
    },
    model: {
      value: _vm.numberplate,
      callback: function callback($$v) {
        _vm.numberplate = $$v;
      },
      expression: "numberplate"
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "mb-n6",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t("components.profile.EditProfileDialog.general")) + " ")]), _c('v-card-subtitle', [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerBillingInformationDataDialog.question.company")) + " ")])], 1), _c('v-card-text', [_c('v-row', {
    staticClass: "mt-n4"
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.isCompany,
      callback: function callback($$v) {
        _vm.isCompany = $$v;
      },
      expression: "isCompany"
    }
  }, [_c('v-radio', {
    staticClass: "ml-3",
    attrs: {
      "label": _vm.$t('components.profile.EditProfileDialog.private'),
      "value": false
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "8"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.isCompany,
      callback: function callback($$v) {
        _vm.isCompany = $$v;
      },
      expression: "isCompany"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": _vm.$t('components.profile.EditProfileDialog.company'),
      "value": true,
      "data-test-form-iscompany": ""
    }
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "mb-n6",
    attrs: {
      "outlined": "",
      "label": _vm.$t('components.profile.EditProfileDialog.name'),
      "rules": [function (v) {
        return !!v || _vm.$t('components.profile.EditProfileDialog.mandatoryInput');
      }],
      "data-test-profile-firstName": ""
    },
    model: {
      value: _vm.firstName,
      callback: function callback($$v) {
        _vm.firstName = $$v;
      },
      expression: "firstName"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "mb-n6",
    attrs: {
      "outlined": "",
      "label": _vm.$t('components.profile.EditProfileDialog.lastname'),
      "rules": [function (v) {
        return !!v || _vm.$t('components.profile.EditProfileDialog.mandatoryInput');
      }],
      "data-test-profile-lastName": ""
    },
    model: {
      value: _vm.lastName,
      callback: function callback($$v) {
        _vm.lastName = $$v;
      },
      expression: "lastName"
    }
  })], 1)], 1), _vm.isCompany ? _c('v-card-text', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "mb-n6 mt-n7",
    attrs: {
      "rules": [function (v) {
        return !!v || _vm.$t('components.profile.EditProfileDialog.mandatoryInput');
      }],
      "outlined": "",
      "label": _vm.$t('components.profile.EditProfileDialog.companyName') + ' *',
      "data-test-profile-company": ""
    },
    model: {
      value: _vm.company,
      callback: function callback($$v) {
        _vm.company = $$v;
      },
      expression: "company"
    }
  })], 1)], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t("components.profile.EditProfileDialog.taxInformation")) + " ")]), _c('v-card-subtitle', [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerBillingInformationDataDialog.question.tax")) + " ")])], 1), _c('v-card-text', [_c('v-row', {
    staticClass: "mt-n13 mb-n7"
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.isTaxDeductible,
      callback: function callback($$v) {
        _vm.isTaxDeductible = $$v;
      },
      expression: "isTaxDeductible"
    }
  }, [_c('v-radio', {
    staticClass: "ml-3",
    attrs: {
      "label": _vm.$t('components.profile.EditProfileDialog.yes'),
      "value": true
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "8"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.isTaxDeductible,
      callback: function callback($$v) {
        _vm.isTaxDeductible = $$v;
      },
      expression: "isTaxDeductible"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": _vm.$t('components.profile.EditProfileDialog.no'),
      "value": false
    }
  })], 1)], 1)], 1)], 1), _vm.isTaxDeductible ? _c('div', [_c('v-card-text', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "mt-n4 mb-n4",
    attrs: {
      "outlined": "",
      "rules": _vm.taxNumberRules,
      "label": "".concat(_vm.$t('components.profile.EditProfileDialog.taxnumber'), " *"),
      "data-test-profile-taxnumber": ""
    },
    model: {
      value: _vm.taxnumber,
      callback: function callback($$v) {
        _vm.taxnumber = $$v;
      },
      expression: "taxnumber"
    }
  })], 1)], 1)], 1) : _vm._e(), _c('v-col', {
    staticClass: "mb-n6",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t("components.profile.EditProfileDialog.contactInformation")) + " ")])], 1), _c('v-card-text', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "mb-n6",
    attrs: {
      "outlined": "",
      "label": "".concat(_vm.$t('components.profile.EditProfileDialog.email')),
      "rules": _vm.emailRules,
      "type": "email",
      "data-test-profile-email": ""
    },
    model: {
      value: _vm.email,
      callback: function callback($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "mb-n6",
    attrs: {
      "outlined": "",
      "label": "".concat(_vm.$t('components.profile.EditProfileDialog.phone')),
      "rules": _vm.phoneNumberRules,
      "data-test-profile-phone": ""
    },
    model: {
      value: _vm.phone,
      callback: function callback($$v) {
        _vm.phone = $$v;
      },
      expression: "phone"
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "mb-n6",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t("components.profile.EditProfileDialog.adress")) + " ")])], 1), _c('v-card-text', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "mb-n6",
    attrs: {
      "outlined": "",
      "rules": _vm.addressRules,
      "label": "".concat(_vm.$t('components.profile.EditProfileDialog.street'), " *"),
      "data-test-profile-street": ""
    },
    model: {
      value: _vm.street,
      callback: function callback($$v) {
        _vm.street = $$v;
      },
      expression: "street"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "mb-n6",
    attrs: {
      "outlined": "",
      "rules": _vm.addressRules,
      "label": "".concat(_vm.$t('components.profile.EditProfileDialog.zip'), " *"),
      "data-test-profile-zip": ""
    },
    model: {
      value: _vm.zip,
      callback: function callback($$v) {
        _vm.zip = $$v;
      },
      expression: "zip"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "mb-n6",
    attrs: {
      "outlined": "",
      "rules": _vm.addressRules,
      "label": "".concat(_vm.$t('components.profile.EditProfileDialog.city'), " *"),
      "data-test-profile-city": ""
    },
    model: {
      value: _vm.city,
      callback: function callback($$v) {
        _vm.city = $$v;
      },
      expression: "city"
    }
  })], 1)], 1)], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }