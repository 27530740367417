var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-menu', {
    attrs: {
      "close-on-content-click": false
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-chip', _vm._g(_vm._b({
          staticClass: "mr-1",
          attrs: {
            "small": "",
            "color": _vm.colorByStatus.get(_vm.signRequest.status)
          }
        }, 'v-chip', attrs, false), on), [_vm._v(" " + _vm._s(_vm.$t("sign.DigitalSignatureRequestStatus.".concat(_vm.signRequest.status))) + " ")])];
      }
    }]),
    model: {
      value: _vm.isMenu,
      callback: function callback($$v) {
        _vm.isMenu = $$v;
      },
      expression: "isMenu"
    }
  }, [_c('v-card', {
    staticStyle: {
      "max-width": "250px"
    },
    attrs: {
      "loading": _vm.loading
    }
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t("project.ticket.stateHistory")) + " ")]), _c('v-list-item', [_c('v-list-item-avatar', [_c('v-avatar', {
    attrs: {
      "size": "15px",
      "color": _vm.colorByStatus.get(_vm.DigitalSignatureRequestStatusEnum.CREATED)
    }
  })], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.$t("sign.DigitalSignatureRequestStatus.".concat(_vm.DigitalSignatureRequestStatusEnum.CREATED))) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm._f("simpleDate")(_vm.signRequest.timestamp.created)) + " - " + _vm._s(_vm._f("formatHoursAndMinutes")(new Date(_vm.signRequest.timestamp.created))) + " ")])], 1)], 1), _vm._l(_vm.signRequest.logs, function (log) {
    return _c('v-list-item', {
      key: log.action + log.timestamp
    }, [_c('v-list-item-avatar', [_c('v-avatar', {
      attrs: {
        "size": "15px",
        "color": _vm.colorByStatus.get(log.action)
      }
    })], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.$t("sign.DigitalSignatureRequestStatus.".concat(log.action))) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm._f("simpleDate")(log.timestamp)) + " - " + _vm._s(_vm._f("formatHoursAndMinutes")(new Date(log.timestamp))) + " ")])], 1)], 1);
  }), _vm.signRequest.nextStates ? _c('div', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t("project.ticket.updateState")) + " ")]), _c('v-list-item-group', {
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, _vm._l(_vm.signRequest.nextStates, function (state, index) {
    return _c('div', {
      key: 'updateStatuses' + state
    }, [_c('v-list-item', {
      attrs: {
        "value": state
      }
    }, [_c('v-list-item-avatar', [_c('v-avatar', {
      attrs: {
        "size": "15px",
        "color": _vm.colorByStatus.get(state)
      }
    })], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.$t("sign.DigitalSignatureRequestStatus.".concat(state))) + " ")])], 1)], 1), index < _vm.signRequest.nextStates.length - 1 ? _c('v-divider') : _vm._e()], 1);
  }), 0), _c('v-card-actions', {
    staticClass: "mt-n7 mb-n7 pl-4"
  }, [_c('v-checkbox', {
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('v-list-item', {
          staticClass: "ml-n3 mr-n2"
        }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" Achtung ")]), _c('v-list-item-subtitle', {
          staticClass: "text-wrap"
        }, [_vm._v(" Diese Aktion kann "), _c('b', [_vm._v("nicht")]), _vm._v(" rückgängig gemacht werden. ")])], 1)], 1)];
      },
      proxy: true
    }], null, false, 198264795),
    model: {
      value: _vm.isWarningAcknowledged,
      callback: function callback($$v) {
        _vm.isWarningAcknowledged = $$v;
      },
      expression: "isWarningAcknowledged"
    }
  })], 1)], 1) : _vm._e(), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "color": "info",
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        _vm.isMenu = false;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("close")))]), _c('v-spacer'), _vm.signRequest.nextStates && _vm.signRequest.nextStates.length ? _c('v-btn', {
    staticClass: "ml-2",
    attrs: {
      "disabled": !(_vm.isWarningAcknowledged && _vm.selected),
      "color": "info",
      "elevation": 0
    },
    on: {
      "click": _vm.updateStatus
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("update")) + " ")]) : _vm._e()], 1)], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }