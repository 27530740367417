














































import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import { Component } from "vue-property-decorator";
import ReportSummary from "./ReportSummary.vue";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";

@Component({
  components: {
    LayoutReportStep
  }
})
export default class ReportSummarySimple extends ReportSummary implements IReportStep {
  screen = ReportScreenEnum.closingsimple;
}
