

















import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import RulesMixin from "@/mixins/RulesMixin.vue";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import Tooltip from "../utility/tooltip.vue";

@Component({
  components: { Tooltip },
  filters: {}
})
export default class TicketDetailCardAssignees extends mixins(RulesMixin, DarkModeHighlightMixin) {
  @Prop()
  assignees!: string[];

  get getFirstAssignee() {
    if (this.assignees.length) {
      return this.assignees[0];
    }
    return undefined;
  }

  get toolTipContent() {
    let tooltip = "";
    for (const userId of this.assignees) {
      tooltip = tooltip + this.getUserName(userId) + "<br>";
    }
    return tooltip;
  }

  getUserDetails(userId: string) {
    return PartnerUserModule.maps.id.get(userId)[0];
  }

  getUserInitials(userId: string) {
    const user = this.getUserDetails(userId);
    if (!user) {
      return "";
    }

    return user.firstName[0] + user.lastName[0];
  }

  getUserName(userId: string) {
    const user = this.getUserDetails(userId);

    return user ? user.firstName + " " + user.lastName : "";
  }

  getUserMail(userId: string) {
    const user = this.getUserDetails(userId);

    return user ? user.userName : "";
  }
}
