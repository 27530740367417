var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('timeline-card', {
    attrs: {
      "title": "Leasinginformationen"
    },
    scopedSlots: _vm._u([_vm.canUpdateReport ? {
      key: "menu",
      fn: function fn() {
        return [_c('partner-report-update-report-dialog', {
          attrs: {
            "config": [_vm.ReportUpdateTabEnum.leasing],
            "activityTarget": "leasing"
          }
        })];
      },
      proxy: true
    } : null], null, true)
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-data-table', {
    attrs: {
      "dense": "",
      "headers": _vm.headers,
      "items": _vm.leasing,
      "hide-default-footer": "",
      "disable-pagination": "",
      "hide-default-header": ""
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }