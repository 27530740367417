














import { Component, Vue, Prop } from "vue-property-decorator";
import MyDamageDetail from "./MyDamageDetail.vue";
import SideCard from "@/components/utility/SideCard.vue";
import { DamageMeModule } from "@/store/modules/damage-me.store";
import { simpleDate } from "@/lib/utility/date-helper";

@Component({
  components: {
    SideCard,
    MyDamageDetail
  }
})
export default class MyDamageDetailSideCard extends Vue {
  @Prop({ default: false })
  loading!: boolean;

  get report() {
    return DamageMeModule.currentReport;
  }

  get creationDate() {
    if (!this.report?.timestamp?.created) {
      return "";
    }
    return simpleDate(this.report?.timestamp?.created);
  }

  get fullscreenBreakpoint() {
    return this.$vuetify.breakpoint.width < 1500;
  }
}
