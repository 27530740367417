

































































import NumberplateForm from "@/components/utility/NumberplateForm.vue";
import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import { emailRule, noNumberRule, phoneRule } from "@/lib/rules/contactRule.ts";
import { requiredRule } from "@/lib/rules/requiredRule.ts";
import ReportStepMixin from "@/mixins/ReportStepMixin.vue";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { ReportModule } from "@/store/modules/report.store";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    LayoutReportStep,
    NumberplateForm
  }
})
export default class ReportPersonalDataSimple extends ReportStepMixin implements IReportStep {
  public md = 4;
  public static screenName = ReportScreenEnum.contactdetails;
  public header: IReportHeaderData = {
    title: "report.formPersonalData.title",
    description: "report.formPersonalData.description"
  };

  screen = ReportScreenEnum.simplecontact;

  phone: any;

  valid = false;

  numberplateValid = false;

  setValid(value: boolean) {
    this.numberplateValid = value;
  }

  get isDone() {
    return this.valid && this.numberplateValid;
  }

  get noNumberRule() {
    const rules = [];

    rules.push(requiredRule());
    rules.push(noNumberRule());

    return rules;
  }

  get emailRules() {
    const rules = [];

    rules.push(requiredRule());
    rules.push(emailRule());

    return rules;
  }

  get phoneNumberRules() {
    const rules = [];

    rules.push(requiredRule());
    rules.push(phoneRule());

    return rules;
  }

  get numberplate(): string {
    return ReportModule.numberplate;
  }

  set numberplate(value: string) {
    ReportModule.setNumberplate(value);
  }

  get user(): any {
    return ReportModule.user;
  }

  get contact(): any {
    return ReportModule.contact;
  }

  get countryCode(): CountryCodeEnum {
    return ReportModule.partnerCountryCode;
  }

  updateUserFirstName(e: any) {
    ReportModule.setUser({ firstName: e.target.value });
  }
  updateUserLastName(e: any) {
    ReportModule.setUser({ lastName: e.target.value });
  }
  updateUserEmail(e: any) {
    ReportModule.setContact({ email: e.target.value });
  }
  updateUserPhone(e: any) {
    ReportModule.setContact({ phone: e.target.value });
  }
}
