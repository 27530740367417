import { PageOrderEnum } from "@/lib/enum/pageOrder.enum";
import faqService from "@/services/shared/faqService";
import {
  ThgCreateFaqDtoGen,
  ThgFaqControllerFindAllParamsGen,
  ThgFaqViewModelGen,
  ThgUpdateFaqDtoGen
} from "@/services/thg/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, getModule, Module, Mutation } from "vuex-module-decorators";
import { BasePagination, PaginationFilterListElement } from "./base-pagination.store";
import { PageFilterTypes } from "@/lib/utility/data/page-filter-types.enum";

@Module({
  dynamic: true,
  namespaced: true,
  name: "faq",
  store
})
export class FaqStore extends BasePagination<ThgFaqViewModelGen, ThgFaqControllerFindAllParamsGen> {
  protected _pageOrder: PageOrderEnum = PageOrderEnum.DESCENDING;
  protected _itemsPerPage = 100;
  protected _totalPages = 0;
  protected _paginationList: ThgFaqViewModelGen[] = [];
  protected _currentPage = 1;
  protected _totalItems = 0;
  protected _isLoadAll = true;
  filterOptions: PaginationFilterListElement[] = [{ key: "partnerId", type: PageFilterTypes.OBJECT_ID }].map(
    f => new PaginationFilterListElement(f)
  );

  private _faq: ThgFaqViewModelGen | null = null;
  get faq() {
    return this._faq;
  }

  get faqs() {
    return this._paginationList;
  }

  @Action
  protected async loadDocuments(query: ThgFaqControllerFindAllParamsGen): Promise<any> {
    return faqService.getAllForPartner(query);
  }

  @Action
  async getAll(data: { partnerId: string }) {
    await this.fetchAllFromBeginning({
      partnerId: data.partnerId
    });

    this.context.commit("mutateDocuments", this._paginationList);
  }

  @Mutation
  mutateDocuments(faqs: ThgFaqViewModelGen[]) {
    this._paginationList = faqs;
  }

  @Mutation
  private mutateFaq(faq: ThgFaqViewModelGen) {
    this._faq = faq;
  }

  @Action
  async create(data: { partnerId: string; data: ThgCreateFaqDtoGen }) {
    const faq = await faqService.create(data.partnerId, data.data);
    this.context.commit("mutateFaq", faq);
    return faq;
  }

  @Action
  async update(data: { faqId: string; partnerId: string; data: ThgUpdateFaqDtoGen }) {
    const faq = await faqService.updateForPartner(data.faqId, data.partnerId, data.data);
    this.context.commit("mutateFaq", faq);
    return faq;
  }

  @Action
  async delete(data: { faqId: string; partnerId: string }) {
    const faq = await faqService.removeForPartner(data.faqId, data.partnerId);
    this.context.commit("mutateFaq", faq);
    return faq;
  }

  @Action
  async get(data: { faqId: string; partnerId: string }) {
    const faq = await faqService.getOneForPartner(data.partnerId, data.faqId);
    this.context.commit("mutateFaq", faq);
    return faq;
  }

  @Action
  async resetFaq() {
    this.context.commit("mutateFaq", null);
  }
}

export const FaqModule = getModule(FaqStore);
