var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "form",
    model: {
      value: _vm.isValid,
      callback: function callback($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('v-text-field', {
    staticClass: "mt-6 mb-n4",
    attrs: {
      "type": "text",
      "label": _vm.$t('objects.vehicle.registration.numberplate'),
      "outlined": "",
      "dense": "",
      "required": ""
    },
    model: {
      value: _vm.registration.numberplate,
      callback: function callback($$v) {
        _vm.$set(_vm.registration, "numberplate", $$v);
      },
      expression: "registration.numberplate"
    }
  }), _c('v-text-field', {
    staticClass: "mt-n4 mb-n4",
    attrs: {
      "type": "text",
      "label": _vm.$t('objects.vehicle.registration.identificationnumber'),
      "rules": _vm.vinRuleLength,
      "outlined": "",
      "dense": "",
      "required": "",
      "suffix": _vm.getChecksumText
    },
    model: {
      value: _vm.registration.identificationnumber,
      callback: function callback($$v) {
        _vm.$set(_vm.registration, "identificationnumber", $$v);
      },
      expression: "registration.identificationnumber"
    }
  }), _c('v-text-field', {
    staticClass: "mt-n4 mb-n4",
    attrs: {
      "type": "text",
      "label": _vm.$t('objects.vehicle.registration.manufacturerNameCode'),
      "outlined": "",
      "dense": "",
      "required": ""
    },
    model: {
      value: _vm.registration.manufacturerNameCode,
      callback: function callback($$v) {
        _vm.$set(_vm.registration, "manufacturerNameCode", $$v);
      },
      expression: "registration.manufacturerNameCode"
    }
  }), _c('v-text-field', {
    staticClass: "mt-n4 mb-n4",
    attrs: {
      "type": "text",
      "label": _vm.$t('objects.vehicle.registration.manufacturerTypeCode'),
      "outlined": "",
      "dense": "",
      "required": ""
    },
    model: {
      value: _vm.registration.manufacturerTypeCode,
      callback: function callback($$v) {
        _vm.$set(_vm.registration, "manufacturerTypeCode", $$v);
      },
      expression: "registration.manufacturerTypeCode"
    }
  }), _c('v-text-field', {
    staticClass: "mt-4 mb-n4",
    attrs: {
      "type": "text",
      "label": _vm.$t('objects.vehicle.registration.huMonth'),
      "outlined": "",
      "dense": "",
      "required": ""
    },
    model: {
      value: _vm.registration.huMonth,
      callback: function callback($$v) {
        _vm.$set(_vm.registration, "huMonth", $$v);
      },
      expression: "registration.huMonth"
    }
  }), _c('v-text-field', {
    staticClass: "mt-n4 mb-n4",
    attrs: {
      "type": "text",
      "label": _vm.$t('objects.vehicle.registration.huYear'),
      "outlined": "",
      "dense": "",
      "required": ""
    },
    model: {
      value: _vm.registration.huYear,
      callback: function callback($$v) {
        _vm.$set(_vm.registration, "huYear", $$v);
      },
      expression: "registration.huYear"
    }
  }), _c('v-text-field', {
    staticClass: "mt-4 mb-n4",
    attrs: {
      "type": "text",
      "label": _vm.$t('objects.vehicle.registration.firstregistrationDay'),
      "outlined": "",
      "dense": "",
      "required": ""
    },
    model: {
      value: _vm.registration.firstregistrationDay,
      callback: function callback($$v) {
        _vm.$set(_vm.registration, "firstregistrationDay", $$v);
      },
      expression: "registration.firstregistrationDay"
    }
  }), _c('v-text-field', {
    staticClass: "mt-n4 mb-n4",
    attrs: {
      "type": "text",
      "label": _vm.$t('objects.vehicle.registration.firstregistrationMonth'),
      "outlined": "",
      "dense": "",
      "required": ""
    },
    model: {
      value: _vm.registration.firstregistrationMonth,
      callback: function callback($$v) {
        _vm.$set(_vm.registration, "firstregistrationMonth", $$v);
      },
      expression: "registration.firstregistrationMonth"
    }
  }), _c('v-text-field', {
    staticClass: "mt-n4 mb-n4",
    attrs: {
      "type": "text",
      "label": _vm.$t('objects.vehicle.registration.firstregistrationYear'),
      "outlined": "",
      "dense": "",
      "required": ""
    },
    model: {
      value: _vm.registration.firstregistrationYear,
      callback: function callback($$v) {
        _vm.$set(_vm.registration, "firstregistrationYear", $$v);
      },
      expression: "registration.firstregistrationYear"
    }
  }), _c('v-text-field', {
    staticClass: "mt-4 mb-n4",
    attrs: {
      "type": "text",
      "label": _vm.$t('objects.vehicle.registration.name'),
      "outlined": "",
      "dense": "",
      "required": ""
    },
    model: {
      value: _vm.registration.name,
      callback: function callback($$v) {
        _vm.$set(_vm.registration, "name", $$v);
      },
      expression: "registration.name"
    }
  }), _c('v-text-field', {
    staticClass: "mt-n4 mb-n4",
    attrs: {
      "type": "text",
      "label": _vm.$t('objects.vehicle.registration.firstname'),
      "outlined": "",
      "dense": "",
      "required": ""
    },
    model: {
      value: _vm.registration.firstname,
      callback: function callback($$v) {
        _vm.$set(_vm.registration, "firstname", $$v);
      },
      expression: "registration.firstname"
    }
  }), _c('v-text-field', {
    staticClass: "mt-4 mb-n4",
    attrs: {
      "type": "text",
      "label": _vm.$t('objects.vehicle.registration.street'),
      "outlined": "",
      "dense": "",
      "required": ""
    },
    model: {
      value: _vm.registration.street,
      callback: function callback($$v) {
        _vm.$set(_vm.registration, "street", $$v);
      },
      expression: "registration.street"
    }
  }), _c('v-text-field', {
    staticClass: "mt-n4 mb-n4",
    attrs: {
      "type": "text",
      "label": _vm.$t('objects.vehicle.registration.zipCode'),
      "outlined": "",
      "dense": "",
      "required": ""
    },
    model: {
      value: _vm.registration.zipCode,
      callback: function callback($$v) {
        _vm.$set(_vm.registration, "zipCode", $$v);
      },
      expression: "registration.zipCode"
    }
  }), _c('v-text-field', {
    staticClass: "mt-n4 mb-n4",
    attrs: {
      "type": "text",
      "label": _vm.$t('objects.vehicle.registration.city'),
      "outlined": "",
      "dense": "",
      "required": ""
    },
    model: {
      value: _vm.registration.city,
      callback: function callback($$v) {
        _vm.$set(_vm.registration, "city", $$v);
      },
      expression: "registration.city"
    }
  }), _c('v-text-field', {
    staticClass: "mt-4 mb-n4",
    attrs: {
      "type": "text",
      "label": _vm.$t('objects.vehicle.registration.driveTyp'),
      "outlined": "",
      "dense": "",
      "required": ""
    },
    model: {
      value: _vm.registration.driveTyp,
      callback: function callback($$v) {
        _vm.$set(_vm.registration, "driveTyp", $$v);
      },
      expression: "registration.driveTyp"
    }
  }), _c('v-text-field', {
    staticClass: "mt-n4 mb-n4",
    attrs: {
      "type": "text",
      "label": _vm.$t('objects.vehicle.registration.vehicleClass'),
      "outlined": "",
      "dense": "",
      "required": ""
    },
    model: {
      value: _vm.registration.vehicleClass,
      callback: function callback($$v) {
        _vm.$set(_vm.registration, "vehicleClass", $$v);
      },
      expression: "registration.vehicleClass"
    }
  }), _c('v-text-field', {
    staticClass: "mt-4 mb-n4",
    attrs: {
      "type": "text",
      "label": _vm.$t('objects.vehicle.registration.manufacturerName'),
      "outlined": "",
      "dense": "",
      "required": ""
    },
    model: {
      value: _vm.registration.manufacturerName,
      callback: function callback($$v) {
        _vm.$set(_vm.registration, "manufacturerName", $$v);
      },
      expression: "registration.manufacturerName"
    }
  }), _c('v-text-field', {
    staticClass: "mt-n4 mb-n4",
    attrs: {
      "type": "text",
      "label": _vm.$t('objects.vehicle.registration.manufacturerType'),
      "outlined": "",
      "dense": "",
      "required": ""
    },
    model: {
      value: _vm.registration.manufacturerType,
      callback: function callback($$v) {
        _vm.$set(_vm.registration, "manufacturerType", $$v);
      },
      expression: "registration.manufacturerType"
    }
  }), _c('v-text-field', {
    staticClass: "mt-n4 mb-n4",
    attrs: {
      "type": "text",
      "label": _vm.$t('objects.vehicle.registration.manufacturerDescription'),
      "outlined": "",
      "dense": "",
      "required": ""
    },
    model: {
      value: _vm.registration.manufacturerDescription,
      callback: function callback($$v) {
        _vm.$set(_vm.registration, "manufacturerDescription", $$v);
      },
      expression: "registration.manufacturerDescription"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }