






import { Component } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import PartnerReportCardGeneral from "./PartnerReportCardGeneral.vue";

@Component({
  components: {
    Card
  }
})
export default class PartnerReportCardMessage extends PartnerReportCardGeneral {}
