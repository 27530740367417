























import { Component, Prop } from "vue-property-decorator";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import Tooltip from "./tooltip.vue";
import { IShortUser, ShortUser } from "@/models/short-user.entity";
import { PartnerModule } from "@/store/modules/partner";
import { PartnerUser } from "@/models/user.entity";

@Component({
  components: {
    Tooltip
  },
  filters: {}
})
export default class AssigneePreview extends PermissionMixin {
  @Prop({ default: false })
  value!: string[];

  @Prop()
  amount?: number;

  assignees: IShortUser[] = [];

  get firstAssignees() {
    if (this.amount) {
      return this.assignees.slice(0, this.amount);
    }

    return this.assignees;
  }

  get remainingAssignees() {
    if (this.amount) {
      return this.assignees.slice(this.amount);
    }

    return [];
  }

  async mounted() {
    for (const assigneeId of this.value) {
      try {
        let assignee = new ShortUser(PartnerUserModule.maps.id.get(assigneeId)[0]);
        if (!assignee) {
          assignee = new ShortUser(
            await new PartnerUser({ id: assigneeId, partnerId: PartnerModule.partner.id }).fetch()
          );
        }
        this.assignees.push(assignee);
      } catch (e) {
        this.$log.error(e);
      }
    }
  }

  getAssigneeName(assignee: IShortUser) {
    return `${assignee?.firstName} ${assignee?.lastName}`;
  }

  getAssigneeInititals(assignee: IShortUser) {
    return `${assignee?.firstName[0]}${assignee?.lastName[0]}`;
  }
}
