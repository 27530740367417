




































































import PartnerMap from "@/components/form/PartnerMap.vue";
import Debug from "@/components/utility/Debug.vue";
import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import { handleError } from "@/lib/utility/handleError";
import ReportStepMixin from "@/mixins/ReportStepMixin.vue";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { FeatureModule } from "@/store/modules/feature.store";
import { PartnerFinderModule } from "@/store/modules/partner-link-finder.store";
import { ReportControlModule } from "@/store/modules/report-control.store";
import { ReportModule } from "@/store/modules/report.store";
import { debounce } from "debounce";
import { Component, Watch } from "vue-property-decorator";

@Component({
  components: {
    LayoutReportStep,
    PartnerMap,
    Debug
  }
})
export default class ReportPartnerFinder extends ReportStepMixin implements IReportStep {
  screen = ReportScreenEnum.PARTNER_FINDER;
  loading = false;
  search = "";

  valid = false;

  selected = "";

  @Watch("selected")
  watchSelected(item: number) {
    this.$log.debug(item, this.partners[item].companyUsername);
    ReportControlModule.setSelected(this.partners[item]);
  }

  async mounted() {
    const address = `${ReportModule.address.street} ${ReportModule.address.zip} ${ReportModule.address.city}`;

    // if address is not empty search for it.
    if (address.trim()) {
      this.search = address;

      await this.find();
    }
  }

  get header(): IReportHeaderData {
    return {
      title: "report.finder.title",
      description: "report.finder.description"
    };
  }

  get isDone() {
    return Boolean(this.selectedPartner);
  }

  get partner() {
    return ReportModule.partner;
  }

  get partnerId() {
    return this.partner.id;
  }

  get partners() {
    return PartnerFinderModule.partners;
  }

  get selectedPartner() {
    return ReportControlModule.selected;
  }

  get isMapShown() {
    return FeatureModule.isMapShown;
  }

  debounceFind = debounce(this.find, 1500);

  async find() {
    if (!this.search) {
      return;
    }

    if (!this.partnerId) {
      this.$toast.error("Select partner"); // FIXME: Translate
      return;
    }

    this.loading = true;

    try {
      const res = await PartnerFinderModule.findPartners({
        partnerId: this.partnerId,
        searchstring: this.search
      });

      if (res.length <= 0) {
        this.$toast.warning("Not found"); // FIXME: Translate
      }
    } catch (error) {
      handleError(error);
    } finally {
      this.loading = false;
    }
  }
}
