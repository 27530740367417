var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-timeline-item', {
    staticClass: "pt-6 mb-n6",
    attrs: {
      "icon": "mdi-export-variant",
      "fill-dot": "",
      "color": "success",
      "small": ""
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("timeLine.ActivityTimeLineItemAttachmentResponse.title", {
    date: _vm.date
  })) + " "), _c('v-spacer'), _vm.attachmentRequestId ? _c('v-btn', {
    staticClass: "mr-4",
    attrs: {
      "color": "info",
      "x-small": "",
      "outlined": "",
      "elevation": 0,
      "loading": _vm.attachmentRequestLoading
    },
    on: {
      "click": _vm.openAttachmentRequestSideCard
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("detail")) + " ")]) : _vm._e()], 1)]), _vm.attachmentRequest ? _c('attachment-request-side-card', {
    attrs: {
      "value": _vm.attachmentRequest
    },
    on: {
      "close": function close($event) {
        _vm.attachmentRequest = null;
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }