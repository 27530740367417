












































import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import ReportStepMixin from "@/mixins/ReportStepMixin.vue";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { ILiabilityDamage } from "@/store/interface/reportbody/liability-damage.interface";
import { ExportModule } from "@/store/modules/export.store";
import { ReportModule } from "@/store/modules/report.store";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    LayoutReportStep
  }
})
export default class ReportResponsibleParty extends ReportStepMixin implements IReportStep {
  header: IReportHeaderData = {
    title: "report.reponsibleParty.title",
    description: "report.reponsibleParty.description"
  };

  screen = ReportScreenEnum.responsibleparty;

  created() {
    this.isLiabilityDamage = { isLiabilityDamage: false };
  }

  get isLiabilityDamage(): ILiabilityDamage {
    return ReportModule.isLiabilityDamage;
  }

  set isLiabilityDamage(liabilityDamage: ILiabilityDamage) {
    ReportModule.setLiabilityDamage(liabilityDamage);
  }

  get isDataProcessingAgreement(): boolean {
    return ExportModule.unfallnaviMetaData.isDataProcessingAgreement;
  }

  set isDataProcessingAgreement(isDataProcessingAgreement: boolean) {
    ExportModule.setUnfallnaviMetaData({ isDataProcessingAgreement });
  }

  get isDone() {
    return true;
  }

  get color() {
    return getDefaultPartnerColor();
  }
}
