





































import { handleError } from "@/lib/utility/handleError";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { ActivityLogModule } from "@/store/modules/activity-log.store";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";

@Component({
  components: {
    ConfirmActionDialog
  }
})
export default class ActivityTimeLineItemDelete extends mixins(PermissionMixin) {
  @Prop()
  partnerId!: string;

  @Prop()
  activityId!: string;

  @Prop({ default: false })
  text!: boolean;

  isDeleteDialogActive = false;

  isLoading = false;

  async deleteActivity() {
    try {
      this.isLoading = true;

      const deleted = await ActivityLogModule.delete({
        partnerId: this.partnerId,
        activityId: this.activityId
      });
      ActivityLogModule.removeInList(deleted);

      this.$toast.success("🗑️ 👍");

      this.isDeleteDialogActive = false;
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
