/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivBasePdfViewmodelGen,
  MrfiktivCreatePdfDtoGen,
  MrfiktivPageViewModelGen,
  MrfiktivPartnerPdfControllerFindAllParamsGen,
  MrfiktivPdfModelGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class PartnerPdf<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags partner-pdf
   * @name PartnerPdfControllerFindAll
   * @summary Get all pdfs
   * @request GET:/pdf/partner/{partnerId}/pdf
   * @secure
   * @response `200` `(MrfiktivPageViewModelGen & { data?: (MrfiktivBasePdfViewmodelGen)[] })`
   */
  partnerPdfControllerFindAll = (
    { partnerId, ...query }: MrfiktivPartnerPdfControllerFindAllParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivPageViewModelGen & { data?: MrfiktivBasePdfViewmodelGen[] }, any>({
      path: `/pdf/partner/${partnerId}/pdf`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags partner-pdf
   * @name PartnerPdfControllerCreate
   * @summary Submit pdf for partner.
   * @request POST:/pdf/partner/{partnerId}/pdf
   * @secure
   * @response `200` `MrfiktivBasePdfViewmodelGen` The id of the pdf
   * @response `400` `void` General error if request is not working.
   * @response `404` `void` No partner found.
   * @response `415` `void` Unsupported file upload.
   */
  partnerPdfControllerCreate = (partnerId: string, data: MrfiktivCreatePdfDtoGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivBasePdfViewmodelGen, void>({
      path: `/pdf/partner/${partnerId}/pdf`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags partner-pdf
   * @name PartnerPdfControllerFindOne
   * @summary Get an pdf by id
   * @request GET:/pdf/partner/{partnerId}/pdf/{id}
   * @secure
   * @response `200` `MrfiktivBasePdfViewmodelGen` The found record
   */
  partnerPdfControllerFindOne = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivBasePdfViewmodelGen, any>({
      path: `/pdf/partner/${partnerId}/pdf/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags partner-pdf
   * @name PartnerPdfControllerRemove
   * @summary Delete a pdf by id
   * @request DELETE:/pdf/partner/{partnerId}/pdf/{id}
   * @secure
   * @response `200` `MrfiktivPdfModelGen` The deleted record
   */
  partnerPdfControllerRemove = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivPdfModelGen, any>({
      path: `/pdf/partner/${partnerId}/pdf/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
}
