var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-avatar', {
    attrs: {
      "size": "56",
      "color": "".concat(_vm.iconColor, " ").concat(_vm.colorVariantBack)
    }
  }, [_c('v-icon', {
    attrs: {
      "outlined": "",
      "x-large": "",
      "color": "".concat(_vm.iconColor, " ").concat(_vm.colorVariantTop)
    }
  }, [_vm._v(_vm._s(_vm.icon))])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }