














import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class ContextMenu extends Vue {
  @Prop({ default: "mdi-dots-vertical" })
  icon?: string;

  @Prop({ default: "" })
  color?: string;

  @Prop({ default: false })
  small?: boolean;

  @Prop({ default: false })
  closeOnContentClick?: boolean;
}
