/**
 * Interface for a class with the capability to create an instance of itself with the backend
 */
export interface ICreateDto<T> {
  /**
   * Create a new instance of the class with the backend
   */
  create: (silent?: boolean) => Promise<T | undefined>;
}

/**
 * Abstract class with the capability to create an instance of itself with the backend
 */
export abstract class CreateDto<T> implements ICreateDto<T> {
  /**
   * @inheritdoc
   */
  abstract create(silent?: boolean): Promise<T | undefined>;
}
