


























































import AdvertisementCard from "@/components/cards/AdvertismentCard.vue";
import FeedbackContainer from "@/components/feedback/FeedbackContainer.vue";
import Debug from "@/components/utility/Debug.vue";
import LayoutReport from "@/layouts/LayoutReport.vue";
import { FeedbackTypeEnum } from "@/store/enum/partner/feedback.type.enum";
import { ReportModule } from "@/store/modules/report.store";
import { Component, Vue } from "vue-property-decorator";
import { FeatureModule } from "@/store/modules/feature.store";
import LoginRegisterButton from "@/components/utility/LoginRegisterButton.vue";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import { PartnerModule } from "@/store/modules/partner";

@Component({
  components: {
    LayoutReport,
    Debug,
    LoginRegisterButton,
    FeedbackContainer,
    ConfirmActionDialog,
    AdvertisementCard
  }
})
export default class ReportSuccess extends Vue {
  isFeedbackDialogActive = false;
  /** If feedback is sent successfully, we should set this to false */
  feedbackCanBeSubmitted = true;

  key = FeedbackTypeEnum.REPORT;

  title = "report.success.title";
  adTitle = "components.cards.AdvertismentCard.wirkaufendeinethg.title";
  adSubtitle = "components.cards.AdvertismentCard.wirkaufendeinethg.subtitle";
  adContent = "components.cards.AdvertismentCard.wirkaufendeinethg.content";
  adBtnText = "components.cards.AdvertismentCard.wirkaufendeinethg.btnText";

  goHome() {
    this.$router.push({ path: "/" });
  }

  get description() {
    return String(
      this.$t("report.success.description", {
        partner: "<a href='" + this.partner?.settings?.websites[0].link + "'>" + this.partner?.companyName + "</a>"
      })
    );
  }

  get partner() {
    return ReportModule.partner;
  }

  get isAdvertisementEnabled() {
    return FeatureModule.isReportAdvertisementEnabled;
  }

  get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }

  get isFeedbackEnabled() {
    return PartnerModule.isRatingSubmissionEnabled;
  }

  onFeedbackSent() {
    this.isFeedbackDialogActive = false;
    this.feedbackCanBeSubmitted = false;
  }
}
