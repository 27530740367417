




















































import { Component, Prop, Vue } from "vue-property-decorator";
import { IUser } from "@/models/user.entity";

@Component({
  components: {}
})
export default class TimelineCard extends Vue {
  @Prop({ default: true })
  header!: boolean;

  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: "" })
  title!: string;

  @Prop({ default: undefined })
  user!: IUser;

  @Prop({ default: true })
  divider!: boolean;
}
