































































































import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import NumberplateForm from "@/components/utility/NumberplateForm.vue";

import { ReportModule } from "@/store/modules/report.store";
import { Component } from "vue-property-decorator";

import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import { emailRule, germanZipRule, noNumberRule, phoneRule } from "@/lib/rules/contactRule.ts";
import { numberplateRule } from "@/lib/rules/numberplateRule.ts";
import { requiredRule } from "@/lib/rules/requiredRule.ts";
import ReportStepMixin from "@/mixins/ReportStepMixin.vue";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { LoginModule } from "@/store/modules/login.store";
import { CountryCodeEnum } from "@/lib/enum/country-code.enum";

@Component({
  components: {
    LayoutReportStep,
    NumberplateForm
  }
})
export default class ReportPersonalData extends ReportStepMixin implements IReportStep {
  public md = 4;
  public static screenName = ReportScreenEnum.contactdetails;
  public header: IReportHeaderData = {
    title: "report.formPersonalData.title",
    description: "report.formPersonalData.description"
  };
  screen = ReportScreenEnum.contactdetails;

  phone: any;

  numberplateValid = false;

  valid = false;

  setValid(value: boolean) {
    this.numberplateValid = value;
  }

  get isDone() {
    return this.valid && this.numberplateValid;
  }

  get numberPlateRules() {
    const rules = [];

    rules.push(requiredRule());
    rules.push(numberplateRule());

    return rules;
  }

  get noNumberRule() {
    const rules = [];

    rules.push(requiredRule());
    rules.push(noNumberRule());

    return rules;
  }

  get emailRules() {
    const rules = [];

    rules.push(requiredRule());
    rules.push(emailRule());

    return rules;
  }

  get phoneNumberRules() {
    const rules = [];

    rules.push(requiredRule());
    rules.push(phoneRule());

    return rules;
  }

  get zipRules() {
    const rules = [];

    rules.push(requiredRule());
    rules.push(germanZipRule());

    return rules;
  }

  get numberplate(): string {
    return ReportModule.numberplate;
  }

  set numberplate(value: string) {
    ReportModule.setNumberplate(value);
  }

  get user(): any {
    return ReportModule.user;
  }

  get contact(): any {
    return ReportModule.contact;
  }

  get address(): any {
    return ReportModule.address;
  }

  get countryCode(): CountryCodeEnum {
    return ReportModule.partnerCountryCode;
  }

  updateNumberplate(e: any) {
    ReportModule.setNumberplate(e.target.value.toUpperCase());
  }

  updateUserFirstName(e: any) {
    ReportModule.setUser({ firstName: e.target.value });
  }

  updateUserLastName(e: any) {
    ReportModule.setUser({ lastName: e.target.value });
  }

  updateUserPhone(e: any) {
    ReportModule.setContact({ phone: e.target.value });
  }

  updateUserEmail(e: any) {
    ReportModule.setContact({ email: e.target.value });
    LoginModule.setCurrentMail(e.target.value);
  }

  updateUserCity(e: any) {
    ReportModule.setAddress({ city: e.target.value });
  }

  updateUserState(e: any) {
    ReportModule.setAddress({ state: e.target.value });
  }

  updateUserStreet(e: any) {
    ReportModule.setAddress({ street: e.target.value });
  }

  updateUserZip(e: any) {
    ReportModule.setAddress({ zip: e.target.value });
  }
}
