





















































































import QueryParamsList from "@/components/settings/QueryParamsList.vue";
import LanguageDialog from "@/components/utility/LanguageDialog.vue";
import LoaderReport from "@/components/utility/LoaderReport.vue";
import LoginRegisterButton from "@/components/utility/LoginRegisterButton.vue";
import LayoutReportWithBanner from "@/layouts/LayoutReportWithBanner.vue";
import { AssetEnum, AssetRepository } from "@/lib/AssetRepository";
import { AppContextEnum } from "@/lib/enum/appContext.enum";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import ReportAsyncOperationMixin from "@/mixins/ReportAsyncOperationMixin.vue";
import { SUPPORT_LOCALES } from "@/plugins/I18nPlugin";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { ConfigModule } from "@/store/modules/config";
import { UserModule } from "@/store/modules/me-user.store";
import { PartnerModule } from "@/store/modules/partner";
import { ReportModule } from "@/store/modules/report.store";
import { Component } from "vue-property-decorator";
import { LanguageCodeEnum } from "@/lib/enum/language-code.enum";

@Component({
  components: {
    LayoutReportWithBanner,
    LoaderReport,
    LoginRegisterButton,
    QueryParamsList: QueryParamsList,
    LanguageDialog
  }
})
export default class ReportHome extends ReportAsyncOperationMixin {
  dialog = false;

  get showLoginButton(): boolean {
    const isCustomerSignup = PartnerModule.partner?.settings?.bookedServices.customer ?? false;
    const isThg = ConfigModule.appContext === AppContextEnum.THG;
    return isThg ? !UserModule.isAuthenticated : !UserModule.isAuthenticated && isCustomerSignup;
  }

  get loading() {
    return ReportModule.loading;
  }

  get headerImage() {
    if (PartnerModule?.partner?.settings?.reportSettings?.headerImageUrl) {
      return PartnerModule?.partner?.settings?.reportSettings?.headerImageUrl;
    } else if (PartnerModule?.partner?.settings?.headerImageUrl) {
      return PartnerModule?.partner?.settings?.headerImageUrl;
    } else {
      return "https://mmmint.azureedge.net/wirkaufendeinethg/header-image.png";
    }
  }

  get videoSrc() {
    return PartnerModule?.partner?.settings?.reportSettings?.welcomeVideoUrl;
  }

  get imageSrc() {
    if (PartnerModule?.partner?.settings?.reportSettings?.welcomeImageUrl) {
      return PartnerModule?.partner?.settings?.reportSettings?.welcomeImageUrl;
    }

    return AssetRepository.getAsset(false, AssetEnum.towingTruck);
  }

  get title() {
    if (PartnerModule?.partner?.settings?.reportSettings?.welcomeTitle) {
      return PartnerModule?.partner?.settings?.reportSettings?.welcomeTitle;
    }

    return "report.home.title";
  }

  get items() {
    if (
      PartnerModule?.partner?.settings?.reportSettings?.welcomeText &&
      PartnerModule?.partner?.settings?.reportSettings?.welcomeText.length >= 1
    ) {
      return PartnerModule?.partner?.settings?.reportSettings?.welcomeText;
    }

    return [
      { text: "report.home.step1", icon: "mdi-camera" },
      { text: "report.home.step2", icon: "mdi-account" },
      { text: "report.home.step3", icon: "mdi-calendar-check" }
    ];
  }

  get slogan() {
    if (PartnerModule?.partner?.settings?.reportSettings?.headerTitle) {
      return PartnerModule?.partner?.settings?.reportSettings?.headerTitle;
    }

    return "report.home.slogan";
  }

  get headerButtonText() {
    if (PartnerModule?.partner?.settings?.reportSettings?.headerBtn) {
      return PartnerModule?.partner?.settings?.reportSettings?.headerBtn;
    }

    return "report.home.start";
  }

  get buttonText() {
    if (PartnerModule?.partner?.settings?.reportSettings?.welcomeBtn) {
      return PartnerModule?.partner?.settings?.reportSettings?.welcomeBtn;
    }

    return "report.home.start";
  }

  get loginButtonText() {
    return "report.home.login";
  }

  start() {
    if (ReportModule.nextScreen) {
      const next = ReportModule.nextScreen;
      ReportModule.setStep(next);
      this.$router.push({ path: `/${next}` });
    }
  }

  get color() {
    return getDefaultPartnerColor();
  }

  get partnerColorDarkmode() {
    return this.$vuetify.theme.dark ? "white" : this.color;
  }

  get partner() {
    return PartnerModule.partner;
  }

  async mounted() {
    // Handle special case if partnerId by route e.g. `/partners/:partnerId/app/report`
    if (this.$route.params.partnerId) {
      try {
        await ReportModule.getPartnerById(this.$route.params.partnerId);
      } catch (error) {
        this.$log.error(error);
        this.$toast("Fehler beim Laden der Anwendung.");
        this.$router.push({ name: "PartnerContactError" });
      }
    }

    // Handle special case if partner by route e.g. `/partner/mmmint.ai/report`.
    if (this.$route.params.partnerName) {
      try {
        await ReportModule.getPartnerByName(this.$route.params.partnerName);
      } catch (error) {
        this.$log.error(error);
        this.$toast("Fehler beim Laden der Anwendung.");
        this.$router.push({ name: "PartnerContactError" });
      }
    }

    this.startAsyncOperations().then(() => {
      this.$log.info("async operations finished");
    });

    if (SUPPORT_LOCALES.includes(ReportModule.partner.language as LanguageCodeEnum)) {
      this.$i18n.locale = ReportModule.partner.language.toLowerCase();
      this.$log.warn(`Set default lang ${this.$i18n.locale}`);
    }

    this.dialog = ReportModule.partner.settings?.isLanguageSelectionActive ?? false;

    ReportModule.setStep(ReportModule.firstScreen);
    if (ReportModule.firstScreen != ReportScreenEnum.welcome) {
      this.$router.push({ path: `/${ReportModule.firstScreen}` });
    }
  }
}
