













import { convertPdfToImg } from "@/lib/utility/convertPdfToImage";
import { handleError } from "@/lib/utility/handleError";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ISignDocument } from "@/models/sign-document.entity";
import { Component, Prop } from "vue-property-decorator";
import TimelineCard from "../utility/TimelineItem.vue";

@Component({
  components: { TimelineCard }
})
export default class ActivityTimeLineItemDocumentCardImages extends DarkModeHighlightMixin {
  @Prop()
  document!: ISignDocument;

  images: string[] = [];

  isLoading = false;

  get mapToImage() {
    return this.images.map((i, index) => {
      return {
        src: i,
        thumbnail: i,
        w: 0,
        h: 0,
        title: `${this.document.title} ${index + 1}/${this.images.length}`
      };
    });
  }

  async mounted() {
    try {
      this.isLoading = true;
      const pages = await convertPdfToImg(this.document.url);
      this.images.splice(0, this.images.length, ...pages);
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
