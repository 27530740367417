var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-report-with-banner', {
    attrs: {
      "showFooter": false,
      "isProgressDone": true
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('v-row', {
          attrs: {
            "dense": "",
            "justify": "center"
          }
        }, [_c('v-col', {
          staticClass: "fore-ground",
          attrs: {
            "md": "12",
            "lg": "10",
            "xl": "8"
          }
        }, [_vm.loading ? _c('v-card', {
          staticClass: "mx-4  mt-4",
          attrs: {
            "flat": "",
            "outlined": ""
          }
        }, [_c('v-skeleton-loader', {
          staticClass: "ma-4",
          attrs: {
            "type": "title, paragraph, paragraph"
          }
        })], 1) : _vm.expired ? _c('shared-content-access-expired', {
          attrs: {
            "partner": _vm.partner
          }
        }) : _vm.sharedContent ? _c('shared-content-access-description', {
          attrs: {
            "partner": _vm.partner,
            "user": _vm.user,
            "sharedContent": _vm.sharedContent
          }
        }) : _vm._e(), _vm.sharedContent ? _c('v-timeline', {
          staticClass: "mx-4 mb-8",
          attrs: {
            "dense": "",
            "clipped": ""
          }
        }, [_vm._l(_vm.sharedDocuments, function (sharedDocument, index) {
          return _c('div', {
            key: "".concat(sharedDocument.refId, "-").concat(sharedDocument.refType)
          }, [index && sharedDocument.refDocument ? _c('v-divider', {
            staticClass: "my-8"
          }) : _vm._e(), sharedDocument.refType === _vm.ResourceEnum.REPORT && sharedDocument.refDocument ? _c('report-base-time-line', {
            attrs: {
              "report": sharedDocument.refDocument
            }
          }) : sharedDocument.refType === _vm.ResourceEnum.DOCUMENT && sharedDocument.refDocument ? _c('activity-time-line-item-document-card', {
            attrs: {
              "isLoading": false,
              "document": sharedDocument.refDocument
            }
          }) : sharedDocument.refType === _vm.ResourceEnum.SNAPSHOT && sharedDocument.refDocument ? _c('v-card', {
            attrs: {
              "flat": ""
            }
          }, [_c('snapshot-detail', {
            attrs: {
              "value": sharedDocument.refDocument,
              "hideBreadCrumbs": true,
              "hideToDetailButton": true,
              "hideShareButton": true,
              "hideSignButton": true,
              "outline": true
            }
          })], 1) : _vm._e()], 1);
        }), !_vm.hidePrint ? _c('div', [_c('v-timeline-item', {
          staticClass: "no-print",
          attrs: {
            "hide-dot": ""
          }
        }), _c('tooltip', {
          staticClass: "no-print",
          attrs: {
            "text": _vm.$t('views.PartnerSharedReport.printHint')
          }
        }, [_c('v-btn', {
          staticClass: "no-print",
          attrs: {
            "elevation": "0",
            "color": "info"
          },
          on: {
            "click": _vm.print
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("views.PartnerSharedReport.print")) + " "), _c('v-icon', {
          attrs: {
            "right": ""
          }
        }, [_vm._v("mdi-printer-outline")])], 1)], 1)], 1) : _vm._e()], 2) : _vm._e()], 1)], 1)];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }