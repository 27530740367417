/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { MrfiktivRegistrationDtoGen } from "@/services/mrfiktiv/v1/data-contracts";

/**
 * Viewmodel of registration of a vehicle
 */
export class UpdateRegistrationDto implements MrfiktivRegistrationDtoGen {
  /**
   * @inheritdoc
   */

  firstname = "";

  /**
   * @inheritdoc
   */

  name = "";

  /**
   * @inheritdoc
   */
  street = "";

  /**
   * @inheritdoc
   */
  zipCode = "";

  /**
   * @inheritdoc
   */
  city = "";

  /**
   * @inheritdoc
   */
  huYear = "";

  /**
   * @inheritdoc
   */
  huMonth = "";

  /**
   * @inheritdoc
   */
  manufacturerNameCode = "";

  /**
   * @inheritdoc
   */
  manufacturerTypeCode = "";

  /**
   * @inheritdoc
   */
  driveTyp = "";

  /**
   * @inheritdoc
   */
  identificationnumber = "";

  /**
   * @inheritdoc
   */
  numberplate = "";

  /**
   * @inheritdoc
   */
  firstregistrationDay = "";

  /**
   * @inheritdoc
   */
  firstregistrationMonth = "";

  /**
   * @inheritdoc
   */
  firstregistrationYear = "";

  /**
   * @inheritdoc
   */
  manufacturerName = "";

  /**
   * @inheritdoc
   */
  manufacturerType = "";

  /**
   * @inheritdoc
   */
  manufacturerDescription = "";

  /**
   * @inheritdoc
   */

  vehicleClass = "";

  /**
   * Creates registration viewmodel
   * @param registration
   */
  constructor(registration: MrfiktivRegistrationDtoGen) {
    this.name = registration?.name || "";
    this.firstname = registration?.firstname || "";

    this.firstregistrationDay = registration?.firstregistrationDay || "";
    this.firstregistrationMonth = registration?.firstregistrationMonth || "";
    this.firstregistrationYear = registration?.firstregistrationYear || "";

    this.numberplate = registration?.numberplate || "";
    this.identificationnumber = registration?.identificationnumber || "";
    this.manufacturerNameCode = registration?.manufacturerNameCode || "";
    this.manufacturerTypeCode = registration?.manufacturerTypeCode || "";

    this.manufacturerName = registration?.manufacturerName || "";
    this.manufacturerType = registration?.manufacturerType || "";
    this.manufacturerDescription = registration?.manufacturerDescription || "";

    this.vehicleClass = registration?.vehicleClass || "";
    this.driveTyp = registration?.driveTyp || "";

    this.huMonth = registration?.huMonth || "";
    this.huYear = registration?.huYear || "";

    this.city = registration?.city || "";
    this.street = registration?.street || "";
    this.zipCode = registration?.zipCode || "";
  }
}
