var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('l-map', {
    style: _vm.style,
    attrs: {
      "zoom": _vm.zoom,
      "center": _vm.marker
    }
  }, [_c('l-tile-layer', {
    attrs: {
      "url": _vm.url,
      "attribution": _vm.attribution
    }
  }), _c('l-marker', {
    attrs: {
      "lat-lng": _vm.marker,
      "icon": _vm.icon
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }