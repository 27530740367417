





































import { InsuranceTypeEnum } from "@/components/report/InsuranceForm.vue";
import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import ReportStepMixin from "@/mixins/ReportStepMixin.vue";
import { MrfiktivCreateInsuranceDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { ReportModule } from "@/store/modules/report.store";
import { Component } from "vue-property-decorator";
import { requiredRule } from "@/lib/rules/requiredRule";

@Component({
  components: {
    LayoutReportStep
  }
})
export default class ReportMotoristLegalInsurance extends ReportStepMixin implements IReportStep {
  header: IReportHeaderData = {
    title: "report.motoristLegalInsurance.title",
    description: "report.motoristLegalInsurance.description"
  };

  question = "report.motoristLegalInsurance.hasInsurance";

  screen = ReportScreenEnum.motoristLegalInsurance;

  type = InsuranceTypeEnum.MOTORISTS_LEGAL_PROTECTION;

  valid = false;
  hasInsurance = false;

  insurance: MrfiktivCreateInsuranceDtoGen = {
    name: "",
    number: "",
    type: InsuranceTypeEnum.MOTORISTS_LEGAL_PROTECTION
  };

  mounted() {
    const insurance = ReportModule.insuranceList.find(insurance => insurance.type === this.type);
    if (insurance) {
      this.insurance = insurance;
      this.hasInsurance = true;
    }
  }

  get requiredRule() {
    return [requiredRule];
  }

  get isDone() {
    return this.valid && (!this.hasInsurance || (this.hasInsurance && Boolean(this.insurance.name)));
  }

  setValid(valid: boolean) {
    this.valid = valid;
  }

  setInsurance() {
    if (this.hasInsurance) {
      const list = ReportModule.insuranceList;
      list.push(this.insurance);
      ReportModule.setInsuranceList(list);
    }
  }
}
