



















































































































































































import Debug from "@/components/utility/Debug.vue";
import LayoutReport from "@/layouts/LayoutReport.vue";
import TheLayoutBase from "@/layouts/TheLayoutBase.vue";
import { BadRequestException, ConflictException, NotFoundException } from "@/lib/exceptions/http";
import { dataURItoBlob } from "@/lib/files/imageResizer";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { reportHomeQueryParams } from "@/lib/queryParams/reportHomeQueryParams";
import { requiredRule } from "@/lib/rules/requiredRule";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { createFileName, FileType } from "@/lib/utility/string-helper";
import { SignCreateSignDtoGen } from "@/services/sign/v1/data-contracts";
import { ReportModule } from "@/store/modules/report.store";
import { SignCreateModule } from "@/store/modules/sign-create.store";
import { Component, Ref, Vue } from "vue-property-decorator";
import VueSignaturePad from "vue-signature-pad";

@Component({
  components: {
    TheLayoutBase,
    LayoutReport,
    Debug
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class Sign extends Vue {
  isLoading = false;
  isSaving = false;
  isValid = false;

  isError = false;

  isPdfLoaded = false;

  errorTitle = "sign.Sign.error.title";

  terms = false;
  withdrawal = false;

  signature: SignCreateSignDtoGen = {
    lastName: ReportModule.user.lastName || "",
    firstName: ReportModule.user.firstName || "",
    location: ""
  };

  get isErrorGetter() {
    return this.isError;
  }

  get color() {
    return getDefaultPartnerColor();
  }

  get partnerColorDarkmode() {
    return this.$vuetify.theme.dark ? "white" : this.color;
  }

  get signRequest() {
    return SignCreateModule.request;
  }

  get partner() {
    return ReportModule.partner;
  }

  get requiredRule() {
    return [requiredRule()];
  }

  get heightIframe() {
    if (this.$vuetify.breakpoint.smAndDown) {
      return "350px";
    } else {
      return "600px";
    }
  }

  get heightSignature() {
    if (this.$vuetify.breakpoint.smAndDown) {
      return "150px";
    } else {
      return "200px";
    }
  }

  get firstNameSmallClass() {
    if (this.$vuetify.breakpoint.mdAndDown) {
      return "pb-0";
    } else {
      return "";
    }
  }
  @Ref("pdf")
  pdf!: HTMLIFrameElement;

  @Ref("signaturePad")
  signaturePad!: VueSignaturePad;

  cancel() {
    this.clear();

    this.signature.firstName = "";
    this.signature.lastName = "";

    this.terms = false;
  }

  clear() {
    this.signaturePad.clearSignature();
  }

  load() {
    this.isPdfLoaded = true;
  }

  async send() {
    const { isEmpty, data } = this.signaturePad.saveSignature();

    if (isEmpty) {
      this.$toast.error(this.$t("sign.Sign.error.missingSignature"));
      this.$log.error("Missing Signature");
      return;
    }

    try {
      this.isSaving = true;

      const fileName = createFileName(this.signature.lastName, FileType.PNG);
      const blob = dataURItoBlob(data);
      const image = new File([blob], fileName, { type: blob.type });

      this.signature.image = image;

      await SignCreateModule.create({
        partnerId: this.signRequest.partnerId,
        requestId: this.signRequest.requestId,
        data: this.signature
      });

      this.$toast.success(this.$t("sign.Sign.success"));
      this.errorTitle = "sign.Sign.error.conflict";
      this.isError = true;
    } catch (error) {
      this.$log.error(error);
      this.$toast.error(this.$t("sign.Sign.error.network"));
    } finally {
      this.isSaving = false;
    }
  }

  get overlayStyle() {
    if (this.$vuetify.theme.dark) {
      return "background-color:#1E1E1E;";
    } else {
      return "color: rgba(0, 0, 0, 0.6);";
    }
  }

  beforeCreate() {
    try {
      reportHomeQueryParams.forEach(param => {
        const value = this.$route.query[param.key];

        if (value) {
          param.onFound((value as string) || "");
          this.$log.info(`${param.key} set in store`);
        }
      });
    } catch (error) {
      this.$log.error(error);
    }
  }

  async mounted() {
    this.isLoading = true;

    try {
      const requestId = this.$route.params.signRequestId;
      await SignCreateModule.find({ partnerId: this.partner._id, requestId });
    } catch (error) {
      this.$log.error(error);

      if (error instanceof ConflictException) {
        this.$toast.success(this.$t("sign.Sign.error.conflict"));
        this.errorTitle = "sign.Sign.error.conflict";
      } else if (error instanceof BadRequestException) {
        this.errorTitle = "sign.Sign.error.title";
        this.$toast.error(this.$t("sign.Sign.error.title"));
      } else if (error instanceof NotFoundException) {
        this.errorTitle = "sign.Sign.error.404";
        this.$toast.error(this.$t("sign.Sign.error.404"));
      }

      this.isError = true;
    } finally {
      this.isLoading = false;
    }
  }
}
