








import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component, Prop } from "vue-property-decorator";
import { IReport } from "@/models/report.entity";

@Component({
  components: {}
})
export default class ReportPreExistingDamageDetailsTimeLineElement extends DarkModeHighlightMixin {
  @Prop()
  report!: IReport;

  get description() {
    return this.report.preExistingDamageDetails?.description;
  }
}
