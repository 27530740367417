const mapping = {
  A: 1,
  B: 2,
  C: 3,
  D: 4,
  E: 5,
  F: 6,
  G: 7,
  H: 8,
  I: 9,
  J: 1,
  K: 2,
  L: 3,
  M: 4,
  N: 5,
  O: 6,
  P: 7,
  Q: 8,
  R: 9,
  S: 2,
  T: 3,
  U: 4,
  V: 5,
  W: 6,
  X: 7,
  Y: 8,
  Z: 9,
  Ä: 1,
  Ö: 6,
  Ü: 4,
  "0": 0,
  "1": 1,
  "2": 2,
  "3": 3,
  "4": 4,
  "5": 5,
  "6": 6,
  "7": 7,
  "8": 8,
  "9": 9
};
const weights = {
  "1": 9,
  "2": 8,
  "3": 7,
  "4": 6,
  "5": 5,
  "6": 4,
  "7": 3,
  "8": 2,
  "9": 10,
  "10": 9,
  "11": 8,
  "12": 7,
  "13": 6,
  "14": 5,
  "15": 4,
  "16": 3,
  "17": 2
};

export type VinChecksum = -1 | 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | "X" | "-";

export const vinChecksumWildCard = "-";
/**
 * All valid checksums (0-10 and X, as well as - for wildcard)
 */
export const legitChecksums: VinChecksum[] = (() => {
  const checksums: VinChecksum[] = Array.from(Array(11).keys()) as VinChecksum[];
  checksums.push("X");
  checksums.push(vinChecksumWildCard);

  return checksums;
})();

export function calculateVinChecksum(vin: string): VinChecksum {
  let sum = 0;
  for (let i = 0; i < vin.length; i++) {
    sum = sum + weights[i + 1] * mapping[vin.charAt(i)];
  }
  if (isNaN(sum % 11)) {
    return -1;
  } else if (sum % 11 === 10) {
    return "X";
  } else {
    return (sum % 11) as VinChecksum;
  }
}

/**
 * VIN is required for all on-road vehicles sold to contain a 17-character VIN.
 * Which does not include the letters O (o), I (i), and Q (q) (to avoid confusion with numerals 0, 1, and 9).
 * @see https://en.wikipedia.org/wiki/Vehicle_identification_number#World_manufacturer_identifier
 */
export class VIN {
  /**
   * World manufacturer identifier
   * European Union more than 500 vehicles/year: Position 1 - 3
   * European Union 500 or fewer vehicles/year: Position 1 - 2 + 9
   * North America more than 2,000 vehicles/year: Position 1 - 3
   * North America 2,000 or fewer vehicles/year: 1 - 2 + 9
   */
  wmi: string;

  /**
   * Vehicle descriptor section
   * Indication of "the general characteristics of the vehicle"
   * North America: Position 4 - 8 + checkSum
   */
  vds: string;

  /**
   * Vehicle identifier section
   * Indication that provides "clear identification of a particular vehicle"
   * North America: Position 10 : Model year
   * North America: Position 11 : Plant code
   * North America more than 2,000 vehicles/year: Position 12 - 17 : Sequential number
   * North America 2,000 or fewer vehicles/year: Position 12 - 14 : Manufacturer identifier
   * North America 2,000 or fewer vehicles/year: Position 15 - 17 : Sequential number
   */
  vis: string;

  constructor(vin: string) {
    if (vin.length !== 17) {
      throw Error("VIN is not 17 characters");
    }

    this.wmi = vin.substring(0, 3);
    this.vds = vin.substring(3, 9);
    this.vis = vin.substring(9, 17);
  }
}
