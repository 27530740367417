import { Filter, IsFilterable } from "@/lib/filterable";
import { CreateDto } from "@/lib/utility/data/create-dto.interface";
import { IEntity } from "@/lib/utility/data/entity.interface";
import { handleError } from "@/lib/utility/handleError";
import { SharingLinkFactory } from "@/lib/utility/sharingLinkFactory";
import { $t } from "@/lib/utility/t";
import attachmentService from "@/services/mrfiktiv/services/attachmentService";
import {
  MrfiktivAttachmentRequestViewModelGen,
  MrfiktivBaseCreateSharedContentDtoGen,
  MrfiktivCreateReportAsPartnerDtoGen,
  MrfiktivSharedContentViewmodelGen,
  MrfiktivTimestampGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { AttachmentRequestDataAccessLayer } from "@/store/modules/access-layers/attachment-request.access-layer";
import Vue from "vue";

@IsFilterable
class AttachmentRequestEntity extends CreateDto<IAttachmentRequest>
  implements MrfiktivAttachmentRequestViewModelGen, Partial<IEntity<IAttachmentRequest>> {
  id: string;

  partnerId: string;

  userId?: string | undefined;

  refs: MrfiktivBaseCreateSharedContentDtoGen[];

  validBy?: string | null;

  token: string;

  isSystemGenerated?: boolean | undefined;

  timestamp: MrfiktivTimestampGen;

  screenOrder: ReportScreenEnum[];

  attachments?: MrfiktivCreateReportAsPartnerDtoGen[];

  isLoading = false;

  isNotFound = false;

  title = "";

  description = "";

  get isExpired() {
    if (!this.validBy) return false;

    return new Date(this.validBy) < new Date();
  }

  get isUpdateable() {
    return true;
  }

  constructor(attachmentRequest?: Partial<AttachmentRequestEntity | MrfiktivSharedContentViewmodelGen>) {
    super();
    this.id = attachmentRequest?.id || "";
    this.partnerId = attachmentRequest?.partnerId || "";
    this.userId = attachmentRequest?.userId || "";
    this.refs = attachmentRequest?.refs || [];
    this.validBy = attachmentRequest?.validBy || null;
    this.token = attachmentRequest?.token || "";
    this.isSystemGenerated = attachmentRequest?.isSystemGenerated || false;
    this.timestamp = (attachmentRequest?.timestamp || {}) as MrfiktivTimestampGen;
    this.screenOrder = ((attachmentRequest as AttachmentRequestEntity)?.screenOrder || []) as ReportScreenEnum[];
    this.attachments = (attachmentRequest as AttachmentRequestEntity)?.attachments || [];
    this.title = attachmentRequest?.title || $t("objects.attachmentRequest.title");
    this.description = attachmentRequest?.description || "";
  }

  private map(attachmentRequest: MrfiktivAttachmentRequestViewModelGen) {
    this.id = attachmentRequest.id;
    this.partnerId = attachmentRequest.partnerId;
    this.userId = attachmentRequest.userId;
    this.refs = attachmentRequest.refs;
    this.validBy = attachmentRequest.validBy || null;
    this.token = attachmentRequest.token;
    this.isSystemGenerated = attachmentRequest.isSystemGenerated;
    this.timestamp = attachmentRequest.timestamp;
    this.screenOrder = attachmentRequest.screenOrder as ReportScreenEnum[];
    this.attachments = attachmentRequest.attachments;
    this.title = attachmentRequest.title || $t("objects.attachmentRequest.title");
    this.description = attachmentRequest.description || "";
  }

  async fetch(silent?: boolean): Promise<this> {
    try {
      this.isLoading = true;
      const attachmentRequest = await attachmentService.getRequest(this.partnerId, this.id);
      this.map(attachmentRequest);
      AttachmentRequestDataAccessLayer.set(this);
    } catch (e) {
      if (silent) Vue.$log.error(e);
      else handleError(e);
      this.isNotFound = true;
    } finally {
      this.isLoading = false;
    }

    return this;
  }

  async update(silent = false): Promise<IAttachmentRequest> {
    try {
      this.isLoading = true;
      const attachmentRequest = await attachmentService.update(this.partnerId, this.id, {
        validBy: this.validBy || null,
        title: this.title,
        description: this.description
      });
      this.map(attachmentRequest);
      AttachmentRequestDataAccessLayer.set(this);
    } catch (e) {
      handleError(e);
      if (!silent) throw e;
    } finally {
      this.isLoading = false;
    }

    return this;
  }

  async create(silent = true): Promise<this> {
    try {
      const res = await attachmentService.create(this.partnerId, {
        refs: this.refs,
        screenOrder: this.screenOrder,
        validBy: this.validBy || null
      });

      const created = new AttachmentRequest(res);

      this.map(created);

      AttachmentRequestDataAccessLayer.set(this);
    } catch (e) {
      handleError(e);

      if (!silent) throw e;
    }

    return this;
  }

  async disable() {
    this.validBy = new Date().toISOString();

    await this.update();
  }

  async delete() {
    try {
      this.isLoading = true;
      await attachmentService.delete(this.partnerId, this.id);
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }

  copyLinkToClipboard(): void {
    try {
      new SharingLinkFactory(this, true).copyLinkToClipboard();
      Vue.$toast.info($t("components.partner.PartnerReportInitializeCard.linkCopied"));
    } catch (error) {
      Vue.$log.error(error);
      Vue.$toast.error($t("components.partner.PartnerReportInitializeCard.error"));
    }
  }
}
type IAttachmentRequest = AttachmentRequestEntity;
const AttachmentRequest = Filter.createForClass(AttachmentRequestEntity);

export { AttachmentRequest, IAttachmentRequest };
