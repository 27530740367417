























































































































































































import { UpdateVehicleRegistration } from "@/models/update-vehicle-registration.dto";
import { MrfiktivUpdateVehicleRegistrationDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ConfigModule } from "@/store/modules/config";
import { Component, Prop, Vue } from "vue-property-decorator";
import Debug from "../utility/Debug.vue";
import { calculateVinChecksum } from "@/lib/utility/calculateVinChecksum";
import { vinOptionalRuleLength } from "@/lib/rules/vinRule";

@Component({
  components: { Debug }
})
export default class RegistrationForm extends Vue {
  @Prop()
  value!: UpdateVehicleRegistration;

  valid = false;

  get registration() {
    return this.value;
  }

  set registration(registration: MrfiktivUpdateVehicleRegistrationDtoGen) {
    this.$emit("input", registration);
  }

  get isValid() {
    return this.valid;
  }

  set isValid(v: boolean) {
    this.$emit("update:valid", v);
  }

  get debug() {
    return ConfigModule.debug;
  }

  get vinRuleLength() {
    return [vinOptionalRuleLength()];
  }

  get getChecksumText() {
    if (!this.registration.identificationnumber || this.registration.identificationnumber.length < 17) {
      return "";
    }

    return `${this.$t("common.nouns.checksum")}: ${calculateVinChecksum(this.registration.identificationnumber || "")}`;
  }
}
