














import { Component, Vue, Prop } from "vue-property-decorator";
import { ISharedContent } from "@/models/shared-content.entity";
import SideCard from "../utility/SideCard.vue";
import SharedContentDetail from "./SharedContentDetail.vue";
import { IRefSuggestion } from "@/store/modules/fleet-aggregation.store";
import { DocumentModule } from "@/store/modules/document.store";
import { SignRequestModule } from "@/store/modules/sign-request.store";
import { DigitalSignatureRequestStatusEnum } from "@/lib/enum/digital-signature-request-status.enum";
import { SignModule } from "@/store/modules/sign.store";
import { ISignDocument, SignDocument } from "@/models/sign-document.entity";
import { ActivityLogModule, ActivityTypeEnum } from "@/store/modules/activity-log.store";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { SignRequest } from "@/models/sign-request.entity";

@Component({
  components: {
    SideCard,
    SharedContentDetail
  }
})
export default class SharedContentSideCard extends Vue {
  @Prop()
  value!: ISharedContent;

  @Prop({ default: true })
  hideSuggested?: boolean;

  suggestedDocuments: IRefSuggestion[] = [];

  isLoading = false;

  async mounted() {
    this.isLoading = true;

    await this.value.fetch();

    if (this.value.isNotFound) {
      this.close();
    }

    this.isLoading = false;

    if (!this.hideSuggested) {
      await this.getDocumentSuggestions().then(suggestedDocuments => {
        this.suggestedDocuments.splice(0, this.suggestedDocuments.length, ...suggestedDocuments);
      });
    }
  }

  close() {
    this.$emit("close");
  }

  private async getDocumentForId(documentId?: string) {
    const partnerId = this.value.partnerId;

    if (!documentId) {
      return;
    }
    const fromList = DocumentModule.maps.id.get(documentId)[0];
    if (fromList) {
      return fromList;
    }

    const document = await new SignDocument({ partnerId, id: documentId }).fetch();

    return document;
  }

  private async getDocumentIdFromSignId(requestId: string) {
    const partnerId = this.value.partnerId;

    const signRequest =
      SignRequestModule.maps.id.get(requestId)[0] ?? (await new SignRequest({ partnerId, id: requestId }).fetch());

    if (signRequest.status !== DigitalSignatureRequestStatusEnum.SIGNED) {
      if (!signRequest.documents.length) {
        return;
      }

      return signRequest.documents[0];
    }

    const signatures = await SignModule.findAll({ partnerId, requestId });

    if (signatures.length) {
      const signId = signatures[0].id;
      const signature = await SignModule.findOne({ partnerId, requestId, signId });
      if (!signature.documents?.length) {
        return;
      }

      return signature.documents[0].id;
    }
  }

  private async getDocumentSuggestions() {
    const suggestedDocuments: IRefSuggestion[] = [];
    const documents: (ISignDocument | undefined)[] = [];

    const promises = [];
    for (const log of ActivityLogModule.paginationList) {
      if (!log?.target?.length || !log?.target[0].refId) {
        continue;
      }
      const refId = log?.target[0].refId;

      this.$log.debug(refId, log);

      switch (log.activity) {
        case ActivityTypeEnum.CREATE_DOCUMENT: {
          promises.push(
            this.getDocumentForId(refId)
              .then(d => documents.push(d))
              .catch(this.$log.error)
          );

          break;
        }

        case ActivityTypeEnum.REQUEST_SIGNATURE: {
          promises.push(
            this.getDocumentIdFromSignId(refId)
              .then(this.getDocumentForId)
              .then(d => documents.push(d))
              .catch(this.$log.error)
          );

          break;
        }
      }
    }

    await Promise.all(promises);

    for (const document of documents) {
      if (document && !suggestedDocuments.find(d => d.id === document.id)) {
        suggestedDocuments.push({
          id: document.id,
          ref: document,
          refType: BackendResourceEnum.DOCUMENT
        });
      }
    }

    return suggestedDocuments;
  }
}
