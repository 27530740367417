























import FileUploadPreview from "@/components/utility/FileUploadPreview.vue";
import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { AssetEnum } from "@/lib/AssetRepository";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportInstructionData } from "@/lib/interfaces/Report/IReportInstructionData";
import { IViewImageUploadable } from "@/lib/interfaces/Report/IViewImageUploadable";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import ReportImageSubmission from "@/mixins/ReportImageSubmissionMixin.vue";
import { IImageUploaded } from "@/models/Image/IImageUploaded";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { ReportModule } from "@/store/modules/report.store";
import { Component } from "vue-property-decorator";
import { CountryCodeEnum } from "@/lib/enum/country-code.enum";

export interface IReportImageCountryConfig {
  asset: AssetEnum;
  example: AssetEnum;
  header: IReportHeaderData;
  instruction: IReportInstructionData;
}

@Component({
  components: {
    LayoutReportStep,
    FileUploadPreview
  }
})
export default class ReportRegistrationImage extends ReportImageSubmission
  implements IReportStep, IViewImageUploadable {
  screen = ReportScreenEnum.registration;

  public maxFiles = 1;

  get asset() {
    return this.currentCountryConfig?.asset || AssetEnum.registrationSchema;
  }

  public get example() {
    return this.currentCountryConfig?.example || AssetEnum.registration;
  }

  public get header(): IReportHeaderData {
    return (
      this.currentCountryConfig?.header || {
        title: "report.imageRegistration.title",
        description: "report.imageRegistration.description",
        image: AssetEnum.registrationExample,
        debug: this.files // This usage is fine since it doesn't create a circular dependency
      }
    );
  }

  public get instruction(): IReportInstructionData {
    return (
      this.currentCountryConfig?.instruction || {
        title: "report.imageRegistration.title",
        text: "report.imageRegistration.description",
        pictures: [AssetEnum.registrationCorrectInstructions, AssetEnum.registrationIncorrectInstructions],
        example: AssetEnum.registrationExample,
        display: true
      }
    );
  }

  get countryCode(): CountryCodeEnum {
    return (ReportModule.partner.countryCode as CountryCodeEnum) || CountryCodeEnum.germany;
  }
  get currentCountryConfig(): IReportImageCountryConfig | undefined {
    return this.countryConfigMap.get(this.countryCode) || this.countryConfigMap.get(CountryCodeEnum.germany);
  }

  get countryConfigMap() {
    return new Map<CountryCodeEnum, IReportImageCountryConfig>([
      [
        CountryCodeEnum.germany,
        {
          asset: AssetEnum.registrationSchema,
          example: AssetEnum.registration,
          header: {
            title: "report.imageRegistration.title",
            description: "report.imageRegistration.description",
            image: AssetEnum.registrationExample,
            debug: this.files // Here we're using `this.files` for the debug field
          },
          instruction: {
            title: "report.imageRegistration.title",
            text: "report.imageRegistration.description",
            pictures: [AssetEnum.registrationCorrectInstructions, AssetEnum.registrationIncorrectInstructions],
            example: AssetEnum.registrationExample,
            display: true
          }
        }
      ],
      [
        CountryCodeEnum.switzerland,
        {
          asset: AssetEnum.registrationSchemaChe,
          example: AssetEnum.registrationChe,
          header: {
            title: "report.imageRegistrationCH.title",
            description: "report.imageRegistrationCH.description",
            image: AssetEnum.registrationSchemaChe,
            debug: this.files
          },
          instruction: {
            title: "report.imageRegistrationCH.title",
            text: "report.imageRegistrationCH.description",
            pictures: [AssetEnum.registrationSchemaChe],
            example: AssetEnum.registrationSchemaChe,
            display: true
          }
        }
      ],
      [
        CountryCodeEnum.austria,
        {
          asset: AssetEnum.registrationAtSchema,
          example: AssetEnum.registrationAt,
          header: {
            title: "report.imageRegistration.title",
            description: "report.imageRegistration.description",
            image: AssetEnum.registrationAtSchema,
            debug: this.files
          },
          instruction: {
            title: "report.imageRegistration.title",
            text: "report.imageRegistration.description",
            pictures: [AssetEnum.registrationAtSchema],
            example: AssetEnum.registrationAtSchema,
            display: true
          }
        }
      ]
    ]);
  }

  /**
   * @inheritdoc
   */
  async handleUpload(file: File): Promise<boolean> {
    if (this.maxFiles !== null && this.files.length >= this.maxFiles) {
      const toDelete = this.files[this.files.length - 1].file;
      await this.handleDelete(toDelete);
    }

    await ReportModule.addRegistration(file);

    return true;
  }

  /**
   * @inheritdoc
   */
  async handleDelete(file: File): Promise<boolean> {
    await ReportModule.removeRegistration(file);
    return true;
  }

  /**
   * @inheritdoc
   */
  get files(): IImageUploaded[] {
    return ReportModule.registrations.slice().reverse();
  }
}
