var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.hideButton ? _c('v-btn', {
    attrs: {
      "elevation": 0
    },
    on: {
      "click": _vm.show
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("create")) + " ")]) : _vm._e(), _vm.isDialogActiveLocal ? _c('confirm-action-dialog', {
    attrs: {
      "width": "1050",
      "persistent": true,
      "supressKeyboardActions": true,
      "title": _vm.$t('cost.createCost'),
      "isDialogActive": _vm.isDialogActiveLocal,
      "rightText": _vm.$t('create'),
      "loading": _vm.isLoading,
      "fullscreen": _vm.fullscreen,
      "rightLoading": _vm.isLoading,
      "rightDisabled": !_vm.cost.title
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDialogActiveLocal = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDialogActiveLocal = $event;
      },
      "leftClick": function leftClick($event) {
        _vm.isDialogActive = false;
      },
      "close": function close($event) {
        _vm.isDialogActive = false;
      },
      "rightClick": _vm.onRightClick
    }
  }, [_c('div', {
    on: {
      "dragover": function dragover($event) {
        $event.preventDefault();
        return _vm.startDrag.apply(null, arguments);
      }
    }
  }, [_vm.isDrag ? _c('div', {
    staticStyle: {
      "z-index": "1",
      "top": "0",
      "left": "0",
      "position": "absolute",
      "height": "100%",
      "width": "100%",
      "background-color": "rgba(200,200,200,0.5)"
    }
  }, [_c('div', {
    staticStyle: {
      "height": "100%",
      "display": "flex",
      "align-items": "center",
      "justify-content": "center"
    },
    on: {
      "dragleave": function dragleave($event) {
        $event.preventDefault();
        return _vm.endDrag.apply(null, arguments);
      },
      "drop": function drop($event) {
        $event.preventDefault();
        return _vm.onFileDrop($event);
      }
    }
  }, [_c('v-icon', {
    staticStyle: {
      "transform": "scale(300%)"
    },
    attrs: {
      "x-large": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.prependIcon) + " ")])], 1)]) : _vm._e(), _c('v-row', {
    staticClass: "pt-3"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-text-field', {
    ref: "radioExpense",
    attrs: {
      "outlined": "",
      "label": _vm.$t('objects.cost.title'),
      "rules": _vm.requiredRule
    },
    model: {
      value: _vm.cost.title,
      callback: function callback($$v) {
        _vm.$set(_vm.cost, "title", $$v);
      },
      expression: "cost.title"
    }
  }), _c('debug', [_vm._v("cost.expenseOrIncome " + _vm._s(_vm.cost.expenseOrIncome))]), _c('v-radio-group', {
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.expenseOrIncome,
      callback: function callback($$v) {
        _vm.expenseOrIncome = $$v;
      },
      expression: "expenseOrIncome"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": _vm.$t('common.nouns.expense'),
      "value": _vm.CostTypeEnum.EXPENSE
    }
  }), _c('v-radio', {
    attrs: {
      "label": _vm.$t('common.nouns.credit'),
      "value": _vm.CostTypeEnum.INCOME
    }
  })], 1), _c('debug', [_vm._v("cost.absoluteTotal " + _vm._s(_vm.cost.absoluteTotal))]), _c('debug', [_vm._v("cost.total " + _vm._s(_vm.cost.total))]), _c('money-input', {
    attrs: {
      "label": _vm.$t('objects.cost.total')
    },
    model: {
      value: _vm.cost.absoluteTotal,
      callback: function callback($$v) {
        _vm.$set(_vm.cost, "absoluteTotal", $$v);
      },
      expression: "cost.absoluteTotal"
    }
  }), _c('v-textarea', {
    attrs: {
      "outlined": "",
      "label": _vm.$t('objects.cost.description')
    },
    model: {
      value: _vm.cost.description,
      callback: function callback($$v) {
        _vm.$set(_vm.cost, "description", $$v);
      },
      expression: "cost.description"
    }
  }), _c('debug', [_vm._v("cost.date " + _vm._s(_vm.cost.date))]), _c('v-text-field', {
    attrs: {
      "type": "date",
      "outlined": "",
      "label": _vm.$t('objects.cost.date')
    },
    model: {
      value: _vm.cost.date,
      callback: function callback($$v) {
        _vm.$set(_vm.cost, "date", $$v);
      },
      expression: "cost.date"
    }
  }), _c('v-combobox', {
    attrs: {
      "items": _vm.tags,
      "label": _vm.$t('objects.cost.tags'),
      "multiple": "",
      "outlined": "",
      "clearable": ""
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-chip', _vm._b({
          attrs: {
            "outlined": ""
          }
        }, 'v-chip', item.attrs, false), [_vm._v(" " + _vm._s(item) + " ")])];
      }
    }, {
      key: "item",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(item) + " ")])], 1)];
      }
    }], null, false, 1686412609),
    model: {
      value: _vm.cost.tags,
      callback: function callback($$v) {
        _vm.$set(_vm.cost, "tags", $$v);
      },
      expression: "cost.tags"
    }
  }), _c('v-select', {
    attrs: {
      "item-value": "id",
      "items": _vm.groups,
      "label": _vm.$t('objects.cost.group'),
      "outlined": ""
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.title) + " ")];
      }
    }, {
      key: "selection",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.title) + " ")];
      }
    }], null, false, 2714690748),
    model: {
      value: _vm.cost.group,
      callback: function callback($$v) {
        _vm.$set(_vm.cost, "group", $$v);
      },
      expression: "cost.group"
    }
  }), _c('div', {
    staticClass: "mt-n7",
    staticStyle: {
      "display": "flex"
    }
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "x-small": "",
      "text": "",
      "elevation": 0,
      "loading": _vm.isLoadingCostGroups
    },
    on: {
      "click": _vm.refreshCostGroups
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("common.verbs.refresh")) + " ")]), _c('v-btn', {
    attrs: {
      "x-small": "",
      "text": "",
      "elevation": 0
    },
    on: {
      "click": _vm.goToCostGroupCustomView
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("common.verbs.view")) + " ")])], 1), _vm.costGroup && _vm.costGroup.configuration && _vm.costGroup.configuration.customFieldConfig && _vm.costGroup.configuration.customFieldConfig.length ? _c('div', [_vm.isLoadingCustomFieldValues ? _c('v-progress-linear', {
    attrs: {
      "indeterminate": ""
    }
  }) : _c('v-divider'), _c('custom-field-list-form', {
    staticClass: "mt-6",
    attrs: {
      "customFieldConfig": _vm.costGroup.configuration.customFieldConfig
    },
    model: {
      value: _vm.cost.values,
      callback: function callback($$v) {
        _vm.$set(_vm.cost, "values", $$v);
      },
      expression: "cost.values"
    }
  }), _c('debug', [_vm._v(" cost.values: " + _vm._s(_vm.cost.values)), _c('br'), _vm._v(" cost.group: " + _vm._s(_vm.cost.group)), _c('br'), _vm._v(" costGroup: " + _vm._s(_vm.costGroup)), _c('br')])], 1) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('cost-card-documents', {
    ref: "costCardDocuments",
    staticClass: "mb-3",
    attrs: {
      "disabledDragAndDrop": true,
      "partnerId": _vm.cost.partnerId,
      "costId": _vm.cost.id,
      "loading": false
    },
    on: {
      "created": _vm.onCreatedDocument
    },
    model: {
      value: _vm.cost.files,
      callback: function callback($$v) {
        _vm.$set(_vm.cost, "files", $$v);
      },
      expression: "cost.files"
    }
  }), _c('refs-select', {
    attrs: {
      "partnerId": _vm.partnerId,
      "loading": _vm.isLoading,
      "suggested": _vm.suggested,
      "listMode": true,
      "hideSuggestions": true,
      "hideEditButton": true,
      "categories": _vm.categories,
      "isConfirmable": false
    },
    on: {
      "change": _vm.onRefsChange
    },
    model: {
      value: _vm.cost.refs,
      callback: function callback($$v) {
        _vm.$set(_vm.cost, "refs", $$v);
      },
      expression: "cost.refs"
    }
  })], 1)], 1), _c('debug', [_vm._v(" " + _vm._s(_vm.cost) + " ")])], 1)]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }