var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-chip', {
    attrs: {
      "small": _vm.small,
      "active": "",
      "color": _vm.color
    }
  }, [_vm.large ? _c('h3', [_vm._v(" " + _vm._s(_vm.value.totalReadable) + " ")]) : _c('div', [_vm._v(" " + _vm._s(_vm.value.totalReadable) + " ")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }