












import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class PartnerDetailAttributeWithToolTip extends Vue {
  @Prop()
  tooltip!: string;

  @Prop()
  icon!: string;

  @Prop()
  displayName!: string;
}
