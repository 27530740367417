var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-carousel', {
    staticClass: "d-sm-none"
  }, _vm._l(_vm.images, function (item, i) {
    return _c('v-carousel-item', {
      key: i,
      attrs: {
        "src": _vm.tryGetUrl(item),
        "reverse-transition": "fade-transition",
        "transition": "fade-transition",
        "contain": ""
      }
    });
  }), 1), _c('v-row', {
    staticClass: "hidden-xs-only"
  }, _vm._l(_vm.images, function (item, i) {
    return _c('v-col', {
      key: i,
      staticClass: "d-flex child-flex",
      attrs: {
        "cols": "12",
        "md": "4",
        "sm": "12",
        "xs": "12"
      }
    }, [_c('v-img', {
      staticClass: "grey lighten-2",
      attrs: {
        "src": _vm.tryGetUrl(item),
        "aspect-ratio": "1"
      },
      scopedSlots: _vm._u([{
        key: "placeholder",
        fn: function fn() {
          return [_c('v-row', {
            staticClass: "fill-height ma-0",
            attrs: {
              "align": "center",
              "justify": "center"
            }
          }, [_c('v-progress-circular', {
            attrs: {
              "indeterminate": "",
              "color": "grey lighten-5"
            }
          })], 1)];
        },
        proxy: true
      }], null, true)
    })], 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }