












import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component, Prop } from "vue-property-decorator";
import CardIcon from "./CardIcon.vue";
import Tooltip from "../utility/tooltip.vue";

@Component({
  components: { CardIcon, Tooltip }
})
export default class CopyClipboardText extends DarkModeHighlightMixin {
  @Prop()
  icon!: string;

  @Prop()
  text!: string;

  @Prop({ default: "components.CopyClipboardText.copy" })
  toolTipText!: string;

  copy() {
    navigator.clipboard.writeText(this.text);
    this.$toast.info(this.$t("components.CopyClipboardText.confirmCopy"));
  }
}
