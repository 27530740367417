var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-report-step', {
    attrs: {
      "isDone": _vm.isDone,
      "title": _vm.header.title,
      "description": _vm.header.description,
      "debug": _vm.message
    }
  }, [_c('v-card', {
    staticClass: "pt-4",
    attrs: {
      "flat": "",
      "max-width": "500"
    }
  }, [_c('v-form', {
    model: {
      value: _vm.isDone,
      callback: function callback($$v) {
        _vm.isDone = $$v;
      },
      expression: "isDone"
    }
  }, [_c('v-textarea', {
    staticClass: "contain padded",
    attrs: {
      "clearable": "",
      "auto-grow": "",
      "outlined": "",
      "label": _vm.$t(_vm.placeholder),
      "counter": _vm.maxLength,
      "rows": "7",
      "rules": [_vm.lengthRule],
      "row-height": "35",
      "data-test-form-description": ""
    },
    on: {
      "blur": _vm.updateMessage
    },
    model: {
      value: _vm.message,
      callback: function callback($$v) {
        _vm.message = $$v;
      },
      expression: "message"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }