



























































import PartnerDetailAttributeWithToolTip from "@/components/partner/PartnerDetailAttributeWithToolTip.vue";
import { ITemplateContext } from "@/lib/interfaces/template/templateContext.interface";
import { handleError } from "@/lib/utility/handleError";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { PartnerEntity } from "@/models/partnerEntity";
import { IReport } from "@/models/report.entity";
import {
  MrfiktivCreateActivityLogDtoGen,
  MrfiktivPartnerMessageViewModelGen,
  MrfiktivReferenceGen,
  MrfiktivUserViewmodelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { ThgReferenceGen } from "@/services/thg/v1/data-contracts";
import { IThg } from "@/models/thg.entity";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { BackendResourceEnum, ResourceEnum } from "@/store/enum/authResourceEnum";
import { ActivityTypeEnum, ActivityLogModule } from "@/store/modules/activity-log.store";
import { Component, Prop } from "vue-property-decorator";
import TemplateCard from "../template/TemplateCard.vue";

@Component({
  components: {
    PartnerDetailAttributeWithToolTip,
    TemplateCard
  }
})
export default class ReportActivityBoxMailComponent extends DarkModeHighlightMixin {
  @Prop()
  partner!: PartnerEntity;

  @Prop()
  source?: MrfiktivReferenceGen | ThgReferenceGen;

  @Prop()
  report?: IReport;

  @Prop()
  thg?: IThg;

  @Prop()
  user?: MrfiktivUserViewmodelGen;

  @Prop()
  mail?: string;

  @Prop()
  isEditorMobile?: boolean;

  isValid = false;

  get from() {
    return this.partner;
  }

  get to() {
    if (this.mail) {
      return [this.mail];
    }

    if (this.report?.customerContact?.email) {
      return [this.report.customerContact.email];
    }

    if (this.user?.contact?.email) {
      return [this.user.contact.email];
    }

    return [];
  }

  get context(): ITemplateContext {
    return { report: this.report, thg: this.thg, partner: this.partner, user: this.user };
  }

  async saveInActivityLog(messages: MrfiktivPartnerMessageViewModelGen[]) {
    let refType = this.source?.refType;
    if (!refType) {
      refType = this.report ? ResourceEnum.REPORT : ResourceEnum.THG;
    }
    const refId = this.source?.refId ?? this.report?._id ?? this.thg?.id ?? "";
    const partnerId = this.partner.id ?? this.partner._id ?? this.report?.partnerId ?? this.thg?.partnerId ?? "";

    for (const message of messages) {
      const source: MrfiktivReferenceGen = {
        refType,
        refId
      };
      const data: MrfiktivCreateActivityLogDtoGen = {
        source,
        target: [{ refType: BackendResourceEnum.MESSAGE, refId: message.id }],
        actionType: ActionEnum.CREATE,
        activity: ActivityTypeEnum.MESSAGE
      };

      const activity = await ActivityLogModule.create({
        partnerId,
        data
      }).catch(handleError);

      if (activity) {
        ActivityLogModule.addToList(activity);
      }
    }
  }

  isSent = false;
  isLoading = false;
  async send() {
    this.isLoading = true;
    const mails = await (this.$refs.templateInput as TemplateCard)?.send().catch(handleError);

    if (mails) {
      await this.saveInActivityLog(mails);
      this.isSent = true;
    }
    this.isLoading = false;
  }
}
