


























import { activityLogName } from "@/lib/utility/activityLogName";
import { detailedDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { MrfiktivActivityLogViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ThgAdminMessageViewModelGen } from "@/services/thg/v1/data-contracts";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { AdminMessageModule } from "@/store/modules/admin-message.store";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import TemplateEditor from "../template/TemplateEditor.vue";
import ContextMenu from "../utility/ContextMenu.vue";
import TimeLineItemText from "../utility/TimeLineItemText.vue";
import ActivityTimeLineItemDelete from "./ActivityTimeLineItemDelete.vue";

@Component({
  components: { TemplateEditor, TimeLineItemText, ActivityTimeLineItemDelete, ContextMenu }
})
export default class ActivityTimeLineItemProgressStatusMessage extends mixins(DarkModeHighlightMixin, PermissionMixin) {
  @Prop({})
  activityLogEntry!: MrfiktivActivityLogViewModelGen;

  message: ThgAdminMessageViewModelGen | null = null;

  loading = false;

  get title() {
    if (this.loading || !this.message?.to) {
      return this.$t("timeLine.ActivityTimeLineItemProgressStatusMessage.title", {
        name: this.name,
        date: this.date,
        status: this.activityLogEntry.comment
      });
    } else {
      return this.$t("timeLine.ActivityTimeLineItemProgressStatusMessage.titleReceiver", {
        name: this.name,
        date: this.date,
        receiver: this.message?.to || "",
        status: this.activityLogEntry.comment
      });
    }
  }

  get name() {
    return activityLogName(this.activityLogEntry);
  }

  get date() {
    if (this.activityLogEntry.timestamp?.created) {
      const locale = this.$t("utility.toLocalDateString").toString() || "de-de";
      return detailedDate(this.activityLogEntry.timestamp?.created, locale);
    }
    return this.$t("sign.DocumentDetail.noDate");
  }

  async mounted() {
    const target = this.activityLogEntry.target?.find(t => t.refType === BackendResourceEnum.MESSAGE);

    const messageId = target?.refId;

    if (!this.isAdmin || !messageId) {
      return;
    }

    try {
      this.loading = true;
      this.message = (await AdminMessageModule.getMessage(messageId)) ?? null;
    } catch (e) {
      handleError(e);
    } finally {
      this.loading = false;
    }
  }
}
