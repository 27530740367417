import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { MrfiktivAgreementViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ITimestamp, Timestamp } from "./timestamp.entity";

@IsFilterable
export class AgreementBase implements MrfiktivAgreementViewModelGen {
  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.agreement.description"
  })
  description: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: Timestamp
  })
  timestamp: ITimestamp;

  /**
   * Construct handover
   */
  constructor(agreement?: Partial<AgreementBase | MrfiktivAgreementViewModelGen>) {
    this.description = agreement?.description ?? "";
    this.timestamp = new Timestamp(
      agreement?.timestamp ?? { created: new Date().toISOString(), lastModified: new Date().toISOString() }
    );
  }
}

type IAgreement = AgreementBase;
const Agreement = Filter.createForClass(AgreementBase);

export { Agreement, IAgreement };
