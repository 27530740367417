
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import ReportDatePicker from "../ReportFormDatePicker.vue";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";

export default class RepareoReportDatePicker extends ReportDatePicker {
  screen = ReportScreenEnum.REPAREO_DATE_REQUEST;
  header: IReportHeaderData = {
    title: "report.datePicker.title",
    description: "report.datePickerRepareo.description"
  };
}
