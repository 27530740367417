




















import PartnerDetailAttributeWithToolTip from "@/components/partner/PartnerDetailAttributeWithToolTip.vue";
import { activityLogName } from "@/lib/utility/activityLogName";
import { detailedDate } from "@/lib/utility/date-helper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { MrfiktivActivityLogViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { Component, Prop } from "vue-property-decorator";

@Component({
  components: {
    PartnerDetailAttributeWithToolTip
  }
})
export default class BaseActivityTimeLineItem extends DarkModeHighlightMixin {
  @Prop({})
  activityLogEntry!: MrfiktivActivityLogViewModelGen;

  get name() {
    return activityLogName(this.activityLogEntry);
  }

  get headers() {
    return [{ value: "key" }, { value: "value" }];
  }

  parseActivityToKeyValue(ativityLogEntry: MrfiktivActivityLogViewModelGen) {
    const keyValueStore = [];

    if (ativityLogEntry.activity) {
      keyValueStore.push({
        key: this.$t("timeLine.BaseActivityTimeLineItem.table.activity"),
        value: ativityLogEntry.activity
      });
    }

    if (ativityLogEntry.actionType) {
      keyValueStore.push({
        key: this.$t("timeLine.BaseActivityTimeLineItem.table.type"),
        value: ativityLogEntry.actionType
      });
    }

    if (ativityLogEntry.comment) {
      keyValueStore.push({
        key: this.$t("timeLine.BaseActivityTimeLineItem.table.comment"),
        value: ativityLogEntry.comment
      });
    }

    if (ativityLogEntry.target?.length) {
      keyValueStore.push({
        key: this.$t("timeLine.BaseActivityTimeLineItem.table.target"),
        value: JSON.stringify(ativityLogEntry.target)
      });
    }

    return keyValueStore;
  }

  get date() {
    if (this.activityLogEntry.timestamp?.created) {
      const locale = this.$t("utility.toLocalDateString").toString() || "de-de";
      return detailedDate(this.activityLogEntry.timestamp?.created, locale);
    }
    return this.$t("sign.DocumentDetail.noDate");
  }
}
