
























import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import ReportStepMixin from "@/mixins/ReportStepMixin.vue";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { ReportModule } from "@/store/modules/report.store";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    LayoutReportStep
  }
})
export default class ReportDatePicker extends ReportStepMixin implements IReportStep {
  header: IReportHeaderData = {
    title: "report.datePicker.title",
    description: "report.datePicker.description"
  };

  screen = ReportScreenEnum.daterequest;

  get isDone() {
    return true;
  }

  get date() {
    return ReportModule.inCreationReport.datePreference || new Date().toISOString().substr(0, 10);
  }

  get color() {
    return getDefaultPartnerColor();
  }

  updateDate(date: string) {
    ReportModule.setDatePreference(date);
  }

  disablePastDates(val: string) {
    return val >= new Date().toISOString().substr(0, 10);
  }

  /**
   * Checks if partner setting for a day can be interpreted as open
   *
   * @param weekDaySetting
   */
  isOpenOnDate(weekDaySetting?: string) {
    if (!weekDaySetting) {
      return false;
    }

    const closedValues = ["-", "closed", "geschlossen", "zu"];
    if (closedValues.includes(weekDaySetting.trim().toLowerCase())) {
      return false;
    }

    return Boolean(weekDaySetting);
  }

  /**
   * Checks opening days in partner configuration and returns them as an array of booleans where sunday comes first and saturday comes last
   */
  get partnerClosedDates() {
    const openingHours = ReportModule.partner?.settings?.openingHours;

    if (!openingHours) return [];

    return [
      this.isOpenOnDate(openingHours.sunday),
      this.isOpenOnDate(openingHours.monday),
      this.isOpenOnDate(openingHours.tuesday),
      this.isOpenOnDate(openingHours.wednesday),
      this.isOpenOnDate(openingHours.thursday),
      this.isOpenOnDate(openingHours.friday),
      this.isOpenOnDate(openingHours.saturday)
    ];
  }

  getAllowedDates(val: string) {
    if (!this.disablePastDates(val)) {
      return false;
    }

    return this.partnerClosedDates[new Date(val).getDay()];
  }
}
