var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hover = _ref.hover;
        return [_c('v-list-item', _vm._g({
          style: _vm.isClosed ? 'opacity: 0.6;' : ''
        }, _vm.attachEventListener), [_vm._t("icon"), _vm.hasActionListener() ? _c('v-list-item-action', [_c('tooltip', {
          attrs: {
            "text": _vm.$t('project.ticketState.' + _vm.item.state)
          }
        }, [_c('v-avatar', {
          staticClass: "mt-n1 white--text",
          attrs: {
            "color": _vm.ticketColor,
            "elevation": 0,
            "size": "8"
          }
        })], 1)], 1) : _vm._e(), _c('v-list-item-content', [_c('v-list-item-subtitle', {
          staticClass: "text-xs"
        }, [!_vm.hasActionListener() ? _c('tooltip', {
          attrs: {
            "text": _vm.$t('project.ticketState.' + _vm.item.state)
          }
        }, [_c('v-avatar', {
          staticClass: "mr-1 white--text",
          attrs: {
            "color": _vm.ticketColor,
            "elevation": 0,
            "size": "8"
          }
        })], 1) : _vm._e(), !_vm.item.due ? _c('span') : _vm.isOverdue ? _c('span', {
          staticClass: "red--text"
        }, [_vm._v(" " + _vm._s(_vm.$t("overdueSince", {
          date: _vm.detailedDateWithDay(_vm.item.due)
        })) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.$t("project.ticket.dueDate", {
          date: _vm.detailedDateWithDay(_vm.item.due)
        })) + " ")])], 1), _c('v-list-item-title', {
          staticClass: "text-truncate ",
          class: _vm.isClosed ? 'text-decoration-line-through' : ''
        }, [_vm.hasOpenDetailListener() ? _c('a', {
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.emitOpenDetail.apply(null, arguments);
            }
          }
        }, [_vm._v(" #" + _vm._s(_vm.item.number) + " " + _vm._s(_vm.item.title) + " ")]) : _c('span', [_vm._v(" #" + _vm._s(_vm.item.number) + " " + _vm._s(_vm.item.title) + " ")])]), !_vm.small ? _c('v-list-item-subtitle', {
          staticClass: "text-xs text-truncate"
        }, [_vm.project ? [_c('span', {
          staticClass: "mr-1",
          attrs: {
            "x-small": "",
            "outlined": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("project.ticket.project")) + ": " + _vm._s(_vm.project.title) + " ")])] : _vm._e(), _vm._l(_vm.visibleCustomFieldValues, function (value) {
          return [_c('span', {
            key: 'custom-field' + value.id,
            staticClass: "mr-1"
          }, [_vm._v(" " + _vm._s(_vm.getCustomFieldLabel(value)) + ": " + _vm._s(_vm.transformCustomFieldValue(value)) + " ")])];
        })], 2) : _vm._e()], 1), _vm.showAssignees && _vm.item.assignees && _vm.item.assignees.length > 0 ? _c('v-list-item-action', [_c('v-list-item-action-text', [_c('ticket-detail-card-assignees', {
          attrs: {
            "assignees": _vm.item.assignees
          }
        })], 1), _c('v-spacer')], 1) : _vm._e(), _vm.confirmable ? _c('v-list-item-action', {
          staticClass: "mx-0 px-0"
        }, [_vm.isConfirmable ? _c('tooltip', {
          attrs: {
            "text": _vm.$t('common.BackendResourceEnum.click.tooltip.ticket')
          }
        }, [_c('v-flex', [hover || _vm.confirmablePersistant ? _c('v-btn', {
          attrs: {
            "text": "",
            "color": "success",
            "elevation": "0",
            "small": "",
            "loading": _vm.loadingConfirm
          },
          on: {
            "click": _vm.confirm
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("common.BackendResourceEnum.click.ticket")) + " "), _c('v-icon', {
          staticClass: "pl-1",
          attrs: {
            "small": ""
          }
        }, [_vm._v(" mdi-check ")])], 1) : _vm._e()], 1)], 1) : _vm._e()], 1) : _vm._e()], 2)];
      }
    }], null, true)
  });
}
var staticRenderFns = []

export { render, staticRenderFns }