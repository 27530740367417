












import LayoutSimple from "@/layouts/LayoutSimple.vue";
import { LocalHostToPartnerMapper } from "@/lib/utility/local-host-to-partner-mapper";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { ReportModule } from "@/store/modules/report.store";
import { Component, Vue } from "vue-property-decorator";
import ErrorCard from "@/components/utility/ErrorCard.vue";
import { PartnerModule } from "@/store/modules/partner";

@Component({
  components: {
    LayoutSimple,
    ErrorCard
  }
})
export default class ReportErrorPage extends Vue {
  items = [
    {
      text: "Website",
      disabled: false,
      href: "https://www.mmmint.ai"
    },
    {
      text: "Impressum",
      disabled: false,
      href: "https://www.mmmint.ai/impressum/"
    },
    {
      text: "AGB",
      disabled: false,
      href: PartnerModule.partner.settings?.agbUrl?.link || "https://www.mmmint.ai/agbs/"
    }
  ];

  loading = false;

  async reload() {
    try {
      this.loading = true;
      await LocalHostToPartnerMapper.tryGetPartnerByHost(window.location.hostname);
      this.$router.push({ path: "/" + ReportScreenEnum.thghome });
    } catch (error) {
      this.$log.error(error);
      this.$toast("Fehler beim Laden der Anwendung.");
      this.$router.push({ name: "PartnerContactError" });
    } finally {
      this.loading = false;
      ReportModule.setLoading(false);
    }
  }
}
