


































































































import FileUploadMulti from "@/components/files/FileUploadMulti.vue";
import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { AssetEnum } from "@/lib/AssetRepository";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportInstructionData } from "@/lib/interfaces/Report/IReportInstructionData";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import ReportStepMixin from "@/mixins/ReportStepMixin.vue";
import { IImageUploaded } from "@/models/Image/IImageUploaded";
import { ReportImageType } from "@/models/Report/ReportImageType";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { ReportModule } from "@/store/modules/report.store";
import { Component } from "vue-property-decorator";
import ReportImageUpload from "./ReportImageUpload.vue";

@Component({
  components: {
    LayoutReportStep,
    FileUploadMulti,
    ReportImageUpload
  }
})
export default class ReportImageOverviewAlt extends ReportStepMixin implements IReportStep {
  header: IReportHeaderData = this.configuration.header;
  instruction: IReportInstructionData = this.configuration.instruction;

  asset = AssetEnum.tuev;

  screen = ReportScreenEnum.overviewimagealt;

  get cockpit() {
    return ReportImageType.cockpit;
  }

  get others() {
    return ReportImageType.others;
  }

  get tuev() {
    return ReportImageType.tuev;
  }

  get multiSteps() {
    return {
      frontLeft: {
        example: this.configuration.exampleImages.frontLeft.example,
        overlay: this.configuration.exampleImages.frontLeft.overlay,
        text: "report.imageOverviewAlt.leftText",
        textAlt: "report.imageOverviewAlt.leftTextAlt",
        filesCb: ReportModule.overviewsFrontLeft,
        handleUpload: ReportModule.addOverviewFrontLeft,
        handleDelete: ReportModule.removeOverviewFrontLeft
      },
      rearLeft: {
        example: this.configuration.exampleImages.rearLeft.example,
        overlay: this.configuration.exampleImages.rearLeft.overlay,
        text: "report.imageOverviewAlt.rearText",
        textAlt: "report.imageOverviewAlt.rearTextAlt",
        filesCb: ReportModule.overviewsRearLeft,
        handleUpload: ReportModule.addOverviewRearLeft,
        handleDelete: ReportModule.removeOverviewRearLeft
      },
      rearRight: {
        example: this.configuration.exampleImages.rearRight.example,
        overlay: this.configuration.exampleImages.rearRight.overlay,
        text: "report.imageOverviewAlt.rightText",
        textAlt: "report.imageOverviewAlt.rightTextAlt",
        filesCb: ReportModule.overviewsRearRight,
        handleUpload: ReportModule.addOverviewRearRight,
        handleDelete: ReportModule.removeOverviewRearRight
      },
      frontRight: {
        example: this.configuration.exampleImages.frontRight.example,
        overlay: this.configuration.exampleImages.frontRight.overlay,
        text: "report.imageOverviewAlt.frontText",
        textAlt: "report.imageOverviewAlt.frontTextAlt",
        filesCb: ReportModule.overviewsFrontRight,
        handleUpload: ReportModule.addOverviewFrontRight,
        handleDelete: ReportModule.removeOverviewFrontRight
      },
      top: {
        example: AssetEnum.motorbikeTop,
        overlay: AssetEnum.motorbikeTopOutline,
        text: "report.imageOverviewAlt.topText",
        textAlt: "report.imageOverviewAlt.topTexteAlt",
        filesCb: ReportModule.cockpits,
        handleUpload: ReportModule.addCockpit,
        handleDelete: ReportModule.removeCockpit
      },
      numberPlate: {
        example: AssetEnum.numberplateMotorbike,
        overlay: AssetEnum.numberplateMotorbike,
        text: "report.imageOverviewAlt.numberplateText",
        textAlt: "report.imageOverviewAlt.numberplateTextAlt"
      },
      cockpit: {
        example: AssetEnum.cockpit,
        overlay: AssetEnum.cockpitOutline,
        text: "report.imageOverviewAlt.cockpitText",
        textAlt: "report.imageOverviewAlt.cockpitTextAlt",
        filesCb: ReportModule.cockpits,
        handleUpload: ReportModule.addCockpit,
        handleDelete: ReportModule.removeCockpit
      }
    };
  }
  get configuration() {
    return {
      exampleImages: {
        frontLeft: {
          example: AssetEnum.motorbikeLeft,
          overlay: AssetEnum.motorbikeLeftOutline
        },
        rearLeft: {
          example: AssetEnum.motorbikeRear,
          overlay: AssetEnum.motorbikeRearOutline
        },
        rearRight: {
          example: AssetEnum.motorbikeRight,
          overlay: AssetEnum.motorbikeRightOutline
        },
        frontRight: {
          example: AssetEnum.motorbikeFront,
          overlay: AssetEnum.motorbikeFrontOutline
        }
      },
      header: {
        title: "report.imageOverviewAlt.title",
        description: "report.imageOverviewAlt.description",
        image: AssetEnum.motorbikeFrontRight,
        debug: false
      },

      instruction: {
        title: "report.imageOverviewAlt.title",
        text: "report.imageOverviewAlt.instructionText",
        pictures: [AssetEnum.motorbikeFrontRight],
        example: AssetEnum.motorbikeFrontRight,
        display: true
      }
    };
  }

  get getOverviewFrontLeft() {
    return ReportModule.overviewsFrontLeft;
  }

  get getOverviewFrontRight() {
    return ReportModule.overviewsFrontRight;
  }

  get getOverviewRearLeft() {
    return ReportModule.overviewsRearLeft;
  }

  get getOverviewRearRight() {
    return ReportModule.overviewsRearRight;
  }

  get getCockpit(): IImageUploaded[] {
    return ReportModule.cockpits;
  }

  get getOthers(): IImageUploaded[] {
    return ReportModule.overviews.filter(image => image.type === this.others);
  }

  /**
   * @inheritdoc
   */
  get isDone(): boolean {
    if (this.getOverviewFrontLeft.length <= 0) {
      return false;
    }

    if (this.getOverviewFrontRight.length <= 0) {
      return false;
    }

    if (this.getOverviewRearLeft.length <= 0) {
      return false;
    }

    if (this.getOverviewRearRight.length <= 0) {
      return false;
    }

    if (this.getOthers.length <= 0) {
      return false;
    }

    if (this.getCockpit.length <= 0) {
      return false;
    }

    return true;
  }
}
