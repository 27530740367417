



























import NumberplateForm from "@/components/utility/NumberplateForm.vue";
import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import { numberplateRule } from "@/lib/rules/numberplateRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import ReportStepMixin from "@/mixins/ReportStepMixin.vue";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { ReportType } from "@/store/enum/reportType";
import { ReportModule } from "@/store/modules/report.store";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    LayoutReportStep,
    NumberplateForm
  }
})
export default class ReportAdditonalInformationHueffermann extends ReportStepMixin implements IReportStep {
  get header(): IReportHeaderData {
    return {
      title: this.$t("report.externalidhueffermann.title").toString(),
      description: this.$t("report.externalidhueffermann.description").toString()
    };
  }
  screen = ReportScreenEnum.externalidhueffermann;

  get isDone() {
    return this.valid && (!this.isNumberplate || this.validNumberplate);
  }

  valid = false;

  validNumberplate = false;

  setValidNumberplate(value: boolean) {
    this.validNumberplate = value;
  }

  mounted() {
    if (!this.isNumberplate) {
      this.numberplate = "";
    }
  }

  get isNumberplate(): boolean {
    return [
      ReportType.CAR,
      ReportType.CRANE,
      ReportType.SEMITRAILER,
      ReportType.TRAILER,
      ReportType.TRUCK,
      ReportType.MOTORBIKE,
      ReportType.TRUCK_TRAILER,
      ReportType.TRACTOR_UNIT
    ].includes(ReportModule.reportType);
  }

  get numberplate(): string {
    return ReportModule.numberplate;
  }

  set numberplate(numberplate: string) {
    ReportModule.setNumberplate(numberplate.toUpperCase());
  }

  get externalId(): string {
    return ReportModule.externalId;
  }

  set externalId(id: string) {
    ReportModule.setExternalId(id);
  }

  get numberPlateRules() {
    const rules = [];

    rules.push(requiredRule());
    rules.push(numberplateRule());

    return rules;
  }
}
