


















































import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import { requiredRule } from "@/lib/rules/requiredRule";
import ReportStepMixin from "@/mixins/ReportStepMixin.vue";
import { MrfiktivLeasingDetailsGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { ReportModule } from "@/store/modules/report.store";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    LayoutReportStep
  }
})
export default class ReportLeasing extends ReportStepMixin implements IReportStep {
  ARVAL = "Arval";
  LEASPLAN = "Leaseplan";
  OTHER = "Anderer";

  LEASING_OPTIONS = [this.ARVAL, this.LEASPLAN, this.OTHER];

  get isLeasing() {
    return this.leasingDetails.isLeasing;
  }

  set isLeasing(isLeasing: boolean) {
    if (!isLeasing) {
      this.leasingDetails.company = "";
    }
    this.leasingDetails.isLeasing = isLeasing;
  }

  get header(): IReportHeaderData {
    return {
      title: "report.leasingDetails.title",
      description: "report.leasingDetails.description"
    };
  }
  screen = ReportScreenEnum.leasing;

  valid = false;

  get isDone() {
    if (this.leasingDetails.isLeasing && !this.leasingDetails.company) {
      return false;
    }
    if (!this.valid) {
      return false;
    }
    return true;
  }

  claimNumberRequired = [this.ARVAL, this.LEASPLAN];

  get claimNumberIsRequired() {
    return this.claimNumberRequired.includes(this.leasingDetails.company || "");
  }

  get requiredRule() {
    const rules = [];

    rules.push(requiredRule());

    return rules;
  }

  get body() {
    return ReportModule.body;
  }

  get leasingDetails(): MrfiktivLeasingDetailsGen {
    return ReportModule.leasing as MrfiktivLeasingDetailsGen;
  }

  set leasingDetails(leasing: MrfiktivLeasingDetailsGen) {
    ReportModule.setLeasing(leasing);
  }

  created() {
    if (!this.leasingDetails) {
      ReportModule.setLeasing({ isLeasing: true, company: "", claimNumber: "" });
    }
  }
}
