



















import { detailedDateWithDay, formatHoursAndMinutes, simpleDate } from "@/lib/utility/date-helper";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { Component, Prop, Vue } from "vue-property-decorator";
import { IRefDetail } from "./RefsList.vue";
import RefsVehicle from "./RefsVehicle.vue";
import { ISnapshot } from "@/models/snapshot.entity";
import { DigitalSignatureRequestStatusEnum } from "@/lib/enum/digital-signature-request-status.enum";

@Component({
  components: { RefsVehicle },
  filters: {
    simpleDate,
    detailedDateWithDay,
    formatHoursAndMinutes
  }
})
export default class RefsSnapshot extends Vue implements IRefDetail<ISnapshot> {
  readonly DigitalSignatureRequestStatusEnum = DigitalSignatureRequestStatusEnum;

  @Prop()
  item!: ISnapshot;

  @Prop({ default: false })
  small!: boolean;

  @Prop({ default: false })
  confirmable!: boolean;

  hasOpenDetailListener() {
    return Boolean(this.$listeners && this.$listeners.openDetail);
  }

  emitOpenDetail() {
    this.$emit("openDetail", this.item);
  }

  isLoading = false;

  images: {
    src: string;
    thumbnail: string;
    w: number;
    h: number;
    title: string;
  }[] = [];

  goToDetail() {
    new GoToHelper(this.$router).goToSnapshotDetailView(this.item.partnerId, this.item.id, this.item.id);
  }
}
