import { MrfiktivHttpClientProvider } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import { MrfiktivBasePdfViewmodelGen, MrfiktivCreatePdfDtoGen } from "../v1/data-contracts";
import { PartnerPdf } from "../v1/PartnerPdf";

/**
 * Communicate with pdf API
 */
class PartnerPdfService {
  mrfiktivProxy: PartnerPdf;

  /**
   * Construct service
   */
  constructor(mrfiktivHttpClientProvider: MrfiktivHttpClientProvider) {
    this.mrfiktivProxy = new PartnerPdf(mrfiktivHttpClientProvider.client());
  }

  /**
   * Create an pdf
   */
  async create(partnerId: string, data: MrfiktivCreatePdfDtoGen): Promise<MrfiktivBasePdfViewmodelGen> {
    const pdf = (await this.mrfiktivProxy.partnerPdfControllerCreate(partnerId, data)).data;

    return pdf;
  }

  /**
   * Get all pdf
   */
  async findOne(partnerId: string, id: string): Promise<MrfiktivBasePdfViewmodelGen> {
    const pdf = (await this.mrfiktivProxy.partnerPdfControllerFindOne(partnerId, id)).data;

    return pdf;
  }
}

/**
 * Communicate with pdf API
 */
export default new PartnerPdfService(new MrfiktivHttpClientProvider());
