/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivCostControllerGetParamsGen,
  MrfiktivCostViewModelGen,
  MrfiktivCreateCostDtoGen,
  MrfiktivExceptionViewmodelGen,
  MrfiktivPageViewModelGen,
  MrfiktivUpdateCostDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Cost<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags cost
   * @name CostControllerCreate
   * @summary Creates a cost for a given partner
   * @request POST:/partner/{partnerId}/cost
   * @secure
   * @response `201` `MrfiktivCostViewModelGen` Cost created
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not Found
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  costControllerCreate = (partnerId: string, data: MrfiktivCreateCostDtoGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivCostViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/cost`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags cost
   * @name CostControllerGet
   * @summary Gets all costs from a given partner
   * @request GET:/partner/{partnerId}/cost
   * @secure
   * @response `200` `(MrfiktivPageViewModelGen & { data?: (MrfiktivCostViewModelGen)[] })`
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not Found
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  costControllerGet = ({ partnerId, ...query }: MrfiktivCostControllerGetParamsGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivPageViewModelGen & { data?: MrfiktivCostViewModelGen[] }, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/cost`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags cost
   * @name CostControllerUpdate
   * @summary Update cost for a given partner
   * @request PUT:/partner/{partnerId}/cost/{id}
   * @secure
   * @response `200` `MrfiktivCostViewModelGen` Updated cost document
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not Found
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  costControllerUpdate = (partnerId: string, id: string, data: MrfiktivUpdateCostDtoGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivCostViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/cost/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags cost
   * @name CostControllerGetOne
   * @summary Gets a particular cost from a given partner
   * @request GET:/partner/{partnerId}/cost/{id}
   * @secure
   * @response `200` `MrfiktivCostViewModelGen` Cost returned
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not Found
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  costControllerGetOne = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivCostViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/cost/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags cost
   * @name CostControllerRemove
   * @summary Deletes a cost for a given partner
   * @request DELETE:/partner/{partnerId}/cost/{id}
   * @secure
   * @response `200` `MrfiktivCostViewModelGen` Cost deleted
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not Found
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  costControllerRemove = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivCostViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/cost/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
}
