






























































































































import PartnerDetailAttributeWithToolTip from "@/components/partner/PartnerDetailAttributeWithToolTip.vue";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { ActivityLogModule, ActivityTypeEnum } from "@/store/modules/activity-log.store";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ActivityTimeLineItemComment from "./ActivityTimeLineItemComment.vue";
import ActivityTimeLineItemMessage from "./ActivityTimeLineItemMessage.vue";
import ActivityTimeLineItemReportStatusUpdate from "./ActivityTimeLineItemReportStatusUpdate.vue";
import ActivityTimeLineItemSignRequest from "./ActivityTimeLineItemSignRequest.vue";
import ActivityTimeLineItemTicket from "./ActivityTimeLineItemTicket.vue";
import BaseActivityTimeLineItem from "./BaseActivityTimeLineItem.vue";
import ActivityTimeLineItemShareLink from "./ActivityTimeLineItemShareLink.vue";
import ActivityTimeLineItemAttachmentResponse from "./ActivityTimeLineItemAttachmentResponse.vue";
import ActivityTimeLineItemReportUpdate from "./ActivityTimeLineItemReportUpdate.vue";
import ActivityTimeLineItemDocument from "./ActivityTimeLineItemDocument.vue";
import FeatureMixin from "@/mixins/FeatureMixin.vue";
import ActivityTimeLineItemCreated from "./ActivityTimeLineItemCreated.vue";
import ActivityTimeLineItemCreateCost from "./ActivityTimeLineItemCreateCost.vue";
import ActivityTimeLineItemProgressStatusMessage from "./ActivityTimeLineItemProgressStatusMessage.vue";
import { MrfiktivActivityLogViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ThgActivityLogViewModelGen } from "@/services/thg/v1/data-contracts";
import ActivityTimeLineItemAssignees from "./ActivityTimeLineItemAssignees.vue";
import ActivityTimeLineItemImage from "./ActivityTimeLineItemImage.vue";
import ActivityTimeLineItemEventSeries from "./ActivityTimeLineItemEventSeries.vue";
import ActivityTimeLineItemReportSummaryCreated from "./ActivityTimeLineReportSummaryCreated.vue";

@Component({
  components: {
    PartnerDetailAttributeWithToolTip,
    BaseActivityTimeLineItem,
    ActivityTimeLineItemSignRequest,
    ActivityTimeLineItemComment,
    ActivityTimeLineItemReportStatusUpdate,
    ActivityTimeLineItemMessage,
    ActivityTimeLineItemProgressStatusMessage,
    ActivityTimeLineItemShareLink,
    ActivityTimeLineItemAttachmentResponse,
    ActivityTimeLineItemReportUpdate,
    ActivityTimeLineItemDocument,
    ActivityTimeLineItemCreated,
    ActivityTimeLineItemCreateCost,
    ActivityTimeLineItemTicket,
    ActivityTimeLineItemImage,
    ActivityTimeLineItemEventSeries,
    ActivityTimeLineItemAssignees,
    ActivityTimeLineItemReportSummaryCreated
  }
})
export default class ActivityTimeLine extends mixins(DarkModeHighlightMixin, PermissionMixin, FeatureMixin) {
  @Prop()
  activities?: (MrfiktivActivityLogViewModelGen | ThgActivityLogViewModelGen)[];

  get activityLog() {
    if (this.activities) {
      return this.activities;
    }
    return ActivityLogModule.paginationList;
  }

  get ActivityTypeEnum() {
    return ActivityTypeEnum;
  }
}
