











































import { Component, Vue, Prop } from "vue-property-decorator";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { PartnerModule } from "@/store/modules/partner";
import { RatingEntity } from "@/models/ratingEntitity";

@Component({
  components: {}
})
export default class FeedbackContainer extends Vue {
  @Prop({ default: "components.feedback.placeholder" })
  feedBackPlaceholder!: string;

  @Prop({ default: 0 })
  rating!: number;

  @Prop({})
  keyForEndpoint!: string;

  message = "";
  isLoading = false;

  get partner() {
    return PartnerModule.partner;
  }

  get color() {
    return getDefaultPartnerColor();
  }

  get isDone() {
    return this.rating > 0 && !this.disabled;
  }

  get disabled() {
    return !PartnerModule.isRatingSubmissionEnabled;
  }

  async sendFeedback() {
    this.isLoading = true;

    const rating: RatingEntity = {
      key: this.keyForEndpoint,
      rating: this.rating,
      message: this.message
    };

    try {
      await PartnerModule.addRatingForPartner(rating);
      this.$emit("sent");
    } catch (error) {
      Vue.$log.error(error);
    } finally {
      this.isLoading = false;
    }
  }
}
