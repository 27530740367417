







































import { CostGoToHelper } from "@/lib/utility/cost.go-to-helper";
import { ICost } from "@/models/cost.entity";
import { mixins } from "vue-class-component";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IRefDetail } from "./RefsList.vue";
import { CostGroupModule } from "@/store/modules/cost-group.store";
import { ICostGroup, CostGroup } from "@/models/cost-group.entity";
import CostChip from "../cost/CostChip.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";

@Component({
  components: { CostChip },
  filters: {}
})
export default class RefsCost extends mixins(Vue) implements IRefDetail<ICost> {
  @Prop()
  item!: ICost;

  @Prop()
  small!: boolean;

  @Prop({ default: false })
  hideCost!: boolean;

  @Prop({ default: false })
  hideDate!: boolean;

  @Prop()
  confirmable!: boolean;

  group: ICostGroup | null = null;

  loadingProject = false;

  get date() {
    return simpleDoubleDigitDate(this.item.date);
  }

  hasClickListener() {
    return Boolean(this.$listeners && this.$listeners.click);
  }

  hasOpenDetailListener() {
    return Boolean(this.$listeners && this.$listeners.openDetail);
  }

  emitOpenDetail() {
    this.$emit("openDetail", this.item);
  }

  async mounted() {
    await this.setGroup();
  }

  async goToDetail() {
    const partnerId = this.item.partnerId;
    const costId = this.item.id;
    const newTab = true;

    new CostGoToHelper(this.$router).goToCostDetail({ costId, partnerId, newTab });
  }

  @Watch("item.group")
  async setGroup() {
    try {
      if (this.item.group) {
        this.loadingProject = true;
        let group = CostGroupModule.maps.id.get(this.item.group)[0];
        if (!group) {
          group = await new CostGroup({ partnerId: this.item.partnerId, id: this.item.group }).fetch();
        }
        this.group = group;
        this.loadingProject = false;
      }
    } catch (e) {
      this.$log.error(e);
    }
  }

  goToCostGroupCustomView() {
    if (!this.item.group) return;

    new CostGoToHelper(this.$router).goToCostGroupCustomView({
      partnerId: this.item.partnerId,
      costGroupId: this.item.group,
      viewId: "0",
      newTab: true
    });
  }
}
