var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('Can', {
    attrs: {
      "I": _vm.ActionEnum.UPDATE,
      "a": _vm.ResourceEnum.ACTIVITY
    }
  }, [_vm.text ? _c('v-list-item', {
    on: {
      "click": _vm.startDialog
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.partner.ActivityTimeLineItemUpdate.edit")) + " ")]) : _c('v-btn', {
    staticStyle: {
      "margin-top": "-3px"
    },
    attrs: {
      "dense": "",
      "icon": "",
      "loading": _vm.isLoading,
      "color": "grey lighten-1"
    },
    on: {
      "click": _vm.startDialog
    }
  }, [_c('v-icon', [_vm._v(" mdi-pen ")])], 1), _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isEditDialogActive,
      "title": _vm.$t('components.partner.ActivityTimeLineItemUpdate.title'),
      "rightText": _vm.$t('components.partner.ActivityTimeLineItemUpdate.update'),
      "leftDisabled": _vm.isLoading,
      "rightDisabled": _vm.isLoading,
      "leftLoading": _vm.isLoading,
      "rightLoading": _vm.isLoading,
      "supressKeyboardConfirm": true,
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isEditDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isEditDialogActive = $event;
      },
      "leftClick": function leftClick($event) {
        _vm.isEditDialogActive = false;
      },
      "rightClick": _vm.deleteActivity
    }
  }, [_c('template-editor', {
    ref: "templateEditor",
    attrs: {
      "readOnly": false,
      "partnerId": _vm.activityLogEntry.partnerId,
      "label": _vm.$t('components.template.detail.body.body')
    },
    model: {
      value: _vm.updateData.comment,
      callback: function callback($$v) {
        _vm.$set(_vm.updateData, "comment", $$v);
      },
      expression: "updateData.comment"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }