var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tooltip', {
    attrs: {
      "text": _vm.$t('common.nouns.suggestion')
    }
  }, [_c('v-icon', {
    attrs: {
      "small": _vm.small,
      "large": _vm.large,
      "color": _vm.color || _vm.mintyFreshness
    }
  }, [_vm._v("mdi-creation ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }