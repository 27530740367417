
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { Component } from "vue-property-decorator";
import ReportImageOverviewAlt from "./ReportImageOverviewAlt.vue";

@Component({
  components: {}
})
export default class ReportImageOverviewAuth extends ReportImageOverviewAlt implements IReportStep {
  screen = ReportScreenEnum.overviewimageauth;
}
