






























































































import { IDialog } from "@/lib/interfaces/dialog.interface";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import { $t } from "@/lib/utility/t";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { CreateSharedContentDto, ICreateSharedContentDto } from "@/models/create-shared-content.dto";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import { IReference } from "@/models/reference.entity";
import { ISharedContent } from "@/models/shared-content.entity";
import { Timestamp } from "@/models/timestamp.entity";
import { PartnerModule } from "@/store/modules/partner";
import { SharedContentModule } from "@/store/modules/shared-content.store";
import { mixins } from "vue-class-component";
import { Component, Prop, Vue } from "vue-property-decorator";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import SharedContentTable from "../partner/SharedContentTable.vue";
import TemplateCard from "../template/TemplateCard.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import { SharingLinkFactory } from "@/lib/utility/sharingLinkFactory";

@Component({
  components: {
    TemplateCard,
    ConfirmActionDialog,
    SharedContentTable,
    LatestEntriesCardEmpty
  },
  filters: { simpleDoubleDigitDate }
})
export default class ShareDialog extends mixins(Vue, PermissionMixin) implements IDialog {
  @Prop()
  refs!: IReference;

  @Prop()
  partnerId!: string;

  isShareDialogActive = false;

  isLoadingSharedContents = false;

  isLoadingCreate = false;

  isSendLoading = false;

  isExpiring = false;

  step = 0;

  sharedContent: ISharedContent | null = null;

  createSharedContentDto: ICreateSharedContentDto = new CreateSharedContentDto();

  validBy = "";

  get partner() {
    return PartnerModule.partner;
  }

  get rightLoading() {
    switch (this.step) {
      case 0:
        return false;
      case 1:
        return this.isLoadingCreate;
      case 2:
        return false;
      case 3:
        return this.isSendLoading;
      case 4:
        return false;
      default:
        return false;
    }
  }

  get leftText() {
    switch (this.step) {
      case 0:
        return $t("close");
      case 1:
        return $t("back");
      case 2:
        return $t("close");
      case 3:
        return $t("back");
      case 4:
        return $t("close");
      default:
        return $t(" ");
    }
  }

  get rightText() {
    switch (this.step) {
      case 0:
        return $t("create");
      case 1:
        return $t("share");
      case 2:
        return $t("components.partner.PartnerReportInitializeCard.selection.email.buttonText");
      case 3:
        return $t("components.partner.PartnerReportInitializeCard.selection.email.buttonText");
      case 4:
        return $t("close");
      default:
        return $t(" ");
    }
  }

  get sharings() {
    return SharedContentModule.filteredAndSorted;
  }

  async mounted() {
    await this.loadSharings();
    this.createSharedContentDto = new CreateSharedContentDto();
    this.createSharedContentDto.partnerId = this.partnerId;
    this.createSharedContentDto.refs.push(this.refs);
  }

  async loadSharings() {
    this.isLoadingSharedContents = true;
    SharedContentModule.setFilters([
      new PageFilterElement({
        key: "refs.refId",
        operation: PageFilterOperationEnum.EQUAL,
        value: this.refs.refId
      }),
      new PageFilterElement({
        key: "refs.refType",
        operation: PageFilterOperationEnum.EQUAL,
        value: this.refs.refType
      })
    ]);
    await SharedContentModule.fetchFirstPage({ partnerId: this.partnerId }).catch(handleError);
    this.isLoadingSharedContents = false;
  }

  openShareDialog() {
    this.isShareDialogActive = true;
  }

  open(): void {
    this.step = 0;
    this.isShareDialogActive = true;
  }

  close(): void {
    this.isShareDialogActive = false;
  }

  leftClick() {
    switch (this.step) {
      case 0:
        this.close();
        break;
      case 1:
        this.step = 0;
        break;
      case 2:
        this.close();
        break;
      case 3:
        this.step--;
        break;
      case 4:
        this.close();
        break;
    }
  }

  async rightClick() {
    switch (this.step) {
      case 0:
        this.createSharedContentDto = new CreateSharedContentDto();
        this.createSharedContentDto.partnerId = this.partnerId;
        this.validBy = new Date(new Date().getFullYear(), new Date().getMonth() + 6, new Date().getDay(), 0, 0, 0)
          .toISOString()
          .substring(0, 10);
        this.createSharedContentDto.refs.push(this.refs);
        this.step++;
        break;
      case 1:
        await this.createSharing();
        this.step++;
        break;
      case 2:
        this.step++;
        this.copyUrl();
        this.$nextTick(async () => {
          await (this.$refs.templateCard as TemplateCard).setTemplate({
            content: { body: this.sharedContent?.url ?? "", subject: this.sharedContent?.title ?? "", tokens: [] },
            isAdminTemplate: false,
            isPublic: false,
            key: "",
            meta: {} as any,
            timestamp: new Timestamp()
          });
        });
        break;
      case 3:
        this.isSendLoading = true;
        await (this.$refs.templateCard as TemplateCard).send();
        this.isSendLoading = false;
        break;
      case 4:
        this.close();
        break;
    }
  }

  async createSharing() {
    try {
      this.isLoadingCreate = true;
      if (this.isExpiring) {
        this.createSharedContentDto.validBy = new Date(this.validBy).toISOString();
      }
      const sharedContent = await this.createSharedContentDto.create();
      this.sharedContent = sharedContent ?? null;
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoadingCreate = false;
    }
  }

  copyUrlFromSharing(sharedContent: ISharedContent | null) {
    if (!sharedContent) {
      Vue.$toast.error($t("components.partner.PartnerReportInitializeCard.error"));
      return;
    }

    new SharingLinkFactory(sharedContent, false).copyLinkToClipboard();

    this.$toast.info(this.$t("components.partner.PartnerReportInitializeCard.linkCopied"));
  }

  copyUrl() {
    this.copyUrlFromSharing(this.sharedContent);
  }
}
