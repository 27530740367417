var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "flat": "",
      "color": _vm.backgroundColor
    },
    on: {
      "click": _vm.openLink
    }
  }, [_c('v-img', {
    staticClass: "white--text align-end",
    attrs: {
      "src": _vm.imgSrc,
      "height": "200px",
      "gradient": _vm.gradient
    }
  }, [_c('v-card-subtitle', {
    staticClass: "text-h5 text-wrap word-break-advertisment-title",
    domProps: {
      "innerHTML": _vm._s(_vm.subtitle)
    }
  })], 1), _c('v-card-title', {
    staticClass: "text-h4 word-break-advertisment-title"
  }, [_vm._v(_vm._s(_vm.title))]), _c('v-img', {
    staticClass: "mx-4",
    attrs: {
      "src": _vm.logoSrc,
      "height": "100px",
      "contain": ""
    }
  }), _c('v-card-text', [_vm._v(_vm._s(_vm.content))]), _c('v-card-actions', {
    staticClass: "mx-2"
  }, [_c('v-btn', {
    staticClass: "mb-2",
    attrs: {
      "block": "",
      "large": "",
      "color": _vm.buttonColor
    },
    on: {
      "click": _vm.openLink
    }
  }, [_vm._v(_vm._s(_vm.btnText))])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }