import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { ISignRequest, SignRequest } from "@/models/sign-request.entity";
import signRequestService from "@/services/sign/services/sign-request.service";
import store from "@/store/VuexPlugin";
import { getModule, Module } from "vuex-module-decorators";
import {
  SignDocumentControllerFindAllParamsGen,
  SignSignRequestControllerFindAllParamsGen
} from "../../services/sign/v1/data-contracts";
import { PaginatedBaseStore } from "../paginated-base.store";
import { SignRequestDataAccessLayer } from "./access-layers/sign-request.access-layer";

const SignRequestPageDataProvider = new (class extends AbstractPageDataProvider<
  ISignRequest,
  SignDocumentControllerFindAllParamsGen
> {
  itemsPerPage = 25;

  async getPage(query: SignSignRequestControllerFindAllParamsGen): Promise<IPageViewModel<ISignRequest>> {
    const res = await signRequestService.getAllForPartner(query);

    const meta = res.meta;
    const data = (res.data ?? []) as ISignRequest[];

    return { meta, data };
  }
})();

@Module({
  dynamic: true,
  namespaced: true,
  name: "sign-request",
  store
})
export class SignRequestStore extends PaginatedBaseStore<ISignRequest, SignSignRequestControllerFindAllParamsGen> {
  protected _data = SignRequestDataAccessLayer;
  protected _pageProvider = SignRequestPageDataProvider;
  protected _pager = new PageDataHandler(this._data, this._pageProvider);
  filterOptions = SignRequest.filterables;

  get requests() {
    return this.filteredAndSorted;
  }
}

export const SignRequestModule = getModule(SignRequestStore);
