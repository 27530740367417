var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', {
    staticClass: "pb-2",
    attrs: {
      "flat": "",
      "elevation": 0
    }
  }, [_vm.title ? _c('v-card-title', [_vm._v(" " + _vm._s(_vm.title)), _c('v-spacer'), _vm.panels.length ? _c('v-btn', {
    attrs: {
      "text": "",
      "small": "",
      "color": "info"
    },
    on: {
      "click": function click($event) {
        _vm.panels = [];
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("designGuide.MDetailForm.collapse")) + " ")]) : _vm.fields.length ? _c('v-btn', {
    attrs: {
      "text": "",
      "small": "",
      "color": "info"
    },
    on: {
      "click": _vm.openAllPanels
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("designGuide.MDetailForm.expand")) + " ")]) : _vm._e()], 1) : _vm._e(), _vm.description ? _c('v-card-text', [_vm._v(_vm._s(_vm.description))]) : _vm._e(), _c('div', {
    staticClass: "mx-4"
  }, [_vm._t("description")], 2), _c('v-text-field', {
    staticClass: "mx-4",
    attrs: {
      "outlined": "",
      "dense": "",
      "placeholder": _vm.$t('designGuide.MDetailForm.search'),
      "prepend-inner-icon": "mdi-magnify"
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _c('v-expansion-panels', {
    staticClass: "mb-10",
    attrs: {
      "multiple": "",
      "flat": ""
    },
    model: {
      value: _vm.panels,
      callback: function callback($$v) {
        _vm.panels = $$v;
      },
      expression: "panels"
    }
  }, [_vm._l(_vm.fields, function (cluster, index) {
    return _c('v-expansion-panel', {
      key: index
    }, [_c('v-expansion-panel-header', {
      staticClass: "custom-expansion-panel",
      scopedSlots: _vm._u([{
        key: "actions",
        fn: function fn() {
          return [_c('v-icon', {
            staticClass: "mr-4",
            attrs: {
              "x-large": "",
              "color": "info"
            }
          }, [_vm._v(" mdi-chevron-down ")])];
        },
        proxy: true
      }], null, true)
    }, [_c('span', {
      staticClass: "title ml-4"
    }, [_vm._v(" " + _vm._s(cluster.category) + " "), _c('span', {
      staticClass: "text-caption"
    }, [_vm.search ? _c('span', [_vm._v(_vm._s(cluster.items.length) + " " + _vm._s(_vm.$t("designGuide.MDetailForm.from")) + " ")]) : _vm._e(), _vm._v(" " + _vm._s(_vm.countObjectsForCategory(_vm.config, cluster.category)) + " " + _vm._s(_vm.$t("designGuide.MDetailForm.props")) + " ")])])]), _c('div', {
      staticClass: "ml-n2 mr-n2"
    }, [_c('v-expansion-panel-content', {
      staticClass: "pr-0 pl-0"
    }, _vm._l(cluster.items, function (inputFieldConfig) {
      var _cluster$items$find;

      return _c('div', {
        key: inputFieldConfig.key + inputFieldConfig.condition ? (_cluster$items$find = cluster.items.find(function (field) {
          var _inputFieldConfig$con;

          return field.key === ((_inputFieldConfig$con = inputFieldConfig.condition) === null || _inputFieldConfig$con === void 0 ? void 0 : _inputFieldConfig$con.key);
        })) === null || _cluster$items$find === void 0 ? void 0 : _cluster$items$find.model : ''
      }, [_c('div', {
        key: inputFieldConfig.condition ? _vm.inputKey : undefined
      }, [_vm.getIsConditionSatisfied(inputFieldConfig.condition) ? _c(inputFieldConfig.type, _vm._b({
        tag: "component",
        attrs: {
          "outlined": "",
          "disabled": _vm.disabled,
          "readonly": _vm.readonly
        },
        on: {
          "input": _vm.onInput
        },
        model: {
          value: inputFieldConfig.model,
          callback: function callback($$v) {
            _vm.$set(inputFieldConfig, "model", $$v);
          },
          expression: "inputFieldConfig.model"
        }
      }, 'component', inputFieldConfig.props, false)) : _vm._e()], 1)]);
    }), 0)], 1)], 1);
  }), _vm._t("extraPanels")], 2), _vm.containButtons ? _c('v-slide-x-reverse-transition', [_vm.changesDetected ? _c('v-card-actions', {
    staticClass: "px-4"
  }, [_vm._t("left"), _c('v-btn', {
    staticStyle: {
      "width": "30%"
    },
    attrs: {
      "elevation": 0,
      "x-large": "",
      "data-test-delete-file": ""
    },
    on: {
      "click": _vm.abort
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]), _c('v-spacer'), _c('v-btn', {
    staticStyle: {
      "width": "60%"
    },
    attrs: {
      "disabled": !_vm.isValid,
      "color": "success",
      "elevation": 0,
      "x-large": ""
    },
    on: {
      "click": _vm.sync
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")])], 2) : _vm._e()], 1) : _vm._e()], 1), !_vm.containButtons ? _c('v-slide-x-reverse-transition', [_vm.changesDetected ? _c('v-card', {
    staticClass: "pa-4 changes-detected-card"
  }, [_c('v-btn', {
    staticClass: "mr-2",
    attrs: {
      "elevation": "0",
      "color": "info",
      "disabled": !_vm.isValid
    },
    on: {
      "click": _vm.sync
    }
  }, [_vm._v(_vm._s(_vm.$t("save")))]), _c('v-btn', {
    staticClass: "mr-2",
    attrs: {
      "outlined": "",
      "color": "info"
    },
    on: {
      "click": _vm.abort
    }
  }, [_vm._v(_vm._s(_vm.$t("cancel")))]), _c('span', {
    staticClass: "text-body-2"
  }, [_vm._v(_vm._s(_vm.$t("designGuide.MDetailForm.changes")))])], 1) : _vm._e()], 1) : _vm._e(), _c('debug', [_c('v-card-title', [_vm._v("Input Object (item)")]), _c('div', [_vm._v(" " + _vm._s(_vm.item) + " ")]), _c('v-card-title', [_vm._v("Configuration")]), _c('div', [_vm._v(" " + _vm._s(_vm.config) + " ")]), _c('v-select'), _c('v-text-field')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }