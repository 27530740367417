/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivAttachmentRequestControllerGetAllForPartnerParamsGen,
  MrfiktivAttachmentRequestViewModelGen,
  MrfiktivCreateAttachmentRequestDtoGen,
  MrfiktivPageViewModelGen,
  MrfiktivSharedContentViewmodelGen,
  MrfiktivUpdateSharedContentElementDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class AttachmentRequest<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags attachment-request
   * @name AttachmentRequestControllerGetAllForPartner
   * @summary Get all attachmentRequests
   * @request GET:/partner/{partnerId}/attachment
   * @secure
   * @response `200` `(MrfiktivPageViewModelGen & { data?: (MrfiktivSharedContentViewmodelGen)[] })`
   */
  attachmentRequestControllerGetAllForPartner = (
    { partnerId, ...query }: MrfiktivAttachmentRequestControllerGetAllForPartnerParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivPageViewModelGen & { data?: MrfiktivSharedContentViewmodelGen[] }, any>({
      path: `/partner/${partnerId}/attachment`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags attachment-request
   * @name AttachmentRequestControllerCreateRequest
   * @summary (ATTACHMENT_REQUEST - CREATE) Create data request
   * @request POST:/partner/{partnerId}/attachment
   * @secure
   * @response `201` `MrfiktivAttachmentRequestViewModelGen` The created report attachment request
   */
  attachmentRequestControllerCreateRequest = (
    partnerId: string,
    data: MrfiktivCreateAttachmentRequestDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivAttachmentRequestViewModelGen, any>({
      path: `/partner/${partnerId}/attachment`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags attachment-request
   * @name AttachmentRequestControllerGetRequest
   * @summary (ATTACHMENT_REQUEST - READ) Get data request
   * @request GET:/partner/{partnerId}/attachment/{attachmentId}
   * @secure
   * @response `200` `MrfiktivAttachmentRequestViewModelGen` The found report attachment request
   */
  attachmentRequestControllerGetRequest = (partnerId: string, attachmentId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivAttachmentRequestViewModelGen, any>({
      path: `/partner/${partnerId}/attachment/${attachmentId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags attachment-request
   * @name AttachmentRequestControllerUpdateRequest
   * @summary (ATTACHMENT_REQUEST - UPDATE) Get data request
   * @request PATCH:/partner/{partnerId}/attachment/{attachmentId}
   * @secure
   * @response `200` `MrfiktivAttachmentRequestViewModelGen` The found report attachment request
   */
  attachmentRequestControllerUpdateRequest = (
    partnerId: string,
    attachmentId: string,
    data: MrfiktivUpdateSharedContentElementDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivAttachmentRequestViewModelGen, any>({
      path: `/partner/${partnerId}/attachment/${attachmentId}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags attachment-request
   * @name AttachmentRequestControllerDelete
   * @summary (ADMIN) Delete an attachment request
   * @request DELETE:/partner/{partnerId}/attachment/{attachmentId}
   * @secure
   * @response `200` `MrfiktivAttachmentRequestViewModelGen` the deleted shared content
   */
  attachmentRequestControllerDelete = (partnerId: string, attachmentId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivAttachmentRequestViewModelGen, any>({
      path: `/partner/${partnerId}/attachment/${attachmentId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
}
