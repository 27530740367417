
import { downloadUrlFromRemoteOrigin } from "@/lib/utility/downloadFileFunc";
import { IReport } from "@/models/report.entity";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class ExportMixin extends Vue {
  loadXml = false;
  async downloadReportExport(type: "ksr" | "dealerdesk", report: IReport) {
    try {
      this.loadXml = true;
      await PartnerModule.getExportForReportById({
        reportId: report._id,
        partnerId: this.$route.params.partnerId,
        type: type
      });

      // Should be lowercase, no whitespace: `{id}-{numberplate}-{externalId}.xml`

      let fileName = `${report._id}`;

      fileName += report.numberplate ? `-${report.numberplate}` : "";
      fileName += report.externalId ? `-${report.externalId}` : "";

      fileName += ".xml";
      fileName = fileName.replace(/\s/g, "").toLocaleLowerCase();

      downloadUrlFromRemoteOrigin(PartnerModule.reportExportFile, fileName);
    } catch (e) {
      this.$toast(this.$t("components.partner.PartnerReportDetail.XML.noXML"));
      this.$log.error((e as any).message);
    } finally {
      this.loadXml = false;
    }
  }
}
