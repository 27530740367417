import { DetailFormComponentsEnum } from "@/lib/enum/detail-form-components.enum";
import { TransmissionTypeEnum } from "@/lib/enum/transmission.enum";
import { Form, FormConfig, IsFormable } from "@/lib/formable";
import { MrfiktivUpdateVehicleRegistrationDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { VehicleTypes } from "@/store/enum/vehicle-types.enum";

@IsFormable
export class UpdateVehicleRegistration implements MrfiktivUpdateVehicleRegistrationDtoGen {
  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.vehicle.registration.firstname"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "objects.vehicle.registration.firstname",
      rules: []
    }
  })
  firstname?: string;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.vehicle.registration.name"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "objects.vehicle.registration.name",
      rules: []
    }
  })
  name?: string;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.vehicle.registration.street"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "objects.vehicle.registration.street",
      rules: []
    }
  })
  street?: string;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.vehicle.registration.zipCode"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "objects.vehicle.registration.zipCode",
      rules: []
    }
  })
  zipCode?: string;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.vehicle.registration.city"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "objects.vehicle.registration.city",
      rules: []
    }
  })
  city?: string;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.vehicle.registration.huYear"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "objects.vehicle.registration.huYear",
      rules: []
    }
  })
  huYear?: string;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.vehicle.registration.huMonth"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "objects.vehicle.registration.huMonth",
      rules: []
    }
  })
  huMonth?: string;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.vehicle.registration.manufacturerNameCode"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "objects.vehicle.registration.manufacturerNameCode",
      rules: []
    }
  })
  manufacturerNameCode?: string;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.vehicle.registration.manufacturerTypeCode"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "objects.vehicle.registration.manufacturerTypeCode",
      rules: []
    }
  })
  manufacturerTypeCode?: string;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.vehicle.registration.driveTyp"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "objects.vehicle.registration.driveTyp",
      rules: []
    }
  })
  driveTyp?: string;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.vehicle.registration.identificationnumber"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "objects.vehicle.registration.identificationnumber",
      rules: []
    }
  })
  identificationnumber?: string;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.vehicle.registration.numberplate"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "objects.vehicle.registration.numberplate",
      rules: []
    }
  })
  numberplate?: string;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.vehicle.registration.firstregistrationDay"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "objects.vehicle.registration.firstregistrationDay",
      rules: []
    }
  })
  firstregistrationDay?: string;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.vehicle.registration.firstregistrationMonth"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "objects.vehicle.registration.firstregistrationMonth",
      rules: []
    }
  })
  firstregistrationMonth?: string;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.vehicle.registration.firstregistrationYear"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "objects.vehicle.registration.firstregistrationYear",
      rules: []
    }
  })
  firstregistrationYear?: string;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.vehicle.registration.manufacturerName"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "objects.vehicle.registration.manufacturerName",
      rules: []
    }
  })
  manufacturerName?: string;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.vehicle.registration.manufacturerType"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "objects.vehicle.registration.manufacturerType",
      rules: []
    }
  })
  manufacturerType?: string;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.vehicle.registration.manufacturerDescription"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "objects.vehicle.registration.manufacturerDescription",
      rules: []
    }
  })
  manufacturerDescription?: string;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.vehicle.registration.country"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "objects.vehicle.registration.country",
      rules: []
    }
  })
  country?: string;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.vehicle.registration.transmission"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "objects.vehicle.registration.transmission",
      rules: [],
      items: Object.values(TransmissionTypeEnum)
    }
  })
  transmission?: TransmissionTypeEnum;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.vehicle.registration.vehicleClass"],
    type: DetailFormComponentsEnum.AUTO_COMPLETE,
    props: {
      required: false,
      label: "objects.vehicle.registration.vehicleClass",
      rules: [],
      items: Object.values(VehicleTypes)
    }
  })
  vehicleClass?: VehicleTypes;

  constructor(data?: MrfiktivUpdateVehicleRegistrationDtoGen) {
    this.firstname = data?.firstname;
    this.name = data?.name;
    this.street = data?.street;
    this.zipCode = data?.zipCode;
    this.city = data?.city;
    this.huYear = data?.huYear;
    this.huMonth = data?.huMonth;
    this.manufacturerNameCode = data?.manufacturerNameCode;
    this.manufacturerTypeCode = data?.manufacturerTypeCode;
    this.driveTyp = data?.driveTyp;
    this.identificationnumber = data?.identificationnumber;
    this.numberplate = data?.numberplate;
    this.firstregistrationDay = data?.firstregistrationDay;
    this.firstregistrationMonth = data?.firstregistrationMonth;
    this.firstregistrationYear = data?.firstregistrationYear;
    this.manufacturerName = data?.manufacturerName;
    this.manufacturerType = data?.manufacturerType;
    this.manufacturerDescription = data?.manufacturerDescription;
    this.country = data?.country;
    this.transmission = data?.transmission as TransmissionTypeEnum;
    this.vehicleClass = data?.vehicleClass as VehicleTypes;
  }
}

export const UpdateVehicleRegistrationDto = Form.createForClass(UpdateVehicleRegistration);
