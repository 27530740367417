






































import MySignatureDetailSideCard from "@/components/report/user/MySignatureDetailSideCard.vue";
import Debug from "@/components/utility/Debug.vue";
import TableWrapper from "@/components/utility/TableWrapper.vue";
import LayoutReportWithBanner from "@/layouts/LayoutReportWithBanner.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import { SignDigitalSignatureRequestSignerViewModelGen } from "@/services/sign/v1/data-contracts";
import { SignMeModule } from "@/store/modules/sign-me.store";
import { SIGN_COLOR_BY_STATUS } from "@/views/sign/SignRequestTable.vue";
import { Component, Vue } from "vue-property-decorator";
import StatisticsCardRow, { IStatisticRowElement } from "@/components/utility/StatisticsCardRow.vue";
import { ReportModule } from "@/store/modules/report.store";
import Notifications from "@/components/thg/Notifications.vue";

@Component({
  components: {
    LayoutReportWithBanner,
    Notifications,
    TableWrapper,
    StatisticsCardRow,
    MySignatureDetailSideCard,
    Debug
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class MySignatureView extends Vue {
  loading = false;

  selectedId: string | null = null;

  get partnerId() {
    return ReportModule.partner.id;
  }

  get selectedSignature() {
    return SignMeModule.map.get(this.selectedId ?? "");
  }

  get selectedDocuments() {
    return SignMeModule.documentsMap.get(this.selectedId ?? "");
  }

  /**
   * Filters out the current partner, as only from the current partner signatures can be created due to the frontend sending the partnerId to the backend
   */
  get items() {
    return SignMeModule.all.filter(s => s.partnerId === this.partnerId);
  }

  /**
   * Filters by not completed signatures.
   * FIXME: Fix the filter. Logic should be somewhere central.
   */
  get open() {
    return this.items.filter(s => s.status !== "signed");
  }

  /**
   * Filters all completed signatures
   * FIXME: Fix the filter. Logic should be somewhere central.
   */
  get signed() {
    return this.items.filter(s => s.status === "signed");
  }

  get colorByStatus() {
    return SIGN_COLOR_BY_STATUS;
  }

  get i18n() {
    return this.$t("views.report.my.MySignatureView");
  }

  get headers() {
    return [
      {
        text: this.i18n["created"],
        align: "left",
        width: 120,
        value: "timestamp.created"
      },
      {
        text: this.i18n["status"],
        align: "left",
        width: 200,
        value: "status"
      },
      {
        text: this.i18n["title"],
        align: "left",
        value: "title"
      },
      { text: "", value: "controls", align: "right", sortable: false }
    ];
  }

  get rowData(): IStatisticRowElement[] {
    return [
      {
        icon: "mdi-signature",
        title: "sign.DigitalSignatureHomeView.statistic.signatureRequestCount",
        color: "primary",
        data: this.open.length.toString(),
        tooltip: "sign.DigitalSignatureHomeView.statistic.signatureRequestCountHint",
        click: () => undefined
      },
      {
        icon: "mdi-signature-freehand",
        title: "sign.DigitalSignatureHomeView.statistic.signatureCount",
        color: "primary",
        data: this.signed.length.toString(),
        tooltip: "sign.DigitalSignatureHomeView.statistic.signatureCountHint",
        click: () => undefined
      }
    ];
  }

  async mounted() {
    await this.load();
  }

  async load() {
    this.loading = true;
    try {
      SignMeModule.getAll();
    } catch (error) {
      handleError(error);
    } finally {
      this.loading = false;
    }
  }

  async openSignature(item: SignDigitalSignatureRequestSignerViewModelGen) {
    this.loading = true;
    this.selectedId = null;

    try {
      const promises: Promise<any>[] = [];

      promises.push(SignMeModule.getOne({ id: item.requestId }));
      promises.push(SignMeModule.getDocumentsBySignatureRequest({ id: item.requestId }));

      await Promise.all(promises);

      this.selectedId = item.requestId;
    } catch (error) {
      handleError(error);
    } finally {
      this.loading = false;
    }
  }
}
