













































import FileUploadPreview from "@/components/utility/FileUploadPreview.vue";
import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { AssetEnum } from "@/lib/AssetRepository";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportInstructionData } from "@/lib/interfaces/Report/IReportInstructionData";
import { IViewImageUploadable } from "@/lib/interfaces/Report/IViewImageUploadable";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import ReportImageSubmission from "@/mixins/ReportImageSubmissionMixin.vue";
import { IImageUploaded } from "@/models/Image/IImageUploaded";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { ReportType } from "@/store/enum/reportType";
import { ReportModule } from "@/store/modules/report.store";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    LayoutReportStep,
    FileUploadPreview
  }
})
export default class ReportImageDamageOptional extends ReportImageSubmission
  implements IReportStep, IViewImageUploadable {
  header: IReportHeaderData = this.configuration.header;
  instruction: IReportInstructionData = this.configuration.instruction;

  screen = ReportScreenEnum.damageimageoptional;

  get isDamage() {
    return ReportModule.isDamage;
  }

  set isDamage(isDamage: boolean) {
    ReportModule.setIsDamage(isDamage);
  }

  get valid(): boolean {
    if (!this.isDamage) {
      return true;
    }

    return this.isDone;
  }

  internalMessage = "";
  counter = 1000;

  get message(): string {
    return ReportModule.inCreationReport.message;
  }

  set message(m: string) {
    this.internalMessage = m;
  }

  updateMessage(e: any) {
    ReportModule.setMessage(e.target.value);
  }

  /**
   * @inheritdoc
   */
  async handleUpload(file: File): Promise<boolean> {
    await ReportModule.addDamageDetail(file);
    return true;
  }

  /**
   * @inheritdoc
   */
  async handleDelete(file: File): Promise<boolean> {
    await ReportModule.removeDamageDetail(file);
    return true;
  }

  /**
   * @inheritdoc
   */
  get files(): IImageUploaded[] {
    return ReportModule.damagesDetail;
  }

  get configuration() {
    switch (ReportModule.reportType) {
      case ReportType.CAR:
        return {
          exampleImages: {
            damageDetail: {
              example: AssetEnum.damageDetail,
              overlay: AssetEnum.damageDetailOutline
            }
          },
          header: {
            title: "report.imageDamageOptional.car.title",
            description: "report.imageDamageOptional.car.description",
            image: AssetEnum.damageDetailExample,
            debug: false
          },
          instruction: {
            title: "report.imageDamageOptional.car.title",
            text: "report.imageDamageOptional.car.instructionText",
            pictures: [AssetEnum.damageDetailInstruction],
            example: AssetEnum.damageDetailExample,
            display: true
          }
        };
      case ReportType.MOTORBIKE:
        return {
          exampleImages: {
            damageDetail: {
              example: AssetEnum.noImage,
              overlay: ""
            }
          },
          header: {
            title: "report.imageDamageOptional.motorbike.title",
            description: "report.imageDamageOptional.motorbike.description",
            image: AssetEnum.noImage,
            debug: false
          },
          instruction: {
            title: "report.imageDamageOptional.motorbike.title",
            text: "report.imageDamageOptional.motorbike.instructionText",
            pictures: [AssetEnum.damageDetailInstruction],
            example: AssetEnum.noImage,
            display: true
          }
        };

      default:
        return {
          exampleImages: {
            damageDetail: {
              example: AssetEnum.damageDetail,
              overlay: AssetEnum.damageDetailOutline
            }
          },
          header: {
            title: "report.imageDamageOptional.car.title",
            description: "report.imageDamageOptional.car.description",
            image: AssetEnum.damageDetailExample,
            debug: false
          },
          instruction: {
            title: "report.imageDamageOptional.car.title",
            text: "report.imageDamageOptional.car.instructionText",
            pictures: [AssetEnum.damageDetailInstruction],
            example: AssetEnum.damageDetailExample,
            display: true
          }
        };
    }
  }
}
