import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { CostGroup, ICostGroup } from "@/models/cost-group.entity";
import costGroupService from "@/services/mrfiktiv/services/costGroupService";
import { MrfiktivCostGroupControllerGetParamsGen } from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import Vue from "vue";
import { Action, Module, getModule } from "vuex-module-decorators";
import { ActionEnum } from "../enum/authActionEnum";
import { ResourceEnum } from "../enum/authResourceEnum";
import { PaginatedBaseStore } from "../paginated-base.store";
import { CostGroupDataAccessLayer } from "./access-layers/cost-group.access-layer";
import { PaginationFilterListElement } from "./base-pagination.store";
import { UserModule } from "./me-user.store";
import { PartnerModule } from "./partner";

const CostGroupPageDataProvider = new (class extends AbstractPageDataProvider<
  ICostGroup,
  MrfiktivCostGroupControllerGetParamsGen
> {
  itemsPerPage = 25;

  async getPage(query: MrfiktivCostGroupControllerGetParamsGen): Promise<IPageViewModel<ICostGroup>> {
    const res = await costGroupService.get(query);

    const meta = res.meta;
    const data = (res.data ?? []) as ICostGroup[];

    return { meta, data };
  }
})();

@Module({
  dynamic: true,
  namespaced: true,
  name: "costGroup",
  store
})
export class CostGroupPaginationStore extends PaginatedBaseStore<ICostGroup, MrfiktivCostGroupControllerGetParamsGen> {
  protected _data = CostGroupDataAccessLayer;
  protected _pageProvider = CostGroupPageDataProvider;
  protected _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions: PaginationFilterListElement[] = CostGroup.filterables;

  @Action
  async reset(): Promise<void> {
    super.reset();
    if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.COST_GROUP, PartnerModule.partner.id)) {
      this.setFilters([]);
      await this.fetchAll({ partnerId: PartnerModule.partner.id }).catch(e => Vue.$log.error(e));
    }
  }
}

export const CostGroupModule = getModule(CostGroupPaginationStore);
