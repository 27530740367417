import { MrfiktivHttpClientProvider } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import { Ai } from "../v1/Ai";
import { MrfiktivAiControllerGetParamsGen } from "../v1/data-contracts";

/**
 * Communicate with ai API
 */
class AiService {
  mrfiktivProxy: Ai;

  /**
   * Construct service
   */
  constructor(mrfiktivHttpClientProvider: MrfiktivHttpClientProvider) {
    this.mrfiktivProxy = new Ai(mrfiktivHttpClientProvider.client());
  }

  /**
   * Get all ai results
   */
  async get(data: MrfiktivAiControllerGetParamsGen) {
    return (await this.mrfiktivProxy.aiControllerGet(data)).data;
  }

  /**
   * Get one result
   */
  async getOne(partnerId: string, id: string) {
    return (await this.mrfiktivProxy.aiControllerGetOne(partnerId, id)).data;
  }

  /**
   * Get the VIN from the registration image
   */
  async getRegistrationResult(partnerId: string, imageId: string) {
    return (await this.mrfiktivProxy.aiControllerReadRegistrationResult(partnerId, imageId)).data;
  }

  /**
   * Get the summary from the report
   */
  async getReportSummary(partnerId: string, reportId: string, force?: boolean) {
    return (await this.mrfiktivProxy.aiControllerSummarize({ partnerId, reportId, force })).data;
  }
}

/**
 * Communicate with ai API
 */
export default new AiService(new MrfiktivHttpClientProvider());
