














import FileUploadPreview from "@/components/utility/FileUploadPreview.vue";
import { AssetEnum } from "@/lib/AssetRepository";
import { IReportInstructionData } from "@/lib/interfaces/Report/IReportInstructionData";
import ReportImageSubmission from "@/mixins/ReportImageSubmissionMixin.vue";
import { IImageUploaded } from "@/models/Image/IImageUploaded";
import { ReportImageType } from "@/models/Report/ReportImageType";
import { ReportModule } from "@/store/modules/report.store";
import { Component, Prop } from "vue-property-decorator";
@Component({
  components: {
    FileUploadPreview
  }
})
/**
 * Uploads images as overview images
 */
export default class ReportImageUpload extends ReportImageSubmission {
  @Prop()
  asset!: AssetEnum;

  @Prop()
  example!: AssetEnum;

  @Prop()
  instruction!: IReportInstructionData;

  @Prop()
  type!: ReportImageType;

  /**
   * Text to be displayed on the button card.
   */
  @Prop({ default: "defaultFoto" })
  text: any;

  /**
   * An alternative text to be displayed on @isUploade
   */
  @Prop({ default: "defaultFotoAgain" })
  textAlt: any;

  @Prop()
  imageIds?: string[];

  @Prop()
  categoryKey?: string;

  get localCategoryKey() {
    return this.categoryKey || this.$route.path;
  }

  /**
   * @inheritdoc
   */
  get files(): IImageUploaded[] {
    if (this.imageIds) {
      return ReportModule.overviews.filter(image => this.imageIds?.includes(image.uploadId));
    }

    return ReportModule.overviews.filter(
      image => image.categoryKey === this.localCategoryKey && image.type === this.type
    );
  }

  /**
   * @inheritdoc
   */
  async handleUpload(file: File): Promise<boolean> {
    const uploaded = await ReportModule.addImage({ file, type: this.type, categoryKey: this.localCategoryKey });
    this.$emit("onImageUpload", uploaded);

    return true;
  }

  /**
   * @inheritdoc
   */
  async handleDelete(file: File): Promise<boolean> {
    const deleted = await ReportModule.removeImage({ file, type: this.type });
    this.$emit("onImageDeleted", deleted);

    return true;
  }
}
