var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.report ? _c('v-simple-table', [_c('tbody', _vm._l(_vm.items, function (item, idx) {
    return _c('tr', {
      key: idx
    }, [_c('td', [_vm._v(_vm._s(item.text))]), _c('td', [_vm._v(_vm._s(item.value))])]);
  }), 0)]) : _vm._e(), _vm.damageLocation ? _c('v-card', {
    staticClass: "ma-auto pt-4",
    attrs: {
      "flat": "",
      "width": _vm.getDamageLocatorWidth,
      "min-width": _vm.getDamageLocatorWidth,
      "max-height": _vm.getDamageLocatorHeight
    }
  }, [_vm.renderComponent ? _c('damage-locator-combined', {
    ref: "DamageLocator",
    attrs: {
      "isEdit": false,
      "reportType": _vm.reportType,
      "clickedColor": "#6fb38a",
      "damage-locations": _vm.damageLocation
    },
    on: {
      "damageLocation": _vm.forceRerender
    }
  }) : _vm._e()], 1) : _vm._e(), _c('partner-report-detail-image-time-line-element', {
    staticClass: "mt-4",
    attrs: {
      "images": _vm.images
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }