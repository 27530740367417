

























































































import PartnerDetailAttributeWithToolTip from "@/components/partner/PartnerDetailAttributeWithToolTip.vue";
import { ImageSwipeImage } from "@/lib/interfaces/vue-image-swipe.interface";
import { activityLogName } from "@/lib/utility/activityLogName";
import { convertPdfToImagePromises } from "@/lib/utility/convertPdfToImage";
import { CreateSignatureUrlFactory } from "@/lib/utility/createSignatureUrlFactory";
import { detailedDate } from "@/lib/utility/date-helper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { MrfiktivActivityLogViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ISignRequest, SignRequest } from "@/models/sign-request.entity";
import { PartnerModule } from "@/store/modules/partner";
import { SignRequestModule } from "@/store/modules/sign-request.store";
import { SignModule } from "@/store/modules/sign.store";
import ReportDocumentCardStatusMenu from "@/views/sign/ReportDocumentCardStatusMenu.vue";
import SignInitializeDialog from "@/views/sign/SignInitializeDialog.vue";
import { DigitalSignatureRequestStatusEnum } from "@/lib/enum/digital-signature-request-status.enum";
import { Component, Prop } from "vue-property-decorator";
import ContextMenu from "../utility/ContextMenu.vue";
import TimelineCard from "../utility/TimelineItem.vue";
import ActivityTimeLineItemDelete from "./ActivityTimeLineItemDelete.vue";
import { SignDocument, ISignDocument } from "@/models/sign-document.entity";

@Component({
  components: {
    TimelineCard,
    PartnerDetailAttributeWithToolTip,
    SignInitializeDialog,
    ActivityTimeLineItemDelete,
    ContextMenu,
    ReportDocumentCardStatusMenu
  }
})
export default class ActivityTimeLineItemSignRequest extends DarkModeHighlightMixin {
  @Prop({})
  activityLogEntry!: MrfiktivActivityLogViewModelGen;

  loading = false;

  error = false;

  signRequest: ISignRequest | null = null;

  document: ISignDocument | null = null;

  images: ImageSwipeImage[] = [];

  get errorState() {
    return this.error;
  }

  get name() {
    return activityLogName(this.activityLogEntry);
  }

  get headers() {
    return [{ value: "key" }, { value: "value" }];
  }

  get date() {
    if (this.activityLogEntry.timestamp?.created) {
      const locale = this.$t("utility.toLocalDateString").toString() || "de-de";
      return detailedDate(this.activityLogEntry.timestamp?.created, locale);
    }
    return this.$t("sign.DocumentDetail.noDate");
  }

  /**
   * Creates the url to the report and appends the specified query params
   */
  get createUrlWithQuery() {
    const baseReportUrl = PartnerModule.partner.settings?.defaultUrl;

    if (!baseReportUrl) {
      throw new Error("URL missing");
    }

    if (!this.signRequest?.id) {
      throw new Error("signRequest missing");
    }

    return new CreateSignatureUrlFactory(
      baseReportUrl,
      this.signRequest.id,
      this.signRequest?.recipient.firstName,
      this.signRequest?.recipient.lastName
    ).build();
  }

  async mounted() {
    try {
      const partnerId = this.activityLogEntry.partnerId;
      this.loading = true;
      if (this.activityLogEntry.target?.length) {
        const requestId = this.activityLogEntry.target[0].refId;
        const signRequest =
          SignRequestModule.maps.id.get(requestId)[0] ??
          (await new SignRequest({
            id: requestId,
            partnerId
          }).fetch());

        if (signRequest.id) {
          this.signRequest = signRequest;
        } else {
          this.error = true;
        }

        if (signRequest.status !== DigitalSignatureRequestStatusEnum.SIGNED) {
          const documentId = signRequest.documents[0];
          this.document = await new SignDocument({ partnerId, id: documentId }).fetch();
        } else {
          const signatures = await SignModule.findAll({ partnerId, requestId });
          if (signatures.length) {
            const signId = signatures[0].id;
            const signature = await SignModule.findOne({ partnerId, requestId, signId });
            if (signature.documents?.length) {
              this.document = new SignDocument(signature.documents[0]);
            }
          }
        }

        if (this.document) {
          const pages = await convertPdfToImagePromises(this.document.url);
          let index = 0;
          for (const page of pages) {
            index++;

            const url = await page;

            this.images.push({
              src: url,
              thumbnail: url,
              w: 0,
              h: 0,
              title: `${index}/${this.images.length}`
            });
          }
        }
      }
    } catch (error) {
      this.error = true;
      this.$log.error(error);
      this.error = true;
    }
    this.loading = false;
  }

  copyLinkToClipboard() {
    try {
      navigator.clipboard.writeText(this.createUrlWithQuery);
      this.$toast.info(this.$t("components.partner.PartnerReportInitializeCard.linkCopied"));
    } catch (error) {
      this.$toast.error(this.$t("components.partner.PartnerReportInitializeCard.error"));
    }
  }

  toDetail(partnerId: string, id: string) {
    this.$router.push({ name: "SignRequestDetailView", params: { partnerId: partnerId, signRequestId: id } });
  }
}
