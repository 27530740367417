








import { Component, Prop, Vue } from "vue-property-decorator";

/**
 * Money, money, money
 */
@Component({
  components: {}
})
export default class MoneyInput extends Vue {
  @Prop({ default: 0 })
  value!: number;

  @Prop({ default: "" })
  label!: string;

  @Prop({ default: () => ({ locale: "de-DE", prefix: "mdi-currency-eur", precision: 2 }) })
  options!: Record<string, any>;

  get valueLocal() {
    return this.value.toLocaleString(this.options.locale, {
      maximumFractionDigits: this.options.precision,
      minimumFractionDigits: this.options.precision
    });
  }

  set valueLocal(value: string) {
    // remove everything that is not a number
    let cleanedStr = value.replace(/[^0-9]/g, "");

    while (cleanedStr.length < this.options.precision + 1) {
      cleanedStr = "0" + cleanedStr;
    }

    // cut off last #precision digits
    const decimals = cleanedStr.slice(-this.options.precision);

    // cut off the leading zeros
    cleanedStr = cleanedStr.replace(/^0+/, "");

    // make this a pwetty digital number
    cleanedStr = cleanedStr.slice(0, -this.options.precision) + "." + decimals;

    if (cleanedStr === ".00") {
      cleanedStr = "0";
    }

    this.$emit("input", Number(cleanedStr));
  }
}
