import { Filter, FilterConfig, IsFilterable } from "@/lib/filterable";
import { ICreateDto } from "@/lib/utility/data/create-dto.interface";
import {
  MrfiktivHandoverSnapshotViewModelGen,
  MrfiktivReportViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { HandoverBase } from "./handover.entity";
import { IReport, Report } from "./report.entity";
import { IVehicle, Vehicle } from "./vehicle.entity";
import { Agreement } from "./agreement.entity";

@IsFilterable
class HandoverSnapshotBase extends HandoverBase
  implements MrfiktivHandoverSnapshotViewModelGen, ICreateDto<IHandoverSnapshot> {
  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: Vehicle
  })
  vehicle?: IVehicle | undefined;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: Report
  })
  reports: IReport[];

  /**
   * Construct handover
   */
  constructor(handover?: Partial<HandoverSnapshotBase | MrfiktivHandoverSnapshotViewModelGen>) {
    super(handover);

    this.vehicle = handover?.vehicle ? new Vehicle(handover.vehicle) : undefined;
    this.reports = ((handover?.reports as MrfiktivReportViewModelGen[]) ?? []).map(r => new Report(r));
    this.agreements = (handover?.agreements ?? []).map(a => new Agreement(a));
  }

  /**
   * map props from viewmodel to this
   */
  protected map(handover?: MrfiktivHandoverSnapshotViewModelGen) {
    if (!handover) return;

    super.map(handover);

    this.vehicle = handover?.vehicle ? new Vehicle(handover.vehicle) : undefined;
    this.reports = ((handover?.reports as MrfiktivReportViewModelGen[]) ?? []).map(r => new Report(r));
  }

  /**
   * fetch handover
   */
  async fetch(): Promise<this> {
    throw new Error("Not implemented for Handover Snapshot");
  }

  /**
   * create fetch handover
   */
  async create(): Promise<this> {
    throw new Error("Not implemented for Handover Snapshot");
  }

  /**
   * delete handover
   */
  async delete(): Promise<void> {
    throw new Error("Not implemented for Handover Snapshot");
  }

  /**
   * update handover
   * @returns
   */
  async update(): Promise<this> {
    throw new Error("Not implemented for Handover Snapshot");
  }
}

type IHandoverSnapshot = HandoverSnapshotBase;
const HandoverSnapshot = Filter.createForClass(HandoverSnapshotBase);

export { HandoverSnapshot, IHandoverSnapshot };
