
















































import { IForm } from "@/lib/interfaces/form.interface";
import { emailRule, phoneRule } from "@/lib/rules/contactRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import { Component } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import PartnerReportCardGeneral from "./PartnerReportCardGeneral.vue";

@Component({
  components: {
    Card
  }
})
export default class PartnerReportCardContact extends PartnerReportCardGeneral {
  showRemove = false;

  get emailRules() {
    return [requiredRule(), emailRule()];
  }
  get phoneRules() {
    return [phoneRule()];
  }

  add() {
    this.reportDto.customerContact = {
      email: "",
      phone: ""
    };

    this.showRemove = true;
    this.key++;

    this.$nextTick(() => {
      (this.$refs.form as IForm)?.validate();
    });
  }

  remove() {
    this.reportDto.customerContact = undefined;
    this.showRemove = false;
    this.key++;
    this.isValid = true;
  }
}
