import reportMeService from "@/services/mrfiktiv/services/reportMeService";
import { MrfiktivReportViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, Module, Mutation, VuexModule, getModule } from "vuex-module-decorators";

@Module({
  dynamic: true,
  namespaced: true,
  name: "damageMeStore",
  store
})
export class DamagesMeStore extends VuexModule {
  _map: Map<string, MrfiktivReportViewModelGen> = new Map();
  _currentReport: MrfiktivReportViewModelGen | null = null;

  get all(): MrfiktivReportViewModelGen[] {
    return Array.from(this._map.values());
  }

  get map() {
    return this._map;
  }

  get currentReport() {
    return this._currentReport;
  }

  @Mutation
  _mutateCurrentReport(data: MrfiktivReportViewModelGen) {
    this._currentReport = data;
  }

  @Action
  setCurrentReport(data: MrfiktivReportViewModelGen) {
    this.context.commit("_mutateCurrentReport", data);
  }

  @Mutation
  private _mutateAll(request: MrfiktivReportViewModelGen[]) {
    this._map = new Map();
    request.map(r => this._map.set(r._id, r));
  }

  @Mutation
  private _mutateOne(request: MrfiktivReportViewModelGen) {
    this._map.set(request._id, request);
  }

  @Action
  async getAll(): Promise<MrfiktivReportViewModelGen[]> {
    const reports = await reportMeService.findAll();

    this.context.commit("_mutateAll", reports.data);

    return reports.data;
  }

  @Action
  async getOne(data: { id: string }): Promise<MrfiktivReportViewModelGen> {
    const reports = await reportMeService.findOne(data.id);

    this.context.commit("_mutateOne", reports.data);
    this.context.commit("_mutateCurrentReport", reports.data);

    return reports.data;
  }
}

export const DamageMeModule = getModule(DamagesMeStore);
