




import { Component, Vue } from "vue-property-decorator";
import ReportDescriptionCard from "@/components/report/ReportDescriptionCard.vue";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";

@Component({
  components: {
    ReportDescriptionCard
  }
})
export default class ReportDescriptionRequired extends Vue {
  screen = ReportScreenEnum.messagerequired;
}
