
































































































































































































import { PartnerModule } from "@/store/modules/partner";
import { ExportModule } from "@/store/modules/export.store";
import { Component, Vue } from "vue-property-decorator";
import { numberplateRule } from "@/lib/rules/numberplateRule";
import { IGetFreeSlotDto } from "@/services/mrfiktiv/services/daService";

@Component({})
export default class SendToDaDialog extends Vue {
  isDialogActive = true;
  isConfirmationDialogActive = false;
  show = false;

  numberPlate = "";
  resourcesLoading = false;
  eventTypesLoading = false;
  freeSlotLoading = false;

  selectionResourceId = NaN;
  selectionEventTypeId = NaN;
  selectionFreeSlot = "";

  get numberPlateRules() {
    return [numberplateRule()];
  }

  get daResources() {
    return ExportModule.daResources?.resources;
  }

  get daEventTypes() {
    return ExportModule.daEventTypes?.eventTypes;
  }

  get daFreeSlots(): IDateDisplay[] | undefined {
    const freeSlots = ExportModule.daFreeSlot?.freeSlots.map(x => {
      return {
        value: x,
        display: new Date(x).toLocaleString("de-De")
      };
    });

    return freeSlots;
  }

  getFieldText(resource: any) {
    if (!resource) {
      return "";
    }

    const id = resource.id;
    const name = resource.name;

    return `${name} (${id})`;
  }

  get selectedResource() {
    const selected = this.daResources?.find(x => x.id === this.selectionResourceId);
    if (!selected) {
      return undefined;
    }

    return selected;
  }

  get selectedEventType() {
    const selected = this.daEventTypes?.find(x => x.id === this.selectionEventTypeId);
    if (!selected) {
      return undefined;
    }

    return selected;
  }

  async mounted() {
    this.clearSelection();
    this.numberPlate = PartnerModule.report.numberplate || "";

    this.resourcesLoading = true;
    this.eventTypesLoading = true;

    try {
      await Promise.all([
        ExportModule.getDaResources(PartnerModule.partner._id).finally(() => (this.resourcesLoading = false)),
        ExportModule.getDaEventTypes(PartnerModule.partner._id).finally(() => (this.eventTypesLoading = false))
      ]);
    } catch (error) {
      this.$log.error(error);
      this.$toast.error("Fehler beim laden.");
    }
  }

  clearSelection() {
    this.selectionResourceId = NaN;
    this.selectionEventTypeId = NaN;
    this.selectionFreeSlot = "";
    ExportModule.clearDaFreeSlot();
  }

  closeDialog() {
    this.isDialogActive = false;
  }

  openConfirmationDialog() {
    this.isDialogActive = false;
    this.isConfirmationDialogActive = true;
  }

  closeConfirmationDialog() {
    this.isDialogActive = true;
    this.isConfirmationDialogActive = false;
  }

  async send() {
    const resourceId = this.selectedResource?.id || undefined;
    const eventTypeId = this.selectedEventType?.id || undefined;
    const startDate = this.selectionFreeSlot || undefined;

    const daExportReportDto = {
      numberPlate: this.numberPlate,
      resourceId: resourceId,
      eventTypeId: eventTypeId,
      startDate: startDate
    };

    try {
      await ExportModule.exportReportToDa({
        partnerId: PartnerModule.partner._id,
        reportId: PartnerModule.report._id,
        daExportReportDto
      });

      this.$toast.success(this.$t("components.partner.PartnerReportDetail.SendToDaDialog.success"));
    } catch (e) {
      this.$log.error(e);

      this.$toast.error(this.$t("components.partner.PartnerReportDetail.SendToExternOption.fail"));
    }

    this.isConfirmationDialogActive = false;
  }

  async fetchFreeSlots(resourceId: string) {
    await this.getFreeSlot(resourceId, new Date());
  }

  async getFreeSlot(resourceId: string, startDate: Date) {
    this.freeSlotLoading = true;

    const freeSlotRequest: IGetFreeSlotDto = {
      partnerId: PartnerModule.partner._id,
      resourceId: Number(resourceId),
      startDate: startDate.toISOString()
    };
    try {
      await ExportModule.getDaFreeSlot(freeSlotRequest).finally(() => (this.freeSlotLoading = false));
    } catch (error) {
      this.$log.error(error);
      this.$toast.error("Fehler beim laden.");
    }
  }
}

interface IDateDisplay {
  value: string;
  display: string;
}
