import signMeService from "@/services/sign/services/signMeService";
import {
  SignDigitalSignatureRequestSignerViewModelGen,
  SignDigitalSignatureRequestViewModelGen,
  SignDocumentViewModelGen
} from "@/services/sign/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, Module, Mutation, VuexModule, getModule } from "vuex-module-decorators";

@Module({
  dynamic: true,
  namespaced: true,
  name: "signMeStore",
  store
})
export class SignMeStore extends VuexModule {
  _map: Map<string, SignDigitalSignatureRequestSignerViewModelGen> = new Map();
  _documentsMap: Map<string, SignDocumentViewModelGen[]> = new Map();

  get all(): SignDigitalSignatureRequestSignerViewModelGen[] {
    return Array.from(this._map.values());
  }

  get map() {
    return this._map;
  }

  get documentsMap() {
    return this._documentsMap;
  }

  @Mutation
  private _mutateAll(request: SignDigitalSignatureRequestSignerViewModelGen[]) {
    this._map = new Map();
    request.map(r => this._map.set(r.requestId, r));
  }

  @Mutation
  private _mutateOne(request: SignDigitalSignatureRequestSignerViewModelGen) {
    this._map.set(request.requestId, request);
  }

  @Mutation
  private _mutateDocumentsForSignatureRequest(payload: {
    signatureRequestId: string;
    documents: SignDocumentViewModelGen[];
  }) {
    this._documentsMap.set(payload.signatureRequestId, payload.documents);
  }

  @Action
  async getAll(): Promise<SignDigitalSignatureRequestSignerViewModelGen[]> {
    const signatureResponse = await signMeService.findAll();

    this.context.commit("_mutateAll", signatureResponse.data);

    return signatureResponse.data;
  }

  @Action
  async getOne(data: { id: string }): Promise<SignDigitalSignatureRequestViewModelGen> {
    const signatureResponse = await signMeService.findOne(data.id);

    this.context.commit("_mutateOne", signatureResponse.data);

    return signatureResponse.data;
  }

  @Action
  async getDocumentsBySignatureRequest(data: { id: string }): Promise<SignDocumentViewModelGen[]> {
    const documentsForSignatureResponse = await signMeService.findAllDocuments(data.id);

    this.context.commit("_mutateDocumentsForSignatureRequest", {
      signatureRequestId: data.id,
      documents: documentsForSignatureResponse.data
    });

    return documentsForSignatureResponse.data;
  }
}

export const SignMeModule = getModule(SignMeStore);
