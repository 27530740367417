/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivBufferGen,
  MrfiktivExceptionViewmodelGen,
  MrfiktivSignSnapshotDtoGen,
  MrfiktivSnapshotControllerFindOneWithAccessSignatureParamsGen,
  MrfiktivSnapshotViewModelGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Snapshot<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Snapshot
   * @name SnapshotControllerCreate
   * @summary (SNAPSHOT-CREATE) Create a snapshot for a handover
   * @request POST:/partner/{partnerId}/handover/{handoverId}/snapshot
   * @secure
   * @response `200` `MrfiktivSnapshotViewModelGen` The created snapshot
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` NotFound.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  snapshotControllerCreate = (partnerId: string, handoverId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivSnapshotViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/handover/${handoverId}/snapshot`,
      method: "POST",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Snapshot
   * @name SnapshotControllerFindAll
   * @summary (HANDOVER-READ) Get all snapshots for a handover
   * @request GET:/partner/{partnerId}/handover/{handoverId}/snapshot
   * @secure
   * @response `200` `(MrfiktivSnapshotViewModelGen)[]` The snapshots of the handover
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` NotFound.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  snapshotControllerFindAll = (partnerId: string, handoverId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivSnapshotViewModelGen[], MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/handover/${handoverId}/snapshot`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Snapshot
   * @name SnapshotControllerSign
   * @summary (SNAPSHOT-CREATE) Create a snapshot for a handover
   * @request POST:/partner/{partnerId}/handover/{handoverId}/snapshot/{snapshotId}/sign
   * @secure
   * @response `200` `MrfiktivSnapshotViewModelGen` The created snapshot
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` NotFound.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  snapshotControllerSign = (
    partnerId: string,
    handoverId: string,
    snapshotId: string,
    data: MrfiktivSignSnapshotDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivSnapshotViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/handover/${handoverId}/snapshot/${snapshotId}/sign`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Snapshot
   * @name SnapshotControllerGetPdf
   * @summary (HANDOVER-READ) Get a PDF preview for a snapshot
   * @request GET:/partner/{partnerId}/handover/{handoverId}/snapshot/preview/pdf
   * @secure
   * @response `200` `MrfiktivBufferGen` PDF for a snapshot
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` NotFound.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  snapshotControllerGetPdf = (partnerId: string, handoverId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivBufferGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/handover/${handoverId}/snapshot/preview/pdf`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Snapshot
   * @name SnapshotControllerFindOne
   * @summary (HANDOVER-READ) Get a snapshot for a handover
   * @request GET:/partner/{partnerId}/handover/{handoverId}/snapshot/{snapshotId}
   * @secure
   * @response `200` `MrfiktivSnapshotViewModelGen` The snapshot
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` NotFound.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  snapshotControllerFindOne = (partnerId: string, handoverId: string, snapshotId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivSnapshotViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/handover/${handoverId}/snapshot/${snapshotId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Snapshot
   * @name SnapshotControllerFindOneWithAccessSignature
   * @summary Get a report by id with a shared content token.
   * @request GET:/partner/{partnerId}/snapshot/{snapshotId}/access
   * @secure
   * @response `200` `MrfiktivSnapshotViewModelGen` The found record
   * @response `400` `void` Bad Request.
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `void` Forbidden.
   * @response `404` `void` Report not found.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  snapshotControllerFindOneWithAccessSignature = (
    { partnerId, snapshotId, ...query }: MrfiktivSnapshotControllerFindOneWithAccessSignatureParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivSnapshotViewModelGen, void | MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/snapshot/${snapshotId}/access`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
}
