var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-report-step', {
    attrs: {
      "isDone": _vm.isDone,
      "title": _vm.header.title,
      "description": _vm.header.description,
      "image": _vm.header.image,
      "debug": _vm.header.debug
    }
  }, [_c('v-card', {
    staticClass: "pt-4 pr-4 pl-4",
    attrs: {
      "flat": "",
      "max-width": "500"
    }
  }, [_c('v-form', {
    ref: "form",
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-card-text', [_vm._v(_vm._s(_vm.$t("report.leasingDetails.isLeasing")))]), _c('v-radio-group', {
    model: {
      value: _vm.isLeasing,
      callback: function callback($$v) {
        _vm.isLeasing = $$v;
      },
      expression: "isLeasing"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": _vm.$t('report.leasingDetails.yes'),
      "value": true
    }
  }), _c('v-radio', {
    attrs: {
      "label": _vm.$t('report.leasingDetails.no'),
      "value": false
    }
  })], 1), _vm.leasingDetails ? _c('div', [_vm.leasingDetails.isLeasing ? _c('v-card-text', [_vm._v(_vm._s(_vm.$t("report.leasingDetails.leasingCompany")))]) : _vm._e(), _vm.leasingDetails.isLeasing ? _c('v-radio-group', {
    model: {
      value: _vm.leasingDetails.company,
      callback: function callback($$v) {
        _vm.$set(_vm.leasingDetails, "company", $$v);
      },
      expression: "leasingDetails.company"
    }
  }, _vm._l(_vm.LEASING_OPTIONS, function (leasingPartner) {
    return _c('v-radio', {
      key: leasingPartner,
      attrs: {
        "label": leasingPartner,
        "value": leasingPartner
      }
    });
  }), 1) : _vm._e(), _vm.claimNumberIsRequired && _vm.leasingDetails.company ? _c('div', [_c('v-card-text', [_vm._v(_vm._s(_vm.$t("report.leasingDetails.claimNumber.isRequired", {
    company: _vm.leasingDetails.company
  })))]), _c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": _vm.$t('report.leasingDetails.claimNumber.isRequiredLabel'),
      "rules": _vm.requiredRule
    },
    model: {
      value: _vm.leasingDetails.claimNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.leasingDetails, "claimNumber", $$v);
      },
      expression: "leasingDetails.claimNumber"
    }
  })], 1) : !_vm.leasingDetails.isLeasing || _vm.leasingDetails.company === _vm.OTHER ? _c('div', [_c('v-card-text', [_vm._v(_vm._s(_vm.$t("report.leasingDetails.claimNumber.isOptional")))]), _c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": _vm.$t('report.leasingDetails.claimNumber.isOptinalLabel')
    },
    model: {
      value: _vm.leasingDetails.claimNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.leasingDetails, "claimNumber", $$v);
      },
      expression: "leasingDetails.claimNumber"
    }
  })], 1) : _vm._e()], 1) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }