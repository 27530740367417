









































































import { BasePagination, PaginationFilterListElement } from "@/store/modules/base-pagination.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import FilterCardPagination from "../filter/FilterCardPagination.vue";
import TableWrapper, { ITableWrapperHeader, IControlElements } from "./TableWrapper.vue";
import { IPageFilterElement } from "@/models/page-filter-element.entity";

@Component({ components: { TableWrapper, FilterCardPagination, LatestEntriesCardEmpty } })
export default class PaginatedTable extends Vue {
  @Prop()
  title!: string;

  @Prop({ default: false })
  dense?: boolean;

  @Prop()
  subtitle?: string;

  @Prop()
  headers!: ITableWrapperHeader[];

  @Prop()
  emptyItem!: Record<string, any>;

  @Prop()
  sortBy?: string;

  @Prop({ default: true })
  sortDesc?: boolean;

  @Prop({ default: false })
  showSelect?: boolean;

  @Prop({ default: () => [] })
  selectedItems!: Record<string, any>[];

  @Prop()
  controlElements?: IControlElements[];

  @Prop({ default: false })
  loading?: boolean;

  @Prop()
  allItems?: any[];

  @Prop()
  itemsPerPage?: number;

  @Prop({ default: undefined })
  predefinedFilter!: [{ name: string; filter: IPageFilterElement[] }];

  @Prop()
  getModule!: () => BasePagination<any, any>;

  get search() {
    return this.getModule().search;
  }

  set search(search: string | undefined) {
    this.getModule().setSearch(search);
  }

  get items() {
    if (this.allItems) {
      return this.allItems;
    }

    return this.getModule().paginationList;
  }

  get paginationFilterList(): PaginationFilterListElement[] {
    return this.getModule().filterList;
  }

  get paginationFilter(): IPageFilterElement[] {
    return this.getModule().filter;
  }

  set paginationFilter(filter: IPageFilterElement[]) {
    this.getModule().setFilter(filter);
  }

  /**
   * Make selectedItems.sync work with the underlying `<table-wrapper />`
   */
  get selected() {
    return this.selectedItems;
  }

  set selected(selectedItems: Record<string, any>[]) {
    this.$emit("update:selectedItems", selectedItems);
  }

  refresh() {
    this.$emit("refresh");
  }

  clickRow(e: any) {
    this.$emit("click:row", e);
  }
}
