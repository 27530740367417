var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.showAsMenu ? _c('v-menu', {
    attrs: {
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('filter-card-pagination', _vm._g(_vm._b({
          staticClass: "pa-0 ma-0",
          attrs: {
            "id": "filterCard",
            "filterList": _vm.store.filterOptions,
            "padding": false,
            "filter": _vm.store.filters,
            "search": _vm.store.search,
            "hideDivider": true,
            "predefinedFilter": _vm.predefinedFilter,
            "isSearchLoading": _vm.isLoading
          },
          on: {
            "update:filter": [function ($event) {
              return _vm.$set(_vm.store, "filters", $event);
            }, _vm.setFilter],
            "update:search": _vm.debounceSetSearch,
            "clickSearch": _vm.openMenu,
            "confirmSearch": function confirmSearch() {
              _vm.openMenu();

              _vm.debounceFetchFirstPage({
                partnerId: _vm.partnerId
              });
            },
            "confirmFilter": function confirmFilter() {
              _vm.openMenu();

              _vm.debounceFetchFirstPage({
                partnerId: _vm.partnerId
              });
            }
          }
        }, 'filter-card-pagination', attrs, false), on))];
      }
    }], null, false, 3951079473),
    model: {
      value: _vm.isMenu,
      callback: function callback($$v) {
        _vm.isMenu = $$v;
      },
      expression: "isMenu"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": "",
      "elevation": 0
    }
  }, [_vm.entities.length === 0 && !_vm.isLoadMorePossible ? _c('latest-entries-card-empty') : _vm.refType === _vm.BackendResourceEnum.EVENT ? _c('v-virtual-scroll', {
    attrs: {
      "height": 400,
      "itemHeight": 77,
      "items": _vm.entities
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var item = _ref2.item,
            index = _ref2.index;
        return [_c('v-list-item', {
          on: {
            "click": function click($event) {
              return _vm.select(item);
            }
          }
        }, [_c('v-list-item-content', {
          staticClass: "pa-0"
        }, [_c('refs-list', {
          key: 'refs-list' + item.id + index,
          attrs: {
            "item": item,
            "refType": _vm.refType,
            "small": true
          },
          on: {
            "openDetail": function openDetail($event) {
              return _vm.select($event);
            }
          }
        })], 1), _c('v-list-item-icon', [_vm.isSuggested(item.id) ? _c('a-eicon') : _vm._e()], 1)], 1), index !== _vm.entities.length - 1 ? _c('v-divider') : _vm._e()];
      }
    }], null, false, 1595123949)
  }) : _c('virtual-list', {
    staticStyle: {
      "max-height": "400px",
      "overflow": "auto"
    },
    attrs: {
      "data-component": {},
      "data-sources": _vm.entities,
      "data-key": "id"
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref3) {
        var item = _ref3.item,
            index = _ref3.index;
        return [_c('v-list-item', {
          on: {
            "click": function click($event) {
              return _vm.select(item);
            }
          }
        }, [_c('v-list-item-content', {
          staticClass: "pa-0"
        }, [_c('refs-list', {
          key: 'refs-list' + item.id + index,
          attrs: {
            "item": item,
            "refType": _vm.refType,
            "small": true
          },
          on: {
            "openDetail": function openDetail($event) {
              return _vm.select($event);
            }
          }
        })], 1), _c('v-list-item-icon', [_vm.isSuggested(item) ? _c('a-eicon') : _vm._e()], 1)], 1), index !== _vm.entities.length - 1 || _vm.isLoadMorePossible ? _c('v-divider') : _vm._e(), index === _vm.entities.length - 1 && _vm.isLoadMorePossible ? _c('v-btn', {
          attrs: {
            "text": "",
            "elevation": 0,
            "block": "",
            "loading": _vm.isLoading
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.fetchNextPage({
                partnerId: _vm.partnerId
              });
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportList.loadMore")) + " ")]) : _vm._e()];
      }
    }], null, false, 2450505297)
  })], 1)], 1) : _c('div', [_c('filter-card-pagination', {
    staticClass: "pa-0 ma-0",
    attrs: {
      "filterList": _vm.store.filterOptions,
      "padding": false,
      "filter": _vm.store.filters,
      "search": _vm.store.search,
      "hideDivider": true,
      "predefinedFilter": _vm.predefinedFilter,
      "isSearchLoading": _vm.isLoading
    },
    on: {
      "update:filter": [function ($event) {
        return _vm.$set(_vm.store, "filters", $event);
      }, _vm.setFilter],
      "update:search": _vm.debounceSetSearch,
      "clickSearch": _vm.openMenu,
      "confirmSearch": function confirmSearch() {
        _vm.openMenu();

        _vm.debounceFetchFirstPage({
          partnerId: _vm.partnerId
        });
      },
      "confirmFilter": function confirmFilter() {
        _vm.openMenu();

        _vm.debounceFetchFirstPage({
          partnerId: _vm.partnerId
        });
      }
    }
  }), _c('v-card', {
    attrs: {
      "outlined": "",
      "flat": "",
      "elevation": 0
    }
  }, [_vm.entities.length === 0 && !_vm.isLoadMorePossible ? _c('latest-entries-card-empty') : _vm.refType === _vm.BackendResourceEnum.EVENT ? _c('v-virtual-scroll', {
    attrs: {
      "max-height": 400,
      "itemHeight": 77,
      "items": _vm.entities
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var item = _ref4.item,
            index = _ref4.index;
        return [_c('refs-list', {
          key: 'refs-list' + item.id + index,
          attrs: {
            "item": item,
            "refType": _vm.refType,
            "small": true
          },
          on: {
            "openDetail": function openDetail($event) {
              return _vm.select($event);
            },
            "click": function click($event) {
              return _vm.select($event);
            }
          }
        }), index !== _vm.entities.length - 1 ? _c('v-divider') : _vm._e()];
      }
    }])
  }) : _c('virtual-list', {
    staticStyle: {
      "max-height": "400px",
      "overflow": "auto"
    },
    attrs: {
      "data-component": {},
      "data-sources": _vm.entities,
      "data-key": "id"
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref5) {
        var item = _ref5.item,
            index = _ref5.index;
        return [item ? _c('refs-list', {
          key: 'refs-list' + item.id + index,
          attrs: {
            "item": item,
            "refType": _vm.refType,
            "small": true
          },
          on: {
            "openDetail": function openDetail($event) {
              return _vm.select($event);
            },
            "click": function click($event) {
              return _vm.select($event);
            }
          }
        }) : _vm._e(), index !== _vm.entities.length - 1 || _vm.isLoadMorePossible ? _c('v-divider') : _vm._e(), index === _vm.entities.length - 1 && _vm.isLoadMorePossible ? _c('v-btn', {
          attrs: {
            "text": "",
            "elevation": 0,
            "block": "",
            "loading": _vm.isLoading
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.fetchNextPage({
                partnerId: _vm.partnerId
              });
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportList.loadMore")) + " ")]) : _vm._e()];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }