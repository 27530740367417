






import LoginCard from "@/components/login/LoginCard.vue";
import LayoutReport from "@/layouts/LayoutReport.vue";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: { LayoutReport, LoginCard }
})
export default class Login extends Vue {
  get title() {
    return this.$t("views.report.Login.title");
  }
  get text() {
    return this.$t("views.report.Login.text");
  }

  onLoginFunction = async () => {
    const loginScreen = "ReportHome";

    this.$router.push({ name: loginScreen });
  };

  /**
   * Try to get the Logo by priority
   */
  get logo() {
    if (PartnerModule.partner.settings?.reportSettings?.logoUrl) {
      return PartnerModule.partner.settings?.reportSettings?.logoUrl;
    }

    if (PartnerModule.partner.settings?.logoUrl) {
      return PartnerModule.partner.settings?.logoUrl;
    }

    return "https://mmmint.azureedge.net/wirkaufendeinethg/header-image.png";
  }
}
