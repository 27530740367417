




import { Component, Vue, Prop } from "vue-property-decorator";
import PdfViewer from "./PdfViewer.vue";
import { convertB64ToFile } from "@/lib/files/PdfHelper";

export interface PdfBase64 {
  /** The base64 encoded file */
  base64: string;
  /** The file name */
  originalname: string;
  /** The mime type */
  mime: string;
  /** The file size */
  size?: number;
  /** The extension */
  ext?: string;
}

/**
 * This component wraps the PdfViewer.vue we already have, but converts the `base64` string to pdf file and
 * passes it to the PdfViewer component
 */
@Component({
  components: {
    PdfViewer
  }
})
export default class PdfViewerBase64 extends Vue {
  @Prop()
  pdf!: PdfBase64;

  internalPdf: File | null = null;

  mounted() {
    this.internalPdf = convertB64ToFile(this.pdf.base64, this.pdf.originalname);
  }

  onImageExport(data: any) {
    this.$emit("onImageExport", data);
  }
}
