var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-menu', {
    attrs: {
      "offset-y": "",
      "offset-x": "",
      "left": "",
      "nudge-right": "45",
      "close-on-content-click": _vm.closeOnContentClick
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": "",
            "color": _vm.color
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          attrs: {
            "small": _vm.small
          }
        }, [_vm._v(_vm._s(_vm.icon))])], 1)];
      }
    }])
  }, [_c('v-card', [_c('v-card-text', [_vm._t("default")], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }