























































































import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { DamageTypeEnum, DamageTypeTruckEnum } from "@/store/enum/damageType.enum";
import { ReportType } from "@/store/enum/reportType";
import { Component } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import PartnerReportCardGeneral from "./PartnerReportCardGeneral.vue";
import DamageLocatorCombined from "@/components/damagelocator/DamageLocatorCombined.vue";

@Component({
  components: {
    Card,
    DamageLocatorCombined
  }
})
export default class PartnerReportCardDamage extends PartnerReportCardGeneral {
  damageKey = 0;
  renderComponent = true;

  get reportTypeItems() {
    return Object.values(ReportType);
  }

  get damageItems() {
    if (!this.reportDto.reportType) {
      return Object.values(DamageTypeEnum);
    }

    return this.reportDto.reportType === ReportType.CAR
      ? Object.values(DamageTypeEnum)
      : Object.values(DamageTypeTruckEnum);
  }

  get color() {
    return getDefaultPartnerColor();
  }

  get damageLocation() {
    this.forceRerender();
    return this.reportDto?.damage;
  }

  forceRerender() {
    // remove the my-component component from the DOM
    this.renderComponent = false;

    this.$nextTick(() => {
      // add my-component component in DOM
      this.renderComponent = true;
    });
  }

  onReportTypeSelected(reportType: ReportType) {
    if (this.reportDto) {
      this.reportDto.reportType = reportType;
    }

    // Clear damages
    this.reportDto?.damage?.splice(0, this.reportDto?.damage?.length);
  }

  get showSelector() {
    if (!this.reportDto.reportType) {
      return true;
    }

    return this.reportDto.reportType === ReportType.CAR || this.reportDto.reportType === ReportType.TRUCK;
  }

  svgClicked(e: any) {
    if (!e) return;
    const newDamage = e.title;

    if (!this.reportDto.damage) {
      this.reportDto.damage = [newDamage];
    }

    const index = this.reportDto.damage?.findIndex(v => v === newDamage) ?? -1;
    if (index > -1) {
      this.reportDto.damage?.splice(index, 1);
    } else {
      this.reportDto.damage?.push(newDamage);
    }

    this.key++;
  }
}
