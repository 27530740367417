/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { SignHttpClientProvider } from "../sign-http-client.provider";
import { MyDigitalSignatureService } from "../v1/MyDigitalSignatureService";

export class SignMeService {
  /**
   * The proxy.
   */
  proxy: MyDigitalSignatureService;

  /**
   * @class Initialize service
   */
  constructor(clientProvider: SignHttpClientProvider) {
    this.proxy = new MyDigitalSignatureService(clientProvider.client());
  }

  findAll() {
    return this.proxy.signRequestMeControllerFindAllSignedRequestsForUser();
  }

  findOne(id: string) {
    return this.proxy.signRequestMeControllerFindSignedRequestForUser(id);
  }

  findAllDocuments(id: string) {
    return this.proxy.signRequestMeControllerFindMyDocumentsForSignatureRequest(id);
  }
}

export default new SignMeService(new SignHttpClientProvider());
