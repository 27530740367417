

































































































































































































import FileUploadMulti from "@/components/files/FileUploadMulti.vue";
import NumberplateForm from "@/components/utility/NumberplateForm.vue";
import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import { disallowUmlaut, emailRule, extendetPhoneRule } from "@/lib/rules/contactRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import ReportStepMixin from "@/mixins/ReportStepMixin.vue";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { ReportModule } from "@/store/modules/report.store";
import { Component } from "vue-property-decorator";
import { UserModule } from "@/store/modules/me-user.store";
import { CountryCodeEnum } from "@/lib/enum/country-code.enum";

@Component({
  components: {
    LayoutReportStep,
    FileUploadMulti,
    NumberplateForm
  }
})
export default class ReportContact extends ReportStepMixin implements IReportStep {
  readonly screen = ReportScreenEnum.CONTACT;

  numberplateValid = false;

  valid = false;

  get header(): IReportHeaderData {
    return {
      title: "report.reportContact.title",
      description: "report.reportContact.description"
    };
  }

  get isTaxDeductible() {
    return ReportModule.isTaxDeductible;
  }

  set isTaxDeductible(value: boolean) {
    ReportModule.setIsTaxDeductible(value);
    if (!value) {
      ReportModule.setTaxnumber("");
    }
  }

  get isCompany() {
    return ReportModule.isCompany;
  }

  set isCompany(value: boolean) {
    ReportModule.setIsCompany(value);
    if (!value) {
      ReportModule.setCompanyName("");
    }
  }

  get countryCode(): CountryCodeEnum {
    return ReportModule.partnerCountryCode;
  }

  get numberplate() {
    return ReportModule.numberplate;
  }
  set numberplate(value: string) {
    ReportModule.setNumberplate(value);
  }

  get firstName() {
    return ReportModule.user.firstName;
  }
  set firstName(value: string) {
    ReportModule.setUser({
      firstName: value,
      lastName: this.lastName
    });
  }

  get lastName() {
    return ReportModule.user.lastName;
  }
  set lastName(value: string) {
    ReportModule.setUser({
      firstName: this.firstName,
      lastName: value
    });
  }

  get company() {
    return ReportModule.companyName;
  }
  set company(value: string) {
    ReportModule.setCompanyName(value);
  }

  get email() {
    return ReportModule.contact.email;
  }
  set email(value: string) {
    ReportModule.setContact({
      phone: this.phone,
      email: value
    });
  }

  get phone() {
    return ReportModule.contact.phone;
  }
  set phone(value: string) {
    ReportModule.setContact({
      phone: value,
      email: this.email
    });
  }

  get taxnumber() {
    return ReportModule.taxnumber;
  }
  set taxnumber(value: string) {
    ReportModule.setTaxnumber(value);
  }

  get street() {
    return ReportModule.address.street;
  }
  set street(value: string) {
    ReportModule.setAddress({
      street: value,
      zip: this.zip,
      city: this.city
    });
  }

  get zip() {
    return ReportModule.address.zip;
  }
  set zip(value: string) {
    ReportModule.setAddress({
      street: this.street,
      zip: value,
      city: this.city
    });
  }

  get city() {
    return ReportModule.address.city;
  }
  set city(value: string) {
    ReportModule.setAddress({
      street: this.street,
      zip: this.zip,
      city: value
    });
  }

  get emailRules() {
    const rules = [];

    rules.push(requiredRule());
    rules.push(emailRule());
    rules.push(disallowUmlaut());

    return rules;
  }

  get phoneNumberRules() {
    const rules = [];

    rules.push(extendetPhoneRule());

    return rules;
  }

  get addressRules() {
    const rules = [];

    rules.push(requiredRule());

    return rules;
  }

  get isDone() {
    return this.valid && this.numberplateValid;
  }

  get requiredRule() {
    const rules = [];

    rules.push(requiredRule());

    return rules;
  }

  get taxNumberRules() {
    const rules = [];

    rules.push(requiredRule());

    return rules;
  }

  mounted() {
    if (UserModule.user) {
      this.firstName = UserModule.user?.firstName ?? "";
      this.lastName = UserModule.user?.lastName ?? "";
      this.email = UserModule.user?.contact?.email ?? "";
      this.phone = UserModule.user?.contact?.phone ?? "";
      this.street = UserModule.user?.address?.street ?? "";
      this.zip = UserModule.user?.address?.zip ?? "";
      this.city = UserModule.user?.address?.city ?? "";
      this.isCompany = UserModule.user?.isCompany ?? false;
      this.isTaxDeductible = UserModule.user?.isTaxDeductible ?? false;
      this.company = UserModule.user?.company ?? "";
      this.taxnumber = UserModule.user?.taxnumber ?? "";
    }
  }
}
