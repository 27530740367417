var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('file-upload-preview', {
    attrs: {
      "example": _vm.AssetEnum.image,
      "filesCb": _vm.displayImages,
      "displayFiles": _vm.displayImages,
      "onDelete": _vm.handleDelete,
      "onUpload": _vm.handleUpload,
      "text": _vm.$t('timeLine.ActivityTimeLineItemImage.dialogTitle'),
      "data-test-images-add-btn": ""
    },
    scopedSlots: _vm._u([{
      key: "file",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-select', {
          attrs: {
            "dense": "",
            "disabled": "",
            "outlined": "",
            "items": _vm.reportImageTypes,
            "label": _vm.$t('timeLine.PartnerReportDetailImageUpload.imageType')
          },
          scopedSlots: _vm._u([{
            key: "item",
            fn: function fn(_ref2) {
              var item = _ref2.item;
              return [_vm._v(" " + _vm._s(_vm.$t("timeLine.PartnerReportDetailImageTimeLineElement.imageTypes.".concat(item))) + " ")];
            }
          }, {
            key: "selection",
            fn: function fn(_ref3) {
              var item = _ref3.item;
              return [_vm._v(" " + _vm._s(_vm.$t("timeLine.PartnerReportDetailImageTimeLineElement.imageTypes.".concat(item))) + " ")];
            }
          }], null, true),
          model: {
            value: item.type,
            callback: function callback($$v) {
              _vm.$set(item, "type", $$v);
            },
            expression: "item.type"
          }
        })];
      }
    }, {
      key: "upload",
      fn: function fn() {
        return [_c('v-select', {
          attrs: {
            "dense": "",
            "outlined": "",
            "items": _vm.reportImageTypes,
            "label": _vm.$t('timeLine.PartnerReportDetailImageUpload.imageType')
          },
          scopedSlots: _vm._u([{
            key: "item",
            fn: function fn(_ref4) {
              var item = _ref4.item;
              return [_vm._v(" " + _vm._s(_vm.$t("timeLine.PartnerReportDetailImageTimeLineElement.imageTypes.".concat(item))) + " ")];
            }
          }, {
            key: "selection",
            fn: function fn(_ref5) {
              var item = _ref5.item;
              return [_vm._v(" " + _vm._s(_vm.$t("timeLine.PartnerReportDetailImageTimeLineElement.imageTypes.".concat(item))) + " ")];
            }
          }]),
          model: {
            value: _vm.imageType,
            callback: function callback($$v) {
              _vm.imageType = $$v;
            },
            expression: "imageType"
          }
        })];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }