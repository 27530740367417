






















import CameraBottomSheet from "@/components/camera/CameraBottomSheet.vue";
import CameraDialog from "@/components/camera/CameraDialog.vue";
import FileExample from "@/components/files/FileExample.vue";
import FileUpload from "@/components/files/FileUpload.vue";
import FileUploadMulti from "@/components/files/FileUploadMulti.vue";
import ImageUploadButton from "@/components/files/ImageUploadButton.vue";
import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { AssetEnum } from "@/lib/AssetRepository";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportInstructionData } from "@/lib/interfaces/Report/IReportInstructionData";
import { IViewImageUploadable } from "@/lib/interfaces/Report/IViewImageUploadable";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import ReportImageSubmission from "@/mixins/ReportImageSubmissionMixin.vue";
import { IImageUploaded } from "@/models/Image/IImageUploaded";
import { ReportModule } from "@/store/modules/report.store";
import { Component, Prop } from "vue-property-decorator";
import PdfViewer from "@/components/files/PdfViewer.vue";
import PdfUploadDialog from "@/components/files/PdfUploadDialog.vue";
import ReportImageUpload from "@/views/report/ReportImageUpload.vue";
import { ReportImageType } from "@/models/Report/ReportImageType";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";

@Component({
  components: {
    PdfViewer,
    LayoutReportStep,
    ImageUploadButton,
    FileExample,
    FileUpload,
    CameraBottomSheet,
    CameraDialog,
    FileUploadMulti,
    PdfUploadDialog,
    ReportImageUpload
  }
})
export default class ReportImageDamageDetailCard extends ReportImageSubmission
  implements IReportStep, IViewImageUploadable {
  @Prop({ default: 1 })
  minimum!: number;

  @Prop()
  screen!: ReportScreenEnum;

  example = AssetEnum.damageDetail;
  asset = AssetEnum.damageDetailOutline;

  get textAlt() {
    if (this.minimum && this.files.length < this.minimum) {
      const count = this.minimum - this.files.length;
      if (count === 1) {
        return this.$t("report.additionalImage.textBtnAltCountSingular");
      } else {
        return this.$t("report.additionalImage.textBtnAltCountPlural", { count });
      }
    }

    return this.$t("report.additionalImage.textBtnAlt");
  }

  get isDone() {
    if (this.minimum) {
      return this.files.length >= this.minimum;
    }

    return true;
  }

  get type() {
    return ReportImageType.damageDetail;
  }

  get header(): IReportHeaderData {
    const header: IReportHeaderData = {
      title: "report.imageDamageDetail.title",
      description: "report.imageDamageDetail.description",
      image: AssetEnum.damageDetailExample,
      debug: this.files
    };

    if (this.minimum > 1) {
      header.description = String(this.$t("report.imageDamageDetail.descriptionMinimum", { count: this.minimum }));
    }

    return header;
  }

  instruction: IReportInstructionData = {
    title: "report.imageDamageDetail.title",
    text: "report.imageDamageDetail.instructionText",
    pictures: [AssetEnum.damageDetailInstruction],
    example: AssetEnum.damageDetailExample,
    display: true
  };

  /**
   * @inheritdoc
   */
  get files(): IImageUploaded[] {
    return ReportModule.overviews.filter(i => i.type === this.type && i.categoryKey === this.type);
  }
}
