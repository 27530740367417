import { MrfiktivHttpClientProvider } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import { Cost } from "../v1/Cost";
import {
  MrfiktivCostControllerGetParamsGen,
  MrfiktivCreateCostDtoGen,
  MrfiktivUpdateCostDtoGen
} from "../v1/data-contracts";

/**
 * Communicate with cost API
 */
class CostService {
  mrfiktivProxy: Cost;

  /**
   * Construct service
   */
  constructor(mrfiktivHttpClientProvider: MrfiktivHttpClientProvider) {
    this.mrfiktivProxy = new Cost(mrfiktivHttpClientProvider.client());
  }

  /**
   * Create an cost
   */
  async create(partnerId: string, data: MrfiktivCreateCostDtoGen) {
    const cost = (await this.mrfiktivProxy.costControllerCreate(partnerId, data)).data;

    return cost;
  }

  /**
   * Get all cost
   */
  async get(data: MrfiktivCostControllerGetParamsGen) {
    const costs = (await this.mrfiktivProxy.costControllerGet(data)).data;

    return costs;
  }

  /**
   * Get a cucoststom
   */
  async getOne(partnerId: string, costId: string) {
    const cost = (await this.mrfiktivProxy.costControllerGetOne(partnerId, costId)).data;

    return cost;
  }

  /**
   * Update a cost
   */
  async update(partnerId: string, costId: string, data: MrfiktivUpdateCostDtoGen) {
    const cost = (await this.mrfiktivProxy.costControllerUpdate(partnerId, costId, data)).data;

    return cost;
  }

  /**
   * Delete a cost
   */
  async delete(partnerId: string, costId: string) {
    const cost = (await this.mrfiktivProxy.costControllerRemove(partnerId, costId)).data;

    return cost;
  }
}

/**
 * Communicate with cost API
 */
export default new CostService(new MrfiktivHttpClientProvider());
