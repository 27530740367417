export enum DamageTypeEnum {
  tireFrontLeft = "tireFrontLeft",
  tireFrontRight = "tireFrontRight",
  tireRearLeft = "tireRearLeft",
  tireRearRight = "tireRearRight",
  doorFrontLeft = "doorFrontLeft",
  doorFrontRight = "doorFrontRight",
  doorRearLeft = "doorRearLeft",
  doorRearRight = "doorRearRight",
  "bumperFront" = "bumperFront",
  "bumperRear" = "bumperRear",
  fenderFrontLeft = "fenderFrontLeft",
  fenderFrontRight = "fenderFrontRight",
  sidepanleRearLeft = "sidepanleRearLeft",
  sidepanelRearRight = "sidepanelRearRight",
  roof = "roof",
  windshield = "windshield",
  tailgate = "tailgate",
  engingeHood = "engingeHood"
}

export enum DamageTypeTruckEnum {
  // Unique
  tireMiddleLeft = "tireMiddleLeft",
  tireMiddleRight = "tireMiddleRight",
  leftMirror = "leftMirror",
  rightMirror = "rightMirror",

  bumperRear = "bumperRear",

  tireFrontLeft = "tireFrontLeft",
  tireFrontRight = "tireFrontRight",
  tireRearLeft = "tireRearLeft",
  tireRearRight = "tireRearRight",
  doorFrontLeft = "doorFrontLeft",
  doorFrontRight = "doorFrontRight",
  roof = "roof",
  windshield = "windshield",
  engingeHood = "engingeHood",
  fenderFrontLeft = "fenderFrontLeft",
  fenderFrontRight = "fenderFrontRight",
  sidepanleRearLeft = "sidepanleRearLeft",
  sidepanelRearRight = "sidepanelRearRight"
}
