/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivCreateReportImageDtoGen,
  MrfiktivImageDocumentGen,
  MrfiktivPageViewModelGen,
  MrfiktivPartnerImageControllerFindAllParamsGen,
  MrfiktivPartnerImageViewModelGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class PartnerImage<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags partner-image
   * @name PartnerImageControllerFindAll
   * @summary Get all images
   * @request GET:/image/partner/{partnerId}/image
   * @secure
   * @response `200` `(MrfiktivPageViewModelGen & { data?: (MrfiktivPartnerImageViewModelGen)[] })`
   */
  partnerImageControllerFindAll = (
    { partnerId, ...query }: MrfiktivPartnerImageControllerFindAllParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivPageViewModelGen & { data?: MrfiktivPartnerImageViewModelGen[] }, any>({
      path: `/image/partner/${partnerId}/image`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags partner-image
   * @name PartnerImageControllerUploadImageQuery
   * @summary Submit image for partner.
   * @request POST:/image/partner/{partnerId}/image
   * @secure
   * @response `200` `MrfiktivPartnerImageViewModelGen` The id of the image
   * @response `400` `void` General error if request is not working.
   * @response `404` `void` No partner found.
   * @response `415` `void` Unsupported file upload.
   */
  partnerImageControllerUploadImageQuery = (
    partnerId: string,
    data: MrfiktivCreateReportImageDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivPartnerImageViewModelGen, void>({
      path: `/image/partner/${partnerId}/image`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags partner-image
   * @name PartnerImageControllerFindOne
   * @summary Get an image by id
   * @request GET:/image/partner/{partnerId}/image/{id}
   * @secure
   * @response `200` `MrfiktivPartnerImageViewModelGen` The found record
   */
  partnerImageControllerFindOne = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivPartnerImageViewModelGen, any>({
      path: `/image/partner/${partnerId}/image/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags partner-image
   * @name PartnerImageControllerRemove
   * @summary Delete a image by id
   * @request DELETE:/image/partner/{partnerId}/image/{id}
   * @secure
   * @response `200` `MrfiktivImageDocumentGen` The deleted record
   */
  partnerImageControllerRemove = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivImageDocumentGen, any>({
      path: `/image/partner/${partnerId}/image/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
}
