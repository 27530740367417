import { MrfiktivHttpClientProvider } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import { CostGroup } from "../v1/CostGroup";
import {
  MrfiktivCostGroupControllerGetParamsGen,
  MrfiktivCreateCostGroupDtoGen,
  MrfiktivUpdateCostGroupDtoGen
} from "../v1/data-contracts";

/**
 * Communicate with cost group API
 */
class CostGroupService {
  mrfiktivProxy: CostGroup;

  /**
   * Construct service
   */
  constructor(mrfiktivHttpClientProvider: MrfiktivHttpClientProvider) {
    this.mrfiktivProxy = new CostGroup(mrfiktivHttpClientProvider.client());
  }

  /**
   * Create an cost group
   */
  async create(partnerId: string, data: MrfiktivCreateCostGroupDtoGen) {
    const costGroup = (await this.mrfiktivProxy.costGroupControllerCreate(partnerId, data)).data;

    return costGroup;
  }

  /**
   * Get all cost group
   */
  async get(data: MrfiktivCostGroupControllerGetParamsGen) {
    const costGroups = (await this.mrfiktivProxy.costGroupControllerGet(data)).data;

    return costGroups;
  }

  /**
   * Get a cost group
   */
  async getOne(partnerId: string, costGroupId: string) {
    const costGroup = (await this.mrfiktivProxy.costGroupControllerGetOne(partnerId, costGroupId)).data;

    return costGroup;
  }

  /**
   * Update a cost group
   */
  async update(partnerId: string, costGroupId: string, data: MrfiktivUpdateCostGroupDtoGen) {
    const costGroup = (await this.mrfiktivProxy.costGroupControllerUpdate(partnerId, costGroupId, data)).data;

    return costGroup;
  }

  /**
   * Delete a cost group
   */
  async delete(partnerId: string, costGroupId: string) {
    const costGroup = (await this.mrfiktivProxy.costGroupControllerRemove(partnerId, costGroupId)).data;

    return costGroup;
  }
}

/**
 * Communicate with cost group API
 */
export default new CostGroupService(new MrfiktivHttpClientProvider());
