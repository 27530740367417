












































import { activityLogName } from "@/lib/utility/activityLogName";
import { detailedDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { MrfiktivActivityLogViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ThgPartnerMessageViewModelGen } from "@/services/thg/v1/data-contracts";
import { MessageModule } from "@/store/modules/message.store";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import TemplateEditor from "../template/TemplateEditor.vue";
import ContextMenu from "../utility/ContextMenu.vue";
import TimelineCard from "../utility/TimelineItem.vue";
import ActivityTimeLineItemDelete from "./ActivityTimeLineItemDelete.vue";

@Component({
  components: { TemplateEditor, TimelineCard, ActivityTimeLineItemDelete, ContextMenu }
})
export default class ActivityTimeLineItemMessage extends mixins(DarkModeHighlightMixin, PermissionMixin) {
  @Prop({})
  activityLogEntry!: MrfiktivActivityLogViewModelGen;

  message: ThgPartnerMessageViewModelGen | null = null;

  loading = false;

  get title() {
    return this.$t("timeLine.ActivityTimeLineEmailComment.titleReceiver", {
      name: this.name,
      date: this.date,
      receiver: this.message?.to || ""
    });
  }

  get name() {
    return activityLogName(this.activityLogEntry);
  }

  get date() {
    if (this.activityLogEntry.timestamp?.created) {
      const locale = this.$t("utility.toLocalDateString").toString() || "de-de";
      return detailedDate(this.activityLogEntry.timestamp?.created, locale);
    }
    return this.$t("sign.DocumentDetail.noDate");
  }

  async mounted() {
    if (!this.activityLogEntry.target || !this.activityLogEntry.target[0]) {
      this.$log.error("no message target");
      this.$toast.error("No message");

      return;
    }

    this.loading = true;

    const partnerId = PartnerModule.partner._id;
    const messageId = this.activityLogEntry.target[0].refId;

    try {
      const message = await MessageModule.getMessage({ partnerId: partnerId, id: messageId });
      if (message) {
        this.message = message;
      }
    } catch (e) {
      handleError(e);
    } finally {
      this.loading = false;
    }
  }
}
