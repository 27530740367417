var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-report-step', {
    attrs: {
      "isDone": _vm.isDone,
      "title": _vm.header.title,
      "description": _vm.header.description,
      "image": _vm.header.image,
      "debug": _vm.header.debug
    }
  }, [_c('v-card', {
    staticClass: "pt-4 pr-4 pl-4",
    attrs: {
      "flat": "",
      "max-width": "500"
    }
  }, [_c('v-radio-group', {
    model: {
      value: _vm.reportType,
      callback: function callback($$v) {
        _vm.reportType = $$v;
      },
      expression: "reportType"
    }
  }, [_c('v-row', _vm._l(_vm.activeReportTypeConfigurations, function (item, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "6",
        "md": "4"
      }
    }, [_c('v-card', {
      staticClass: "mx-1 my-2",
      attrs: {
        "flat": "",
        "outlined": "",
        "height": "120"
      },
      on: {
        "click": function click($event) {
          _vm.reportType = item.type;
        }
      }
    }, [_c('v-radio', {
      staticClass: "mx-2 mt-2",
      attrs: {
        "label": item.label,
        "value": item.type
      }
    }), _c('v-img', {
      attrs: {
        "contain": "",
        "src": item.image,
        "max-height": "70"
      }
    })], 1)], 1);
  }), 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }