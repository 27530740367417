import { calculateVinChecksum, VinChecksum, vinChecksumWildCard } from "../utility/calculateVinChecksum";
import i18n from "../../plugins/I18nPlugin";

export function vinRuleChecksum(
  message = "Prüfziffern stimmen nicht überein.",
  isChecksum: VinChecksum
): (value: any) => boolean | string {
  return value => {
    if (isChecksum === vinChecksumWildCard) {
      return true;
    }
    if (isChecksum === 10 && calculateVinChecksum(value) === "X") {
      return true;
    }
    return calculateVinChecksum(value) === isChecksum || message;
  };
}

export function vinRuleLength(message = "Muss 17 Zeichen lang sein."): (value: any) => boolean | string {
  return value => value.length === 17 || message;
}

export function vinRuleNotAllowedCharacter(
  message = "rule.vinRuleNotAllowedCharacter"
): (value: any) => boolean | string {
  const regExp = /^[A-HJ-NPR-Z0-9]+$/; // This regex allows all letters except I, O, Q and all digits 0-9
  return value => regExp.test(value) || String(i18n.tc(message));
}

/**
 * Allows an optional vin, either empty or 17 characters
 * @param message the message to display on error
 * @returns text field rule item
 */
export function vinOptionalRuleLength(
  message = "Eingabe entfernen oder genau 17 Zeichen eingeben."
): (value: any) => boolean | string {
  return value => !value || value.length === 17 || message;
}
