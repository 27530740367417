


























import { Component, Vue, Prop } from "vue-property-decorator";
import { IBaseImage } from "@/models/caseEntity";

@Component({})
export default class ReportImageGallery extends Vue {
  @Prop({})
  images!: IBaseImage[];

  tryGetUrl(item: any) {
    if (item.url) {
      return item.url;
    }
    return "";
  }
}
