var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('confirm-action-dialog', {
    attrs: {
      "title": _vm.title,
      "isDialogActive": _vm.isDialogActive,
      "loading": _vm.loadingAck || _vm.loadingRecurringEvent,
      "rightLoading": _vm.loadingAck || _vm.loadingRecurringEvent || _vm.loadingUpdateEventSeries,
      "rightDisabled": _vm.loadingRecurringEvent
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "leftClick": function leftClick($event) {
        _vm.isDialogActive = false;
      },
      "rightClick": _vm.onRightClick,
      "close": function close($event) {
        _vm.isDialogActive = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "rightText",
      fn: function fn() {
        return [_c('div', {
          domProps: {
            "innerHTML": _vm._s(_vm.rightText)
          }
        })];
      },
      proxy: true
    }])
  }, [_c('v-stepper', {
    attrs: {
      "elevation": 0
    },
    model: {
      value: _vm.step,
      callback: function callback($$v) {
        _vm.step = $$v;
      },
      expression: "step"
    }
  }, [_c('v-stepper-items', [_c('v-stepper-content', {
    attrs: {
      "step": "0"
    }
  }, [_c('v-text-field', {
    staticClass: "mt-1",
    attrs: {
      "label": _vm.$t('components.EventCardAcknowledgementDialog.ackDate'),
      "type": "datetime-local",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.ackAt,
      callback: function callback($$v) {
        _vm.ackAt = $$v;
      },
      expression: "ackAt"
    }
  })], 1), _vm.recurringEvent ? _c('v-stepper-content', {
    attrs: {
      "step": "1"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('p', {
    staticClass: "mb-0",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('components.EventCardAcknowledgementDialog.description.belongsTo', {
        title: _vm.recurringEvent.summary
      }))
    }
  }), _c('v-btn', {
    staticStyle: {
      "margin-top": "-1px",
      "margin-bottom": "1px"
    },
    attrs: {
      "small": "",
      "icon": ""
    },
    on: {
      "click": _vm.goToRecurringEvent
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" mdi-open-in-new ")])], 1)], 1), _c('small', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('components.EventCardAcknowledgementDialog.description.configuration', {
        configuration: _vm.rruleTextTranslated
      }))
    }
  }), _c('p', {
    staticClass: "mt-4 mb-n1",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('components.EventCardAcknowledgementDialog.description.chooseNextOccurence'))
    }
  }), _c('div', [_c('v-sheet', {
    staticClass: "mt-1",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "large": "",
      "color": _vm.nextOccurenceType === _vm.NextOccurenceTypeEnum.PLANNED ? 'grey lighten-3' : '',
      "rounded": ""
    },
    on: {
      "click": function click($event) {
        _vm.nextOccurenceType = _vm.NextOccurenceTypeEnum.PLANNED;
      }
    }
  }, [_c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', {
    attrs: {
      "large": ""
    }
  }, [_vm._v("mdi-calendar-check-outline")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_c('b', [_vm._v(" " + _vm._s(_vm.$t("components.EventCardAcknowledgementDialog.description.planned")))])]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.nextPlannedOccurence) + " ")])], 1)], 1)], 1), _vm.isSuggestedDifferentThanPlanned ? _c('v-sheet', {
    staticClass: "mt-1",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "large": "",
      "color": _vm.nextOccurenceType === _vm.NextOccurenceTypeEnum.SUGGESTED ? 'grey lighten-3' : '',
      "rounded": ""
    },
    on: {
      "click": function click($event) {
        _vm.nextOccurenceType = _vm.NextOccurenceTypeEnum.SUGGESTED;
      }
    }
  }, [_c('v-list-item', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('v-list-item-icon', [_c('a-eicon', {
    attrs: {
      "small": false,
      "large": true,
      "noColor": true
    }
  })], 1), _c('v-list-item-content', [_c('v-list-item-title', [_c('b', [_vm._v(" " + _vm._s(_vm.$t("components.EventCardAcknowledgementDialog.description.suggested")))])]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.readableSuggestedNextOccurenceDate) + " ")])], 1)], 1)], 1) : _vm._e(), _c('v-sheet', {
    staticClass: "mt-1",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "large": "",
      "color": _vm.nextOccurenceType === _vm.NextOccurenceTypeEnum.CUSTOM ? 'grey lighten-3' : '',
      "rounded": ""
    },
    on: {
      "click": function click($event) {
        _vm.nextOccurenceType = _vm.NextOccurenceTypeEnum.CUSTOM;
      }
    }
  }, [_c('v-list-item', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('v-list-item-icon', [_c('v-icon', {
    attrs: {
      "large": ""
    }
  }, [_vm._v("mdi-calendar-edit")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_c('b', [_vm._v(" " + _vm._s(_vm.$t("components.EventCardAcknowledgementDialog.description.custom")))])]), _c('v-list-item-subtitle', [_vm.nextOccurenceType !== _vm.NextOccurenceTypeEnum.CUSTOM ? _c('div', [_vm._v(" " + _vm._s(_vm.readableCustomNextOccurenceDate) + " ")]) : _c('v-date-picker', {
    attrs: {
      "full-width": ""
    },
    model: {
      value: _vm.customNextOccurenceDate,
      callback: function callback($$v) {
        _vm.customNextOccurenceDate = $$v;
      },
      expression: "customNextOccurenceDate"
    }
  })], 1)], 1)], 1)], 1)], 1)]) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }