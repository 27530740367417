































import LayoutReport from "@/layouts/LayoutReport.vue";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { ThgFaqViewModelGen } from "@/services/thg/v1/data-contracts";
import { FaqModule } from "@/store/modules/faq.store";
import { ReportModule } from "@/store/modules/report.store";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: { LayoutReport }
})
export default class FrequentlyAskedQuestions extends Vue {
  isLoading = false;

  get color() {
    return getDefaultPartnerColor();
  }

  async mounted() {
    this.isLoading = true;

    try {
      await FaqModule.getAll({ partnerId: ReportModule.partner._id });
    } catch (error) {
      this.$log.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  get faqs() {
    const faqs = FaqModule.paginationList;

    if (faqs && faqs.length >= 1) {
      this.faqList.push(...faqs);
    }

    return this.faqList.filter(faq => faq.isActive);
  }

  faqList: ThgFaqViewModelGen[] = [];
}
