var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    model: {
      value: _vm.isValid,
      callback: function callback($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('v-textarea', {
    attrs: {
      "flat": "",
      "outlined": ""
    },
    model: {
      value: _vm.reportDto.message,
      callback: function callback($$v) {
        _vm.$set(_vm.reportDto, "message", $$v);
      },
      expression: "reportDto.message"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }