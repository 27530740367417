

























import { SvgPathClickObject } from "@/components/damagelocator/DamageLocator.vue";
import DamageLocatorCombined from "@/components/damagelocator/DamageLocatorCombined.vue";

import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import ReportStepMixin from "@/mixins/ReportStepMixin.vue";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { ReportModule } from "@/store/modules/report.store";
import { Component } from "vue-property-decorator";
import Debug from "@/components/utility/Debug.vue";
import { IReportInstructionData } from "@/lib/interfaces/Report/IReportInstructionData";

@Component({
  components: {
    LayoutReportStep,
    DamageLocatorCombined,
    Debug
  }
})
export default class ReportDamageLocationCombined extends ReportStepMixin implements IReportStep {
  instruction?: IReportInstructionData | undefined;
  header: IReportHeaderData = {
    title: "report.damageLocator.title",
    description: "report.damageLocator.description"
  };

  damageLocation: string[] = ReportModule.inCreationReport.damageLocation;

  screen = ReportScreenEnum.damagelocatorcombined;

  get isDone() {
    return this.damageLocation.length >= 1;
  }

  get reportType() {
    return ReportModule.reportType;
  }

  svgClicked(e: any) {
    if (!e) return;

    const clickedObject: SvgPathClickObject = {
      mapId: e.mapId,
      title: e.title
    };

    if (this.damageLocation && this.damageLocation.includes(clickedObject.title)) {
      ReportModule.popDamageLocation(clickedObject.title);
    } else {
      ReportModule.pushDamageLocation(clickedObject.title);
    }
  }

  get color() {
    return getDefaultPartnerColor();
  }
}
