


























































































































import { ReportRoleEnum } from "@/lib/enum/ReportRole.enum";
import { Component } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import DateTimePicker from "../utility/DateTimePicker.vue";
import PartnerReportCardGeneral from "./PartnerReportCardGeneral.vue";
import { emailRule, phoneRule } from "@/lib/rules/contactRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import { IForm } from "@/lib/interfaces/form.interface";
import { numberplateRule } from "@/lib/rules/numberplateRule";

@Component({
  components: {
    Card,
    DateTimePicker
  }
})
export default class PartnerReportCardWitnesses extends PartnerReportCardGeneral {
  get roleItems() {
    return Object.values(ReportRoleEnum);
  }

  get emailRules() {
    return [requiredRule(), emailRule()];
  }

  get phoneRules() {
    return [requiredRule(), phoneRule()];
  }

  get numberplateRules() {
    const rules = [];

    rules.push(requiredRule());
    rules.push(numberplateRule());

    return rules;
  }

  add() {
    const emptyWitness = {
      contact: {
        email: "",
        phone: ""
      },
      firstName: "",
      imageIds: [],
      lastName: "",
      note: "",
      isInjured: false,
      role: ReportRoleEnum.WITNESS,
      numberplate: ""
    };

    if (!this.reportDto.witness) {
      this.reportDto.witness = [emptyWitness];
    } else {
      this.reportDto.witness.push(emptyWitness);
    }

    this.isValid = false;
    this.key++;

    this.$nextTick(() => {
      (this.$refs.form as IForm)?.validate();
    });
  }

  remove(index: number) {
    this.reportDto.witness?.splice(index, 1);
    this.key++;

    (this.$refs.form as any)?.validate();

    if (!this.reportDto?.witness?.length) {
      this.isValid = true;
    }
  }
}
