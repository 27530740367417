var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('card', {
    key: 'contact' + _vm.key,
    attrs: {
      "margin": 0,
      "flat": "",
      "outlined": "",
      "hideTitle": true,
      "subtitle": _vm.$t('components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.report.title.customerContact')
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [!_vm.reportDto.customerContact ? _c('v-btn', {
          attrs: {
            "text": "",
            "data-test-contact-add-btn": ""
          },
          on: {
            "click": _vm.add
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("add")) + " ")]) : _vm._e(), _vm.showRemove ? _c('v-btn', {
          attrs: {
            "text": "",
            "data-test-contact-close-btn": ""
          },
          on: {
            "click": _vm.remove
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("close")) + " ")]) : _vm._e()];
      },
      proxy: true
    }])
  }, [_vm.reportDto.customerContact ? _c('div', {
    staticClass: "pt-2 pr-2 pl-2"
  }, [_c('v-form', {
    ref: "form",
    model: {
      value: _vm.isValid,
      callback: function callback($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.report.email'),
      "flat": "",
      "outlined": "",
      "rules": _vm.emailRules,
      "data-test-email": ""
    },
    model: {
      value: _vm.reportDto.customerContact.email,
      callback: function callback($$v) {
        _vm.$set(_vm.reportDto.customerContact, "email", $$v);
      },
      expression: "reportDto.customerContact.email"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.report.phone'),
      "flat": "",
      "outlined": "",
      "rules": _vm.phoneRules,
      "data-test-phone": ""
    },
    model: {
      value: _vm.reportDto.customerContact.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.reportDto.customerContact, "phone", $$v);
      },
      expression: "reportDto.customerContact.phone"
    }
  })], 1)], 1)], 1)], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }