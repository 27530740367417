























import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { AssetEnum } from "@/lib/AssetRepository";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportInstructionData } from "@/lib/interfaces/Report/IReportInstructionData";
import { IViewImageUploadable } from "@/lib/interfaces/Report/IViewImageUploadable";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import ReportImageSubmission from "@/mixins/ReportImageSubmissionMixin.vue";
import { IImageUploaded } from "@/models/Image/IImageUploaded";
import { ReportModule } from "@/store/modules/report.store";
import { Component } from "vue-property-decorator";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import FileUploadPreview from "@/components/utility/FileUploadPreview.vue";

@Component({
  components: {
    LayoutReportStep,
    FileUploadPreview
  }
})
export default class ReportImageCockpit extends ReportImageSubmission implements IReportStep, IViewImageUploadable {
  public example = AssetEnum.cockpit;
  public asset = AssetEnum.cockpitOutline;

  screen = ReportScreenEnum.mileage;

  header: IReportHeaderData = {
    title: "report.imageCockpit.title",
    description: "report.imageCockpit.description",
    image: AssetEnum.cockpitExample,
    debug: this.files
  };

  instruction: IReportInstructionData = {
    title: "report.imageCockpit.instructionTitle",
    text: "report.imageCockpit.instructionText",
    pictures: [AssetEnum.cockpitInstruction],
    example: AssetEnum.cockpitExample,
    display: true
  };

  maxFiles = 1;

  /**
   * @inheritdoc
   */
  async handleUpload(file: File): Promise<boolean> {
    if (this.maxFiles !== null && this.files.length >= this.maxFiles) {
      const toDelete = this.files[this.files.length - 1].file;
      await this.handleDelete(toDelete);
    }

    await ReportModule.addCockpit(file);
    return true;
  }

  /**
   * @inheritdoc
   */
  async handleDelete(file: File): Promise<boolean> {
    await ReportModule.removeCockpit(file);
    return true;
  }

  /**
   * @inheritdoc
   */
  get files(): IImageUploaded[] {
    return ReportModule.cockpits;
  }
}
