






























import { Component } from "vue-property-decorator";

import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { AssetEnum } from "@/lib/AssetRepository";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportInstructionData } from "@/lib/interfaces/Report/IReportInstructionData";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import ReportImageSubmission from "@/mixins/ReportImageSubmissionMixin.vue";
import { IImageUploaded } from "@/models/Image/IImageUploaded";
import { ReportModule } from "@/store/modules/report.store";
import ReportImageUpload from "./ReportImageUpload.vue";
import { ReportImageType } from "@/models/Report/ReportImageType";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";

@Component({
  components: {
    ReportImageUpload,
    LayoutReportStep
  }
})
export default class ReportDamageNotice extends ReportImageSubmission implements IReportStep {
  example = AssetEnum.damagenotice;
  screen = ReportScreenEnum.damagenotice;
  damageNotice = "damageNotice";

  get header(): IReportHeaderData {
    return {
      title: "report.damagenotice.title",
      description: "report.damagenotice.description",
      image: AssetEnum.prevDamagenotice,
      debug: this.files
    };
  }

  instruction: IReportInstructionData = {
    title: "report.damagenotice.title",
    text: "report.damagenotice.description",
    pictures: [AssetEnum.prevDamagenotice],
    example: AssetEnum.damagenotice,
    display: false
  };

  get type() {
    return ReportImageType.others;
  }

  get isDone() {
    return this.imageCount > 0;
  }

  get imageCount() {
    return this.files.filter(i => i.categoryKey === this.damageNotice).length;
  }

  /**
   * @inheritdoc
   */
  get files(): IImageUploaded[] {
    return ReportModule.overviews;
  }
}
