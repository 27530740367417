var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('paginated-table', {
    attrs: {
      "store": _vm.SharedContentModule,
      "baseQuery": {
        partnerId: _vm.partnerId
      },
      "headers": _vm.headers,
      "loading": _vm.isEntityLoading,
      "controlElements": _vm.controlElements,
      "selectedItems": [],
      "sortBy": "timestamp.created"
    },
    on: {
      "click:row": _vm.openDetail
    },
    scopedSlots: _vm._u([{
      key: "bodyActions",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "text": ""
          },
          on: {
            "click": function click($event) {
              _vm.isCreateDialog = true;
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("create")) + " ")])];
      },
      proxy: true
    }, {
      key: "item.title",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.title) + " "), _c('br'), _vm._v(" " + _vm._s(item.description) + " ")];
      }
    }, {
      key: "item.refs",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item && item.refs && item.refs.length ? _c('div', _vm._l(item.refs, function (ref, index) {
          return _c('shared-content-card-ref-types', {
            key: ref.refId + ref.refType + index,
            staticClass: "ml-1 mr-1",
            attrs: {
              "reference": ref
            }
          });
        }), 1) : _c('div', [_c('v-chip', {
          staticClass: "ml-1 mr-1",
          attrs: {
            "color": "grey"
          }
        }, [_c('v-icon', {
          attrs: {
            "color": "white"
          }
        }, [_vm._v(" mdi-minus ")])], 1)], 1)];
      }
    }, {
      key: "item.isSystemGenerated",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [item.isSystemGenerated ? _c('v-chip', [_vm._v(" " + _vm._s(_vm.$t("timeLine.ActivityTimeLineItemDocument.systemgenerated")) + " ")]) : item.userId && _vm.getUserNameForId(item.userId) ? _c('v-chip', [_vm._v(" " + _vm._s(_vm.getUserNameForId(item.userId)) + " ")]) : _c('v-chip', [_vm._v(" " + _vm._s(_vm.$t("timeLine.ActivityTimeLineItemDocument.unkown")) + " ")])];
      }
    }, {
      key: "item.validBy",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [item.validBy ? _c('div', [_vm.calculateDayDifference(item.validBy) < 0 ? _c('tooltip', {
          attrs: {
            "text": _vm.$t('timeLine.ActivityTimeLineItemDocument.expired')
          }
        }, [_c('v-chip', {
          attrs: {
            "color": "red"
          }
        }, [_c('v-icon', {
          attrs: {
            "color": "white"
          }
        }, [_vm._v(" mdi-calendar-alert-outline")])], 1)], 1) : _vm.calculateDayDifference(item.validBy) === 0 ? _c('tooltip', {
          attrs: {
            "text": _vm.$t('timeLine.ActivityTimeLineItemDocument.lastDay')
          }
        }, [_c('v-chip', {
          attrs: {
            "color": "red"
          }
        }, [_c('v-icon', {
          attrs: {
            "color": "white"
          }
        }, [_vm._v(" mdi-calendar-alert-outline")])], 1)], 1) : _c('div', [_c('v-chip', {
          attrs: {
            "color": "green"
          }
        }, [_c('b', {
          staticStyle: {
            "color": "white"
          }
        }, [_vm._v("+ " + _vm._s(_vm.calculateDayDifference(item.validBy)))])])], 1)], 1) : _c('div', [_c('v-chip', {
          attrs: {
            "color": "green"
          }
        }, [_c('small', {
          staticStyle: {
            "color": "white"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("timeLine.ActivityTimeLineItemShare.validIndefinitelyShort")) + " ")])])], 1)];
      }
    }])
  }), _vm.isCreateDialog ? _c('create-dialog', {
    attrs: {
      "dto": _vm.CreateSharedContentDto
    },
    on: {
      "created": function created($event) {
        _vm.sideCardItem = $event;
      }
    },
    model: {
      value: _vm.isCreateDialog,
      callback: function callback($$v) {
        _vm.isCreateDialog = $$v;
      },
      expression: "isCreateDialog"
    }
  }) : _vm._e(), _vm.sideCardItem ? _c('shared-content-side-card', {
    attrs: {
      "value": _vm.sideCardItem
    },
    on: {
      "close": function close($event) {
        _vm.sideCardItem = null;
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }