






































import { Component, Prop, Ref } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import PartnerReportCardGeneral from "./PartnerReportCardGeneral.vue";
import { MrfiktivUpdateReportDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { IVehicle } from "@/models/vehicle.entity";

import { VehicleModule } from "@/store/modules/vehicle.store";
import { handleError } from "@/lib/utility/handleError";
import { CreateReportAsPartnerDto } from "@/lib/dto/create-report-as-partner-dto";
import RefsVehicle from "../utility/RefsVehicle.vue";
import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import { requiredRule } from "@/lib/rules/requiredRule";
import VehicleApiSelection from "../utility/VehicleApiSelection.vue";

@Component({
  components: {
    Card,
    RefsVehicle,
    LatestEntriesCardEmpty,
    PartnerReportCardVehicle,
    ConfirmActionDialog
  }
})
export default class PartnerReportCardVehicle extends PartnerReportCardGeneral {
  @Prop()
  outlined!: boolean;

  @Prop({ default: true })
  showCopyAction!: boolean;

  @Prop({ default: true })
  showSubtitle!: boolean;

  loadingVehicle = false;

  @Ref("vehicle-api-selection")
  selection!: VehicleApiSelection;

  vehicle: IVehicle | null = null;

  isReplacementDialogActive = false;

  get requiredRule() {
    return [requiredRule()];
  }

  async mounted() {
    await this.getVehicle();
  }

  async getVehicle() {
    if (this.reportDto.vehicleId) {
      try {
        this.loadingVehicle = true;
        this.vehicle = await VehicleModule.getOne({
          partnerId: this.partnerId,
          vehicleId: this.reportDto.vehicleId
        });
        this.$nextTick(() => {
          if (this.vehicle) {
            this.selection.selected = this.vehicle?.id;
            if (!this.selection.items?.find(i => i.id === this.vehicle?.id)) {
              this.selection.items?.push(this.vehicle);
            }
          }
        });
      } catch (error) {
        handleError(error);
      } finally {
        this.loadingVehicle = false;
      }
    }
  }

  onChangeVehicleSelection(value: IVehicle) {
    this.reportDto = { ...this.reportDto, vehicleId: value?.id || null } as
      | CreateReportAsPartnerDto
      | MrfiktivUpdateReportDtoGen;
    this.vehicle = value;
  }

  replaceVehicleData() {
    this.isReplacementDialogActive = false;

    if (!this.vehicle) {
      return;
    }

    const reportDto = CreateReportAsPartnerDto.createFromVehicle(this.vehicle);

    this.reportDto = { ...this.reportDto, ...reportDto };
  }

  startConfirmReplacementDialog() {
    this.isReplacementDialogActive = true;
  }
}
