











import { activityLogName } from "@/lib/utility/activityLogName";
import { handleError } from "@/lib/utility/handleError";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { MrfiktivActivityLogViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ISignDocument, SignDocument } from "@/models/sign-document.entity";
import { DocumentModule } from "@/store/modules/document.store";
import { Component, Prop } from "vue-property-decorator";
import ActivityTimeLineItemDocumentCard from "./ActivityTimeLineItemDocumentCard.vue";

@Component({
  components: { ActivityTimeLineItemDocumentCard }
})
export default class ActivityTimeLineItemDocument extends DarkModeHighlightMixin {
  @Prop({})
  activityLogEntry!: MrfiktivActivityLogViewModelGen;

  isLoading = false;

  document: ISignDocument | null = null;

  get source() {
    return this.activityLogEntry?.source;
  }

  get name() {
    return activityLogName(this.activityLogEntry);
  }

  get referenceId() {
    const reference = (this.activityLogEntry?.target ?? [])[0];

    return reference?.refId ?? "";
  }

  get date() {
    return this.activityLogEntry.timestamp?.created;
  }

  async mounted() {
    this.isLoading = true;
    const target = this.activityLogEntry.target;
    if (!target) {
      return;
    }

    try {
      this.document = await (
        DocumentModule.maps.id.get(this.referenceId)[0] ??
        new SignDocument({ partnerId: this.activityLogEntry.partnerId, id: this.referenceId })
      ).fetch();
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
