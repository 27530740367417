



















































































import { IEventUIDto } from "@/lib/dto/event/event-ui.dto";
import {
  detailedDate,
  detailedDateWithDay,
  formatHoursAndMinutes,
  simpleDate,
  simpleDoubleDigitDate
} from "@/lib/utility/date-helper";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { ConfigModule } from "@/store/modules/config";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { VehicleModule } from "@/store/modules/vehicle.store";
import { RRule } from "rrule";
import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import EventCardAcknowledgementDialog from "../event/EventCardAcknowledgementDialog.vue";
import { IRefDetail } from "./RefsList.vue";
import RefsVehicle from "./RefsVehicle.vue";
import Tooltip from "./tooltip.vue";

@Component({
  components: { RefsVehicle, Tooltip, EventCardAcknowledgementDialog },
  filters: {
    simpleDate,
    simpleDoubleDigitDate,
    detailedDateWithDay,
    detailedDate,
    formatHoursAndMinutes
  }
})
export default class RefsEvent extends Vue implements IRefDetail<IEventUIDto> {
  @Prop()
  item!: IEventUIDto;

  @Prop({ default: false })
  small!: boolean;

  @Prop({ default: false })
  showIcons!: boolean;

  @Prop({ default: false })
  confirmable!: boolean;

  @Prop({ default: false })
  confirmablePersistant!: boolean;

  @Ref("eventCardAcknowledgementDialog")
  eventCardAcknowledgementDialog!: EventCardAcknowledgementDialog;

  hasClickListener() {
    return Boolean(this.$listeners && this.$listeners.click);
  }

  hasOpenDetailListener() {
    return Boolean(this.$listeners && this.$listeners.openDetail);
  }

  hasActionListener() {
    return Boolean(this.$listeners && this.$listeners.action);
  }

  get attachEventListener() {
    if (this.hasClickListener()) {
      return { click: this.click };
    }

    return undefined;
  }

  get recurrenceDescription() {
    if (this.item.rrule) {
      return RRule.fromString(this.item.rrule).toText();
    }

    return "";
  }

  get vehicle() {
    const vehicleId = this.item.vehicleId;
    if (!vehicleId) {
      return undefined;
    }

    const item = VehicleModule.maps.id.get(vehicleId)[0];
    if (item) {
      return item;
    }

    return VehicleModule.paginationList.find(v => v.id === vehicleId);
  }

  get isDebug() {
    return ConfigModule.debug;
  }

  get ackUser() {
    const userId = this.item.ack?.by;
    if (userId) {
      return PartnerUserModule.maps.id.get(userId)[0];
    }

    return undefined;
  }

  get confirmationText() {
    return this.$t("views.fleet.FleetHomeVehicleTableEventsListItem.confirmed", {
      date: simpleDoubleDigitDate(this.item.ack?.at ?? ""),
      firstName: this.ackUser?.firstName,
      lastName: this.ackUser?.lastName
    });
  }

  get isConfirmable() {
    return Boolean(!this.item.ack);
  }

  simpleDoubleDigitDate(date: string) {
    return simpleDoubleDigitDate(date);
  }

  detailedDateWithDay(date: string) {
    return detailedDateWithDay(date);
  }

  emitOpenDetail() {
    this.$emit("openDetail", this.item);
  }

  click() {
    this.$emit("click", this.item);
  }

  action() {
    this.$emit("action", this.item);
  }

  confirm() {
    this.$log.debug("confirm");
    this.eventCardAcknowledgementDialog.open();
  }

  isOverdue(item: IEventUIDto) {
    return item.isOverdue;
  }

  goToDetail(openInNew = true) {
    if (!this.item.id) {
      throw new Error("Event has no id");
    }

    new GoToHelper(this.$router).goToEventDetail(this.item.partnerId, this.item.id, openInNew);
  }
}
