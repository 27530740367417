























import { Component, Vue, Prop, Ref } from "vue-property-decorator";
import damageLocatorImage from "./damageLocatorImage";
import { ConfigModule } from "@/store/modules/config";
import { Svg, SVG, Path } from "@svgdotjs/svg.js";
import { changeOpacityOfColor } from "@/lib/utility/changeOpacityOfColorFunc";

@Component({
  components: {}
})
export default class DamageLocator extends Vue {
  @Prop({ default: [] })
  damageLocations!: string[];

  @Prop({ default: 1 })
  clickable!: number;

  @Ref("locatorDiv")
  readonly locatorDiv!: HTMLDivElement;

  @Prop()
  public clickedColor!: string;

  @Prop()
  public readOnly!: boolean;

  private calculatedHeight = 50;

  private animationSpeed = 300;

  get svgContainerId() {
    return this.readOnly ? "damageLocatorImageContainerReadOnly" : "damageLocatorImageContainerEdit";
  }

  get height() {
    return this.calculatedHeight;
  }

  created() {
    window.addEventListener("resize", this.setDivHeightByWidth);
  }

  setDivHeightByWidth() {
    if (!this.locatorDiv) return;

    const ratio = 4 / 3;
    const width = this.locatorDiv.offsetWidth;
    const height = width * ratio;

    this.calculatedHeight = height;
  }

  mapAttr = {
    viewBoxWidth: 524,
    viewBoxHeight: 680,
    imageWidth: 524,
    imageHeight: 680
  };

  svgContainer = null;

  mounted() {
    this.generateVenueMap();
    this.setDivHeightByWidth();

    if (!this.readOnly) {
      const damageLocator = document.getElementById(this.svgContainerId);
      if (damageLocator) {
        damageLocator.style.cursor = "pointer";
      }
    }
  }

  generateVenueMap() {
    const path = damageLocatorImage.g.path;

    const svgContainer = SVG()
      .addTo(`#${this.svgContainerId}`)
      .viewbox(0, 0, this.mapAttr.viewBoxWidth, this.mapAttr.viewBoxHeight);

    path.forEach(pathObj => {
      this.generatePath(svgContainer, pathObj);
    });
  }

  generatePath(svgCont: Svg, pathObj: PathObject) {
    const title = pathObj["-title"];
    let fillColor = this.clickedColor;

    if ("#888888" == fillColor) {
      fillColor = ConfigModule.color.primary;
    }

    let clicked = false;
    let fill = pathObj["-fill"];

    if (this.damageLocations.includes(title)) {
      clicked = true;
      fill = fillColor;
    }

    const attrs = {
      fill: fill,
      unselectedColor: pathObj["-fill"],
      clickable: this.clickable,
      clicked: clicked,
      stroke: "white",
      "stroke-width": 1,
      title: title,
      "map-id": pathObj["-id"]
    };

    const element: Path = svgCont.path(pathObj["-d"]).attr(attrs);

    let clickedElement = {};
    element.click(function(this: Path) {
      if (this.node.attributes["clickable"].value == 1) {
        const svgPathClickObject: SvgPathClickObject = {
          mapId: this.node.attributes["map-id"].value,
          title: this.node.attributes["title"].value
        };

        if (this.node.attributes["clicked"].value === "true") {
          this.node.attributes["clicked"].value = "false";
          this.node.attributes["fill"].value = this.node.attributes["unselectedColor"].value;

          clickedElement = svgPathClickObject;
        } else {
          this.node.attributes["clicked"].value = "true";
          this.node.attributes["fill"].value = fillColor;

          clickedElement = svgPathClickObject;
        }
      }
    });

    if (!this.readOnly) {
      element.on("mouseover", () => {
        if (element.node.attributes["clicked"].value == "false") {
          const hoverColor = changeOpacityOfColor(this.clickedColor, 0.7);
          element.fill(hoverColor);
        }
      });

      element.on("mouseout", () => {
        if (element.node.attributes["clicked"].value == "false") {
          element.fill(fill);
        }
      });
    }

    element.on("click", () => {
      this.$emit("svgClicked", clickedElement);
    });
  }
}

export interface SvgPathClickObject {
  mapId: string;
  title: string;
}

interface PathObject {
  "-id": string;
  "-title": string;
  "-class": string;
  "-fill": string;
  "-d": string;
  "-self-closing": string;
}
