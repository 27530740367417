














import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { AssetEnum } from "@/lib/AssetRepository";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportInstructionData } from "@/lib/interfaces/Report/IReportInstructionData";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import ReportImageSubmission from "@/mixins/ReportImageSubmissionMixin.vue";
import { IImageUploaded } from "@/models/Image/IImageUploaded";
import { ReportImageType } from "@/models/Report/ReportImageType";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { ReportModule } from "@/store/modules/report.store";
import { Component } from "vue-property-decorator";
import ReportImageUpload from "./ReportImageUpload.vue";

@Component({
  components: {
    ReportImageUpload,
    LayoutReportStep
  }
})
export default class ReportImageVin extends ReportImageSubmission implements IReportStep {
  asset = AssetEnum.vinExmapleOutlined;
  example = AssetEnum.vinExample;

  screen = ReportScreenEnum.vinimage;

  header: IReportHeaderData = {
    title: "report.imageVin.title",
    description: "report.imageVin.description",
    debug: this.files
  };

  instruction: IReportInstructionData = {
    title: "",
    text: "",
    pictures: [],
    example: AssetEnum.registration,
    display: false
  };

  get isDone() {
    return true;
  }

  get type() {
    return ReportImageType.vin;
  }

  get files(): IImageUploaded[] {
    return ReportModule.overviews.filter(image => image.type === this.type);
  }
}
