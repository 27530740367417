





































































import { IForm } from "@/lib/interfaces/form.interface";
import { emailRule, phoneRule } from "@/lib/rules/contactRule";
import { MrfiktivCreateInsuranceDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { Component } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import PartnerReportCardGeneral from "./PartnerReportCardGeneral.vue";
import { requiredRule } from "@/lib/rules/requiredRule";
import { InsuranceTypeEnum } from "@/components/report/InsuranceForm.vue";

@Component({
  components: {
    Card
  }
})
export default class PartnerReportCardInsurances extends PartnerReportCardGeneral {
  showRemove = false;

  get emailRules() {
    return [requiredRule(), emailRule()];
  }
  get phoneRules() {
    return [requiredRule(), phoneRule()];
  }

  get requiredRule() {
    return [requiredRule()];
  }

  // insurances?: MrfiktivCreateInsuranceDtoGen[];
  add() {
    if (!this.reportDto.insurances) {
      this.reportDto.insurances = [];
    }

    const emptyInsurance: MrfiktivCreateInsuranceDtoGen = {
      name: "",
      number: "",
      type: undefined
    };
    this.reportDto.insurances.push(emptyInsurance);

    this.key++;

    this.$nextTick(() => {
      (this.$refs.form as IForm)?.validate();
    });
  }

  /**
   * All available insurance types
   */
  get insuranceTypeItems() {
    return Object.values(InsuranceTypeEnum);
  }

  remove(index: number) {
    this.reportDto.insurances?.splice(index, 1);
    this.key++;

    this.$nextTick(() => {
      (this.$refs.form as IForm)?.validate();
    });
  }
}
