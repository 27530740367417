





import LoaderReport from "@/components/utility/LoaderReport.vue";
import { AppContextEnum } from "@/lib/enum/appContext.enum";
import { reportHomeQueryParams } from "@/lib/queryParams/reportHomeQueryParams";
import { thgQueryParams } from "@/lib/queryParams/thgQueryParams";
import { AppColorSchema } from "@/lib/utility/AppColorSchema";
import { LocalHostToPartnerMapper } from "@/lib/utility/local-host-to-partner-mapper";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { ReportType } from "@/store/enum/reportType";
import { ConfigModule } from "@/store/modules/config";
import { FeatureModule } from "@/store/modules/feature.store";
import { PartnerModule } from "@/store/modules/partner";
import { ReportModule } from "@/store/modules/report.store";
import { SettingModule } from "@/store/modules/setting.store";
import { Component, Vue } from "vue-property-decorator";
import { NotificationModule } from "@/store/modules/notification.store";
import eventBus from "@/lib/eventBus";
import { CustomerAccountMeModule } from "@/store/modules/customer-account-me.store";

@Component({
  components: { LoaderReport }
})
export default class V extends Vue {
  created() {
    this.trySetStepperByPath();

    ConfigModule.setNavigationSettings({
      showMmmmintLogo: false,
      showTheReportList: false,
      showThePartnerList: false,
      showTheCustomerList: false,
      showTheOrganizationList: false,
      showTheFleetList: false,
      showTheAnonymizer: false,
      showTheOnlineBookingList: false,
      showTheDigitalSignatureList: false,
      showTheAdminList: false,
      showTheThgList: false,
      showTheAboutList: true,
      showThgAboutList: false,
      showThgFeatureSettings: false,
      showTheProjectList: false,
      showTheCostList: false
    });

    if (FeatureModule.onlineBooking) {
      ConfigModule.setNavigationSettings({
        showMmmmintLogo: true,
        showTheReportList: true
      });
    }
  }

  async mounted() {
    ConfigModule.setAppContext(AppContextEnum.REPORT);
    new AppColorSchema(this.$vuetify, ConfigModule.damageReportColorSchema);

    try {
      await LocalHostToPartnerMapper.tryGetPartnerByHost(window.location.hostname);
    } catch (error) {
      this.$log.error(error);
      this.$toast("Fehler beim Laden der Anwendung.");
      this.$router.push({ name: "PartnerContactError" });
    } finally {
      ReportModule.setLoading(false);
    }

    await this.handlePartnerSettings();

    try {
      // if some data is given in the query the report is prefilled.
      this.prefillDataFromQuery();
    } catch (error) {
      Vue.$log.error(error);
    }

    eventBus.$on("logout", () => {
      NotificationModule.setIsNotificationsLoaded(false);
      this.$log.info("Handle Logout Event(Redirect to Login)");
      this.$router.push({ name: "Logout" });
    });

    eventBus.$on("login", async () => {
      /**
       * Create customer account if it does not exist
       */
      try {
        await CustomerAccountMeModule.create({});
      } catch (error) {
        Vue.$log.error(error);
      }
    });
  }

  get loading() {
    return ReportModule.loading;
  }

  trySetStepperByPath() {
    const route = this.$route.path;
    const parts = route.split("/"); // "/3" -> ["", "3" ]
    const maybeAStep: string = parts[1];
    if (Object.values(ReportScreenEnum).includes(maybeAStep as ReportScreenEnum)) {
      ReportModule.setStep(maybeAStep as ReportScreenEnum);
    }
  }

  async handlePartnerSettings() {
    try {
      const partnerId = ReportModule.partner?._id || PartnerModule.partner?._id;

      if (!partnerId) {
        this.$log.error("No partnerId given when trying to load the partner's settings");

        return;
      }

      await SettingModule.getPublicSettingsByPartnerId(partnerId);
    } catch (error) {
      this.$log.error(error);
    }

    try {
      const reportType = await SettingModule.findSettingsInPartnerSettings("REPORT_TYPE");
      if (reportType) {
        ReportModule.setReportType(reportType as ReportType);
      }
    } catch (error) {
      this.$log.error(error);
      this.$log.error("Error during setting reporttype");
    }

    try {
      const activeReportTypes = await SettingModule.findSettingsInPartnerSettings("ACTIVE_REPORT_TYPES");
      if (activeReportTypes) {
        const activeReprotTypes = JSON.parse(activeReportTypes);
        if (activeReprotTypes.length) {
          ReportModule.setActiveReportTypes(activeReprotTypes as ReportType[]);
        }
      }
    } catch (error) {
      this.$log.error(error);
      this.$log.error("Error during setting active reporttypes");
    }
  }

  prefillDataFromQuery() {
    reportHomeQueryParams.concat(thgQueryParams).forEach(param => {
      const value = this.$route.query[param.key];

      if (value) {
        param.onFound((value as string) || "");
        this.$log.info(`${param.key} set in store`);
      }
    });
  }
}
