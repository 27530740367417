var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-report-step', {
    attrs: {
      "isDone": _vm.isDone,
      "title": _vm.header.title,
      "description": _vm.header.description,
      "image": _vm.header.image,
      "debug": _vm.header.debug
    }
  }, [_c('v-card', {
    staticClass: "pt-4",
    attrs: {
      "flat": "",
      "max-width": "500"
    }
  }, [_c('numberplate-form', {
    staticClass: "mx-4",
    attrs: {
      "valid": _vm.valid,
      "countryCode": _vm.countryCode
    },
    on: {
      "valid": _vm.setValid
    },
    model: {
      value: _vm.numberplate,
      callback: function callback($$v) {
        _vm.numberplate = $$v;
      },
      expression: "numberplate"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }