
































































































import { ExportReportMetaTypeEnum } from "@/lib/enum/export-report-meta-type.enum";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import {
  MrfiktivExportReportMetaViewModelGen,
  MrfiktivKsrExportReportDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { ExportModule } from "@/store/modules/export.store";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import Tooltip from "../utility/tooltip.vue";
import PartnerReportDetailSendToKsrDialogKsrDetail from "./PartnerReportDetailSendToKsrDialogKsrDetail.vue";

@Component({ components: { Tooltip, ConfirmActionDialog, PartnerReportDetailSendToKsrDialogKsrDetail, Card } })
export default class PartnerReportDetailSendToKsrDialogKsrDetailExportRequests extends mixins(PermissionMixin) {
  /**
   * list of requests to export to new ksr document
   */
  @Prop()
  metaDataNew?: MrfiktivExportReportMetaViewModelGen;

  /**
   * list of requests to export to existing ksr document
   */
  @Prop()
  metaDataExisting?: MrfiktivExportReportMetaViewModelGen;

  /**
   * dialog to confirm deletion
   */
  isDeleteDialogActive = false;

  /**
   * is detail for existing job suggested through meta loading
   */
  isDetailMetaExistingLoading = true;

  /**
   * if theres an error while loading detail to a suggested ksr job hide it
   */
  suggestedDetailNotLoaded = false;

  /**
   * Whether to delete the existing meta or the new meta
   */
  deleteType: ExportReportMetaTypeEnum | null = null;

  /**
   * The selected document
   */
  selected = -1;

  get ExportReportMetaTypeEnum() {
    return ExportReportMetaTypeEnum;
  }

  /**
   * gets detail of existing ksr document
   */
  get jobDetailMetaExisting() {
    return ExportModule.ksrDocument;
  }

  /**
   * Id of partner for whom export is made
   */
  get partnerId() {
    return this.$route.params.partnerId ?? PartnerModule.partner._id;
  }

  /**
   * Id of report for whom export is made
   */
  get reportId() {
    return this.$route.params.reportId ?? PartnerModule.report._id;
  }

  /**
   * initialize component
   */
  async mounted() {
    await this.getDetailForMetaExisting();
  }

  /**
   * get customer identity token from export report meta
   *
   * @param meta
   */
  getCustomerIdentityToken(meta: MrfiktivKsrExportReportDtoGen) {
    return meta.customerIdentityToken;
  }

  /**
   * get job id from export report meta
   *
   * @param meta
   */
  getNumberPlate(meta: MrfiktivKsrExportReportDtoGen) {
    return meta.numberPlate;
  }

  /**
   * opens a dialog to ensure that deletion is wanted
   */
  openDeleteDialog(deleteType: ExportReportMetaTypeEnum) {
    this.deleteType = deleteType;
    this.isDeleteDialogActive = true;
  }

  /**
   * cancels the deletion dialog and returns to initial dialog
   */
  cancelDeleteDialog() {
    this.isDeleteDialogActive = false;
  }

  /**
   * gets detail for the existing job that is mentioned in meta data
   */
  async getDetailForMetaExisting() {
    if (this.metaDataExisting) {
      this.isDetailMetaExistingLoading = true;
      try {
        const jobId = (this.metaDataExisting.meta as MrfiktivKsrExportReportDtoGen).jobId;
        if (jobId) {
          await ExportModule.getJobDetailById({
            partnerId: this.partnerId,
            jobId: jobId.toString()
          });
        }
      } catch (e) {
        this.$log.error(e);
        this.suggestedDetailNotLoaded = true;
        this.$toast.error(this.$t("components.partner.PartnerReportDetail.SendToKsrDialog.suggestedNotFound"));
      }
    }
    this.isDetailMetaExistingLoading = false;
  }

  /**
   * deletes a meta document
   */
  async confirmDelete() {
    try {
      let metaId = "";
      if (this.deleteType === ExportReportMetaTypeEnum.KSR_EXISTING) {
        metaId = this.metaDataExisting?._id.toString() ?? "";
        this.metaDataExisting = undefined;
      } else if (this.deleteType === ExportReportMetaTypeEnum.KSR_NEW) {
        metaId = this.metaDataNew?._id.toString() ?? "";
        this.metaDataNew = undefined;
      }

      await ExportModule.handleDelete({
        partnerId: this.partnerId,
        metaId: metaId
      });
      this.$toast.success(this.$t("components.partner.PartnerReportDetail.SendToKsrDialog.deleteSuccessToast"));
    } catch {
      this.$toast.error(this.$t("components.partner.PartnerReportDetail.SendToKsrDialog.deleteFailToast"));
    }
    this.isDeleteDialogActive = false;
  }

  /**
   * Send to KSR
   * @param type
   */
  confirm(meta: MrfiktivExportReportMetaViewModelGen) {
    this.$emit("submit", meta?.meta);
  }
}
