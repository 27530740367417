
import { GoToHelper } from "@/lib/utility/goToHelper";
import { MrfiktivBaseCreateSharedContentDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class SharedContentMixin extends Vue {
  getIcon(type: ResourceEnum) {
    return (
      new Map<ResourceEnum, string>([
        [ResourceEnum.REPORT, "mdi-car"],
        [ResourceEnum.DOCUMENT, "mdi-file-document"],
        [ResourceEnum.SNAPSHOT, "mdi-clipboard-text-outline"]
      ]).get(type) ?? "mdi-folder-question-outline"
    );
  }

  get BackendResourceEnum() {
    return ResourceEnum;
  }

  get resourceList() {
    return [ResourceEnum.REPORT, ResourceEnum.DOCUMENT, ResourceEnum.SNAPSHOT];
  }

  get partnerId() {
    return this.$route.params.partnerId ?? PartnerModule.partner._id;
  }

  goToDetail(content: MrfiktivBaseCreateSharedContentDtoGen) {
    const goToHelper = new GoToHelper(this.$router);

    switch (content.refType as ResourceEnum) {
      case ResourceEnum.REPORT:
        goToHelper.goToReportDetail(content.refId, this.partnerId, true);
        break;
      case ResourceEnum.DOCUMENT:
        goToHelper.goToDocumentDetail(content.refId, this.partnerId, true);
        break;
      case ResourceEnum.SNAPSHOT:
        goToHelper.goToSnapshotDetailView(this.partnerId, content.refId, content.refId);
        break;
    }
  }
}
