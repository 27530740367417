var render = function () {
  var _this = this;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-report-step', {
    attrs: {
      "md": _vm.md,
      "isDone": _vm.isDone,
      "title": _vm.header.title,
      "description": _vm.header.description,
      "debug": [_vm.numberplate, _vm.user, _vm.contact, _vm.address]
    }
  }, [_c('v-card', {
    staticClass: "pt-4 pl-4 pr-4",
    attrs: {
      "flat": "",
      "max-width": "500"
    }
  }, [_c('v-form', {
    ref: "form",
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-subheader', [_vm._v(_vm._s(_vm.$t("report.formPersonalData.numberplateForm")))]), _c('numberplate-form', {
    attrs: {
      "valid": _vm.valid,
      "countryCode": _vm.countryCode
    },
    on: {
      "valid": _vm.setValid
    },
    model: {
      value: _vm.numberplate,
      callback: function callback($$v) {
        _vm.numberplate = $$v;
      },
      expression: "numberplate"
    }
  }), _c('v-subheader', [_vm._v(_vm._s(_vm.$t("report.formPersonalData.subtitle")))]), _c('v-text-field', {
    attrs: {
      "value": _vm.user.firstName,
      "label": _vm.$t('report.formPersonalData.firstName'),
      "rules": _vm.noNumberRule,
      "required": "",
      "data-test-form-firstName": "",
      "outlined": ""
    },
    on: {
      "blur": _vm.updateUserFirstName
    }
  }), _c('v-text-field', {
    attrs: {
      "value": _vm.user.lastName,
      "label": _vm.$t('report.formPersonalData.lastName'),
      "rules": _vm.noNumberRule,
      "required": "",
      "data-test-form-lastName": "",
      "outlined": ""
    },
    on: {
      "blur": _vm.updateUserLastName
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.$t('report.formPersonalData.mail'),
      "value": _vm.contact.email,
      "type": "email",
      "rules": _vm.emailRules,
      "autocomplete": "email",
      "required": "",
      "data-test-form-email": "",
      "outlined": ""
    },
    on: {
      "blur": _vm.updateUserEmail
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.$t('report.formPersonalData.phone'),
      "value": _vm.contact.phone,
      "type": "tel",
      "rules": _vm.phoneNumberRules,
      "required": "",
      "data-test-form-phone": "",
      "outlined": ""
    },
    on: {
      "blur": _vm.updateUserPhone
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.$t('report.formPersonalData.street'),
      "value": _vm.address.street,
      "rules": [function (v) {
        return !!v || _this.$t('report.formPersonalData.mandatoryInput');
      }],
      "required": "",
      "data-test-form-street": "",
      "outlined": ""
    },
    on: {
      "blur": _vm.updateUserStreet
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.$t('report.formPersonalData.zip'),
      "type": "number",
      "value": _vm.address.zip,
      "rules": _vm.zipRules,
      "required": "",
      "data-test-form-zip": "",
      "outlined": ""
    },
    on: {
      "blur": _vm.updateUserZip
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.$t('report.formPersonalData.city'),
      "value": _vm.address.city,
      "rules": _vm.noNumberRule,
      "required": "",
      "data-test-form-city": "",
      "outlined": ""
    },
    on: {
      "blur": _vm.updateUserCity
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }