var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-report-step', {
    attrs: {
      "isDone": _vm.isDone,
      "title": _vm.header.title,
      "description": _vm.header.description,
      "image": _vm.header.image,
      "debug": _vm.header.debug
    }
  }, [_c('v-card', {
    staticClass: "pt-4 pr-4 pl-4",
    attrs: {
      "flat": "",
      "max-width": "500"
    }
  }, [_c('form', [_c('v-date-picker', {
    attrs: {
      "allowed-dates": _vm.getAllowedDates,
      "color": _vm.color,
      "value": _vm.date,
      "first-day-of-week": "1",
      "full-width": "",
      "locale": _vm.$t('report.datePicker.locale')
    },
    on: {
      "change": _vm.updateDate
    }
  })], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }