













































































































































































































































































import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import Debug from "../utility/Debug.vue";
import { IHandover } from "@/models/handover.entity";
import { handleError } from "@/lib/utility/handleError";
import { IDialog } from "@/lib/interfaces/dialog.interface";
import { HandoverSnapshotModule } from "@/store/modules/handover-snapshot.store";
import { convertPdfToImg } from "@/lib/utility/convertPdfToImage";
import PdfViewerBase64 from "../files/PdfViewerBase64.vue";
import { ISnapshot, Snapshot } from "@/models/snapshot.entity";
import { requiredRule } from "@/lib/rules/requiredRule";
import VueSignaturePad from "vue-signature-pad";
import { ImageSwipeImage } from "@/lib/interfaces/vue-image-swipe.interface";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import Tooltip from "../utility/tooltip.vue";
import { convertDataURItoFile } from "@/lib/files/imageResizer";
import { $t } from "@/lib/utility/t";
import { createFileName, FileType } from "@/lib/utility/string-helper";

@Component({
  components: {
    ConfirmActionDialog,
    PdfViewerBase64,
    Debug,
    Tooltip
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class CreateHandoverSnapshotDialog extends Vue implements IDialog {
  isLoadingCreateSnapshot = false;

  isLoadingSignSnapshot = false;

  isDialogActive = false;

  isNewSignature = false;

  showToSnapshotButton = false;

  @Prop()
  value!: IHandover;

  @Ref("signaturePad")
  signaturePad!: VueSignaturePad;

  isFormValid = false;

  isLoadingPreview = false;

  previewImages: ImageSwipeImage[] = [];

  snapshotImages: ImageSwipeImage[] = [];

  signedSnapshotImages: ImageSwipeImage[] = [];

  step = 0;

  snapshot: ISnapshot = new Snapshot();

  location = "";

  createSignDto = {
    firstName: "",
    lastName: "",
    company: ""
  };

  signers: { image: File; firstName: string; lastName: string; location: string; company: string }[] = [];

  signatures: string[] = [];

  /**
   * Somehow the sign pad does not work initially. It does not show the drawings
   * a few ms after starting the sign pad the key is incremented, somehow this makes the sign pad work reliably
   */
  signPadMotivationalKey = 0;

  @Watch("isNewSignature")
  updateMotivationalKey() {
    setTimeout(() => {
      this.signPadMotivationalKey++;
    }, 100);
  }

  get width() {
    if (this.$vuetify.breakpoint.width * 0.8 > 400) {
      return 500;
    } else {
      return this.$vuetify.breakpoint.width * 0.8;
    }
  }

  get rightDisabled() {
    switch (this.step) {
      case 0:
        return false;
      case 1:
        return false;
      case 2:
        return !this.isFormValid;
      default:
        return false;
    }
  }

  get isLoading() {
    switch (this.step) {
      case 0:
        return this.isLoadingPreview;
      case 1:
        return this.isLoadingCreateSnapshot || this.isLoadingSignSnapshot;
      case 2:
        return this.isLoadingSignSnapshot;
      default:
        return false;
    }
  }

  get title() {
    switch (this.step) {
      case 0:
        if (!this.previewImages.length) return " ";
        return $t("snapshot.create");
      case 1:
        if (!this.snapshotImages.length) return " ";
        return $t("snapshot.sign");
      case 2:
        if (this.isLoadingSignSnapshot) return " ";
        return $t("snapshot.location");
      case 3:
        return $t("snapshot.signed");
      default:
        return "";
    }
  }

  get description() {
    switch (this.step) {
      case 0:
        if (!this.previewImages.length) return " ";
        return $t("snapshot.description");
      case 1:
        if (!this.snapshotImages.length) return " ";
        return $t("snapshot.signSubtitle");
      case 2:
        if (this.isLoadingSignSnapshot) return " ";
        return $t("snapshot.locationSubtitle");
      default:
        return "";
    }
  }

  get leftText() {
    switch (this.step) {
      case 0:
        return $t("close");
      case 1:
        return $t("close");
      case 2:
        return $t("back");
      case 3:
        return $t("close");
      default:
        return " ";
    }
  }

  get rightText() {
    switch (this.step) {
      case 0:
        return $t("snapshot.create");
      case 1:
        if (this.signers.length) return $t("snapshot.allSignaturesCollected");
        else if (this.showToSnapshotButton) return $t("snapshot.toSnapshot");
        return " ";
      case 2:
        return $t("snapshot.confirmSignature");
      case 3:
        return $t("snapshot.toSnapshot");
      default:
        return " ";
    }
  }

  close(): void {
    this.isDialogActive = false;
  }

  leftClick(): void {
    switch (this.step) {
      case 0:
        this.close();
        break;
      case 1:
        if (this.isNewSignature) this.isNewSignature = false;
        else this.close();
        break;
      case 2:
        this.decrementStep();
        break;
      case 3:
        this.close();
        break;
      default:
        this.decrementStep();
    }
  }

  async rightClick(): Promise<void> {
    switch (this.step) {
      case 0:
        this.incrementStep();
        await this.createSnapshot();
        break;
      case 1:
        if (this.isNewSignature) this.confirmSignature();
        else if (this.signers.length) this.incrementStep();
        else if (this.showToSnapshotButton) this.snapshot.goToDetail(this.$router);
        break;
      case 2:
        await this.signSnapshot();
        this.incrementStep();
        break;
      case 3:
        this.snapshot.goToDetail(this.$router);
        break;
    }
  }

  get overlayStyle() {
    if (this.$vuetify.theme.dark) {
      return "background-color:#1E1E1E;";
    } else {
      return "color: rgba(0, 0, 0, 0.6);";
    }
  }

  get requiredRule() {
    return [requiredRule()];
  }

  removeSignature(index: number) {
    this.signers.splice(index, 1);
    this.signatures.splice(index, 1);
  }

  reset() {
    this.location = "";
    this.isNewSignature = false;
    this.previewImages.splice(0);
    this.snapshotImages.splice(0);
    this.signedSnapshotImages.splice(0);
    this.signatures.splice(0);
    this.signers.splice(0);
  }

  async open(): Promise<void> {
    this.reset();
    this.showToSnapshotButton = true;
    this.step = 0;
    this.snapshot = new Snapshot({ partnerId: this.value.partnerId, handoverId: this.value.id });
    this.isDialogActive = true;
    await this.loadPreview();
  }

  async create(): Promise<void> {
    this.reset();
    this.showToSnapshotButton = true;
    this.step = 1;
    this.snapshot = new Snapshot({ partnerId: this.value.partnerId, handoverId: this.value.id });
    this.isDialogActive = true;
    await this.createSnapshot();
  }

  async openToSign(value: ISnapshot): Promise<void> {
    this.reset();
    this.showToSnapshotButton = false;
    this.step = 1;
    this.snapshot = value;
    this.isLoadingSignSnapshot = true;
    this.isDialogActive = true;
    await this.setSnapshotImages(value.documents[value.documents.length - 1].url).catch(handleError);
    this.isLoadingSignSnapshot = false;
  }

  incrementStep() {
    this.step++;
  }

  decrementStep() {
    this.step--;
  }

  clearSignPad() {
    this.signaturePad.clearSignature();
  }

  async createSnapshot() {
    this.isLoadingCreateSnapshot = true;
    try {
      this.snapshotImages.splice(0);

      await this.snapshot.create();

      await this.setSnapshotImages(this.snapshot.documents[0].url);

      this.$toast.success("👍");
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoadingCreateSnapshot = false;
    }
  }

  async setSnapshotImages(url: string) {
    this.snapshotImages.splice(0);
    const srcs = await convertPdfToImg(url);
    const images: ImageSwipeImage[] = srcs.map((src, index) => ({
      src: src,
      thumbnail: src,
      w: 0,
      h: 0,
      title: `${$t("sign.DocumentTemplateDetailTokenList.page")}: ${index + 1}`
    }));

    this.snapshotImages.splice(0, this.snapshotImages.length, ...images);
  }

  async confirmSignature() {
    const { isEmpty, data } = this.signaturePad.saveSignature();
    if (isEmpty) {
      this.$toast.error($t("snapshot.signatureRequired"));
      return;
    }
    const firstName = this.createSignDto.firstName;
    const lastName = this.createSignDto.lastName;
    const company = this.createSignDto.company;
    const fileName = createFileName(`${firstName}_${lastName}`, FileType.PNG);

    // Convert Blob to File
    const file = convertDataURItoFile(data, fileName);

    this.signers.push({
      firstName,
      lastName,
      company,
      location: "",
      image: file
    });
    this.signatures.push(data);

    this.createSignDto.firstName = "";
    this.createSignDto.lastName = "";
    this.createSignDto.company = "";

    this.clearSignPad();
    this.isNewSignature = false;
  }

  async signSnapshot() {
    this.isLoadingSignSnapshot = true;
    try {
      await this.snapshot.sign({
        signers: this.signers.map(signer => ({
          firstName: signer.firstName,
          lastName: signer.lastName,
          location: this.location,
          company: signer.company,
          image: signer.image
        }))
      });

      this.signedSnapshotImages.splice(0);
      const srcs = await convertPdfToImg(this.snapshot.documents[this.snapshot.documents.length - 1].url);
      const images: ImageSwipeImage[] = srcs.map((src, index) => ({
        src: src,
        thumbnail: src,
        w: 0,
        h: 0,
        title: `${$t("sign.DocumentTemplateDetailTokenList.page")}: ${index + 1}`
      }));
      this.signedSnapshotImages.splice(0, this.signedSnapshotImages.length, ...images);

      this.$toast.success("👍");
      this.$emit("signed");
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoadingSignSnapshot = false;
    }
  }

  async loadPreview() {
    try {
      this.isLoadingPreview = true;
      const pdf = await HandoverSnapshotModule.generatePdfPreview({
        handoverId: this.value.id,
        partnerId: this.value.partnerId
      });

      const srcs = await convertPdfToImg(new Uint8Array(pdf));
      const images: ImageSwipeImage[] = srcs.map((src, index) => ({
        src: src,
        thumbnail: src,
        w: 0,
        h: 0,
        title: `${$t("sign.DocumentTemplateDetailTokenList.page")}: ${index + 1}`
      }));

      this.previewImages.splice(0, this.previewImages.length, ...images);
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoadingPreview = false;
    }
  }
}
