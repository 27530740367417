














import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { AssetEnum } from "@/lib/AssetRepository";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportInstructionData } from "@/lib/interfaces/Report/IReportInstructionData";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import ReportStepMixin from "@/mixins/ReportStepMixin.vue";
import { IImageUploaded } from "@/models/Image/IImageUploaded";
import { ReportImageType } from "@/models/Report/ReportImageType";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { ReportModule } from "@/store/modules/report.store";
import { Component } from "vue-property-decorator";
import ReportImageUpload from "./ReportImageUpload.vue";

@Component({
  components: {
    LayoutReportStep,
    ReportImageUpload
  }
})
export default class ReportImageTuev extends ReportStepMixin implements IReportStep {
  asset = AssetEnum.tuev;

  screen = ReportScreenEnum.tuevimage;

  header: IReportHeaderData = {
    title: "report.imageTuev.title",
    description: "report.imageTuev.description",
    debug: this.files,
    image: AssetEnum.tuev
  };

  instruction: IReportInstructionData = {
    title: "",
    text: "",
    pictures: [this.asset],
    example: this.asset,
    display: false
  };

  get isDone() {
    return Boolean(this.files.length);
  }

  get color() {
    return getDefaultPartnerColor();
  }

  get type() {
    return ReportImageType.tuev;
  }

  get files(): IImageUploaded[] {
    return ReportModule.overviews.filter(image => image.type === this.type);
  }
}
