





































































































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { mixins } from "vue-class-component";
import { Component, Prop, Ref } from "vue-property-decorator";
import {
  MrfiktivCreateVehicleDtoGen,
  MrfiktivCreateVehicleRegistrationDtoGen,
  MrfiktivPageViewModelGen,
  MrfiktivReportViewModelGen,
  MrfiktivUpdateReportDtoGen,
  MrfiktivVehicleViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { VehicleModule } from "@/store/modules/vehicle.store";
import { handleError } from "@/lib/utility/handleError";
import { IVehicle, Vehicle } from "@/models/vehicle.entity";

import RefsVehicle from "../utility/RefsVehicle.vue";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import { PartnerModule } from "@/store/modules/partner";
import VehicleApiSelection from "../utility/VehicleApiSelection.vue";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { VehicleTabs } from "@/lib/enum/vehicle-tabs.enum";
import { requiredRule } from "@/lib/rules/requiredRule";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import vehicleService from "@/services/mrfiktiv/services/vehicleService";
import { VehicleStateEnum } from "@/lib/enum/vehicleState.enum";
import { Registration } from "@/models/registration.entity";
import { VehicleRegistration } from "@/models/vehicle-registration.entity";

@Component({
  components: { RefsVehicle, ConfirmActionDialog, VehicleApiSelection, LatestEntriesCardEmpty }
})
export default class VehicleTimeLineElement extends mixins(DarkModeHighlightMixin, PermissionMixin) {
  @Prop()
  report!: MrfiktivReportViewModelGen;

  loadingVehicle = false;

  loadingUpdate = false;

  vehicle: IVehicle | null = null;

  selectedVehicle: IVehicle | null = null;

  isUpdateVehicleDialog = false;

  isSelectionMade = false;

  vehicleSuggestions: IVehicle[] = [];

  isLoadingVehicleIdUpdate = false;

  selected: IVehicle | null = null;

  @Ref("vehicle-api-selection")
  selection!: VehicleApiSelection;

  get requiredRule() {
    return [requiredRule()];
  }

  async mounted() {
    await this.getVehicle();
  }

  async lookupVehicleSuggestions() {
    // user has no rights
    if (!this.canReadVehicle) return;

    this.isLoadingVehicleIdUpdate = true;

    const found: IVehicle[] = [];
    const promises = [];

    const identificationnumber = this.report.registrationResults.identificationnumber;
    const externalId = this.report.externalId;
    const numberplate = this.report.numberplate;

    // make sure results are unique
    const addToFound = (
      foundPage: MrfiktivPageViewModelGen & {
        data?: MrfiktivVehicleViewModelGen[] | undefined;
      }
    ) => {
      for (const newFound of foundPage.data ?? []) {
        if (!found.find(foundElement => newFound.id === foundElement.id)) {
          found.push(new Vehicle(newFound));
        }
      }
    };

    if (identificationnumber) {
      promises.push(
        await vehicleService
          .getAll({
            partnerId: this.report.partnerId,
            filter: [{ key: "identificationnumber", operation: "$eq", value: identificationnumber }]
          })
          .then(addToFound)
          .catch(handleError)
      );
    }
    if (externalId) {
      promises.push(
        await vehicleService
          .getAll({
            partnerId: this.report.partnerId,
            filter: [{ key: "displayName", operation: "$eq", value: externalId }]
          })
          .then(addToFound)
          .catch(handleError)
      );
    }
    if (numberplate) {
      promises.push(
        await vehicleService
          .getAll({
            partnerId: this.report.partnerId,
            filter: [{ key: "numberplate", operation: "$eq", value: numberplate }]
          })
          .then(addToFound)
          .catch(handleError)
      );
    }

    await Promise.all(promises);

    this.vehicleSuggestions.splice(0, this.vehicleSuggestions.length, ...found);
    this.isLoadingVehicleIdUpdate = false;
  }

  async getVehicle() {
    const vehicleId = this.report.vehicleId;
    if (!vehicleId) return;

    this.loadingVehicle = true;

    try {
      this.vehicle = await VehicleModule.getOne({ partnerId: this.report.partnerId, vehicleId: vehicleId });

      this.$nextTick(() => {
        if (this.vehicle && this.selection) {
          this.selection.selected = this.vehicle?.id ?? null;
          if (!this.selection.items?.find(i => i.id === this.vehicle?.id)) {
            this.selection.items?.push(this.vehicle);
          }
        }
      });
    } catch (error) {
      this.$log.error(error);
    } finally {
      this.loadingVehicle = false;
    }
  }

  changeVehicleReference() {
    this.isUpdateVehicleDialog = true;
    this.isSelectionMade = false;

    this.lookupVehicleSuggestions();

    // Preselect current vehicle
    this.getVehicle();
  }

  async openVehicleDetail(vehicle: IVehicle) {
    if (!vehicle) return;

    await new GoToHelper(this.$router).goToVehicleDetail(vehicle.id, vehicle.partnerId, VehicleTabs.HOME);
  }

  async createNewVehicle() {
    const partnerId = this.report.partnerId;
    const data: MrfiktivCreateVehicleDtoGen = {
      identificationnumber: this.report.registrationResults.identificationnumber,
      displayName: this.report.numberplate,
      state: VehicleStateEnum.INACTIVE,
      mileages: [],
      numberplate: this.report.numberplate,
      registration: new VehicleRegistration(this.report.registrationResults) as MrfiktivCreateVehicleRegistrationDtoGen
    };

    try {
      this.isLoadingVehicleIdUpdate = true;

      const vehicle = await VehicleModule.create({ partnerId, data });

      await this.select(vehicle);

      this.isUpdateVehicleDialog = false;
      this.report.vehicleId = vehicle.id;
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoadingVehicleIdUpdate = false;
    }
  }

  select(selected?: IVehicle) {
    this.selected = selected ?? null;
    this.isSelectionMade = true;
  }

  async confirm() {
    try {
      const selected = this.selected;

      this.isLoadingVehicleIdUpdate = true;

      const registrationDto = new Registration({
        ...selected?.registration,
        numberplate: selected?.numberplate ?? selected?.registration?.numberplate,
        identificationnumber: selected?.identificationnumber ?? selected?.registration?.identificationnumber
      });

      const updateReportDto = {
        numberplate: selected?.numberplate,
        vehicleId: selected?.id || null,
        registrationResults: registrationDto
      } as MrfiktivUpdateReportDtoGen;

      const updated = await PartnerModule.report.updateReport(updateReportDto);

      this.isUpdateVehicleDialog = false;
      this.report = updated;
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoadingVehicleIdUpdate = false;
    }
  }
}
