








import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { MrfiktivReportViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { Component, Prop } from "vue-property-decorator";

@Component({
  components: {}
})
export default class ReportPoliceDetailsTimeLineElement extends DarkModeHighlightMixin {
  @Prop()
  report!: MrfiktivReportViewModelGen;

  get isRecorded() {
    return this.report.policeDetails?.isRecorded;
  }

  get department() {
    return this.report.policeDetails?.department || this.$t("timeLine.PartnerPoliceDetails.noData");
  }

  get fileReference() {
    return this.report.policeDetails?.fileReference || this.$t("timeLine.PartnerPoliceDetails.noData");
  }
}
