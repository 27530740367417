









import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class TimeLineItemText extends Vue {
  @Prop()
  icon!: string;

  @Prop()
  color!: string;

  @Prop()
  text!: string;
}
