/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  SignDigitalSignatureRequestSignerViewModelGen,
  SignDigitalSignatureRequestViewModelGen,
  SignDocumentViewModelGen,
  SignExceptionViewmodelGen
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class MyDigitalSignatureService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags My Digital Signature Service
   * @name SignRequestMeControllerFindAllSignedRequestsForUser
   * @summary Get all signature requests for a given user
   * @request GET:/sign-request/me
   * @secure
   * @response `200` `(SignDigitalSignatureRequestSignerViewModelGen)[]` The signature requests for the user
   * @response `401` `SignExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `SignExceptionViewmodelGen` Forbidden.
   * @response `500` `SignExceptionViewmodelGen` Internal Server Error
   */
  signRequestMeControllerFindAllSignedRequestsForUser = (params: RequestParams = {}) =>
    this.http.request<SignDigitalSignatureRequestSignerViewModelGen[], SignExceptionViewmodelGen>({
      path: `/sign-request/me`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags My Digital Signature Service
   * @name SignRequestMeControllerFindSignedRequestForUser
   * @summary Get a signature request by id for a user
   * @request GET:/sign-request/me/{signatureRequestId}
   * @secure
   * @response `200` `SignDigitalSignatureRequestViewModelGen` The signature requests for the user
   * @response `400` `SignExceptionViewmodelGen` Bad Request
   * @response `401` `SignExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `SignExceptionViewmodelGen` Forbidden.
   * @response `404` `SignExceptionViewmodelGen` NotFound.
   * @response `500` `SignExceptionViewmodelGen` Internal Server Error
   */
  signRequestMeControllerFindSignedRequestForUser = (signatureRequestId: string, params: RequestParams = {}) =>
    this.http.request<SignDigitalSignatureRequestViewModelGen, SignExceptionViewmodelGen>({
      path: `/sign-request/me/${signatureRequestId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags My Digital Signature Service
   * @name SignRequestMeControllerFindMyDocumentsForSignatureRequest
   * @summary Gets all documents for a signature request by id for a given user
   * @request GET:/sign-request/me/{signatureRequestId}/document
   * @secure
   * @response `200` `(SignDocumentViewModelGen)[]` The documents assigned to signature requests for the given user
   * @response `400` `SignExceptionViewmodelGen` Bad Request
   * @response `401` `SignExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `SignExceptionViewmodelGen` Forbidden.
   * @response `500` `SignExceptionViewmodelGen` Internal Server Error
   */
  signRequestMeControllerFindMyDocumentsForSignatureRequest = (
    signatureRequestId: string,
    params: RequestParams = {}
  ) =>
    this.http.request<SignDocumentViewModelGen[], SignExceptionViewmodelGen>({
      path: `/sign-request/me/${signatureRequestId}/document`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
}
