var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-list-item', [_vm._t("icon"), _c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "text-truncate "
  }, [_vm.hasOpenDetailListener() ? _c('a', {
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.emitOpenDetail.apply(null, arguments);
      }
    }
  }, [_vm._v(" #" + _vm._s(_vm.item.number) + " " + _vm._s(_vm.item.title || "-") + " ")]) : _c('span', [_vm._v(" #" + _vm._s(_vm.item.number) + " " + _vm._s(_vm.item.title || "-") + " ")]), _c('span', [_c('v-chip', {
    staticClass: "mx-1",
    attrs: {
      "x-small": "",
      "color": _vm.item.state === _vm.HandoverStateEnum.OPEN ? 'success' : ''
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("objects.handover.states." + _vm.item.state)) + " ")])], 1)]), !_vm.small && _vm.companies.length ? _c('v-list-item-subtitle', {
    staticClass: "text-xs"
  }, [_c('v-icon', {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("mdi-domain")]), _vm._v(" " + _vm._s(_vm.companies.join(", ")) + " ")], 1) : _vm._e(), !_vm.small && _vm.names.length ? _c('v-list-item-subtitle', {
    staticClass: "text-xs"
  }, [_c('v-icon', {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("mdi-account")]), _vm._v(" " + _vm._s(_vm.names.join(", ")) + " ")], 1) : _vm._e()], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }