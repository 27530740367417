import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { IEntity } from "@/lib/utility/data/entity.interface";
import { handleError } from "@/lib/utility/handleError";
import { SharingLinkFactory } from "@/lib/utility/sharingLinkFactory";
import { $t } from "@/lib/utility/t";
import sharedContentService from "@/services/mrfiktiv/services/sharedContentService";
import {
  MrfiktivBaseCreateSharedContentDtoGen,
  MrfiktivSharedContentViewmodelGen,
  MrfiktivTimestampGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { SharedContentDataAccessLayer } from "@/store/modules/access-layers/shared-content.access-layer";
import Vue from "vue";
import { Reference } from "./reference.entity";
import { Timestamp } from "./timestamp.entity";

@IsFilterable
class SharedContentEntity implements MrfiktivSharedContentViewmodelGen, Partial<IEntity<ISharedContent>> {
  id: string;

  @FilterConfig({
    type: FilterTypes.BOOLEAN,
    displayName: "objects.sharedContent.isSystemGenerated"
  })
  isSystemGenerated?: boolean | undefined;

  partnerId: string;

  userId?: string | undefined;

  refId?: string | undefined;

  refType?: BackendResourceEnum;

  @FilterConfig({
    type: Reference
  })
  refs: MrfiktivBaseCreateSharedContentDtoGen[];

  @FilterConfig({
    type: FilterTypes.DATE,
    displayName: "objects.sharedContent.validBy"
  })
  validBy: string | null;

  token: string;

  @FilterConfig({
    type: Timestamp
  })
  timestamp: MrfiktivTimestampGen;

  isLoading = false;

  isNotFound = false;

  title = "";

  description = "";

  get isUpdateable(): boolean {
    return true;
  }

  get isExpired() {
    if (!this.validBy) return false;

    return new Date(this.validBy) < new Date();
  }

  get url() {
    return new SharingLinkFactory(this, false).createUrlWithQuery;
  }

  constructor(sharedContent?: Partial<SharedContentEntity | MrfiktivSharedContentViewmodelGen>) {
    this.id = sharedContent?.id || "";
    this.isSystemGenerated = sharedContent?.isSystemGenerated || false;
    this.partnerId = sharedContent?.partnerId || "";
    this.userId = sharedContent?.userId;
    this.refId = sharedContent?.refId || "";
    this.refType = sharedContent?.refType as BackendResourceEnum | undefined;
    this.refs = sharedContent?.refs || [];
    this.validBy = sharedContent?.validBy || null;
    this.token = sharedContent?.token || "";
    this.timestamp = sharedContent?.timestamp || {
      created: "",
      lastModified: ""
    };
    this.title = sharedContent?.title || $t("timeLine.ActivityTimeLineItemDocument.sharedDocument");
    this.description = sharedContent?.description || "";

    this.isLoading = (sharedContent as SharedContentEntity)?.isLoading || false;
    this.isNotFound = (sharedContent as SharedContentEntity)?.isNotFound || false;
  }

  private map(sharedContent: MrfiktivSharedContentViewmodelGen) {
    if (sharedContent.id) this.id = sharedContent.id;
    if (sharedContent.isSystemGenerated) this.isSystemGenerated = sharedContent.isSystemGenerated;
    if (sharedContent.partnerId) this.partnerId = sharedContent.partnerId;
    if (sharedContent.refId) this.refId = sharedContent.refId;
    if (sharedContent.refType) this.refType = sharedContent.refType as BackendResourceEnum;
    if (sharedContent.refs) this.refs.splice(0, this.refs.length, ...sharedContent.refs);
    if (sharedContent.validBy) this.validBy = sharedContent.validBy;
    if (sharedContent.token) this.token = sharedContent.token;
    if (sharedContent.timestamp) this.timestamp = sharedContent.timestamp;
    if (sharedContent.userId) this.userId = sharedContent.userId;
    if (sharedContent.title)
      this.title = sharedContent.title || $t("timeLine.ActivityTimeLineItemDocument.sharedDocument");
    if (sharedContent.description) this.description = sharedContent.description;
  }

  async fetch(silent?: boolean): Promise<this> {
    this.isLoading = true;
    try {
      const fetched = await sharedContentService.get(this.partnerId, this.id);
      this.map(fetched);
      SharedContentDataAccessLayer.set(this);
    } catch (e) {
      if (silent) Vue.$log.error(e);
      else handleError(e);
      this.isNotFound = true;
    } finally {
      this.isLoading = false;
    }

    return this;
  }

  async delete(): Promise<void> {
    this.isLoading = true;
    await sharedContentService.delete(this.partnerId, this.id).catch(handleError);

    SharedContentDataAccessLayer.delete(this);

    this.isLoading = false;
  }

  copyLinkToClipboard(): void {
    try {
      new SharingLinkFactory(this, false).copyLinkToClipboard();
      Vue.$toast.info($t("components.partner.PartnerReportInitializeCard.linkCopied"));
    } catch (error) {
      Vue.$log.error(error);
      Vue.$toast.error($t("components.partner.PartnerReportInitializeCard.error"));
    }
  }

  async update() {
    this.isLoading = true;
    await sharedContentService
      .update(this.partnerId, this.id, {
        refs: this.refs,
        validBy: this.validBy,
        title: this.title,
        description: this.description
      })
      .then(b => this.map(b))
      .catch(handleError);

    SharedContentDataAccessLayer.set(this);

    this.isLoading = false;

    return this;
  }

  async deactivate() {
    this.validBy = new Date().toISOString();

    return this.update();
  }
}
type ISharedContent = SharedContentEntity;
const SharedContent = Filter.createForClass(SharedContentEntity);

export { ISharedContent, SharedContent };
