













































































































































import ReportImageGallery from "@/components/report/ReportImageGallery.vue";
import FileDownload from "@/components/utility/FileDownload.vue";
import { AssetEnum, AssetRepository } from "@/lib/AssetRepository";
import { handleError } from "@/lib/utility/handleError";
import { isMobile } from "@/lib/utility/isMobile";
import { mapBaseImageToDownloadFile } from "@/lib/utility/map-base-image-to-download-file";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IBaseImage } from "@/models/caseEntity";
import { IImageUploaded } from "@/models/Image/IImageUploaded";
import { ReportImageEntity } from "@/models/reportImagesEntity";
import {
  MrfiktivImageEditDataDtoGen,
  MrfiktivUpdateReportImagesDtoGen,
  MrfiktivReportViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { PartnerModule } from "@/store/modules/partner";
import ReportImageUpload from "@/views/report/ReportImageUpload.vue";
import debounce from "debounce";
import { mixins } from "vue-class-component";
import { Component, Prop, Watch } from "vue-property-decorator";
import CameraBottomSheet from "../camera/CameraBottomSheet.vue";
import CameraDialog from "../camera/CameraDialog.vue";
import ImageUploadButton from "../files/ImageUploadButton.vue";
import PdfUploadDialog from "../files/PdfUploadDialog.vue";
import Card from "../utility/Card.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import { IDownloadFile } from "../utility/interface/download-file.interface";
import TimelineCard from "../utility/TimelineItem.vue";
import Tooltip from "../utility/tooltip.vue";
import PartnerReportDetailImageUpload from "./PartnerReportDetailImageUpload.vue";

@Component({
  components: {
    TimelineCard,
    ReportImageGallery,
    FileDownload,
    Card,
    ConfirmActionDialog,
    ReportImageUpload,
    CameraDialog,
    PdfUploadDialog,
    ImageUploadButton,
    CameraBottomSheet,
    Tooltip,
    PartnerReportDetailImageUpload
  }
})
export default class PartnerReportDetailImageTimeLineElement extends mixins(DarkModeHighlightMixin, PermissionMixin) {
  @Prop({})
  images!: ReportImageEntity;

  @Prop()
  report!: MrfiktivReportViewModelGen;

  readonly all = "all";

  selectedImageType = this.all;

  activeImage = "";

  isDeleteDialogActive = false;

  isImageDialogActive = false;

  imageDialogIndex = -1;

  isUploading = false;

  isDeletionLoading = false;

  isRotationLoading = false;

  isEditActive = false;

  selectedForDeletion: string[] = [];

  rotation = 0;

  imageTypes: string[] = [this.all];

  selectedImageTypes: string[] = [this.all];

  get zipPrefix() {
    if (this.report?.numberplate) {
      return this.report.numberplate;
    }
    return "";
  }

  get imagesHaveId() {
    for (const i of this.mappedImages) {
      if (i.id) {
        return true;
      }
    }

    return false;
  }

  get empty() {
    return AssetRepository.getAsset(false, AssetEnum.emptyData);
  }

  get showSelectableGallery() {
    return !(this.isMobileDevice && !this.isEditActive);
  }

  get isMobileDevice() {
    return isMobile();
  }

  get downloadableFiles(): IDownloadFile[] {
    const imagesToDownload: IDownloadFile[] = [];
    if (this.imagesHaveId) {
      const images = this.filteredImages.filter(i => this.selectedForDeletion.includes(i.url));
      mapBaseImageToDownloadFile(imagesToDownload, images, "");
    } else {
      mapBaseImageToDownloadFile(imagesToDownload, this.images.registrations, "fahrzeugschein");
      mapBaseImageToDownloadFile(imagesToDownload, this.images.overviews, "uerbersicht");
      mapBaseImageToDownloadFile(imagesToDownload, this.images.damages, "schaden");
      mapBaseImageToDownloadFile(imagesToDownload, this.images.damagesDetail, "detail");
      mapBaseImageToDownloadFile(imagesToDownload, this.images.cockpits, "cockpit");
    }

    return imagesToDownload;
  }

  get mappedImages() {
    return [
      ...(this.images.registrations || []),
      ...(this.images.cockpits || []),
      ...(this.images.damages || []),
      ...(this.images.damagesDetail || []),
      ...(this.images.overviews || []),
      ...(this.images.plates || [])
    ];
  }

  get filteredImages() {
    this.setImageTypes();
    if (!this.isEditActive) {
      return this.mappedImages.filter(
        i => this.selectedImageTypes.includes((i.type as any) as string) || this.selectedImageTypes.includes(this.all)
      );
    }
    return this.mappedImages;
  }

  get imageIds() {
    const ids: string[] = [];

    this.filteredImages.forEach(i => {
      if (i.id) {
        ids.push(i.id);
      }
    });

    return ids;
  }

  setSelectedImageUrls() {
    const urls: string[] = [];
    this.filteredImages.forEach(i => {
      if (
        i.url &&
        (this.selectedImageTypes.includes((i.type as any) as string) || this.selectedImageTypes.includes(this.all))
      ) {
        urls.push(i.url);
      }
    });

    this.selectedForDeletion.splice(0, this.selectedForDeletion.length, ...urls);
  }

  getImageRotationStyle(image: IBaseImage) {
    const rotation = image.editData?.rotation || 0;

    return `transform: rotate(${rotation}deg)`;
  }

  rotateImage(rotation: number) {
    rotation = this.rotation + rotation;
    rotation = rotation % 360;

    if (rotation < 0) {
      rotation += 360;
    }

    this.rotation = rotation;

    this.debounceRotate(this.rotation)();
  }

  debounceRotate = (rotation: number) => debounce(() => this.rotate(rotation), 200, false);

  @Watch("imageDialogIndex")
  changeImageIndex() {
    this.rotation = this.filteredImages[this.imageDialogIndex]?.editData?.rotation || 0;
  }

  async rotate(rotation: number) {
    this.isRotationLoading = true;
    const imageId = this.filteredImages[this.imageDialogIndex]?.id || "";
    const data: MrfiktivImageEditDataDtoGen = { rotation };

    await PartnerModule.report.editReportImages(imageId, data).catch(handleError);

    this.isRotationLoading = false;
  }
  startEdit() {
    this.setSelectedImageUrls();
    this.isEditActive = true;
  }

  isSelectedForDeletion(url: string) {
    return Boolean(this.selectedForDeletion.find(s => s === url));
  }
  toggleSelectForDeletion(url: string) {
    const index = this.selectedForDeletion.findIndex(s => s === url);
    if (index > -1) {
      this.selectedForDeletion.splice(index, 1);
    } else {
      this.selectedForDeletion.push(url);
    }
  }

  mounted() {
    this.setSelectedImageUrls();
  }

  abortDeletion() {
    this.setSelectedImageUrls();
    this.isEditActive = false;
  }

  startDeleteDialog() {
    this.isDeleteDialogActive = true;
  }

  onImageClick(url: string) {
    if (this.isEditActive) {
      this.toggleSelectForDeletion(url);
    } else {
      this.goFullscreen(url);
    }
  }

  mouseover(url?: string) {
    this.activeImage = url || "";
  }

  mouseleave() {
    this.activeImage = "";
  }

  goFullscreen(url: string) {
    this.imageDialogIndex = this.filteredImages.findIndex(i => i.url === url);
    this.rotation = this.filteredImages[this.imageDialogIndex]?.editData?.rotation || 0;
    // open image in swiper
    (document.querySelector(`[src="${url}"]`) as any)?.click();
    this.isImageDialogActive = true;
  }

  closeImageDialog() {
    this.isImageDialogActive = false;
  }

  async handleUpload(uploaded: IImageUploaded) {
    this.assertIsString(uploaded.type);
    const imageType = uploaded.type;
    if (!this.selectedImageTypes.includes(imageType)) {
      // this.selectedImageTypes.push(imageType);
    }
    if (!this.imageTypes.includes(imageType)) {
      this.imageTypes.push(imageType);
    }
  }

  async deleteImage() {
    this.mouseleave();
    this.isDeletionLoading = true;

    const deletedTypes: string[] = [];
    const remainingImageIds: string[] = [];
    const remainingImageTypes: string[] = [];
    this.filteredImages?.forEach(i => {
      this.assertIsString(i.type);
      if (!this.selectedForDeletion.includes(i.url) && i.id) {
        remainingImageIds.push(i.id);
        remainingImageTypes.push(i.type);
      } else {
        if (!deletedTypes.includes(i.type)) {
          deletedTypes.push(i.type);
        }
      }
    });

    await this.update(remainingImageIds);

    // remove types if they are not available anymore because we deleted the pictures of that type
    for (const deletedType of deletedTypes) {
      if (!remainingImageTypes.includes(deletedType)) {
        const selectionIndex = this.selectedImageTypes.findIndex(t => t === deletedType);
        this.selectedImageTypes.splice(selectionIndex, 1, this.all);
        const typeIndex = this.imageTypes.findIndex(t => t === deletedType);
        this.imageTypes.splice(typeIndex, 1);
      }
    }

    // Reset state after successful delete
    this.selectedForDeletion.splice(0);
    this.isDeletionLoading = false;
    this.isDeleteDialogActive = false;
    this.isEditActive = false;
  }

  async update(imageIds: string[]) {
    const updateDto: MrfiktivUpdateReportImagesDtoGen = { imageIds };

    await PartnerModule.report.updateReportImages(updateDto).catch(handleError);
  }

  get mapToImage() {
    return this.filteredImages.map(i => {
      return {
        src: i.url,
        thumbnail: i.url,
        w: 0,
        h: 0,
        title: this.$t(`timeLine.PartnerReportDetailImageTimeLineElement.imageTypes.${i.type}`)
      };
    });
  }

  @Watch("images")
  setImageTypes() {
    // this.selectedImageTypes = [this.all];
    this.imageTypes.splice(
      0,
      this.imageTypes.length,
      this.all,
      ...new Set(this.mappedImages.map(i => (i.type as any) as string))
    );
  }

  assertIsString(s: any): asserts s is string {
    if (typeof s !== "string") {
      throw Error(`${s} not string`);
    }
  }
}
