/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { ProgressStatusEnum } from "../enum/partner/progress.status.enum";

/**
 * @description
 * Icons displayed on PartnerReportList depending on status of report
 */
export const reportStatusIconMap = new Map<ProgressStatusEnum, string>([
  [ProgressStatusEnum.DRAFT, "mdi-moon-new"],
  [ProgressStatusEnum.NEW, "mdi-moon-full"],
  [ProgressStatusEnum.IN_PROGRESS, "mdi-circle-half-full"],
  [ProgressStatusEnum.FINISHED, "mdi-check-circle-outline"],
  [ProgressStatusEnum.DELETED, "mdi-trash-can-outline"]
]);

export const reportStateMap = new Map<ProgressStatusEnum, { color: string; label: string }>([
  [ProgressStatusEnum.DRAFT, { color: "teal lighten-5", label: "Draft" }],
  [ProgressStatusEnum.NEW, { color: "blue-grey lighten-4", label: "Open" }],
  [ProgressStatusEnum.IN_PROGRESS, { color: "green lighten-1", label: "In Progress" }],
  [ProgressStatusEnum.FINISHED, { color: "deep-purple accent-2", label: "Done" }],
  [ProgressStatusEnum.DELETED, { color: "deep-purple lighten-4", label: "Archived" }]
]);
