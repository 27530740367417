import { MrfiktivHttpClientProvider } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import { Snapshot } from "../v1/Snapshot";
import { MrfiktivSignSnapshotDtoGen, MrfiktivSnapshotViewModelGen } from "../v1/data-contracts";
import { Utils } from "@/lib/utility/utils";
import { HttpClient } from "../v1/http-client";

/**
 * Communicate with snapshot endpoints
 */
class SnapshotService {
  client: HttpClient;
  mrfiktivProxy: Snapshot;

  /**
   * Construct service
   */
  constructor(mrfiktivHttpClientProvider: MrfiktivHttpClientProvider) {
    this.client = mrfiktivHttpClientProvider.client();
    this.mrfiktivProxy = new Snapshot(this.client);
  }

  async createForHandover(partnerId: string, handoverId: string) {
    return (await this.mrfiktivProxy.snapshotControllerCreate(partnerId, handoverId)).data;
  }

  async findAllByHandoverId(partnerId: string, handoverId: string) {
    return (await this.mrfiktivProxy.snapshotControllerFindAll(partnerId, handoverId)).data;
  }

  async signSnapshot(
    partnerId: string,
    handoverId: string,
    snapshotId: string,
    data: MrfiktivSignSnapshotDtoGen
  ): Promise<MrfiktivSnapshotViewModelGen> {
    const form = new FormData();
    Utils.buildFormData(form, data);

    // Does not work.. The Files are not processed well in the form data builder of the generated client
    // return (await this.mrfiktivProxy.snapshotControllerSign(partnerId, handoverId, snapshotId, data)).data;

    return (
      await this.client.instance.post(`/partner/${partnerId}/handover/${handoverId}/snapshot/${snapshotId}/sign`, form)
    ).data;
  }

  async findOneByHandoverId(partnerId: string, handoverId: string, snapshotId: string) {
    return (await this.mrfiktivProxy.snapshotControllerFindOne(partnerId, handoverId, snapshotId)).data;
  }

  async generatePdfPreview(partnerId: string, handoverId: string): Promise<Buffer> {
    const bufferObject = (await this.mrfiktivProxy.snapshotControllerGetPdf(partnerId, handoverId)).data;

    return Buffer.from(Object.values(bufferObject));
  }

  /**
   * Gets a document by shared access signature
   *
   * @param partnerId
   * @param documentId
   * @param token
   * @returns
   */
  async getDocumentBySharedAccessToken(
    partnerId: string,
    snapshotId: string,
    token: string
  ): Promise<MrfiktivSnapshotViewModelGen> {
    const shared = await this.mrfiktivProxy.snapshotControllerFindOneWithAccessSignature({
      partnerId,
      snapshotId,
      sct: token
    });

    return shared.data;
  }
}

/**
 * Communicate with snapshot endpoints
 */
export default new SnapshotService(new MrfiktivHttpClientProvider());
