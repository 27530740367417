import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { ISharedContent, SharedContent } from "@/models/shared-content.entity";
import sharedContentService from "@/services/mrfiktiv/services/sharedContentService";
import { MrfiktivSharedContentControllerGetAllForPartnerParamsGen } from "@/services/mrfiktiv/v1/data-contracts";
import { Module, getModule } from "vuex-module-decorators";
import { PaginatedBaseStore } from "../paginated-base.store";
import { SharedContentDataAccessLayer } from "./access-layers/shared-content.access-layer";
import { PaginationFilterListElement } from "./base-pagination.store";
import store from "@/store/VuexPlugin";

export class SharedContentPageDataProvider extends AbstractPageDataProvider<
  ISharedContent,
  MrfiktivSharedContentControllerGetAllForPartnerParamsGen
> {
  async getPage(
    query: MrfiktivSharedContentControllerGetAllForPartnerParamsGen
  ): Promise<IPageViewModel<ISharedContent>> {
    const res = await sharedContentService.getSharedContentPaginated(query);

    if (!res.data) {
      res.data = [];
    }

    return res as IPageViewModel<ISharedContent>;
  }

  getIdentifier(entity: ISharedContent): string {
    return entity.id;
  }
}

@Module({
  dynamic: true,
  namespaced: true,
  name: "sharedContentPaginationStore",
  store
})
export class SharedContentStore extends PaginatedBaseStore<
  ISharedContent,
  MrfiktivSharedContentControllerGetAllForPartnerParamsGen
> {
  _data = SharedContentDataAccessLayer;
  _pageProvider = new SharedContentPageDataProvider();
  _pager = new PageDataHandler<ISharedContent, MrfiktivSharedContentControllerGetAllForPartnerParamsGen>(
    this._data,
    this._pageProvider
  );

  filterOptions: PaginationFilterListElement[] = SharedContent.filterables;
}

export const SharedContentModule = getModule(SharedContentStore);
