var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-col', {
    staticClass: "d-flex align-center",
    attrs: {
      "cols": "12",
      "lg": "4",
      "md": "6",
      "sm": "6"
    }
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": "",
      "disabled": !_vm.tooltip
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-avatar', _vm._g(_vm._b({
          staticClass: "elevation-1",
          attrs: {
            "size": "44",
            "color": _vm.color,
            "rounded": ""
          }
        }, 'v-avatar', attrs, false), on), [_c('v-icon', {
          attrs: {
            "dark": "",
            "color": "white",
            "size": "30"
          },
          on: {
            "click": _vm.handleClick
          }
        }, [_vm._v(_vm._s(_vm.icon))])], 1)];
      }
    }])
  }, [_vm.tooltip ? _c('span', [_vm._v(_vm._s(_vm.$t(_vm.tooltip)))]) : _vm._e()]), _c('div', {
    staticClass: "ms-3"
  }, [_c('h2', {
    staticClass: "text-4xl font-weight-semibold"
  }, [_vm._v(" " + _vm._s(_vm.data) + " ")]), _c('p', {
    staticClass: "text-xs mb-0"
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.title)) + " ")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }