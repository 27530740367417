























import { Component, Prop, Vue } from "vue-property-decorator";
import { MrfiktivExportReportMetaViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import Card from "../utility/Card.vue";
import { ExportReportMetaTypeMap, ExportReportMetaTypeEnum } from "@/lib/enum/export-report-meta-type.enum";
import { detailedDate } from "@/lib/utility/date-helper";
import TimelineCard from "../utility/TimelineItem.vue";

@Component({
  components: {
    TimelineCard,
    Card
  }
})
export default class PartnerReportDetailMetaTimeLineElement extends Vue {
  @Prop({})
  meta!: MrfiktivExportReportMetaViewModelGen;

  get headers() {
    return [
      { text: "Schlüssel", value: "key" },
      { text: "Wert", value: "value" }
    ];
  }

  get exportSystemName() {
    return ExportReportMetaTypeMap.get(this.meta.exportReportMetaType as ExportReportMetaTypeEnum);
  }

  get metaData() {
    const data: { key: string; value: string }[] = [];

    for (const metaKey of Object.keys(this.meta.meta)) {
      data.push({ key: metaKey, value: this.meta.meta[metaKey] });
    }
    return data;
  }

  get date() {
    if (this.meta?.timestamp?.created) {
      const locale = this.$t("utility.toLocalDateString").toString() || "de-de";
      return detailedDate(this.meta?.timestamp?.created, locale);
    }
    return "";
  }
}
