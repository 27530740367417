import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { CostGroup, ICostGroup } from "@/models/cost-group.entity";

export const CostGroupDataAccessLayer = new (class extends AbstractLocalDataAccessLayer<ICostGroup> {
  protected mapEntity(entity: ICostGroup): ICostGroup {
    return new CostGroup(entity);
  }

  getIdentifier(entity: ICostGroup): string {
    return entity.id;
  }

  mapsConfig = {};
})();
