


































import PartnerDetailAttributeWithToolTip from "@/components/partner/PartnerDetailAttributeWithToolTip.vue";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { MrfiktivBaseImageGen } from "@/services/mrfiktiv/v1/data-contracts";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import FileDownload from "../utility/FileDownload.vue";
import TimelineCard from "../utility/TimelineItem.vue";

@Component({
  components: {
    TimelineCard,
    FileDownload,
    PartnerDetailAttributeWithToolTip,
    ConfirmActionDialog
  }
})
export default class PartnerReportDetailInfoWitnessTimeLineElementDialog extends DarkModeHighlightMixin {
  isDialogActive = false;

  @Prop()
  image!: MrfiktivBaseImageGen;

  download() {
    (this.$refs.download as FileDownload).download();
  }
}
