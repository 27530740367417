












import InsurancesForm from "@/components/report/InsurancesForm.vue";
import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import ReportStepMixin from "@/mixins/ReportStepMixin.vue";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    LayoutReportStep,
    InsurancesForm
  }
})
export default class ReportInsurance extends ReportStepMixin implements IReportStep {
  header: IReportHeaderData = {
    title: "report.insurance.title",
    description: "report.insurance.description"
  };

  screen = ReportScreenEnum.insurance;

  valid = false;

  get isDone() {
    return this.valid;
  }

  setValid(valid: boolean) {
    this.valid = valid;
  }
}
