















import { activityLogName } from "@/lib/utility/activityLogName";
import { detailedDate } from "@/lib/utility/date-helper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { MrfiktivActivityLogViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ActivityTimeLineItemDelete from "./ActivityTimeLineItemDelete.vue";
import ContextMenu from "../utility/ContextMenu.vue";
import { CostModule } from "@/store/modules/cost.store";
import { Cost, ICost } from "@/models/cost.entity";
import { handleError } from "@/lib/utility/handleError";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import TimelineCard from "../utility/TimelineItem.vue";
import RefsCost from "../utility/RefsCost.vue";
import CostSideCard from "../cost/CostSideCard.vue";
import { CostGoToHelper } from "@/lib/utility/cost.go-to-helper";

@Component({
  components: { ActivityTimeLineItemDelete, ContextMenu, LatestEntriesCardEmpty, TimelineCard, RefsCost, CostSideCard }
})
export default class ActivityTimeLineItemCreateCost extends mixins(DarkModeHighlightMixin, PermissionMixin) {
  @Prop({})
  activityLogEntry!: MrfiktivActivityLogViewModelGen;

  get name() {
    return activityLogName(this.activityLogEntry);
  }

  get date() {
    if (this.activityLogEntry.timestamp?.created) {
      const locale = this.$t("utility.toLocalDateString").toString() || "de-de";
      return detailedDate(this.activityLogEntry.timestamp?.created, locale);
    }
    return this.$t("sign.DocumentDetail.noDate");
  }

  isLoading = true;

  isNotFound = false;

  showSideCard = false;

  cost: ICost | null = null;

  async mounted() {
    const targets = this.activityLogEntry.target;
    if (!targets) {
      return;
    }
    const target = targets[0];

    if (!target?.refId) {
      return;
    }

    try {
      this.isLoading = true;
      this.cost = await (
        CostModule.maps.id.get(target.refId)[0] ??
        new Cost({
          id: target.refId,
          partnerId: this.activityLogEntry.partnerId
        })
      ).fetch();
    } catch (error) {
      this.cost = null;
      handleError(error);
    } finally {
      this.isLoading = false;
    }
  }

  getUserDetails(userId: string) {
    return PartnerUserModule.maps.id.get(userId)[0];
  }

  openSideCard() {
    // this.showSideCard = true;

    if (!this.cost) {
      return;
    }

    new CostGoToHelper(this.$router).goToCostDetail({
      costId: this.cost.id,
      partnerId: this.cost.partnerId,
      newTab: true
    });
  }
}
