









































import { IKsrJobDetailViewmodel } from "@/lib/interfaces/ksr/ksr-job-detail-viewmodel.interface";
import { detailedDate } from "@/lib/utility/date-helper";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({ components: {} })
export default class PartnerReportDetailSendToKsrDialogKsrDetail extends Vue {
  @Prop()
  job!: IKsrJobDetailViewmodel;

  getDate(date: string) {
    return detailedDate(date);
  }
}
