import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { EventUIDto, IEventUIDto } from "@/lib/dto/event/event-ui.dto";
import eventService from "@/services/mrfiktiv/services/eventService";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { MrfiktivEventControllerGetParamsGen } from "@/services/mrfiktiv/v1/data-contracts";

export const EventPageDataProvider = new (class extends AbstractPageDataProvider<
  IEventUIDto,
  MrfiktivEventControllerGetParamsGen
> {
  itemsPerPage = 25;

  async getPage(query: MrfiktivEventControllerGetParamsGen): Promise<IPageViewModel<IEventUIDto>> {
    const data = (
      (await eventService.listAll({
        from: new Date(new Date().getFullYear(), new Date().getMonth() - 1).getTime(),
        to: new Date(new Date().getFullYear(), new Date().getMonth() + 2).getTime(),
        partnerId: query.partnerId
      })) ?? []
    ).map(d => new EventUIDto(d));

    const meta = {
      currentPage: 1,
      itemsPerPage: data.length,
      totalItems: 1,
      totalPages: 1
    };

    return { meta, data };
  }
})();
