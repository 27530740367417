





















import { Vue, Component, Prop } from "vue-property-decorator";
@Component({})
export default class StatisticsCardColumn extends Vue {
  @Prop()
  icon!: string;

  @Prop()
  color!: string;

  @Prop()
  title!: string;

  @Prop()
  data!: string;

  @Prop()
  tooltip?: string;

  @Prop()
  click?: Function;

  handleClick() {
    if (this.click) {
      this.click();
    }
  }
}
