var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-rating', {
    staticClass: "pb-4",
    attrs: {
      "hover": "",
      "length": "5",
      "disabled": _vm.disabled,
      "background-color": _vm.color,
      "data-test-rating": "",
      "label": _vm.$t(_vm.feedBackPlaceholder)
    },
    model: {
      value: _vm.rating,
      callback: function callback($$v) {
        _vm.rating = $$v;
      },
      expression: "rating"
    }
  }), _c('v-textarea', {
    attrs: {
      "clearable": "",
      "auto-grow": "",
      "outlined": "",
      "label": _vm.$t(_vm.feedBackPlaceholder),
      "disabled": _vm.disabled,
      "data-test-feedback-description": ""
    },
    model: {
      value: _vm.message,
      callback: function callback($$v) {
        _vm.message = $$v;
      },
      expression: "message"
    }
  }), _c('v-btn', {
    staticClass: "feedback-button",
    attrs: {
      "color": "success",
      "rounded": "",
      "large": "",
      "disabled": !_vm.isDone,
      "loading": _vm.isLoading,
      "data-test-submit-feedback-button": ""
    },
    on: {
      "click": _vm.sendFeedback
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-send ")]), _vm._v(" " + _vm._s(_vm.$t("components.feedback.send")) + " ")], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }