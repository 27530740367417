var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', {
    staticClass: "ma-2",
    attrs: {
      "outlined": "",
      "min-width": "250px",
      "height": "158px",
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "title pt-4",
    class: _vm.color
  }, [_c('h4', {
    staticClass: "text-truncate"
  }, [_vm._v(_vm._s(_vm.$t("components.partner.PartnerReportDetailInfoDateCard.title")))])]), _c('v-divider'), _c('v-card-text', [_c('small', [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportDetailInfoDateCard.text")))]), _c('PartnerDetailAttributeWithToolTip', {
    attrs: {
      "tooltip": "Wunschtermin",
      "displayName": _vm.datePreference,
      "icon": "mdi-calendar-star"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }