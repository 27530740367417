import signService from "@/services/sign/services/sign.service";
import store from "@/store/VuexPlugin";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { SignDigitalSignatureViewModelGen } from "../../services/sign/v1/data-contracts";

@Module({
  dynamic: true,
  namespaced: true,
  name: "sign",
  store
})
export class SignStore extends VuexModule {
  private _signature: SignDigitalSignatureViewModelGen = {} as any;
  private _signatures: SignDigitalSignatureViewModelGen[] = [];

  get signatures() {
    return this._signatures;
  }

  get signature() {
    return this._signature;
  }

  @Mutation
  mutateSignatures(signatures: SignDigitalSignatureViewModelGen[]) {
    this._signatures = signatures;
  }

  @Mutation
  mutateSignature(signature: SignDigitalSignatureViewModelGen) {
    this._signature = signature;
  }

  @Action
  async findAll(data: { partnerId: string; requestId: string }) {
    const res = await signService.getAllForPartner(data.partnerId, data.requestId);

    this.context.commit("mutateSignatures", res);

    return res;
  }

  @Action
  async findOne(data: { partnerId: string; requestId: string; signId: string }) {
    const res = await signService.getOneForPartner(data.partnerId, data.requestId, data.signId);

    this.context.commit("mutateSignature", res);

    return res;
  }

  @Action
  async findOneByDocument({ partnerId, documentId }: { partnerId: string; documentId: string }) {
    const res = await signService.findOneByDocument(partnerId, documentId);

    this.context.commit("mutateSignature", res);

    return res;
  }
}

export const SignModule = getModule(SignStore);
