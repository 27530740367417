





























import { activityLogName } from "@/lib/utility/activityLogName";
import { detailedDate } from "@/lib/utility/date-helper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { MrfiktivActivityLogViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ActivityTimeLineItemDelete from "./ActivityTimeLineItemDelete.vue";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { ActivityTypeEnum } from "@/store/modules/activity-log.store";
import TimeLineItemText from "../utility/TimeLineItemText.vue";
import ContextMenu from "../utility/ContextMenu.vue";

@Component({
  components: { TimeLineItemText, ActivityTimeLineItemDelete, ContextMenu }
})
export default class ActivityTimeLineItemAssignees extends mixins(DarkModeHighlightMixin, PermissionMixin) {
  @Prop({})
  activityLogEntry!: MrfiktivActivityLogViewModelGen;

  get name() {
    return activityLogName(this.activityLogEntry);
  }

  get isAdd() {
    return ActivityTypeEnum.CREATE_ASSIGNEE === this.activityLogEntry.activity;
  }

  get date() {
    if (this.activityLogEntry.timestamp?.created) {
      const locale = this.$t("utility.toLocalDateString").toString() || "de-de";
      return detailedDate(this.activityLogEntry.timestamp?.created, locale);
    }
    return this.$t("sign.DocumentDetail.noDate");
  }

  get text() {
    const name = this.name;
    const date = this.date;
    const assignees = this.assignees;

    return this.$t(`timeLine.ActivityTimeLineItemAssignees.${this.activityLogEntry.activity}`, {
      name,
      date,
      assignees
    });
  }

  get assignees() {
    const assignees: string[] = [];

    if (this.activityLogEntry.target) {
      this.activityLogEntry.target.forEach(a => {
        const user = this.getUserDetails(a.refId);
        if (user) {
          assignees.push(user.firstName + " " + user.lastName);
        }
      });
    }

    return assignees.join(", ");
  }

  getUserDetails(userId: string) {
    return PartnerUserModule.maps.id.get(userId)[0];
  }
}
