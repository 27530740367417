var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-report-step', {
    attrs: {
      "isDone": _vm.isDone,
      "title": _vm.header.title,
      "description": _vm.header.description,
      "image": _vm.header.image,
      "debug": _vm.header.debug
    }
  }, [_c('v-card', {
    staticClass: "pt-4 pr-4 pl-4",
    attrs: {
      "flat": "",
      "max-width": "800"
    }
  }, [_c('v-form', {
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "outlined": "",
      "label": _vm.$t('report.accidentDetails.role.label'),
      "persistent-hint": "",
      "hint": _vm.$t('report.accidentDetails.role.hint'),
      "items": _vm.roles,
      "rules": _vm.requiredRule,
      "item-text": "text",
      "item-value": "value"
    },
    model: {
      value: _vm.accidentDetails.role,
      callback: function callback($$v) {
        _vm.$set(_vm.accidentDetails, "role", $$v);
      },
      expression: "accidentDetails.role"
    }
  }), _c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "nudge-right": 40,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "value": _vm.localeDateFormat,
            "label": _vm.$t('report.accidentDetails.date'),
            "outlined": "",
            "readonly": ""
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.menu2,
      callback: function callback($$v) {
        _vm.menu2 = $$v;
      },
      expression: "menu2"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "max": new Date().toISOString().substr(0, 10),
      "color": "primary",
      "first-day-of-week": "1",
      "locale": _vm.$t('report.datePicker.locale')
    },
    on: {
      "input": function input($event) {
        _vm.menu2 = false;
      }
    },
    model: {
      value: _vm.formatedDate,
      callback: function callback($$v) {
        _vm.formatedDate = $$v;
      },
      expression: "formatedDate"
    }
  })], 1), _c('v-menu', {
    ref: "menu",
    attrs: {
      "close-on-content-click": false,
      "nudge-right": 40,
      "return-value": _vm.formatedtime,
      "transition": "scale-transition",
      "offset-y": "",
      "max-width": "290px",
      "min-width": "290px"
    },
    on: {
      "update:returnValue": function updateReturnValue($event) {
        _vm.formatedtime = $event;
      },
      "update:return-value": function updateReturnValue($event) {
        _vm.formatedtime = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on;
        return [_c('v-text-field', _vm._g({
          attrs: {
            "label": _vm.$t('report.accidentDetails.time'),
            "readonly": "",
            "outlined": ""
          },
          model: {
            value: _vm.formatedtime,
            callback: function callback($$v) {
              _vm.formatedtime = $$v;
            },
            expression: "formatedtime"
          }
        }, on))];
      }
    }]),
    model: {
      value: _vm.timepickerMenu,
      callback: function callback($$v) {
        _vm.timepickerMenu = $$v;
      },
      expression: "timepickerMenu"
    }
  }, [_vm.timepickerMenu ? _c('v-time-picker', {
    attrs: {
      "ampm-in-title": "",
      "format": "24hr",
      "full-width": ""
    },
    on: {
      "click:minute": function clickMinute($event) {
        return _vm.$refs.menu.save(_vm.formatedtime);
      }
    },
    model: {
      value: _vm.formatedtime,
      callback: function callback($$v) {
        _vm.formatedtime = $$v;
      },
      expression: "formatedtime"
    }
  }) : _vm._e()], 1), _c('v-radio-group', {
    attrs: {
      "label": _vm.$t('report.accidentDetails.location.title')
    },
    on: {
      "change": _vm.getPositionIfTrue
    },
    model: {
      value: _vm.isAtAccidentPlace,
      callback: function callback($$v) {
        _vm.isAtAccidentPlace = $$v;
      },
      expression: "isAtAccidentPlace"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": _vm.$t('report.accidentDetails.location.true'),
      "value": 1
    }
  }), _c('v-radio', {
    attrs: {
      "label": _vm.$t('report.accidentDetails.location.false'),
      "value": 2
    }
  })], 1), _vm.isAtAccidentPlace ? _c('span', [_c('v-radio-group', {
    attrs: {
      "label": _vm.$t('report.accidentDetails.location.isAddress')
    },
    model: {
      value: _vm.isAddress,
      callback: function callback($$v) {
        _vm.isAddress = $$v;
      },
      expression: "isAddress"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": _vm.$t('report.accidentDetails.location.true'),
      "value": true
    }
  }), _c('v-radio', {
    attrs: {
      "label": _vm.$t('report.accidentDetails.location.false'),
      "value": false
    }
  })], 1), _vm.isAddress ? _c('span', [_c('v-text-field', {
    attrs: {
      "rules": _vm.requiredRule,
      "outlined": "",
      "label": _vm.$t('report.accidentDetails.location.street')
    },
    model: {
      value: _vm.accidentDetails.address.street,
      callback: function callback($$v) {
        _vm.$set(_vm.accidentDetails.address, "street", $$v);
      },
      expression: "accidentDetails.address.street"
    }
  }), _c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": _vm.$t('report.accidentDetails.location.zip')
    },
    model: {
      value: _vm.accidentDetails.address.zip,
      callback: function callback($$v) {
        _vm.$set(_vm.accidentDetails.address, "zip", $$v);
      },
      expression: "accidentDetails.address.zip"
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": _vm.requiredRule,
      "outlined": "",
      "label": _vm.$t('report.accidentDetails.location.city')
    },
    model: {
      value: _vm.accidentDetails.address.city,
      callback: function callback($$v) {
        _vm.$set(_vm.accidentDetails.address, "city", $$v);
      },
      expression: "accidentDetails.address.city"
    }
  })], 1) : _c('span', [_c('v-textarea', {
    attrs: {
      "rules": _vm.requiredRule,
      "outlined": "",
      "persistent-hint": "",
      "label": _vm.$t('report.accidentDetails.location.comment.label'),
      "hint": _vm.$t('report.accidentDetails.location.comment.hint')
    },
    model: {
      value: _vm.accidentDetails.comment,
      callback: function callback($$v) {
        _vm.$set(_vm.accidentDetails, "comment", $$v);
      },
      expression: "accidentDetails.comment"
    }
  })], 1)], 1) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }