var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('v-dialog', {
    attrs: {
      "auto": "",
      "offset-y": "",
      "flat": "",
      "width": "600px"
    },
    on: {
      "keydown": [function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) { return null; }
        return _vm.closeDialog.apply(null, arguments);
      }, function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.openConfirmationDialog.apply(null, arguments);
      }]
    },
    model: {
      value: _vm.isDialogActive,
      callback: function callback($$v) {
        _vm.isDialogActive = $$v;
      },
      expression: "isDialogActive"
    }
  }, [_c('v-card', [_c('v-card-title', {
    attrs: {
      "primary-title": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportDetail.SendToDaDialog.title")) + " ")]), _c('v-divider'), _c('v-card-subtitle', {
    staticClass: "mt-4 "
  }, [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportDetail.SendToDaDialog.numberPlate")) + " ")]), _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('components.partner.PartnerReportDetail.SendToDaDialog.numberPlate'),
      "rules": _vm.numberPlateRules,
      "solo": ""
    },
    model: {
      value: _vm.numberPlate,
      callback: function callback($$v) {
        _vm.numberPlate = $$v;
      },
      expression: "numberPlate"
    }
  })], 1), _c('v-expand-transition', [_vm.show ? _c('div', [_c('v-card-title', [_vm._v(_vm._s(_vm.$t("components.partner.PartnerReportDetail.SendToDaDialog.exportOptions")))]), _c('v-divider'), _c('v-card-subtitle', {
    staticClass: "mt-4 "
  }, [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportDetail.SendToDaDialog.resource")) + " ")]), _c('v-card-text', [_c('v-autocomplete', {
    attrs: {
      "disabled": _vm.resourcesLoading,
      "loading": _vm.resourcesLoading,
      "items": _vm.daResources,
      "clearable": "",
      "solo": "",
      "label": _vm.$t('components.partner.PartnerReportDetail.SendToDaDialog.resource'),
      "item-text": "name",
      "item-value": "id"
    },
    on: {
      "input": _vm.fetchFreeSlots
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.name) + " (" + _vm._s(data.item.id) + ") ")];
      }
    }, {
      key: "item",
      fn: function fn(data) {
        return [[_c('v-list-item-content', [_c('v-list-item-title', {
          domProps: {
            "innerHTML": _vm._s(data.item.name)
          }
        }), _c('v-list-item-subtitle', {
          domProps: {
            "innerHTML": _vm._s(data.item.id)
          }
        })], 1)]];
      }
    }], null, false, 2765469812),
    model: {
      value: _vm.selectionResourceId,
      callback: function callback($$v) {
        _vm.selectionResourceId = $$v;
      },
      expression: "selectionResourceId"
    }
  })], 1), _c('v-card-subtitle', {
    staticClass: "mt-4 "
  }, [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportDetail.SendToDaDialog.eventType")) + " ")]), _c('v-card-text', [_c('v-autocomplete', {
    attrs: {
      "disabled": _vm.eventTypesLoading,
      "loading": _vm.eventTypesLoading,
      "items": _vm.daEventTypes,
      "clearable": "",
      "solo": "",
      "label": _vm.$t('components.partner.PartnerReportDetail.SendToDaDialog.eventType'),
      "item-text": "name",
      "item-value": "id"
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.name) + " (" + _vm._s(data.item.id) + ") ")];
      }
    }, {
      key: "item",
      fn: function fn(data) {
        return [[_c('v-list-item-content', [_c('v-list-item-title', {
          domProps: {
            "innerHTML": _vm._s(data.item.name)
          }
        }), _c('v-list-item-subtitle', {
          domProps: {
            "innerHTML": _vm._s(data.item.id)
          }
        })], 1)]];
      }
    }], null, false, 2765469812),
    model: {
      value: _vm.selectionEventTypeId,
      callback: function callback($$v) {
        _vm.selectionEventTypeId = $$v;
      },
      expression: "selectionEventTypeId"
    }
  })], 1), _vm.daFreeSlots || _vm.freeSlotLoading ? _c('v-card-subtitle', {
    staticClass: "mt-4 "
  }, [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportDetail.SendToDaDialog.freeSlot")) + " ")]) : _vm._e(), _vm.daFreeSlots || _vm.freeSlotLoading ? _c('v-card-text', [_c('v-autocomplete', {
    attrs: {
      "disabled": _vm.freeSlotLoading,
      "loading": _vm.freeSlotLoading,
      "items": _vm.daFreeSlots,
      "clearable": "",
      "solo": "",
      "label": _vm.$t('components.partner.PartnerReportDetail.SendToDaDialog.freeSlot'),
      "item-text": "display",
      "item-value": "value"
    },
    model: {
      value: _vm.selectionFreeSlot,
      callback: function callback($$v) {
        _vm.selectionFreeSlot = $$v;
      },
      expression: "selectionFreeSlot"
    }
  })], 1) : _vm._e()], 1) : _vm._e()]), _c('v-divider'), _c('v-card-actions', [_c('v-tooltip', {
    attrs: {
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              _vm.show = !_vm.show;
            }
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v(_vm._s(_vm.show ? "mdi-chevron-up" : "mdi-chevron-down"))])], 1)];
      }
    }])
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportDetail.SendToDaDialog.exportOptions")) + " ")])]), _vm.selectionResourceId || _vm.selectionEventTypeId ? _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.clearSelection
    }
  }, [_vm._v(_vm._s(_vm.$t("components.partner.PartnerReportDetail.SendToDaDialog.clearSelection")))]) : _vm._e(), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.openConfirmationDialog
    }
  }, [_vm._v(_vm._s(_vm.selectionResourceId || _vm.selectionEventTypeId ? _vm.$t("components.partner.PartnerReportDetail.SendToDaDialog.send") : _vm.$t("components.partner.PartnerReportDetail.SendToDaDialog.sendToDefault")))])], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "auto": "",
      "offset-y": "",
      "flat": "",
      "width": "600px"
    },
    on: {
      "keydown": [function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.openConfirmationDialog.apply(null, arguments);
      }, function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "escape", undefined, $event.key, undefined)) { return null; }
        return _vm.closeConfirmationDialog.apply(null, arguments);
      }]
    },
    model: {
      value: _vm.isConfirmationDialogActive,
      callback: function callback($$v) {
        _vm.isConfirmationDialogActive = $$v;
      },
      expression: "isConfirmationDialogActive"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportDetail.SendToDaDialog.confirmDialogTitle", {
    numberPlate: _vm.numberPlate
  })) + " ")]), _c('v-card-text', [_vm.numberPlate ? _c('v-list-item', [_c('v-list-item-content', [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportDetail.SendToDaDialog.text.numberPlate", {
    numberPlate: _vm.numberPlate
  })) + " ")])], 1) : _vm._e(), _vm.selectionResourceId ? _c('v-list-item', [_c('v-list-item-content', [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportDetail.SendToDaDialog.text.resourceId", {
    selectionResource: _vm.selectedResource.name
  })) + " ")])], 1) : _vm._e(), _vm.selectionEventTypeId ? _c('v-list-item', [_c('v-list-item-content', [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportDetail.SendToDaDialog.text.eventType", {
    selectionEventType: _vm.selectedEventType.name
  })) + " ")])], 1) : _vm._e(), _vm.selectionFreeSlot ? _c('v-list-item', [_c('v-list-item-content', [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportDetail.SendToDaDialog.text.freeSlot", {
    selectionFreeSlot: _vm.selectionFreeSlot
  })) + " ")])], 1) : _vm._e()], 1), _c('v-card-actions', [_c('v-flex', {
    staticClass: "d-flex mb-5"
  }, [_c('v-btn', {
    staticClass: "pa-2 align-end ma-1",
    attrs: {
      "outlined": "",
      "text": ""
    },
    on: {
      "click": _vm.closeConfirmationDialog
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportDetail.SendToDaDialog.back")) + " ")]), _c('v-spacer'), _c('v-btn', {
    staticClass: "pa-2 align-end ma-1",
    attrs: {
      "outlined": "",
      "text": ""
    },
    on: {
      "click": _vm.send,
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.send.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportDetail.SendToDaDialog.confirm")) + " ")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }