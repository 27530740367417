
import { KsrModule } from "@/store/modules/ksr.store";
import { Component, Vue } from "vue-property-decorator";
import { reportHomeQueryParams } from "@/lib/queryParams/reportHomeQueryParams";
import { ReportModule } from "@/store/modules/report.store";

@Component({})
export default class ReportAsyncOperationMixin extends Vue {
  async startAsyncOperations() {
    const asyncOperations = [this.loadKsrCustomerData()];

    await Promise.all(asyncOperations);
  }

  async loadKsrCustomerData() {
    const customerIdentityToken = reportHomeQueryParams.find(item => item.key === "customerIdentityToken")?.get();
    if (!customerIdentityToken) {
      return;
    }

    try {
      await KsrModule.getUserDetails(customerIdentityToken);
      Vue.$log.info("KSR customer data loaded");
    } catch (e) {
      Vue.$log.error(e);
      return;
    }

    if (KsrModule.ksrCustomerInfo) {
      ReportModule.setAddress({
        street: ReportModule.address.street || KsrModule.ksrCustomerInfo.address?.street,
        zip: ReportModule.address.zip || KsrModule.ksrCustomerInfo.address?.zip,
        city: ReportModule.address.city || KsrModule.ksrCustomerInfo.address?.city,
        state: ReportModule.address.state || KsrModule.ksrCustomerInfo.address?.state
      });
      ReportModule.setContact({
        email: ReportModule.contact.email || KsrModule.ksrCustomerInfo.contact?.email,
        phone: ReportModule.contact.phone || KsrModule.ksrCustomerInfo.contact?.phone
      });
      ReportModule.setUser({
        firstName: ReportModule.user.firstName || KsrModule.ksrCustomerInfo.firstName,
        lastName: ReportModule.user.lastName || KsrModule.ksrCustomerInfo.lastName
      });
      ReportModule.setKsrData({
        contactId: KsrModule.ksrCustomerInfo.contactId,
        addressId: KsrModule.ksrCustomerInfo.addressId
      });
    }
  }
}
