import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { IVSelectItem } from "@/lib/interfaces/v-select-item.interface";
import { MrfiktivCompanyViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";

/**
 * The types of companies
 */
export enum HandoverCompanyTypeEnum {
  OWNER = "owner",
  KEEPER = "keeper",
  NEW_KEEPER = "new_keeper",
  TENANT = "tenant",
  NEW_TENANT = "new_tenant",
  OTHER = "other"
}

@IsFilterable
class CompanyBase implements MrfiktivCompanyViewModelGen {
  @FilterConfig({ displayName: "objects.handover.company.name", type: FilterTypes.STRING })
  companyName?: string;

  @FilterConfig({
    displayName: "objects.handover.company.type",
    type: FilterTypes.ENUM,
    config: {
      items: Object.values(HandoverCompanyTypeEnum).map(e => {
        return {
          text: `enums.HandoverCompanyTypeEnum.${e}`,
          value: e
        } as IVSelectItem;
      }),
      itemValue: "value"
    }
  })
  companyType?: string;

  @FilterConfig({ displayName: "objects.handover.company.email", type: FilterTypes.STRING })
  email?: string;

  @FilterConfig({ displayName: "objects.handover.company.phone", type: FilterTypes.STRING })
  phone?: string;

  constructor(contact?: Partial<ICompany>) {
    this.companyName = contact?.companyName ?? "";
    this.companyType = contact?.companyType ?? HandoverCompanyTypeEnum.OTHER;
    this.email = contact?.email ?? "";
    this.phone = contact?.phone ?? "";
  }
}

type ICompany = CompanyBase;
const Company = Filter.createForClass(CompanyBase);

export { Company, ICompany };
