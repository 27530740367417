
































































import { IForm } from "@/lib/interfaces/form.interface";
import { Component } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import PartnerReportCardGeneral from "./PartnerReportCardGeneral.vue";

@Component({
  components: {
    Card
  }
})
export default class PartnerReportCardPolice extends PartnerReportCardGeneral {
  showRemove = false;

  add() {
    if (!this.reportDto.policeDetails) {
      this.reportDto.policeDetails = { isRecorded: false, department: "", fileReference: "" };
    }
    this.showRemove = true;
    this.key++;

    this.$nextTick(() => {
      (this.$refs.form as IForm)?.validate();
    });
  }

  remove() {
    this.reportDto.policeDetails = undefined;
    this.showRemove = false;
    this.key++;
  }
}
