















import { Component, Vue, Prop } from "vue-property-decorator";

@Component({})
export default class AdvertisementCard extends Vue {
  @Prop()
  title!: string;

  @Prop()
  subtitle!: string;

  @Prop()
  content!: string;

  @Prop()
  imgSrc!: string;

  @Prop()
  logoSrc!: string;

  @Prop()
  link!: string;

  @Prop()
  backgroundColor!: string;

  @Prop()
  btnText!: string;

  @Prop()
  buttonColor!: string;

  @Prop({ default: "to top right, rgba(30, 30, 30, 0.58), rgba(30, 30, 30, 0.58)" })
  gradient!: string;

  openLink() {
    window.open(this.link, "_blank");
  }
}
