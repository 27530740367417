
































































































import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import { emailRule, noNumberRule, phoneRule } from "@/lib/rules/contactRule";
import { numberplateRule } from "@/lib/rules/numberplateRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import ReportStepMixin from "@/mixins/ReportStepMixin.vue";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { ICreateReport } from "@/store/models/mrfiktiv/report";
import { ConfigModule } from "@/store/modules/config";
import { PartnerModule } from "@/store/modules/partner";
import { ReportModule } from "@/store/modules/report.store";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    LayoutReportStep
  }
})
export default class ReportSummaryAlt extends ReportStepMixin implements IReportStep {
  header: IReportHeaderData = {
    title: "report.summaryAlt.title",
    description: this.description
  };

  screen = ReportScreenEnum.closingalt;

  protected isPrivacyShown = true;

  // TODO: Fix this as it is not reactive.
  get description() {
    return String(
      this.$t("report.summaryAlt.description", {
        privacy:
          "<a target='blank()' href='https://www.mmmint.ai/datenschutz/'>" +
          this.$t("report.summaryAlt.privacyPolicy") +
          "</a>."
      })
    );
  }

  get privacyPolicyLink() {
    return ConfigModule.privacyPolicyLink;
  }

  isLoading = false;
  valid = false;
  isReviewed = true;

  get email() {
    return ReportModule.contact.email;
  }

  get isDone() {
    return this.valid && this.isPrivacyChecked;
  }

  get isPrivacyChecked(): boolean {
    return ReportModule.inCreationReport.isPrivacyChecked;
  }

  set isPrivacyChecked(bool: boolean) {
    ReportModule.setPrivacyCheck(bool);
  }

  get report(): ICreateReport {
    return ReportModule.inCreationReport;
  }

  get isOverviewsRequired(): boolean {
    const conditions = [ReportScreenEnum.overviewimage, ReportScreenEnum.overviewimagealt];

    return conditions.some(el => PartnerModule.partner.settings?.reportSettings.screenOrder?.includes(el));
  }

  async submit() {
    this.isLoading = true;
    try {
      if (this.isOverviewsRequired && !ReportModule.overviewsFrontLeft.length) {
        this.$toast.error(this.$t("report.summaryAlt.error"));
        this.$router.push({ name: "ReportHome" });
        return;
      }

      await ReportModule.submit();
      PartnerModule.setRatingSubmissionEnabled(true);
      this.$router.push({ name: "ReportSuccess" });
    } catch (error) {
      this.$log.error(error);
      this.$toast.error("Fehler beim senden.");
    } finally {
      this.isLoading = false;
    }
  }

  phone: any;

  get numberPlateRules() {
    const rules = [];

    rules.push(requiredRule());
    rules.push(numberplateRule());

    return rules;
  }

  get requiredRule() {
    const rules = [];

    rules.push(requiredRule());

    return rules;
  }

  get noNumberRule() {
    const rules = [];

    rules.push(noNumberRule());

    return rules;
  }

  get emailRules() {
    const rules = [];

    rules.push(requiredRule());
    rules.push(emailRule());

    return rules;
  }

  get phoneNumberRules() {
    const rules = [];

    rules.push(phoneRule());

    return rules;
  }

  get numberplate(): string {
    return ReportModule.numberplate;
  }

  get user(): any {
    return ReportModule.user;
  }

  get contact(): any {
    return ReportModule.contact;
  }

  get address(): any {
    return ReportModule.address;
  }

  updateNumberplate(e: any) {
    ReportModule.setNumberplate(e.target.value.toUpperCase());
  }

  updateUserFirstName(e: any) {
    ReportModule.setUser({ firstName: e.target.value });
  }

  updateUserLastName(e: any) {
    ReportModule.setUser({ lastName: e.target.value });
  }

  updateUserPhone(e: any) {
    ReportModule.setContact({ phone: e.target.value });
  }

  updateUserEmail(e: any) {
    ReportModule.setContact({ email: e.target.value });
  }

  updateUserCity(e: any) {
    ReportModule.setAddress({ city: e.target.value });
  }

  updateUserState(e: any) {
    ReportModule.setAddress({ state: e.target.value });
  }

  updateUserStreet(e: any) {
    ReportModule.setAddress({ street: e.target.value });
  }

  updateUserZip(e: any) {
    ReportModule.setAddress({ zip: e.target.value });
  }
}
