






































import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { requiredRule } from "@/lib/rules/requiredRule";
import { handleError } from "@/lib/utility/handleError";
import {
  MrfiktivAttachmentResponseDtoGen,
  MrfiktivAttachmentResponseElementDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { AttachmentResponseModule } from "@/store/modules/attachment-response.store";
import { ReportModule } from "@/store/modules/report.store";
import { Component, Vue } from "vue-property-decorator";
import { PartnerModule } from "@/store/modules/partner";

@Component({
  components: {
    LayoutReportStep
  }
})
export default class ReportDataResponseConfirm extends Vue {
  loading = false;
  isReviewed = false;
  isPrivacyChecked = false;
  public header: IReportHeaderData = {
    title: "report.summary.title",
    description: this.description
  };

  get description() {
    return String(
      this.$t("report.summary.description", {
        privacy:
          "<a target='blank()' href='https://www.mmmint.ai/datenschutz/'>" +
          this.$t("report.summary.privacyPolicy") +
          "</a>."
      })
    );
  }

  get requiredRule() {
    return [requiredRule()];
  }

  async attach() {
    try {
      this.loading = true;

      const partnerId = ReportModule.partner.id;
      const attachmentId = AttachmentResponseModule.attachmentRequestId;
      const accessToken = AttachmentResponseModule.attachmentRequestToken;

      if (!attachmentId || !accessToken) {
        this.$toast.error("No attachment request found");
        return;
      }

      const reportReference = AttachmentResponseModule.attachmentResponse?.shared.find(
        ref => ref.refType === ResourceEnum.REPORT
      );

      if (!reportReference) {
        this.$toast.error("No report reference found");
        return;
      }

      const attachment: MrfiktivAttachmentResponseElementDtoGen = {
        refType: reportReference.refType,
        refId: reportReference.refId,
        attachment: ReportModule.reportDto
      };
      const data: MrfiktivAttachmentResponseDtoGen = {
        attachments: [attachment]
      };

      if (attachmentId && accessToken) {
        await AttachmentResponseModule.attach({
          partnerId,
          attachmentId,
          accessToken,
          data
        });

        PartnerModule.setRatingSubmissionEnabled(true);
        this.$router.push({ name: "ReportSuccess" });

        ReportModule.reset();
        // load partner to reset the report step order
        ReportModule.getPartnerById(partnerId);
      }
    } catch (e) {
      handleError(e);
    } finally {
      this.loading = false;
    }
  }
}
