var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-timeline-item', {
    staticClass: "pt-6 mb-n6",
    attrs: {
      "icon": "mdi-creation",
      "fill-dot": "",
      "color": "primary",
      "small": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("timeLine.ActivityTimeLineItemReportSummaryCreated.title")) + " ")]), _c('timeline-card', {
    attrs: {
      "title": _vm.$t('components.partner.PartnerReportDetail.summarize')
    }
  }, [_c('template', {
    slot: "menu"
  }, [_c('activity-time-line-item-delete', {
    attrs: {
      "partnerId": _vm.activityLogEntry.partnerId,
      "activityId": _vm.activityLogEntry.id
    }
  })], 1), _c('v-card-text', {
    domProps: {
      "innerHTML": _vm._s(_vm.content)
    }
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }