
import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { Component } from "vue-property-decorator";
import ReportSummaryAlt from "./ReportSummaryAlt.vue";
import { ReportModule } from "@/store/modules/report.store";

@Component({
  components: {
    LayoutReportStep
  }
})
export default class ReportSummaryAltAuth extends ReportSummaryAlt implements IReportStep {
  screen = ReportScreenEnum.closingaltauth;

  isPrivacyShown = false;

  mounted() {
    ReportModule.setPrivacyCheck(true);
  }

  get isDone() {
    return this.valid;
  }
}
