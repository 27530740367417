var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-report-step', {
    attrs: {
      "isDone": _vm.isDone,
      "title": _vm.header.title,
      "description": _vm.header.description,
      "image": _vm.header.image,
      "debug": _vm.header.debug
    }
  }, [_c('v-card', {
    staticClass: "pt-4 pr-4 pl-4",
    attrs: {
      "flat": "",
      "max-width": "500"
    }
  }, [_c('v-form', {
    ref: "form",
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-radio-group', {
    model: {
      value: _vm.hasPreExistingDamage,
      callback: function callback($$v) {
        _vm.hasPreExistingDamage = $$v;
      },
      expression: "hasPreExistingDamage"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": _vm.$t('report.preexistingdamage.false'),
      "value": false
    }
  }), _c('v-radio', {
    attrs: {
      "label": _vm.$t('report.preexistingdamage.true'),
      "value": true
    }
  })], 1), _vm.hasPreExistingDamage ? _c('div', [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "label": _vm.$t('report.preexistingdamage.field'),
      "rules": _vm.requiredRule
    },
    model: {
      value: _vm.description,
      callback: function callback($$v) {
        _vm.description = $$v;
      },
      expression: "description"
    }
  }), _c('file-upload-multi', {
    attrs: {
      "instruction": _vm.instruction,
      "textAlt": "report.additionalImage.textBtnAlt",
      "example": _vm.example,
      "overlay": _vm.asset,
      "filesCb": _vm.displayImages,
      "displayImages": _vm.displayImages,
      "handleDeleteCb": _vm.handleDelete,
      "handleUploadCb": _vm.handleUpload,
      "data-test-pre-existing-damage": ""
    }
  })], 1) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }