
























































import { IForm } from "@/lib/interfaces/form.interface";
import { Component } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import PartnerReportCardGeneral from "./PartnerReportCardGeneral.vue";

@Component({
  components: {
    Card
  }
})
export default class PartnerReportCardRegistrationMainInspection extends PartnerReportCardGeneral {
  showRemove = false;

  get isRegistrationName() {
    const registrationResults = this.reportDto.registrationResults;
    if (!registrationResults) {
      return false;
    }

    if (undefined === registrationResults.huMonth && undefined === registrationResults.huYear) {
      return false;
    }

    return true;
  }

  add() {
    this.reportDto = {
      ...this.reportDto,
      registrationResults: {
        ...(this.reportDto.registrationResults ?? {}),
        huMonth: "",
        huYear: ""
      }
    };
    this.showRemove = true;
    this.key++;

    this.$nextTick(() => {
      (this.$refs.form as IForm)?.validate();
    });
  }

  remove() {
    this.reportDto = {
      ...this.reportDto,
      registrationResults: {
        ...(this.reportDto.registrationResults ?? {}),
        huMonth: undefined,
        huYear: undefined
      }
    };
    this.showRemove = false;
    this.key++;
  }
}
