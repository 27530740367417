



































































import FileUploadMulti from "@/components/files/FileUploadMulti.vue";
import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { AssetEnum } from "@/lib/AssetRepository";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportInstructionData } from "@/lib/interfaces/Report/IReportInstructionData";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import ReportStepMixin from "@/mixins/ReportStepMixin.vue";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { ReportType } from "@/store/enum/reportType";
import { ReportModule } from "@/store/modules/report.store";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    LayoutReportStep,
    FileUploadMulti
  }
})
export default class ReportImageOverview extends ReportStepMixin implements IReportStep {
  header: IReportHeaderData = this.configuration.header;
  instruction: IReportInstructionData = this.configuration.instruction;
  screen = ReportScreenEnum.overviewimage;

  get multiSteps() {
    return {
      frontLeft: {
        example: this.configuration.exampleImages.frontLeft.example,
        overlay: this.configuration.exampleImages.frontLeft.overlay,
        text: "report.imageOverview.frontLeftText",
        textAlt: "report.imageOverview.frontLeftTextAlt",
        filesCb: ReportModule.overviewsFrontLeft,
        handleUpload: ReportModule.addOverviewFrontLeft,
        handleDelete: ReportModule.removeOverviewFrontLeft
      },
      rearLeft: {
        example: this.configuration.exampleImages.rearLeft.example,
        overlay: this.configuration.exampleImages.rearLeft.overlay,
        text: "report.imageOverview.rearLeftText",
        textAlt: "report.imageOverview.rearLeftTextAlt",
        filesCb: ReportModule.overviewsRearLeft,
        handleUpload: ReportModule.addOverviewRearLeft,
        handleDelete: ReportModule.removeOverviewRearLeft
      },
      rearRight: {
        example: this.configuration.exampleImages.rearRight.example,
        overlay: this.configuration.exampleImages.rearRight.overlay,
        text: "report.imageOverview.rearRightText",
        textAlt: "report.imageOverview.rearRightTextAlt",
        filesCb: ReportModule.overviewsRearRight,
        handleUpload: ReportModule.addOverviewRearRight,
        handleDelete: ReportModule.removeOverviewRearRight
      },
      frontRight: {
        example: this.configuration.exampleImages.frontRight.example,
        overlay: this.configuration.exampleImages.frontRight.overlay,
        text: "report.imageOverview.frontRightText",
        textAlt: "report.imageOverview.frontRightTextAlt",
        filesCb: ReportModule.overviewsFrontRight,
        handleUpload: ReportModule.addOverviewFrontRight,
        handleDelete: ReportModule.removeOverviewFrontRight
      }
    };
  }
  get configuration() {
    switch (ReportModule.reportType) {
      case ReportType.CAR:
        return {
          exampleImages: {
            frontLeft: {
              example: AssetEnum.carFrontLeft,
              overlay: AssetEnum.carFrontLeftOutline
            },
            rearLeft: {
              example: AssetEnum.carRearLeft,
              overlay: AssetEnum.carRearLeftOutline
            },
            rearRight: {
              example: AssetEnum.carRearRight,
              overlay: AssetEnum.carRearRightOutline
            },
            frontRight: {
              example: AssetEnum.carFrontRight,
              overlay: AssetEnum.carFrontRightOutline
            }
          },
          header: {
            title: "report.imageOverview.title",
            description: "report.imageOverview.description",
            image: AssetEnum.overview,
            debug: false
          },

          instruction: {
            title: "report.imageOverview.title",
            text: "report.imageOverview.instructionText",
            pictures: [AssetEnum.damageOverviewFrontInstruction, AssetEnum.damageOverviewBehindInstruction],
            example: AssetEnum.damageInstruction,
            display: true
          }
        };
      case ReportType.CONTAINER:
        return {
          exampleImages: {
            frontLeft: {
              example: AssetEnum.containerFrontLeft,
              overlay: AssetEnum.containerFrontLeftOutline
            },
            rearLeft: {
              example: AssetEnum.containerRearLeft,
              overlay: AssetEnum.containerRearLeftOutline
            },
            rearRight: {
              example: AssetEnum.containerRearRight,
              overlay: AssetEnum.containerRearRightOutline
            },
            frontRight: {
              example: AssetEnum.containerFrontRight,
              overlay: AssetEnum.containerFrontRightOutline
            }
          },
          header: {
            title: "report.imageOverview.title",
            description: "report.imageOverview.container.description",
            image: AssetEnum.damageOverviewContainerInstruction,
            debug: false
          },

          instruction: {
            title: "report.imageOverview.title",
            text: "report.imageOverview.container.instructionText",
            pictures: [AssetEnum.damageOverviewContainerInstruction],
            example: AssetEnum.damageOverviewContainerInstruction,
            display: true
          }
        };
      case ReportType.MOTORBIKE:
        return {
          exampleImages: {
            frontLeft: {
              example: AssetEnum.motorbikeFrontLeft,
              overlay: AssetEnum.motorbikeFrontLeftOutline
            },
            rearLeft: {
              example: AssetEnum.motorbikeRearLeft,
              overlay: AssetEnum.motorbikeRearLeftOutline
            },
            rearRight: {
              example: AssetEnum.motorbikeRearRight,
              overlay: AssetEnum.motorbikeRearRightOutline
            },
            frontRight: {
              example: AssetEnum.motorbikeFrontRight,
              overlay: AssetEnum.motorbikeFrontRightOutline
            }
          },
          header: {
            title: "report.imageOverview.title",
            description: "report.imageOverview.motorbike.description",
            image: AssetEnum.motorbikeFrontRight,
            debug: false
          },

          instruction: {
            title: "report.imageOverview.title",
            text: "report.imageOverview.motorbike.instructionText",
            pictures: [AssetEnum.motorbikeFrontRight],
            example: AssetEnum.motorbikeFrontRight,
            display: true
          }
        };
      case ReportType.CRANE:
        return {
          exampleImages: {
            frontLeft: {
              example: AssetEnum.craneFrontLeft,
              overlay: AssetEnum.craneFrontLeftOutline
            },
            rearLeft: {
              example: AssetEnum.craneRearLeft,
              overlay: AssetEnum.craneRearLeftOutline
            },
            rearRight: {
              example: AssetEnum.craneRearRight,
              overlay: AssetEnum.craneRearRightOutline
            },
            frontRight: {
              example: AssetEnum.craneFrontRight,
              overlay: AssetEnum.craneFrontRightOutline
            }
          },
          header: {
            title: "report.imageOverview.title",
            description: "report.imageOverview.crane.description",
            image: AssetEnum.craneFrontLeft,
            debug: false
          },

          instruction: {
            title: "report.imageOverview.title",
            text: "report.imageOverview.crane.instructionText",
            pictures: [AssetEnum.craneFrontLeft],
            example: AssetEnum.craneFrontLeft,
            display: true
          }
        };
      case ReportType.TRUCK:
        return {
          exampleImages: {
            frontLeft: {
              example: AssetEnum.truckFrontLeft,
              overlay: AssetEnum.truckFrontLeftOutline
            },
            rearLeft: {
              example: AssetEnum.truckRearLeft,
              overlay: AssetEnum.truckRearLeftOutline
            },
            rearRight: {
              example: AssetEnum.truckRearRight,
              overlay: AssetEnum.truckRearRightOutline
            },
            frontRight: {
              example: AssetEnum.truckFrontRight,
              overlay: AssetEnum.truckFrontRightOutline
            }
          },
          header: {
            title: "report.imageOverview.title",
            description: "report.imageOverview.truck.description",
            image: AssetEnum.truckFrontLeft,
            debug: false
          },

          instruction: {
            title: "report.imageOverview.title",
            text: "report.imageOverview.truck.instructionText",
            pictures: [AssetEnum.truckFrontLeft],
            example: AssetEnum.truckFrontLeft,
            display: true
          }
        };
      case ReportType.TRACTOR_UNIT:
        return {
          exampleImages: {
            frontLeft: {
              example: AssetEnum.tractorUnitFrontLeft,
              overlay: AssetEnum.tractorUnitFrontLeftOutline
            },
            rearLeft: {
              example: AssetEnum.tractorUnitRearLeft,
              overlay: AssetEnum.tractorUnitRearLeftOutline
            },
            rearRight: {
              example: AssetEnum.tractorUnitRearRight,
              overlay: AssetEnum.tractorUnitRearRightOutline
            },
            frontRight: {
              example: AssetEnum.tractorUnitFrontRight,
              overlay: AssetEnum.tractorUnitFrontRightOutline
            }
          },
          header: {
            title: "report.imageOverview.title",
            description: "report.imageOverview.truck.description",
            image: AssetEnum.tractorUnitFrontLeft,
            debug: false
          },

          instruction: {
            title: "report.imageOverview.title",
            text: "report.imageOverview.truck.instructionText",
            pictures: [AssetEnum.tractorUnitFrontLeft],
            example: AssetEnum.tractorUnitFrontLeft,
            display: true
          }
        };
      case ReportType.TRAILER:
        return {
          exampleImages: {
            frontLeft: {
              example: AssetEnum.trailerFrontLeft,
              overlay: AssetEnum.trailerFrontLeftOutline
            },
            rearLeft: {
              example: AssetEnum.trailerRearLeft,
              overlay: AssetEnum.trailerRearLeftOutline
            },
            rearRight: {
              example: AssetEnum.trailerRearRight,
              overlay: AssetEnum.trailerRearRightOutline
            },
            frontRight: {
              example: AssetEnum.trailerFrontRight,
              overlay: AssetEnum.trailerFrontRightOutline
            }
          },
          header: {
            title: "report.imageOverview.title",
            description: "report.imageOverview.trailer.description",
            image: AssetEnum.trailerFrontLeft,
            debug: false
          },

          instruction: {
            title: "report.imageOverview.title",
            text: "report.imageOverview.trailer.instructionText",
            pictures: [AssetEnum.trailerFrontLeft],
            example: AssetEnum.trailerFrontLeft,
            display: true
          }
        };
      case ReportType.TRUCK_TRAILER:
        return {
          exampleImages: {
            frontLeft: {
              example: AssetEnum.truckTrailerFrontLeft,
              overlay: AssetEnum.truckTrailerFrontLeftOutline
            },
            rearLeft: {
              example: AssetEnum.truckTrailerRearLeft,
              overlay: AssetEnum.truckTrailerRearLeftOutline
            },
            rearRight: {
              example: AssetEnum.truckTrailerRearRight,
              overlay: AssetEnum.truckTrailerRearRightOutline
            },
            frontRight: {
              example: AssetEnum.truckTrailerFrontRight,
              overlay: AssetEnum.truckTrailerFrontRightOutline
            }
          },
          header: {
            title: "report.imageOverview.title",
            description: "report.imageOverview.trailer.description",
            image: AssetEnum.truckTrailerFrontLeft,
            debug: false
          },

          instruction: {
            title: "report.imageOverview.title",
            text: "report.imageOverview.trailer.instructionText",
            pictures: [AssetEnum.truckTrailerFrontLeft],
            example: AssetEnum.truckTrailerFrontLeft,
            display: true
          }
        };
      case ReportType.SEMITRAILER:
        return {
          exampleImages: {
            frontLeft: {
              example: AssetEnum.semitrailerFrontLeft,
              overlay: AssetEnum.semitrailerFrontLeftOutline
            },
            rearLeft: {
              example: AssetEnum.semitrailerRearLeft,
              overlay: AssetEnum.semitrailerRearLeftOutline
            },
            rearRight: {
              example: AssetEnum.semitrailerRearRight,
              overlay: AssetEnum.semitrailerRearRightOutline
            },
            frontRight: {
              example: AssetEnum.semitrailerFrontRight,
              overlay: AssetEnum.semitrailerFrontRightOutline
            }
          },
          header: {
            title: "report.imageOverview.title",
            description: "report.imageOverview.semitrailer.description",
            image: AssetEnum.semitrailerFrontLeft,
            debug: false
          },

          instruction: {
            title: "report.imageOverview.title",
            text: "report.imageOverview.semitrailer.instructionText",
            pictures: [AssetEnum.semitrailerFrontLeft],
            example: AssetEnum.semitrailerFrontLeft,
            display: true
          }
        };
      case ReportType.OTHER:
        return {
          exampleImages: {
            frontLeft: {
              example: AssetEnum.noImage,
              overlay: ""
            },
            rearLeft: {
              example: AssetEnum.noImage,
              overlay: ""
            },
            rearRight: {
              example: AssetEnum.noImage,
              overlay: ""
            },
            frontRight: {
              example: AssetEnum.noImage,
              overlay: ""
            }
          },
          header: {
            title: "report.imageOverview.title",
            description: "report.imageOverview.other.description",
            image: AssetEnum.noImage,
            debug: false
          },

          instruction: {
            title: "report.imageOverview.title",
            text: "report.imageOverview.other.instructionText",
            pictures: [AssetEnum.noImage],
            example: AssetEnum.noImage,
            display: true
          }
        };
      case ReportType.BICYCLE:
        return {
          exampleImages: {
            frontLeft: {
              example: AssetEnum.bicycleFrontLeft,
              overlay: AssetEnum.bicycleFrontLeftOutline
            },
            rearLeft: {
              example: AssetEnum.bicycleRearLeft,
              overlay: AssetEnum.bicycleRearLeftOutline
            },
            rearRight: {
              example: AssetEnum.bicycleRearRight,
              overlay: AssetEnum.bicycleFrontRightOutline
            },
            frontRight: {
              example: AssetEnum.bicycleFrontRight,
              overlay: AssetEnum.bicycleFrontRightOutline
            }
          },
          header: {
            title: "report.imageOverview.title",
            description: "report.imageOverview.bicycle.description",
            image: AssetEnum.bicycleFrontLeft,
            debug: false
          },

          instruction: {
            title: "report.imageOverview.title",
            text: "report.imageOverview.bicycle.instructionText",
            pictures: [AssetEnum.bicycleFrontLeft],
            example: AssetEnum.bicycleFrontLeft,
            display: true
          }
        };
      default:
        return {
          exampleImages: {
            frontLeft: {
              example: AssetEnum.carFrontLeft,
              overlay: AssetEnum.carFrontLeftOutline
            },
            rearLeft: {
              example: AssetEnum.carRearLeft,
              overlay: AssetEnum.carRearLeftOutline
            },
            rearRight: {
              example: AssetEnum.carRearRight,
              overlay: AssetEnum.carRearRightOutline
            },
            frontRight: {
              example: AssetEnum.carFrontRight,
              overlay: AssetEnum.carFrontRightOutline
            }
          },
          header: {
            title: "report.imageOverview.title",
            description: "report.imageOverview.description",
            image: AssetEnum.overview,
            debug: false
          },

          instruction: {
            title: "report.imageOverview.title",
            text: "report.imageOverview.instructionText",
            pictures: [AssetEnum.damageOverviewFrontInstruction, AssetEnum.damageOverviewBehindInstruction],
            example: AssetEnum.damageInstruction,
            display: true
          }
        };
    }
  }

  get getOverviewFrontLeft() {
    return ReportModule.overviewsFrontLeft;
  }

  get getOverviewFrontRight() {
    return ReportModule.overviewsFrontRight;
  }

  get getOverviewRearLeft() {
    return ReportModule.overviewsRearLeft;
  }

  get getOverviewRearRight() {
    return ReportModule.overviewsRearRight;
  }

  /**
   * @inheritdoc
   */
  get isDone(): boolean {
    if (this.getOverviewFrontLeft.length <= 0) {
      return false;
    }

    if (this.getOverviewFrontRight.length <= 0) {
      return false;
    }

    if (this.getOverviewRearLeft.length <= 0) {
      return false;
    }

    if (this.getOverviewRearRight.length <= 0) {
      return false;
    }

    return true;
  }
}
