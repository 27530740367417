

































































import { IForm } from "@/lib/interfaces/form.interface";
import { Component } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import DateTimePicker from "../utility/DateTimePicker.vue";
import PartnerReportCardGeneral from "./PartnerReportCardGeneral.vue";

@Component({
  components: {
    Card,
    DateTimePicker
  }
})
export default class PartnerReportCardRegistrationAddress extends PartnerReportCardGeneral {
  showRemove = false;

  get isRegistrationAddress() {
    const registrationResults = this.reportDto.registrationResults;
    if (!registrationResults) {
      return false;
    }

    if (
      undefined === registrationResults.street &&
      undefined === registrationResults.zipCode &&
      undefined === registrationResults.city
    ) {
      return false;
    }

    return true;
  }

  add() {
    this.reportDto = {
      ...this.reportDto,
      registrationResults: {
        ...(this.reportDto.registrationResults ?? {}),
        street: "",
        zipCode: "",
        city: ""
      }
    };
    this.showRemove = true;
    this.key++;

    this.$nextTick(() => {
      (this.$refs.form as IForm)?.validate();
    });
  }

  remove() {
    this.reportDto = {
      ...this.reportDto,
      registrationResults: {
        ...(this.reportDto.registrationResults ?? {}),
        street: undefined,
        zipCode: undefined,
        city: undefined
      }
    };
    this.showRemove = false;
    this.key++;
  }
}
