import { IDownloadFile } from "@/components/utility/interface/download-file.interface";
import { IBaseImage } from "@/models/caseEntity";

export function mapBaseImageToDownloadFile(
  imageItemList: IDownloadFile[],
  baseImages: IBaseImage[],
  title: string
): IDownloadFile[] {
  for (const image of baseImages) {
    if (image.url && image.name) {
      const item = {
        url: image.url,
        filename: (title || image.type) + "_" + image.name
      };
      imageItemList.push(item);
    }
  }

  return imageItemList;
}
