












import PartnerDetailAttributeWithToolTip from "@/components/partner/PartnerDetailAttributeWithToolTip.vue";
import { Component, Prop } from "vue-property-decorator";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";

@Component({
  components: {
    PartnerDetailAttributeWithToolTip
  }
})
export default class PartnerReportDetailInfoMessageCard extends DarkModeHighlightMixin {
  @Prop()
  message!: string;
}
