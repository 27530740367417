import { MrfiktivHttpClientProvider } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import {
  MrfiktivCreateHandoverDtoGen,
  MrfiktivHandoverControllerFindAllParamsGen,
  MrfiktivUpdateHandoverDtoGen
} from "../v1/data-contracts";
import { Handover } from "../v1/Handover";

/**
 * Communicate with handover endpoints
 */
class HandoverService {
  mrfiktivProxy: Handover;

  /**
   * Construct service
   */
  constructor(mrfiktivHttpClientProvider: MrfiktivHttpClientProvider) {
    this.mrfiktivProxy = new Handover(mrfiktivHttpClientProvider.client());
  }

  async create(partnerId: string, data: MrfiktivCreateHandoverDtoGen) {
    return (await this.mrfiktivProxy.handoverControllerCreate(partnerId, data)).data;
  }

  async findAll(params: MrfiktivHandoverControllerFindAllParamsGen) {
    return (await this.mrfiktivProxy.handoverControllerFindAll(params)).data;
  }

  async findOne(partnerId: string, handoverId: string) {
    return (await this.mrfiktivProxy.handoverControllerFindOne(partnerId, handoverId)).data;
  }

  async update(partnerId: string, handoverId: string, data: MrfiktivUpdateHandoverDtoGen) {
    return (await this.mrfiktivProxy.handoverControllerUpdate(partnerId, handoverId, data)).data;
  }

  async remove(partnerId: string, handoverId: string) {
    return (await this.mrfiktivProxy.handoverControllerRemove(partnerId, handoverId)).data;
  }
}

/**
 * Communicate with handover endpoints
 */
export default new HandoverService(new MrfiktivHttpClientProvider());
