















































































































import { Component } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import PartnerReportCardGeneral from "./PartnerReportCardGeneral.vue";
import { VehicleTypes } from "@/store/enum/vehicle-types.enum";
import { ThgRegistration } from "@/store/models/thg/registration";
import { IForm } from "@/lib/interfaces/form.interface";

@Component({
  components: {
    Card
  }
})
export default class PartnerReportCardRegistrationVehicle extends PartnerReportCardGeneral {
  showRemove = false;

  get driveType() {
    return ThgRegistration.DRIVE_TYPE;
  }

  get vehicleTypesOptions() {
    return Object.values(VehicleTypes);
  }

  get isRegistrationVehicleInfo() {
    const registrationResults = this.reportDto.registrationResults;
    if (!registrationResults) {
      return false;
    }

    if (
      undefined === registrationResults.driveTyp &&
      undefined === registrationResults.identificationnumber &&
      undefined === registrationResults.manufacturerName &&
      undefined === registrationResults.manufacturerNameCode &&
      undefined === registrationResults.manufacturerType &&
      undefined === registrationResults.manufacturerTypeCode &&
      undefined === registrationResults.vehicleClass
    ) {
      return false;
    }

    return true;
  }

  add() {
    this.reportDto = {
      ...this.reportDto,
      registrationResults: {
        ...(this.reportDto.registrationResults ?? {}),
        driveTyp: "",
        identificationnumber: "",
        manufacturerName: "",
        manufacturerNameCode: "",
        manufacturerType: "",
        manufacturerTypeCode: "",
        vehicleClass: ""
      }
    };
    this.showRemove = true;
    this.key++;

    this.$nextTick(() => {
      (this.$refs.form as IForm)?.validate();
    });
  }

  remove() {
    this.reportDto = {
      ...this.reportDto,
      registrationResults: {
        ...(this.reportDto.registrationResults ?? {}),
        driveTyp: undefined,
        identificationnumber: undefined,
        manufacturerName: undefined,
        manufacturerNameCode: undefined,
        manufacturerType: undefined,
        manufacturerTypeCode: undefined,
        vehicleClass: undefined
      }
    };
    this.showRemove = false;
    this.key++;
  }
}
