var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('side-card', {
    attrs: {
      "width": '600px',
      "absolute": true,
      "title": _vm.i18n['title'],
      "subtitle": _vm.$t('views.report.my.MySignatureView.subtitle', {
        date: _vm.creationDate
      }),
      "loading": _vm.loading
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('debug', {
    attrs: {
      "debug": _vm.signature
    }
  }), _c('debug', {
    attrs: {
      "debug": _vm.documents
    }
  }), _vm.signature ? _c('v-simple-table', [_c('tbody', _vm._l(_vm.items, function (item, idx) {
    return _c('tr', {
      key: idx
    }, [_c('td', [_vm._v(_vm._s(_vm.$t(item.text)))]), _c('td', [_vm._v(_vm._s(item.value))])]);
  }), 0)]) : _vm._e(), _c('v-card-actions', {
    staticClass: "pl-4 pr-4 pt-4"
  }, [_c('v-btn', {
    staticClass: "mr-2",
    attrs: {
      "small": "",
      "outlined": "",
      "elevation": "0",
      "loading": _vm.loadingDownload
    },
    on: {
      "click": _vm.download
    }
  }, [_vm._v(" " + _vm._s(_vm.i18n["download"]) + " ")]), _c('tooltip', {
    attrs: {
      "text": _vm.isSignable ? _vm.i18n['signable'] : _vm.i18n['notSignable']
    }
  }, [_c('v-btn', {
    attrs: {
      "small": "",
      "color": _vm.isSigned ? 'primary' : 'success',
      "elevation": "0",
      "disabled": !_vm.isSignable
    },
    on: {
      "click": _vm.update
    }
  }, [_vm._v(" " + _vm._s(_vm.isSigned ? _vm.i18n["view"] : _vm.i18n["sign"]) + " ")])], 1)], 1), _c('debug', _vm._l(_vm.documents, function (pdf, i) {
    return _c('div', {
      key: i
    }, [pdf ? _c('iframe', {
      attrs: {
        "title": "pdf",
        "src": pdf.url,
        "width": "100%",
        "height": "400px"
      }
    }) : _vm._e()]);
  }), 0)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }