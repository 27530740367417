import { MrfiktivAdminMessageViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import adminMessageService from "@/services/shared/adminMessageService";
import { ThgAdminMessageViewModelGen } from "@/services/thg/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({
  dynamic: true,
  namespaced: true,
  name: "adminMessage",
  store
})
export class AdminMessageStore extends VuexModule {
  private _message: MrfiktivAdminMessageViewModelGen | ThgAdminMessageViewModelGen | undefined = undefined;

  get message() {
    return this._message;
  }

  @Mutation
  private _mutateMessage(data: MrfiktivAdminMessageViewModelGen | ThgAdminMessageViewModelGen | undefined) {
    this._message = data;
  }

  @Action
  async getMessage(
    messageId: string
  ): Promise<MrfiktivAdminMessageViewModelGen | MrfiktivAdminMessageViewModelGen | undefined> {
    const message = await adminMessageService.getMessage(messageId);

    this.context.commit("_mutateMessage", message);

    return message;
  }
}

export const AdminMessageModule = getModule(AdminMessageStore);
