









































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component, Prop } from "vue-property-decorator";

@Component({
  components: {}
})
export default class DateTimePicker extends DarkModeHighlightMixin {
  @Prop()
  date!: Date;

  @Prop({ default: false })
  hideTime!: boolean;

  get day() {
    return (this.date.getDate() + 1).toString().padStart(2, "0");
  }
  set day(d: string) {
    this.date.setDate(Number(d));
    this.$emit("update:date", this.date);
  }
  get month() {
    return (this.date.getMonth() + 1).toString().padStart(2, "0");
  }
  set month(m: string) {
    this.date.setMonth(Number(m) - 1);
    this.$emit("update:date", this.date);
  }
  get year() {
    return this.date.getFullYear().toString();
  }
  set year(m: string) {
    this.date.setFullYear(Number(m));
    this.$emit("update:date", this.date);
  }
  get hour() {
    return this.date
      .getHours()
      .toString()
      .padStart(2, "0");
  }
  set hour(m: string) {
    this.date.setHours(Number(m));
    this.$emit("update:date", this.date);
  }
  get minute() {
    return this.date
      .getMinutes()
      .toString()
      .padStart(2, "0");
  }
  set minute(m: string) {
    this.date.setMinutes(Number(m));
    this.$emit("update:date", this.date);
  }

  get days() {
    return this.fromTo(1, 31);
  }
  get months() {
    return this.fromTo(1, 12);
  }
  get years() {
    return this.fromTo(2022, 2025);
  }
  get minutes() {
    return this.fromTo(0, 59);
  }
  get hours() {
    return this.fromTo(0, 23);
  }

  fromTo(from: number, to: number): string[] {
    const items: string[] = [];
    for (let i = from; i <= to; i++) {
      items.push(i.toString().padStart(2, "0"));
    }
    return items;
  }
}
