import { AppContextEnum } from "@/lib/enum/appContext.enum";
import { MrfiktivHttpClientProvider } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import { MrfiktivAdminMessageViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ConfigModule } from "@/store/modules/config";
import { Message as MrfiktivMessage } from "../mrfiktiv/v1/Message";
import { ThgHttpClientProvider } from "../thg/thg-http-client.provider";
import { ThgAdminMessageViewModelGen } from "../thg/v1/data-contracts";
import { Message as ThgPartnerMessage } from "../thg/v1/Message";

/**
 * Communicates with the message endpoints for admins
 */
class AdminMessageService {
  /**
   * The proxy.
   */
  mrfiktivProxy: MrfiktivMessage;

  /**
   * The proxy.
   */
  thgProxy: ThgPartnerMessage;

  /**
   * @class Initialize AccountService
   */
  constructor(mrfiktivHttpClientProvider: MrfiktivHttpClientProvider, thgHttpClientProvider: ThgHttpClientProvider) {
    this.mrfiktivProxy = new MrfiktivMessage(mrfiktivHttpClientProvider.client());
    this.thgProxy = new ThgPartnerMessage(thgHttpClientProvider.client());
  }

  async getMessage(id: string): Promise<MrfiktivAdminMessageViewModelGen | ThgAdminMessageViewModelGen | undefined> {
    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL) {
      return (await this.mrfiktivProxy.adminMessageControllerFindOneById(id)).data;
    }

    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL) {
      return (await this.thgProxy.adminMessageControllerFindOneById(id)).data;
    }
  }
}

export default new AdminMessageService(new MrfiktivHttpClientProvider(), new ThgHttpClientProvider());
