





































import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { MrfiktivPartnerViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { Component, Prop, Vue } from "vue-property-decorator";
import { ISharedContent } from "@/models/shared-content.entity";
import { $t } from "@/lib/utility/t";
import { IShortUser } from "@/models/short-user.entity";

@Component({
  components: {}
})
export default class SharedContentAccessDescription extends Vue {
  @Prop()
  sharedContent!: ISharedContent;

  @Prop()
  user!: IShortUser;

  @Prop()
  partner!: MrfiktivPartnerViewModelGen;

  get validByText() {
    if (this.sharedContent.validBy) {
      return $t("views.PartnerSharedReport.validBy", { date: simpleDoubleDigitDate(this.sharedContent.validBy) });
    }
    return $t("views.PartnerSharedReport.validIndefinitely");
  }
}
