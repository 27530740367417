

















import { Component, Prop, Vue } from "vue-property-decorator";
import { MrfiktivExportReportMetaViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import Card from "../utility/Card.vue";
import { ExportReportMetaTypeMap, ExportReportMetaTypeEnum } from "@/lib/enum/export-report-meta-type.enum";

@Component({
  components: {
    Card
  }
})
export default class PartnerReportDetailMetaCard extends Vue {
  @Prop({})
  meta!: MrfiktivExportReportMetaViewModelGen;

  get title() {
    return ExportReportMetaTypeMap.get(this.meta.exportReportMetaType as ExportReportMetaTypeEnum) + " Export";
  }
}
