














import { activityLogName } from "@/lib/utility/activityLogName";
import { simpleDate } from "@/lib/utility/date-helper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { MrfiktivActivityLogViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { Component, Prop } from "vue-property-decorator";
import TimelineCard from "../utility/TimelineItem.vue";
import ActivityTimeLineItemDelete from "./ActivityTimeLineItemDelete.vue";

@Component({
  components: {
    TimelineCard,
    ActivityTimeLineItemDelete
  }
})
export default class ActivityTimeLineItemReportSummaryCreated extends DarkModeHighlightMixin {
  @Prop({})
  activityLogEntry!: MrfiktivActivityLogViewModelGen;

  get content() {
    return this.activityLogEntry.comment;
  }

  get name() {
    return activityLogName(this.activityLogEntry);
  }

  get date() {
    return simpleDate(this.activityLogEntry.timestamp.created);
  }
}
