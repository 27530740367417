
import { ReportModule } from "@/store/modules/report.store";
import ReportSuccess from "../ReportSuccess.vue";

export default class RepareoSuccess extends ReportSuccess {
  title = "report.successRepareo.title";

  get description() {
    return String(
      this.$t("report.successRepareo.description", {
        partner: "<a href='" + this.partner?.settings?.websites[0].link + "'>" + "Werkstattmeister" + "</a>"
      })
    );
  }

  get partner() {
    return ReportModule.partner;
  }
}
