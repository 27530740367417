/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */
import partnerLinkerFinderServiceService from "@/services/mrfiktiv/services/partnerLinkerFinderServiceService";
import {
  MrfiktivPartnerLinkFinderControllerFindByGeocodeParamsGen,
  MrfiktivPartnerWithDistanceViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, Module, Mutation, VuexModule, getModule } from "vuex-module-decorators";

/**
 * Store for finding partner links
 */
@Module({
  dynamic: true,
  namespaced: true,
  name: "partner-finder",
  store
})
export class PartnerFinderStore extends VuexModule {
  _partners: MrfiktivPartnerWithDistanceViewModelGen[] = [];

  get partners() {
    return this._partners;
  }

  /**
   * Finds partners by string
   */
  @Action
  async findPartners(query: MrfiktivPartnerLinkFinderControllerFindByGeocodeParamsGen) {
    const res = await partnerLinkerFinderServiceService.find(query);

    this.context.commit("_mutatePartners", res);

    return res;
  }

  /**
   * Mutates the vuex property
   */
  @Mutation
  private _mutatePartners(partners: MrfiktivPartnerWithDistanceViewModelGen[]) {
    this._partners = partners;
  }
}

export const PartnerFinderModule = getModule(PartnerFinderStore);
