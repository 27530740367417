import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { ISharedContent, SharedContent } from "@/models/shared-content.entity";

export const SharedContentDataAccessLayer = new (class extends AbstractLocalDataAccessLayer<ISharedContent> {
  getIdentifier(entity: ISharedContent): string {
    return entity.id;
  }

  protected mapEntity(entity: ISharedContent): ISharedContent {
    return new SharedContent(entity);
  }
})();
