/**
 * Changes the opacity of a given color
 *
 * @param {String} color The hex color code (with or without # prefix).
 * @param {numer} opacity
 * @example let lessOpacity = changeOpacityOfColor("#F06D06", 0.7)
 */

import { MrfiktivActivityLogViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import VueI18n from "@/plugins/I18nPlugin";

/**
 *
 * returns the name of the user who generates the activity
 *
 * @param activityLogEntry
 * @returns
 */
export function activityLogName(activityLogEntry: MrfiktivActivityLogViewModelGen) {
  const firstName = (activityLogEntry.user?.firstName || "") as string;
  const lastName = (activityLogEntry.user?.lastName || "") as string;

  if (firstName || lastName) {
    return firstName + " " + lastName;
  }

  return activityLogEntry.user?.userName || VueI18n.t("timeLine.ActivityTimeLineItemDocument.systemgenerated");
}
