


















import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import { IFormableClass } from "@/lib/formable";
import { CreateDto } from "@/lib/utility/data/create-dto.interface";
import { Component, Prop, Vue } from "vue-property-decorator";
import CreateForm from "./CreateForm.vue";

@Component({
  components: {
    ConfirmActionDialog,
    CreateForm
  },
  filters: {}
})
export default class CreateDialog<T> extends Vue {
  @Prop()
  value!: boolean;

  @Prop()
  dto!: { new (): CreateDto<T> } & IFormableClass;

  model = new this.dto();

  get config() {
    return this.dto.formables;
  }

  get isDialogActive() {
    return this.value;
  }

  set isDialogActive(value: boolean) {
    this.$emit("input", value);
  }
}
