import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { ISnapshot, Snapshot } from "@/models/snapshot.entity";

export const HandoverSnapshotDataAccessLayer = new (class extends AbstractLocalDataAccessLayer<ISnapshot> {
  protected mapEntity(entity: ISnapshot): ISnapshot {
    return new Snapshot(entity);
  }

  getIdentifier(entity: ISnapshot): string {
    return entity.id;
  }
})();
