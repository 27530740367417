












































































































import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { AssetEnum } from "@/lib/AssetRepository";
import { ReportRoleEnum } from "@/lib/enum/ReportRole.enum";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { emailRule, phoneRule } from "@/lib/rules/contactRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import { deepCopy } from "@/lib/utility/deep-copy";
import { IImageUploaded } from "@/models/Image/IImageUploaded";
import { ReportImageType } from "@/models/Report/ReportImageType";
import { MrfiktivCreateReportWittnesDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ReportModule } from "@/store/modules/report.store";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IReportInstructionData } from "@/lib/interfaces/Report/IReportInstructionData";
import ReportImageUpload from "@/views/report/ReportImageUpload.vue";
import NumberplateForm from "../utility/NumberplateForm.vue";
import { numberplateRule } from "@/lib/rules/numberplateRule";
import InsuranceForm from "./InsuranceForm.vue";

@Component({
  components: {
    LayoutReportStep,
    ReportImageUpload,
    NumberplateForm,
    InsuranceForm
  }
})
export default class WitnessesForm extends Vue {
  @Prop({ default: false })
  numberplate!: boolean;

  valid = false;
  hasInsurance = false;

  /**
   * Feature flag
   */
  isInsuranceDisplayed = false;

  @Watch("valid", { deep: true })
  emitValid() {
    this.$emit("valid", this.valid);
  }

  @Watch("hasInsurance")
  toggleInsurance() {
    if (!this.hasInsurance) {
      this.witness.insurances?.splice(0);
    } else if (this.witness.insurances) {
      this.witness.insurances.push({ name: "", number: "" });
    }
  }

  get isDone() {
    return this.valid;
  }

  assetFront = AssetEnum.driversLicenseExampleFrontOutlined;
  exampleFront = AssetEnum.driversLicenseExampleFront;

  instruction: IReportInstructionData = {
    title: "",
    text: "",
    pictures: [AssetEnum.registration],
    example: AssetEnum.registration,
    display: false
  };

  witness: MrfiktivCreateReportWittnesDtoGen = {
    firstName: "",
    lastName: "",
    contact: {
      email: "",
      phone: ""
    },
    imageIds: [],
    role: "" as ReportRoleEnum,
    isInjured: false,
    note: "",
    numberplate: "",
    insurances: []
  };

  ReportRoleEnum = ReportRoleEnum;

  get witnessRolesItems() {
    return Object.values(ReportRoleEnum);
  }

  get typeFront() {
    return ReportImageType.driverslicensefront;
  }

  get witnessList() {
    return ReportModule.wittnessList;
  }

  set witnessList(list: MrfiktivCreateReportWittnesDtoGen[]) {
    ReportModule.setWitnessList(list);
  }

  get numberplateRules() {
    const rules = [];

    rules.push(numberplateRule());

    return rules;
  }

  get emailRules() {
    const rules = [];

    rules.push(requiredRule());
    rules.push(emailRule());

    return rules;
  }

  get nameRule() {
    const rules = [];
    rules.push(requiredRule());

    return rules;
  }

  get noteRule() {
    const rules = [];
    rules.push(requiredRule());

    return rules;
  }

  get phoneNumberRules() {
    const rules = [];

    rules.push(requiredRule());
    rules.push(phoneRule());

    return rules;
  }

  get roleRules() {
    const rules = [];

    rules.push(requiredRule());

    return rules;
  }

  get color() {
    return getDefaultPartnerColor();
  }

  addWittness() {
    this.witnessList.push(deepCopy(this.witness));
  }

  removeWittness(index: number) {
    this.witnessList.splice(index, 1);
  }

  onImageUpload(image: IImageUploaded, index: number) {
    this.witnessList[index].imageIds.push(image.uploadId);
  }

  onImageDeleted(images: IImageUploaded[], index: number) {
    const removed = images.map(i => i.uploadId);
    const filtered = this.witnessList[index].imageIds.filter((i: string) => {
      return !removed.includes(i);
    });

    this.witnessList[index].imageIds.splice(0);
    this.witnessList[index].imageIds.push(...filtered);
  }
}
