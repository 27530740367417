var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mx-auto mt-4 mb-4",
    attrs: {
      "outlined": "",
      "loading": _vm.loading
    }
  }, [_vm.header ? _c('v-list-item', {
    staticClass: "grow"
  }, [_vm.user ? _c('v-list-item-avatar', {
    attrs: {
      "color": "grey darken-3"
    }
  }, [_vm.user.firstName && _vm.user.lastName ? _c('v-avatar', {
    staticClass: "white--text",
    attrs: {
      "data-test-user-menu": "",
      "color": "primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.user.firstName[0] || "") + _vm._s(_vm.user.lastName[0] || "") + " ")]) : _c('v-avatar', {
    staticClass: "white--text",
    attrs: {
      "size": "45px",
      "color": "primary"
    }
  }, [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-cog-outline")])], 1)], 1) : _vm._e(), _c('v-list-item-content', [_c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "center"
    }
  }, [_c('div', {
    staticStyle: {
      "flex-grow": "1"
    }
  }, [_c('v-card-subtitle', {
    staticClass: "ma-0 pa-0"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")])], 1), _c('div', [_c('div', {
    staticClass: "d-print-none",
    staticStyle: {
      "display": "flex",
      "justify-items": "end",
      "align-items": "center"
    }
  }, [_vm._t("menu")], 2)])])])], 1) : _vm._e(), _vm.header && _vm.divider ? _c('v-divider') : _vm._e(), _vm._t("default"), _vm.$slots.actions ? _c('v-divider') : _vm._e(), _vm.$slots.actions ? _c('v-card-actions', [_vm._t("actions")], 2) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }