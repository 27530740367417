/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ThgCreateFaqDtoGen,
  ThgExceptionViewmodelGen,
  ThgFaqControllerFindAllParamsGen,
  ThgFaqViewModelGen,
  ThgPageViewModelGen,
  ThgUpdateFaqDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class FrequentlyAskedQuestionsService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Frequently Asked Questions Service
   * @name FaqControllerCreate
   * @summary Create a FAQ for a partner
   * @request POST:/partner/{partnerId}/faq
   * @secure
   * @response `200` `ThgFaqViewModelGen` The created FAQ
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `404` `ThgExceptionViewmodelGen` NotFound.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  faqControllerCreate = (partnerId: string, data: ThgCreateFaqDtoGen, params: RequestParams = {}) =>
    this.http.request<ThgFaqViewModelGen, ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/faq`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Frequently Asked Questions Service
   * @name FaqControllerFindAll
   * @summary Get all FAQs from a partner
   * @request GET:/partner/{partnerId}/faq
   * @response `200` `(ThgPageViewModelGen & { data?: (ThgFaqViewModelGen)[] })`
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `404` `ThgExceptionViewmodelGen` NotFound.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  faqControllerFindAll = ({ partnerId, ...query }: ThgFaqControllerFindAllParamsGen, params: RequestParams = {}) =>
    this.http.request<ThgPageViewModelGen & { data?: ThgFaqViewModelGen[] }, ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/faq`,
      method: "GET",
      query: query,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Frequently Asked Questions Service
   * @name FaqControllerFindOne
   * @summary Get a FAQ from a partner
   * @request GET:/partner/{partnerId}/faq/{id}
   * @response `200` `ThgFaqViewModelGen` The FAQ
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `404` `ThgExceptionViewmodelGen` NotFound.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  faqControllerFindOne = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<ThgFaqViewModelGen, ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/faq/${id}`,
      method: "GET",
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Frequently Asked Questions Service
   * @name FaqControllerUpdate
   * @summary Update a FAQ entry from a partner
   * @request PATCH:/partner/{partnerId}/faq/{id}
   * @secure
   * @response `200` `ThgFaqViewModelGen` The FAQ
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `404` `ThgExceptionViewmodelGen` NotFound.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  faqControllerUpdate = (partnerId: string, id: string, data: ThgUpdateFaqDtoGen, params: RequestParams = {}) =>
    this.http.request<ThgFaqViewModelGen, ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/faq/${id}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Frequently Asked Questions Service
   * @name FaqControllerRemove
   * @summary Delete a FAQ from a partner
   * @request DELETE:/partner/{partnerId}/faq/{id}
   * @secure
   * @response `200` `ThgFaqViewModelGen` The deleted FAQ
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `404` `ThgExceptionViewmodelGen` NotFound.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  faqControllerRemove = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<ThgFaqViewModelGen, ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/faq/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
}
