/**
 * Changes the opacity of a given color
 *
 * @param {String} color The hex color code (with or without # prefix).
 * @param {numer} opacity
 * @example let lessOpacity = changeOpacityOfColor("#F06D06", 0.7)
 */

export function changeOpacityOfColor(color: string, opacity: number) {
  // if hex is 8 digits (so alpha value is already given, delete the last 2 digits)
  if (color.length == 9) color = color.slice(0, -2);

  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + ("0" + _opacity.toString(16)).slice(-2).toUpperCase();
}
