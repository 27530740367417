
































import { MrfiktivCreateInsuranceDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { requiredRule } from "@/lib/rules/requiredRule";

export enum InsuranceTypeEnum {
  /**
   * Personal and third party liability coverage aka Haftpflicht
   */
  MOTORISTS_THIRD_PARTY_LIABILITY_COVERAGE = "MOTORISTS_THIRD_PARTY_LIABILITY_COVERAGE",

  /**
   * fully comprehensive motor vehicle insurance (Vollkaskoversicherung) PROVIDES coverage for accidents that are the policyholder’s own fault, and for damage as a result of vandalism.
   */
  FULLY_COMPREHENSIVE_COVERAGE = "FULLY_COMPREHENSIVE_COVERAGE",

  /**
   * Partially comprehensive motor vehicle insurance (Teilkaskoversicherung) provides cover against theft, hailstorm damage and broken glass.
   */
  PARTIALLY_COMPREHENSIVE_COVERAGE = "PARTIALLY_COMPREHENSIVE_COVERAGE",

  /**
   * For example towing, delivery of replacement parts, but not repair costs), accident (for example rental car or hotel costs) or illness (return transport).
   * e.g. ADAC
   */
  ROADSIDE_ASSISTANCE_COVERAGE = "ROADSIDE_ASSISTANCE_COVERAGE",

  /**
   * Verkehrsrechtsschutzversicherung
   */
  MOTORISTS_LEGAL_PROTECTION = "MOTORISTS_LEGAL_PROTECTION",

  /**
   * Felgenversicherung
   */
  WHEEL_INSURANCE = "WHEEL_INSURANCE",

  /**
   * Others
   */
  OTHER = "OTHER"
}

@Component({
  components: {}
})
export default class InsuranceForm extends Vue {
  valid = false;

  @Prop({ default: true })
  required!: boolean;

  @Prop()
  type?: InsuranceTypeEnum;

  @Prop({ default: Object.values(InsuranceTypeEnum) })
  types!: InsuranceTypeEnum[];

  @Prop()
  value!: MrfiktivCreateInsuranceDtoGen;
  insurance = this.value ?? {
    name: "",
    number: "",
    type: this.type ?? InsuranceTypeEnum.MOTORISTS_THIRD_PARTY_LIABILITY_COVERAGE
  };

  @Watch("valid", { deep: true })
  emitValid() {
    this.$emit("valid", this.valid);
  }

  @Watch("insurance", { deep: true })
  emitUpdated() {
    this.$emit("input", this.insurance);
  }

  get isDone() {
    return this.valid;
  }

  get requiredRule() {
    if (!this.required) {
      return [];
    }

    return [requiredRule()];
  }
}
