
























import { MrfiktivPartnerViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class SharedContentAccessExpired extends Vue {
  @Prop()
  partner!: MrfiktivPartnerViewModelGen;
}
