import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { IHandover, Handover } from "@/models/handover.entity";

export const HandoverDataAccessLayer = new (class extends AbstractLocalDataAccessLayer<IHandover> {
  getIdentifier(entity: IHandover): string {
    return entity.id;
  }

  mapEntity(entity: IHandover): IHandover {
    return new Handover(entity);
  }
})();
