



































































import { IRefSuggestion, IRefSuggestionTyped } from "@/store/modules/fleet-aggregation.store";
import { MrfiktivReportViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { Component, Prop, Vue } from "vue-property-decorator";
import VirtualList from "vue-virtual-scroll-list";
import draggable from "vuedraggable";
import RefsList from "../utility/RefsList.vue";
import Tooltip from "./tooltip.vue";
import AEicon from "./AEicon.vue";
import Debug from "./Debug.vue";
import { IEventUIDto } from "@/lib/dto/event/event-ui.dto";
import { ITicket } from "@/models/ticket.entity";
import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import { RefTypeMap } from "@/store/modules/refs.store";
import { debounce } from "debounce";
import { handleError } from "@/lib/utility/handleError";

@Component({
  components: { RefsList, draggable, VirtualList, Tooltip, AEicon, Debug },
  filters: {}
})
export default class RefsSelectionSuggestion extends Vue {
  @Prop()
  suggested!: IRefSuggestion[];

  @Prop()
  selected!: MrfiktivReferenceGen[];

  @Prop()
  loading!: boolean;

  @Prop()
  partnerId!: string;

  readonly RefTypeMap = RefTypeMap;

  searchFilter = "";

  isLoading = false;

  debounceSetSearchFilter = debounce((v: string) => {
    this.searchFilter = v;
  }, 300);

  isSelected(refId: string) {
    return this.selected.some(s => s.refId === refId);
  }

  matchesFilter(ref: Record<any, any>) {
    return (
      !this.searchFilter ||
      JSON.stringify(ref)
        .toLowerCase()
        .includes(this.searchFilter.toLowerCase())
    );
  }

  get suggestedSorted() {
    const overDueTickets: IRefSuggestionTyped<ITicket>[] = [];
    const notDueTickets: IRefSuggestionTyped<ITicket>[] = [];
    const neverDueTickets: IRefSuggestionTyped<ITicket>[] = [];

    const overDueEvents: IRefSuggestionTyped<IEventUIDto>[] = [];
    const notDueEvents: IRefSuggestionTyped<IEventUIDto>[] = [];

    const report: IRefSuggestionTyped<MrfiktivReportViewModelGen>[] = [];

    const rest: IRefSuggestion[] = [];

    const date = new Date();
    for (const sugg of this.suggested) {
      if (this.isSelected(sugg.id) || !this.matchesFilter(sugg)) continue;

      const cat = sugg.refType;
      if (cat === BackendResourceEnum.TICKET) {
        const suggTicket = sugg as IRefSuggestionTyped<ITicket>;
        const isOverdue = suggTicket.ref.due && new Date(suggTicket.ref.due) < date;
        if (isOverdue) {
          overDueTickets.push(suggTicket);
        } else if (suggTicket.ref.due) {
          notDueTickets.push(suggTicket);
        } else {
          neverDueTickets.push(suggTicket);
        }
      } else if (cat === BackendResourceEnum.EVENT) {
        const suggEvent = sugg as IRefSuggestionTyped<IEventUIDto>;

        const isOverdue = !suggEvent.ref.ack && suggEvent.ref.start < date.getTime();
        if (isOverdue) {
          overDueEvents.push(suggEvent);
        } else {
          notDueEvents.push(suggEvent);
        }
      } else if (cat === BackendResourceEnum.VEHICLE) {
        const suggReport = sugg as IRefSuggestionTyped<MrfiktivReportViewModelGen>;
        report.push(suggReport);
      } else if (cat === BackendResourceEnum.REPORT) {
        const vehicleReport = sugg as IRefSuggestionTyped<MrfiktivReportViewModelGen>;
        report.push(vehicleReport);
      } else {
        rest.push(sugg);
      }
    }

    return [
      ...overDueTickets,
      ...overDueEvents,
      ...notDueTickets,
      ...neverDueTickets,
      ...notDueEvents,
      ...report,
      ...rest
    ];
  }

  async select(item: IRefSuggestion) {
    if (item.refType === BackendResourceEnum.EVENT) {
      const event = item.ref as IEventUIDto;
      if (event.isVirtual) {
        this.isLoading = true;
        await event.createVirtual().catch(handleError);
        this.isLoading = false;
        item.ref = event;
      }
    }

    this.$emit("select", item);
  }
}
