













































import TableWrapper from "@/components/utility/TableWrapper.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { ProgressStatusEnum } from "@/store/enum/partner/progress.status.enum";
import { DamageMeModule } from "@/store/modules/damage-me.store";
import { Component, Vue } from "vue-property-decorator";
import { handleError } from "@/lib/utility/handleError";
import { MrfiktivReportViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import MyDamageDetailSideCard from "@/components/report/user/MyDamageDetailSideCard.vue";
import { ReportModule } from "@/store/modules/report.store";
import LayoutReportWithBanner from "@/layouts/LayoutReportWithBanner.vue";
import Notifications from "@/components/thg/Notifications.vue";

// A map of states and colors
export const REPORT_COLOR_BY_STATUS = new Map([
  [ProgressStatusEnum.NEW, "rgb(224,224,224)"],
  [ProgressStatusEnum.IN_PROGRESS, "#F9E79F"],
  [ProgressStatusEnum.FINISHED, "#9EE09E"],
  [ProgressStatusEnum.DELETED, "grey"]
]);

@Component({
  components: {
    LayoutReportWithBanner,
    Notifications,
    TableWrapper,
    MyDamageDetailSideCard
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class MyDamageView extends Vue {
  loading = false;
  selectedId: string | null = null;

  get partnerId() {
    return ReportModule.partner.id;
  }

  get items() {
    return DamageMeModule.all.filter(s => s.partnerId === this.partnerId || s?.forwarded?.by === this.partnerId);
  }

  get colorByStatus() {
    return REPORT_COLOR_BY_STATUS;
  }

  get i18n() {
    return this.$t("views.report.my.MyDamageView");
  }

  get headers() {
    return [
      {
        text: this.i18n["created"],
        align: "left",
        width: 120,
        value: "timestamp.created"
      },
      {
        text: this.i18n["progressStatus"],
        align: "left",
        width: 150,
        value: "progressStatus"
      },
      {
        text: this.i18n["numberplate"],
        align: "left",
        width: 150,
        value: "numberplate"
      },
      {
        text: this.i18n["message"],
        align: "left",
        value: "message"
      },

      { text: "", value: "controls", align: "right", sortable: false }
    ];
  }

  async mounted() {
    await this.load();
  }

  async load() {
    this.loading = true;
    try {
      await DamageMeModule.getAll();
    } catch (error) {
      handleError(error);
    } finally {
      this.loading = false;
    }
  }

  async openDetail(item: MrfiktivReportViewModelGen) {
    this.$log.debug(item);
    this.loading = true;

    try {
      const report = await DamageMeModule.getOne({ id: item._id });
      this.selectedId = report._id;
    } catch (error) {
      handleError(error);
    } finally {
      this.loading = false;
    }
  }
}
